import React, {useEffect, useRef, useState} from "react";
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {DateInput, DatePicker, DateRangePicker} from "@progress/kendo-react-dateinputs";
import {Button} from "@progress/kendo-react-buttons";
import {Checkbox, RadioGroup} from "@progress/kendo-react-inputs";
import {Label} from "@progress/kendo-react-labels";
import Loader from "../Loader";
import {useFormik} from "formik";
import {
    semcastAxios,
    showErrorToastMessage,
    showSuccessToastMessage
} from "../../../Utils/Common";
import {
    ACTIVATION_ID_PLACE_HOLDER,
    FTP_SETTINGS,
    FTP_STRING,
    IDENTITY_REPORT_ACTIVATION_API,
    IDENTITY_REPORT_TRACKING_PIXEL_API,
    SEGMENT_ID_PLACE_HOLDER,
    S3_STRING,
    SUCCESS_STATUS, TRACKING_PIXELS_BY_USER, SEGMENTS_ACTIVATION_QUEUE_DATA
} from "../../../api/constants";
import { getFTPSettings } from "../../../actions/OnBoardingActions";
import "./Identity.css";
import LoadOverlay from "../SharedComponents/LoadOverlay";
import {Tooltip} from "@progress/kendo-react-tooltip";

const Identity = (props) => {
    const [visible, setVisible] = useState(props.visible);
    const [isLoaded, setIsLoaded] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState(null);
    const [ftpSettingsId, setFtpSettingsId] = useState(0);
    const [allSavedSettings, setAllSavedSettings] = useState([]);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [defaultStartDate, setDefaultStartDate] = useState(
        (props.Identity.profileFormik ?
            new Date(props.Identity.profileFormik.values.selectedSegments[0].createdAt) : null)
    );
    const reportFromData = [
        {
            label: "Campaign Start",
            value: "start",
        },
        {
            label: "Last Report",
            value: "last",
        },
    ];
    const [createNewSegment, setCreateNewSegment] = useState(false);
    const [createSegmentVisible, setCreateSegmentVisible] = useState("hidden");
    const CREATE_NEW_SEGMENT_INFO_MESSAGE = "Only available if either Consumer or Business is selected.";
    const IDX_TAG_MESSAGE = "Select Summary under Summarization to enable this dropdown.";
    const [currentConnection, setCurrentConnection] = useState(
        {text: '', id: 0}
    );
    const [overlayActive, setOverlayActive] = useState(true);
    const [reportFromVal, setReportFromVal] = useState("");
    const [currentScope, setCurrentScope] = useState({text: "All", id: "A"});
    const defaultTagObj = {
        "id": 0,
        "available_return_properties": null,
        "ftp_settings_name": "",
        "group_name": "",
        "resource_type": "WORK",
        "resource_group_id": 0,
        "return_properties_list": [],
        "user_id": 0,
        "ftp_settings_id": 0,
        "created_at": 0,
        "updated_at": 0,
        "deleted_at": null,
        "group_id": 0,
        "match_return_properties": {
            "Contact Details": {},
            "Business Information": {},
            "Basic Demographics": {},
            "Home Description": {},
            "Digital Identifiers": {},
            "Retail Store Visits": {}
        },
        "timestamp_detail": true,
        "label": "No Tag Selected",
        "segment_naming_type": "URL",
        "status": "ACTIVE"
    };
    const dayList = [0,1,2,3,4,5,6,7];
    const [dayOption, setDayOption] = useState(dayList[0]);
    const [currentSummarization, setCurrentSummarization] = useState({text: "Summary", id: "M"});
    const [selectedTag, setSelectedTag] = useState(defaultTagObj)
    const [idxTagList, setIdxTagList] = useState([]);
    const [activationQueueData, setActivationQueueData] = useState({});
    const segment1ObjectType = props.Identity.profileFormik?.values?.selectedSegments[0]?.objectType;
    const isToolboxLogged = sessionStorage.getItem("isToolboxLogged");
    // current connection (default / chosen) details
    // const [connection, setConnection] = useState(
    //     {
    //         id: 0,
    //         connectionName: '',
    //         protocol: 'ftps',
    //         host: '',
    //         port: '',
    //         destinationDir: '',
    //         delimiter: '',
    //         transferMode: 'Passive',
    //         username: '',
    //         password: '',
    //     }
    // );
    console.log(props)
    const getIdxByUser = async() => {
        try {
            let response = await semcastAxios.get(TRACKING_PIXELS_BY_USER, {withCredentials: true})
                .then((res) => {
                    console.log(res.data)
                    if (res.data.code === 200) {
                        if(res.data.data.length > 0) {
                            setIdxTagList(res.data.data);
                            setSelectedTag({label: "No Tag Selected", id: 0});
                            setOverlayActive(false);
                        }else{
                            setIdxTagList(res.data.data);
                            setSelectedTag({label: "No Tag Selected", id: 0});
                            setOverlayActive(false);
                        }

                    } else {
                        showErrorToastMessage(`Error occurred: Error loading Idx Tags`, 10);
                    }

                }).catch((error) => {
                    if (error.response) {
                        showErrorToastMessage("Response Error: " + error.response.data.data, 10);
                        console.log(error.response);
                    }
                    if (error.request) {
                        showErrorToastMessage("Request Error: " + error.request.data.data, 10);
                        console.log(error.request);
                    }
                });

            console.log("createNewIdxTag response:", response);
        } catch (e) {
            console.log(e);
            showErrorToastMessage(`Error occurred: ` + e);
        }
    }
    const getSettings = async() => {
        try {
            const settings = await getFTPSettings();
            if (settings && settings.data.length) {
                setAllSavedSettings(settings.data);
                const setting = settings.data[settings.data.length - 1];
                if (setting) {
                    setCurrentConnection({
                        ...currentConnection,
                        ['text']: setting.connection_name,
                        ['id']: setting.id,
                        ['protocol']: setting.transfer_protocol
                    });
                } else {
                    setCurrentConnection({text: '', id: 0});
                }
                // setConnection(setting);
                setFtpSettingsId(setting.id);
            }
        } catch {
            showErrorToastMessage("Could not access Connection information.");
        } finally {
            setIsLoaded(true);
        }
    }
    const getActivationQueueData = async() => {
        const activationID = props.segId;
        try {
            let response = await semcastAxios.get(SEGMENTS_ACTIVATION_QUEUE_DATA.replace(SEGMENT_ID_PLACE_HOLDER, activationID), {withCredentials: true})
                .then((res) => {
                    if (res.status === 200) {
                        console.log(res.data)

                        let queueData = res.data
                        setActivationQueueData(queueData);
                        //pre-populate all fields
                        if(queueData.reportFrom){
                            setReportFromVal(queueData.reportFrom)
                        }
                        if(typeof queueData.reportEvery === 'number'&& !isNaN(queueData.reportEvery) && Number.isFinite(queueData.reportEvery)){
                            setDayOption(queueData.reportEvery)
                            if(queueData.reportEvery === 0){
                                setReportFromVal("")
                            }
                        }
                        if(queueData.summarizationType){
                            const tempSummarizationType = summarizations.filter(item => item.id === queueData.summarizationType)[0];
                            setCurrentSummarization(tempSummarizationType);
                        }
                        if(queueData.reportScope){
                            const tempScopeType = scopes.filter(item => item.id === queueData.reportScope)[0];
                            setCurrentScope(tempScopeType);
                        }
                    } else {
                        showErrorToastMessage(`Error occurred: Error loading activation queue data`, 10);
                    }

                }).catch((error) => {
                    if (error.response) {
                        showErrorToastMessage("Response Error: " + error.response.data.data, 10);
                        console.log(error.response);
                    }
                    if (error.request) {
                        showErrorToastMessage("Request Error: " + error.request.data.data, 10);
                        console.log(error.request);
                    }
                });

            console.log("activationQueue response:", response);
        } catch (e) {
            console.log(e);
            showErrorToastMessage(`Error occurred: ` + e);
        }
    }
    useEffect(() => {
        getIdxByUser();
        getSettings();
        if(props.Identity.profileFormik.values.selectedSegments[0].objectType === "Activation"){
            getActivationQueueData();
        }
    }, []);


    useEffect(() => {
        //pre-populate selected tag
        if(activationQueueData.idxTagId && currentSummarization.id === "M"){
            const tempTag = idxTagList.filter(tag => tag.id === activationQueueData.idxTagId)[0];
            setSelectedTag(tempTag);
        }
        if(currentSummarization.id === "DM"){
            setSelectedTag(defaultTagObj);
        }
        if(allSavedSettings.length > 0 && activationQueueData){
           const tempConnection = allSavedSettings.filter(item => item.id === activationQueueData.ftpSettingsId)[0];
           if(tempConnection){
               setCurrentConnection({text: tempConnection.connection_name, id: tempConnection.id});
           }
        }
    }, [activationQueueData, idxTagList,currentSummarization,allSavedSettings]);

    useEffect(() => {
    }, [props.segName]);

    useEffect(() => {
        if (props.Identity.profileFormik.values &&
            props.Identity.profileFormik.values.selectedSegments[0] &&
            props.Identity.profileFormik.values.selectedSegments[0].createdAt) {
            const createdAtDate = new Date(props.Identity.profileFormik.values.selectedSegments[0].createdAt);
            const formattedDate =
                createdAtDate.toLocaleString("default", {year: "numeric"}) + "-" +
                createdAtDate.toLocaleString("default", {month: "2-digit"})  + "-" +
                createdAtDate.toLocaleString("default", {day: "2-digit"});
            setStartDate(formattedDate);
        }
    }, [])

    useEffect(()  => {
        if (startDate !== "" && currentConnection.id > 0) {
            setDisableSubmit(false);
        }
    }, [startDate, currentConnection.id, currentScope]);

    useEffect(() => {
        if (currentScope.id === 'A') {
            setCreateNewSegment(false);
            setCreateSegmentVisible("hidden");
        } else if (currentScope.id === 'C' || currentScope.id === 'B') {{
            setCreateNewSegment(true);
            setCreateSegmentVisible("visible");
        }};
    }, [currentScope]);

    const CustomStartDateInput = (props) => {
        return (
            <>
                <DateInput {...props} format="yyyy-MM-dd" placeholder="YYYY-MM-DD"
                           label={<span className={"required-asterisk"}>Start Date</span>}/>
            </>
        )
    };

    const CustomEndDateInput = (props) => {
        return (
            <>
                <DateInput {...props} format="yyyy-MM-dd" placeholder="YYYY-MM-DD" label={"End Date"} />
            </>
        )
    };

    const onDatesChange = (e) => {
        if (e.value.start) {
            const formattedDate =
                e.value.start.toLocaleString("default", {year: "numeric"}) + "-" +
                e.value.start.toLocaleString("default", {month: "2-digit"})  + "-" +
                e.value.start.toLocaleString("default", {day: "2-digit"});
            setStartDate(formattedDate);
        } else {
            setDisableSubmit(true);
        }
        if (e.value.end) {
            const formattedDate =
                e.value.end.toLocaleString("default", {year: "numeric"}) + "-" +
                e.value.end.toLocaleString("default", {month: "2-digit"})  + "-" +
                e.value.end.toLocaleString("default", {day: "2-digit"});
            setEndDate(formattedDate);
        } else {
            setEndDate(null);
        }
    };

    const connections = allSavedSettings?.map((item, i) => {
        if (allSavedSettings.length > 0) {
            return (
                {text: item.connection_name, id: item.id, protocol: item.transfer_protocol}
            )
        } else {
            return (
                {text: "No Saved Connections", id: 0}
            )
        }
    });

    const scopes = [
        {text: "All", id: "A"},
        {text: "Consumer", id: "C"},
        {text: "Business", id: "B"}
    ];
    const summarizations = [
        {text: "Details", id: "DM"},
        {text: "Summary", id: "M"},
    ];

    const handleConnectionChange = (e) => {
        console.log(e.value);
        const setting = allSavedSettings.filter(ftpSetting => ftpSetting.id === e.value.id);
        // setConnection(setting[0]);
        setCurrentConnection({
            ...currentConnection,
            ['text']: e.value.text,
            ['id']: e.value.id
        });
        setFtpSettingsId(e.value.id);
    };

    const handleScopeChange = (e) => {
        console.log(e.value);
        if(e.value.id === "A"){
            setCreateNewSegment(false);
        }
        setCurrentScope({
            ...currentScope,
            ['text']: e.value.text,
            ['id']: e.value.id
        });
    };
    const handleSummarizationChange = (e) => {
        console.log(e.value);
        setCurrentSummarization({
            ...currentSummarization,
            ['text']: e.value.text,
            ['id']: e.value.id
        });
    };

    const handleIdxTagChange = (e) => {
        console.log(e.value);
        setSelectedTag({
            ...selectedTag,
            ['label']: e.value.label,
            ['id']: e.value.id
        });
    };

    const handleCreateNewSegmentChange = (e) => {
        setCreateNewSegment(!createNewSegment);
    }
    const testConnection = async (payload, protocolType) => {
        try {
            let response = await semcastAxios.post(
                FTP_SETTINGS + "/test-connection",
                payload,
                {
                    withCredentials: true,
                }
            );
            if (response.data.status === SUCCESS_STATUS) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    }

    const identityReport = async () => {
        setDisableSubmit(true);
        try {
            const payload = {
                start_date: startDate,
                end_date: endDate,
                ftpsettings_id: currentConnection.id, //FD#11983
                scope: currentScope.id,
                create_segment: createNewSegment,
                summarization: currentSummarization.id,
                idxtag_id: selectedTag.id,
                report_from: reportFromVal,
                repeatEvery:dayOption
            };
            console.log(payload)
            let url = "";
            if (segment1ObjectType === "Site-Visitor-Tag") url = IDENTITY_REPORT_TRACKING_PIXEL_API.replace(SEGMENT_ID_PLACE_HOLDER, props.segId);
            if (segment1ObjectType  === "Activation") url = IDENTITY_REPORT_ACTIVATION_API.replace(ACTIVATION_ID_PLACE_HOLDER, props.segId);
            let response = await semcastAxios.post(url,
                payload,
                {withCredentials: true})
                .then(async (res) => {
                    // console.log("identity report res:", res);
                    if (res.data.status === SUCCESS_STATUS) {
                        showSuccessToastMessage("SpotLight Report Request Sent Successfully.");
                        // FD: 10863 (reversal of 10839)
                        props.reloadAfterIdentity();
                        setVisible(false);
                    }
                    ;
                });
        } catch (error) {
            showErrorToastMessage(error.response.data.data);
            props.reloadAfterIdentity();
        } finally {
            setVisible(false);
            setDisableSubmit(false);
        }
    }
    const tagListNoDataRender = (element) => {
        const noData = (
            <div onMouseDown={(e) => e.stopPropagation()}>
                <div>You don't have any IDX Tags that have segments yet.</div>
            </div>
        );

        return React.cloneElement(element, {...element.props}, noData);
    };

    const handleReportFromChange = (event) => {
        setReportFromVal(event.value);
    };
    const handleRepeatOptionChange = (e) =>{
        setDayOption(e.target.value);
        if(e.target.value === 0){
            setReportFromVal("")
        }

    }
    return (
        <>
            <div id="identityReport">
                {visible && (
                    <Dialog
                        title={"SpotLight Report - "+props.segName}
                        width={570}
                        height={"60vh"}
                        closeIcon={false}
                        className={"dialog-standard"}
                    >
                        {/*<div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">*/}
                        <div className={"identity-controls-content"}>
                            <div className="form-group">
                                <DateRangePicker
                                    startDateInput={CustomStartDateInput}
                                    endDateInput={CustomEndDateInput}
                                    onChange={onDatesChange}
                                    className={"identity-date-picker"}
                                    defaultValue={{start: defaultStartDate, end: null}}
                                />
                            </div>
                            <div>
                                <Label className={"identity-connection-label required-asterisk"}>Save To
                                    Connection</Label>
                                {isLoaded ? (
                                    <DropDownList
                                        data={connections}
                                        // itemRender={itemRender}
                                        textField="text"
                                        dataItemKey="id"
                                        onChange={(e) => handleConnectionChange(e)}
                                        value={currentConnection}
                                        fillMode={"outline"}
                                        className="identity-connection-settings"
                                        disabled={allSavedSettings.length === 0}
                                        style={{fontSize: "14px", width: "15rem"}}
                                    />
                                ) : (
                                    <Loader height={"100%"}/>
                                )}
                            </div>
                            <div>
                                <Label className={"identity-connection-label required-asterisk"}>Scope</Label>
                                <DropDownList
                                    data={scopes}
                                    textField="text"
                                    dataItemKey="id"
                                    onChange={(e) => handleScopeChange(e)}
                                    value={currentScope}
                                    fillMode={"outline"}
                                    className="identity-scope"
                                    style={{fontSize: "14px"}}
                                />
                            </div>
                            <div>
                                <Label className={"identity-connection-label required-asterisk"}>Summarization</Label>
                                <DropDownList
                                    data={summarizations}
                                    textField="text"
                                    dataItemKey="id"
                                    onChange={(e) => handleSummarizationChange(e)}
                                    value={currentSummarization}
                                    fillMode={"outline"}
                                    className="identity-summarization"
                                    style={{fontSize: "14px"}}
                                />
                            </div>
                            {isToolboxLogged &&props.Identity.profileFormik.values.selectedSegments[0].objectType === "Activation" &&<div>
                                <div className={"spotlight-report-tooltip-wrapper"}>
                                <Label className={"identity-connection-label required-asterisk"} style={{width:"auto", marginRight:"5px"}}>Idx Tag Conversions</Label>
                                <Tooltip
                                    position="right"
                                    anchorElement="target"
                                    className={"spotlight-tooltip"}
                                    tooltipStyle={{
                                        width: "15rem",
                                        borderRadius: "0.25rem",
                                    }}>
                                    <i className="fa fa-question-circle-o tooltip-question-icon"
                                       aria-hidden="true"
                                       title={"Only tag with child segment(s) is available. Select \"Summary\" under Summarization to enable this dropdown. You can submit No Tag Selected if no tag is available."}></i>
                                </Tooltip>
                                </div>
                               <div>
                                    <LoadOverlay active={overlayActive} width={40} height={40}
                                                 wrapperStyle={{width: "30px"}}>
                                        <DropDownList
                                            data={idxTagList}
                                            textField="label"
                                            dataItemKey="id"
                                            disabled={currentSummarization.id !== "M"}
                                            onChange={(e) => handleIdxTagChange(e)}
                                            value={selectedTag}
                                            fillMode={"outline"}
                                            className="identity-idxtag"
                                            style={{fontSize: "14px"}}
                                            listNoDataRender={tagListNoDataRender}
                                        />
                                    </LoadOverlay>
                                    <Label
                                        className={"hint-standard identity-create-segment-info-message"}>{IDX_TAG_MESSAGE}</Label>
                                </div>
                            </div>}
                            <br/>
                            <div className={"spotlight-automation-outer-wrapper"}>
                                <div className={"spotlight-automation-tooltip-wrapper"}>
                            <Label
                                className={"identity-automate-header"}>Automating Spotlight Report</Label>
                                <Tooltip
                                    position="right"
                                    anchorElement="target"
                                    className={"spotlight-tooltip"}
                                    tooltipStyle={{
                                        width: "15rem",
                                        borderRadius: "0.25rem",
                                    }}>
                                    <i className="fa fa-question-circle-o tooltip-question-icon"
                                       aria-hidden="true"
                                       title={"Previous schedule will be pre-populated. Changes to the schedule will overwrite the previous setup."}></i>
                                </Tooltip>
                                </div>
                            <div className={"identity-repeat-action-label"}>
                                <Label className={"required-asterisk"}>Report every <DropDownList
                                    className={"day-dropdown"}
                                    defaultItem={dayList[0]}
                                    value={dayOption}
                                    onChange={(e) => handleRepeatOptionChange(e)}
                                    data={dayList}/> day(s)</Label>
                                {dayOption === 0 &&
                                    <Label className={"hint-standard"}>(Will not be repeated)</Label>}
                            </div>
                            <div className={"identity-report-from-wrapper"}>
                                <Label className={"identity-report-from required-asterisk"}>Report From</Label>
                                <RadioGroup
                                    data={reportFromData}
                                    value={reportFromVal}
                                    onChange={handleReportFromChange}
                                    className="identity-report-from"
                                    disabled={dayOption === 0}
                                />
                            </div>
                            </div>
                            <br/>
                            <div style={{visibility: createSegmentVisible}}>
                                <Label className={"identity-create-segment-label"}>Create New Segment:</Label>
                                <Checkbox
                                    size="large"
                                    checked={createNewSegment}
                                    onChange={handleCreateNewSegmentChange}
                                />
                                <br/>
                                <Label
                                    className={"hint-standard identity-create-segment-info-message"}>{CREATE_NEW_SEGMENT_INFO_MESSAGE}</Label>
                            </div>

                        </div>
                        <DialogActionsBar layout={"end"}>
                            <Button
                                // className="dialog-cancel"
                                className={"button-standard button-white"}
                                onClick={() => {
                                    props.cancelSubscriber(undefined)
                                }}>
                                Cancel
                            </Button>
                            <Button
                                disabled={disableSubmit}
                                className={"button-standard button-submit"}
                                onClick={identityReport}>
                                Submit
                            </Button>
                        </DialogActionsBar>
                    </Dialog>)}
            </div>
        </>
    );
}

export default Identity;
