import React, {useEffect, useState} from "react";
import "./NotificationAgent.css"
import pic1 from "../images/profile/headsetIcon.svg";
import {semcastAxios} from "../../Utils/Common";
import {SUCCESS_STATUS} from "../../api/constants";
import {loadNotificationsOutstanding} from "../../actions/NotificationActions";
import {NavLink} from "react-router-dom";
import { Tooltip } from "@progress/kendo-react-tooltip";
import EventsEmitter from "../../Utils/EventsEmitter";

const NotificationAgent = (props) => {

    const [notifications, setNotifications] = useState(0);

    const initNotificationCount = async () => {
        let cnt = 0;
        await Promise.allSettled([loadNotificationsOutstanding()]).then(r => {
            for (let i = 0; i < r[0]?.value?.length; i++) {
                if (r[0].value[i].manual_task_required) cnt++;
            }
            setNotifications(cnt);
        });
    }

    useEffect(() => {

    }, [notifications]);


    useEffect(() => {
        document.body.classList.add("body_background");
        EventsEmitter.on("UpdatedNotificationsEvent", function updateNotifications(allNotifications) {
            if (allNotifications.length > 0) {
                const outstandingNotificationCount = allNotifications.filter(notification => notification.manual_task_required === true && notification.manual_task_completed === false).length;
                setNotifications(outstandingNotificationCount);
            } else {
                initNotificationCount();
            }
        });
        initNotificationCount();
    }, []);

    return (
        <>
            {notifications > 0 && (
                <>
                    <NavLink to={"/admin-settings"} className={"nav-link-to-admin"}
                             onClick={() => sessionStorage.getItem("isToolboxLogged") ? sessionStorage.setItem('adminTabSelected', '5') : sessionStorage.setItem('adminTabSelected', '4')}>
                    <Tooltip><span
                        title={`You have
                        ${notifications} outstanding notifications.  Click on this icon to manage your outstanding notifications.`}
                        className="badge rounded-pill badge-notification bg-danger notification-icon">{notifications}
                    </span></Tooltip>
                    </NavLink>
                </>
            )}
        </>
    );
};

export default NotificationAgent;