import React, {useEffect, useContext, useState} from "react";
import "./Header.css"
import {useLocation} from "react-router-dom";
import {UserContext} from "../../contexts/UserContext";
import {
    isUserLoggedIn,
    semcastAxios,
    setUserDataToStorage,
} from "../../Utils/Common";
import {ADMIN_EMAILS, SUCCESS_STATUS, USER_INFO_API} from "../../api/constants";
import {loadUserPermissions} from "../../actions/UserActions";
import {hasAtLeastOneInAnyGroup, PERMISSIONS_NEEDED_TO_VIEW_PERMISSIONS_PAGE} from "../../helpers/UsersHelpers";
import NotificationAgent from "./NotificationAgent";
import {loadFounderAccountsData} from "../../actions/AccountActions";

const Header = ({menuToggle, setMenuToggle, drawerExpanded, setDrawerExpanded}) => {
    const [hamburgerToggle, setHamburgerToggle] = useState(true);

    const searchParams = new URLSearchParams(document.location.search);
    const segName = searchParams.get('name');

    const {user, setUser} = useContext(UserContext);
    useEffect(() => {
        document.body.classList.add("body_background");
        if (isUserLoggedIn()) {
            let dataToAdd = {};
            async function fetchUserInfo() {
                const response = await loadUserInfo();
                if (response?.data?.status === SUCCESS_STATUS) {
                    dataToAdd.id = response.data.data.id;
                    dataToAdd.email = response.data.data.email;
                    dataToAdd.firstName = response.data.data.first_name;
                    dataToAdd.lastName = response.data.data.last_name;
                    dataToAdd.phone = response.data.data.phone;
                    dataToAdd.company = response.data.data.company;
                    dataToAdd.defaultGroupId = response.data.data.default_group_id;

                    // extra business logic fields
                    // LOGIC to show/hide Admin Settings side menu
                    //dataToAdd.isAdmin = ADMIN_EMAILS.includes(response.data.data.email)
                    dataToAdd.isAdmin = response.data.data.isAdmin;

                    dataToAdd = {...dataToAdd, ...response.data.data}
                    //FD:13512
                    // await fetchAccountForUser(response.data.data.id);
                    assignDataToContext(dataToAdd)
                    setUserDataToStorage(dataToAdd);
                }
            }
            // LOGIC to show/ hide permissions side menu item
            async function fetchUserPermissions() {
                const permissionsObj = await loadUserPermissions();
                //console.log("HeaderPermissionsObj=", permissionsObj);
                dataToAdd['canViewPermissionsPage'] = hasAtLeastOneInAnyGroup(PERMISSIONS_NEEDED_TO_VIEW_PERMISSIONS_PAGE, permissionsObj)
                assignDataToContext(dataToAdd)
            }
            //FD:13512
            // async function fetchAccountForUser(id) {
            //     const accountObj = await loadFounderAccountsData(id);
            //     dataToAdd['canViewToolBox'] = accountObj.ads_type === 3 ? true:false;
            // }

            fetchUserInfo();
            fetchUserPermissions();
        }
    }, []);

    const handleClick = () => {
        setMenuToggle(!menuToggle);
        setHamburgerToggle(!hamburgerToggle);
        setDrawerExpanded(!drawerExpanded);
    };
    const loadUserInfo = async () => {
        try {
            let response = await semcastAxios.get(USER_INFO_API, {
                withCredentials: true,
            });
            return response
        } catch (e) {
            console.log(e)
        }
    };
    const assignDataToContext = (dataToAdd) => {
        const mergedData = {...user, ...dataToAdd}
        setUser(mergedData)
    }

    let segmentName = "";
    const pathname = useLocation().pathname;
    let active = pathname;
    if (active.slice(0, 22) === "/segmentation/activate")
        active = "/segmentation/activate";
    if (active.slice(0, 38) === "/identitytoolbox/segmentation/activate")
        active = "/identitytoolbox/segmentation/activate";
    if (active.slice(0, 22) === "/audience-design-edit/") {
        const parts = active.split("/");
        segmentName = parts[3];
        active = "/audience-design-edit/";
    }
    if (active.slice(0, 38) === "/identitytoolbox/audience-design-edit/") {
        const parts = active.split("/");
        segmentName = parts[3];
        active = "/identitytoolbox/audience-design-edit/";
    }
    const current = {
        //Added Toolbox items
        "/segmentation-dashboard": "Dashboard",
        "/identitytoolbox/segmentation-dashboard": "Dashboard",
        "/attribution": "Attribution",
        "/identitytoolbox/attribution": "Attribution",
        "/user-search": "User Search",
        "/identitytoolbox/user-search": "User Search",
        "/account-pricing": "Account Pricing",
        "/identitytoolbox/account-pricing": "Account Pricing",
        "/account-details": "Account Details",
        "/identitytoolbox/account-details": "Account Details",
        "/create-account": "Create Account",
        "/identitytoolbox/create-account": "Create Account",
        "/app-status": "App Status",
        "/identitytoolbox/app-status": "App Status",
        "/job-statuses": "Job Status",
        "/identitytoolbox/job-statuses": "Job Status",
        "/billing": "Billing",
        "/identitytoolbox/billing": "Billing",
        "/onboarding": "Onboarding",
        "/identitytoolbox/onboarding": "Onboarding",
        "/audience-design": "Audience Design",
        "/identitytoolbox/audience-design": "Audience Design",
        "/audience-design-edit": `Editing ${segName}`,
        "/identitytoolbox/audience-design-edit": `Editing ${segName}`,
        "/data-enhancement": "Enhancement",
        "/identitytoolbox/data-enhancement": "Enhancement",
        "/activate-media": "Activate Media",
        "/identitytoolbox/activate-media": "Activate Media",
        "/segmentation-activate": "Activation",
        "/identitytoolbox/segmentation-activate": "Activation",
        "/activate-form": "Activation Form",
        "/identitytoolbox/activate-form": "Activation Form",
        "/permission": "Permissions",
        "/identitytoolbox/permission": "Permissions",
        "/user-profile": "User Profile",
        "/identitytoolbox/user-profile": "User Profile",
        // "/account-details": "Account Details",
        "/accounts-details": "Account Details",
        "/identitytoolbox/accounts-details": "Account Details",
        "/file-transfer-settings": "File Transfer Settings",
        "/identitytoolbox/file-transfer-settings": "File Transfer Settings",
        "/admin-settings": "Admin Settings",
        "/identitytoolbox/admin-settings": "Admin Settings",
        "/contact-us": "Contact Us",
        "/identitytoolbox/contact-us": "Contact Us",
        "/segmentation/activate": "Activate Segment",
        "/identitytoolbox/segmentation/activate": "Activate Segment",
        "/dashboard": "View Consumer Profile",
        "/identitytoolbox/dashboard": "View Consumer Profile",
        "/business-dashboard": "View Business Profile",
        "/identitytoolbox/business-dashboard": "View Business Profile",
        "/help-center": "Help Center",
        "/identitytoolbox/help-center": "Help Center",
    };
    console.log(user)
    return (
        <>
            <div id={"header"} className="header headerLandingPage"
                 style={drawerExpanded ? {paddingLeft: "18rem"} : {paddingLeft: "5rem"}}>
                <div className="header-content">
                    <nav className="navbar navbar-expand">
                        <div className="collapse navbar-collapse justify-content-between">
                            <div className="header-left">
                                <ul>
                                    <li className="nav-item dropdown notification_dropdown">
                                        <a
                                            className="nav-link"
                                            href="javascript:void(0);"
                                            onClick={handleClick}
                                        >
                                            <div className="nav-control">
                                                <div
                                                    className={
                                                        "hamburger " + (hamburgerToggle ? "is-active" : "")
                                                    }
                                                >
                                                    <span className="line"/>
                                                    <span className="line"/>
                                                    <span className="line"/>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                                <div className="custom-page-title">
                                    <h3>{current[active]}</h3>
                                </div>


                            </div>
                            <div className="header-right">

                                <NotificationAgent/>

                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <ul className="navbar-nav">
                                    <li className="nav-item dropdown header-profile">
                                        {/*<a*/}
                                        {/*    className="nav-link"*/}
                                        {/*    href="#"*/}
                                        {/*    role="button"*/}
                                        {/*    data-toggle="dropdown"*/}
                                        {/*>*/}
                                        <div className="header-info" style={{marginRight: "1rem"}}>

                                                <span style={{
                                                    fontFamily: "Helvetica, Arial, 'Roboto', sans-serif",
                                                    fontSize: "16px",
                                                    color: "gray"
                                                }}>
                                                  Hello, <strong>{user.firstName}</strong>
                                                </span>
                                        </div>
                                        {/*<img src={pic1} width={20} style={{height: "50px", pointerEvents: "none", userSelect: "none"}} alt/>*/}
                                        {/*</a>*/}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>

        </>
    );
};

export default Header;