import React, {useEffect, useRef, useState} from "react";
import {
    handleCheckboxChange,
    handleTrendingCheckboxChange,
    handleDropDownChange,
    handleDropDownChangeSemcastingXandr,
    handlePinterestAccessClick,
    handleGrantAccessClick,
    priceFormat,
    handleDatePickerEndDateChange,
    handleDatePickerStartDateChange, handleDragNDropValue,
} from "../Utils/Common";

import {ComboBox, DropDownList} from "@progress/kendo-react-dropdowns";
import "./ActivateSegmentFormHelpers.css"
import { DatePicker } from "@progress/kendo-react-dateinputs";
import {
    ListBox,
    ListBoxToolbar,
    processListBoxData,
    processListBoxDragAndDrop,
} from "@progress/kendo-react-listbox";
import moment from "moment";

import {Button} from "@progress/kendo-react-buttons";
import {NavLink} from "react-router-dom";
import twoArrowsIcon from "../Admin/Components/Segmentation/temporaryresources/images/two-arrows.png";
import angleLeftRightIcon from "../Admin/Components/Segmentation/temporaryresources/images/arrow-left-right.svg";
import {processTreeViewItems, TreeView} from "@progress/kendo-react-treeview";
import {
    TreeList,
    orderBy,
    filterBy,
    mapTree,
    extendDataItem,
    TreeListTextFilter,
    getItemPath,
} from "@progress/kendo-react-treelist";

import {filterBy as filterByData} from "@progress/kendo-data-query";
import {TextArea} from "@progress/kendo-react-inputs";
import {Hint} from "@progress/kendo-react-labels";

export const preFormikAssign =  (companyData, segmentData, aqData) => {
    // console.log("preFormikAssign aqData=", aqData);
    try {
        let formik = {...companyData.formik};
        if (companyData?.company === "roku")
            formik.initialValues["segment_name"] = segmentData?.segment_name;
        //Activation Measurement Tile rules
        if (companyData?.company === "semcastingmeasurement" && segmentData.segment_type === "CONSUMER" && segmentData.party === 3) {
            formik.initialValues["location_properties"] = ["semcasting_id"];
        }else{
            formik.initialValues["location_properties"] = [];
        }
        //Get tag id dropdown data to be pre-populated into formik
        // if(companyData?.company !== 'semcasting' && companyData?.company !== 'semcastingmeasurement'){
        //     // semcastAxios.get(ACTIVATION_CREATIVE_TAGs.replace(USER_ID_PLACE_HOLDER, currentUserId), {withCredentials: true}).then((r)=> {
        //     semcastAxios.get(ACTIVATION_CREATIVE_TAGs.replace(PLATFORM_NAME_PLACE_HOLDER, companyData?.company), {withCredentials: true}).then((r)=> {
        //         // console.log(r)
        //     formik.initialValues["creative_tags"] = r.data.creativeTags;
        //    });
        // }
        if (aqData?.credentials_data) {
            formik.initialValues["upload"] = "upload_direct";
        } else {
            formik.initialValues["upload"] = "upload_semcasting";
        }
        return formik;
    } catch (e) {
        console.log("Exception in pre populating values for form");
    }
};

const LocationItem1 = props => {
    let {
        dataItem,
        selected,
        ...others
    } = props;
    return (

        <li name={props.dataItem.value} style={{backgroundColor:props.dataItem.backgroundColor}}{...others} >
            <div disabled={props.dataItem.disabled}>
                <span>{props.dataItem.labelText}</span>
            </div>
        </li>
    );
};
const LocationItem2 = props => {
    let {
        dataItem,
        selected,
        ...others
    } = props;
    return (
        <li name={props.dataItem.value} style={{backgroundColor: props.dataItem.backgroundColor}}{...others} >
            <div disabled={props.dataItem.disabled}>
                <span style={{color: "blue"}}>{props.dataItem.labelText}</span>
            </div>
        </li>
    );
};

const PlatformItem1 = props => {
    let {
        dataItem,
        selected,
        ...others
    } = props;
    return (
        <li name={props.dataItem.value} style={{backgroundColor: props.dataItem.backgroundColor}}{...others} >
            <div>
                <span>{props.dataItem.labelText}</span>
            </div>
        </li>
    );
};

const PlatformItem2 = props => {
    let {
        dataItem,
        selected,
        ...others
    } = props;
    return (
        <li name={props.dataItem.value} style={{backgroundColor: props.dataItem.backgroundColor}}{...others} >
            <div>
                <span style={{color: "blue"}}>{props.dataItem.labelText}</span>
            </div>
        </li>
    );
};

let currentDraggedItem = {};

const renderListItemBackground = (list) => {
    let itemCnt = 0;
    list.forEach(item => {
        itemCnt++;
        if (itemCnt % 2 !== 0) {
            item.backgroundColor = "#f0f0f0";
        } else {
            delete item.backgroundColor;
        }
    });
};

export const LocationDragDropComponent = (item, formik, activateFormBase) => {
    const [state, setState] = useState({
        list1: item.item.labels,
        list2: [],
        draggedItem: {}
    });

    useEffect(() => {
        //load default business rules on page render
        const isConsumer = item.activateFormBase.segment.segment_type === "CONSUMER";
        const isThirdPartySegment = item.activateFormBase.segment.party === 3;


        if (isConsumer && isThirdPartySegment) {
            setState({...state, list1:item.item.labels.filter(item => item.fieldName !== "consumer_pii" && item.fieldName !== "consumer_demographics" && item.fieldName !== "business_basics" && item.fieldName !== "semcasting_id"),list2: item.item.labels.filter(item => item.fieldName === "semcasting_id")})
        } else if (isConsumer && !isThirdPartySegment) {
            setState({...state, list1:item.item.labels.filter(item => item.fieldName !== "business_basics")});
        } else if (!isConsumer) {
            setState({...state, list1:item.item.labels.filter(item => item.fieldName !== "consumer_pii" && item.fieldName !== "consumer_demographics" && item.fieldName !== "consumer_basics")});
        }
    }, []);

    useEffect(()  => {
        renderListItemBackground(state.list1);
    }, [state.list1]);

    useEffect(() => {
        let location_properties_list = [];
        state.list2.forEach(item => {location_properties_list.push(item.fieldName)})
        item.formik.setFieldValue("location_properties", location_properties_list);
        renderListItemBackground(state.list2);
    }, [state.list2]);

    const handleDragStart = (e) => {
        let locationItem  = e.dataItem;
        locationItem.type = "location";
        currentDraggedItem = locationItem;
        setState({...state, draggedItem: locationItem});
    };

    const handleDrop1 = (e) => {
        if (currentDraggedItem.type !== "location") return;
        let result;

        result = processListBoxDragAndDrop(
            state.list1,
            state.list2,
            state.draggedItem,
            e.dataItem,
            "value"
        );


        setState({
            ...state,
            list1: result.listBoxOneData,
            list2: result.listBoxTwoData,
        });

        let sortedList1 = handleConsumerOptionRules(state.draggedItem)[0].sort((a, b) => {
            if (a.labelText < b.labelText) {
                return -1;
            }
            if (a.labelText > b.labelText) {
                return 1;
            }
            return 0;
        });
        let sortedList2 = handleConsumerOptionRules(state.draggedItem)[1].sort((a, b) => {
            if (a.labelText < b.labelText) {
                return -1;
            }
            if (a.labelText > b.labelText) {
                return 1;
            }
            return 0;
        });
        setState({...state, list1:sortedList1, list2:sortedList2});
    };
    const handleDrop2 = (e) => {
        if (currentDraggedItem.type !== "location") return;
        let result = processListBoxDragAndDrop(
            state.list1,
            state.list2,
            state.draggedItem,
            e.dataItem,
            "value"
        );
        setState({
            ...state,
            list1: result.listBoxOneData,
            list2: result.listBoxTwoData,
        });
        // IF WE WANT TO SORT AFTER DROPPED ITEM
        // let sortedList1 = result.listBoxOneData.sort((a, b) => {
        //     if (a.labelText < b.labelText) {
        //         return -1;
        //     }
        //     if (a.labelText > b.labelText) {
        //         return 1;
        //     }
        //     return 0;
        // });
        // let sortedList2 = result.listBoxTwoData.sort((a, b) => {
        //     if (a.labelText < b.labelText) {
        //         return -1;
        //     }
        //     if (a.labelText > b.labelText) {
        //         return 1;
        //     }
        //     return 0;
        // });
        // setState({
        //     ...state,
        //     list1: sortedList1,
        //     list2: sortedList2,
        // });
    };

    const handleConsumerOptionRules = (dataItem) => {
        const item = state.list1.filter(item => item.fieldName === dataItem.fieldName);
        let updatedList2;
        let updatedList1;
        const consumerBasicsObj = state.list2.filter(item => item.fieldName === "consumer_basics");
        const consumerPiiObj = state.list2.filter(item => item.fieldName === "consumer_pii");
        const consumerDemographicsObj = state.list2.filter(item => item.fieldName === "consumer_demographics");

        if (dataItem.fieldName === 'consumer_pii'){
            updatedList2 = item.concat(state.list2).filter(item => item.fieldName !== "consumer_basics");
            updatedList1 = consumerBasicsObj.concat(state.list1.filter(item => item.fieldName !== dataItem.fieldName));

        } else if (dataItem.fieldName === 'consumer_basics') {
            updatedList2 = item.concat(state.list2).filter(item => item.fieldName !== "consumer_pii" && item.fieldName !== "consumer_demographics");
            updatedList1 = consumerPiiObj.concat(state.list1.filter(item => item.fieldName !== dataItem.fieldName)).concat(consumerDemographicsObj);
        } else if (dataItem.fieldName === 'consumer_demographics'){
            updatedList2 = item.concat(state.list2).filter(item => item.fieldName !== "consumer_basics");
            updatedList1 = consumerDemographicsObj.concat(state.list1.filter(item => item.fieldName !== dataItem.fieldName)).concat(consumerBasicsObj);
        } else {
            updatedList2 = item.concat(state.list2);
            updatedList1 = state.list1.filter(item => item.fieldName !== dataItem.fieldName);
        }
        return [updatedList1, updatedList2];
    }

    const handleClickConsumerOptionRules = (e) => {
        return handleConsumerOptionRules(e.dataItem);
    }
    const handleItemClick1 = (e) => {

        let sortedList1 = handleClickConsumerOptionRules(e)[0].sort((a, b) => {
            if (a.labelText < b.labelText) {
                return -1;
            }
            if (a.labelText > b.labelText) {
                return 1;
            }
            return 0;
        });
        let sortedList2 = handleClickConsumerOptionRules(e)[1].sort((a, b) => {
            if (a.labelText < b.labelText) {
                return -1;
            }
            if (a.labelText > b.labelText) {
                return 1;
            }
            return 0;
        });
        setState({...state, list1:sortedList1, list2:sortedList2});
    }

    const handleItemClick2 = (e) => {
        const item = state.list2.filter(item => item.fieldName === e.dataItem.fieldName);
        let updatedList1 = item.concat(state.list1);
        let updatedList2 = state.list2.filter(item => item.fieldName !== e.dataItem.fieldName);
        let sortedList1 = updatedList1.sort((a, b) => {
            if (a.labelText < b.labelText) {
                return -1;
            }
            if (a.labelText > b.labelText) {
                return 1;
            }
            return 0;
        });
        let sortedList2 = updatedList2.sort((a, b) => {
            if (a.labelText < b.labelText) {
                return -1;
            }
            if (a.labelText > b.labelText) {
                return 1;
            }
            return 0;
        });
        setState({...state, list1:sortedList1, list2:sortedList2});
    }

    return (
        <>
            <div >
                {/*<span>*/}
                <label style={{width: "46%"}}
                       className={` ${item.item.required ? "required-asterisk" : ""} `}
                >
                    {item.item.labelText} <a>{item.item.navLink}</a>
                </label>
                <label style={{width: "46%", paddingLeft:"35px"}}
                       // className={` ${item.item.required ? "required-asterisk" : ""} `}
                >
                    {state.list2.length} Selections <a>{item.item.navLink}</a>
                </label>
                {/*</span>*/}
            </div>
            <div style={{display: "flex", flexDirection: "row"}}>
                <ListBox
                    style={{width: "46%"}}
                    data={state.list1}
                    textField={"labelText"}
                    onDragStart={handleDragStart}
                    onDrop={handleDrop2}
                    onItemClick={handleItemClick1}
                    item={LocationItem1}
                />
                <div style={{alignContent: "center"}}>
                    <img alt="" src={twoArrowsIcon}
                        style={{
                            width: "35px",
                            height: "auto",
                            padding: "0 5px 0 5px"
                        }}
                    />
                </div>
                <ListBox
                    style={{width: "46%"}}
                    data={state.list2}
                    textField={"labelText"}
                    onDragStart={handleDragStart}
                    onDrop={handleDrop1}
                    onItemClick={handleItemClick2}
                    item={LocationItem2}
                />
            </div>
        </>
    );
}

export const PlatformDragDropComponent = (item) => {

    const [state, setState] = useState({
        list1: item.item.labels,
        list2: [],
        draggedItem: {},
    });

    useEffect(()  => {
        renderListItemBackground(state.list1);
    }, [state.list1]);

    useEffect(() => {
    let platform_id_list = [];
        state.list2.forEach(item => {platform_id_list.push(item.fieldName)})
        item.formik.setFieldValue("platform_id_sampling", platform_id_list);
        renderListItemBackground(state.list2);
    }, [state.list2]);
    const handleDragStart = (e) => {
        let platformItem = e.dataItem;
        platformItem.type = "platform";
        currentDraggedItem = platformItem;
        currentDraggedItem = platformItem;
        setState({...state, draggedItem: platformItem});
    };

    const handleDrop = (e) => {
        if (currentDraggedItem.type !== "platform") return;
        let result = processListBoxDragAndDrop(
            state.list1,
            state.list2,
            state.draggedItem,
            e.dataItem,
            "value"
        );

        // IF WE WANT TO SORT AFTER DROPPED ITEM
        // let sortedList1 = result.listBoxOneData.sort((a, b) => {
        //     if (a.labelText < b.labelText) {
        //         return -1;
        //     }
        //     if (a.labelText > b.labelText) {
        //         return 1;
        //     }
        //     return 0;
        // });
        // let sortedList2 = result.listBoxTwoData.sort((a, b) => {
        //     if (a.labelText < b.labelText) {
        //         return -1;
        //     }
        //     if (a.labelText > b.labelText) {
        //         return 1;
        //     }
        //     return 0;
        // });
        // setState({
        //     ...state,
        //     list1: sortedList1,
        //     list2: sortedList2,
        // });
        setState({
            ...state,
            list1: result.listBoxOneData,
            list2: result.listBoxTwoData
        });
    };

    const handleItemClick1 = (e) => {
        const item = state.list1.filter(item => item.fieldName === e.dataItem.fieldName);
        let updatedList2 = item.concat(state.list2);
        let updatedList1 = state.list1.filter(item => item.fieldName !== e.dataItem.fieldName);
        let sortedList1 = updatedList1.sort((a, b) => {
            if (a.labelText < b.labelText) {
                return -1;
            }
            if (a.labelText > b.labelText) {
                return 1;
            }
            return 0;
        });
        let sortedList2 = updatedList2.sort((a, b) => {
            if (a.labelText < b.labelText) {
                return -1;
            }
            if (a.labelText > b.labelText) {
                return 1;
            }
            return 0;
        });
        setState({...state, list1:sortedList1, list2:sortedList2});
    };

    const handleItemClick2 = (e) => {
        const item = state.list2.filter(item => item.fieldName === e.dataItem.fieldName);
        let updatedList1 = item.concat(state.list1);
        let updatedList2 = state.list2.filter(item => item.fieldName !== e.dataItem.fieldName);
        let sortedList1 = updatedList1.sort((a, b) => {
            if (a.labelText < b.labelText) {
                return -1;
            }
            if (a.labelText > b.labelText) {
                return 1;
            }
            return 0;
        });
        let sortedList2 = updatedList2.sort((a, b) => {
            if (a.labelText < b.labelText) {
                return -1;
            }
            if (a.labelText > b.labelText) {
                return 1;
            }
            return 0;
        });
        setState({...state, list1:sortedList1, list2:sortedList2});
    };

    return (
        <>
            <div style={{paddingTop:"1rem"}}>
                <label style={{width: "46%"}}
                       className={` ${item.item.required ? "required-asterisk" : ""} `}>
                    {item.item.labelText} <a>{item.item.navLink}</a>
                </label>
                <label style={{width: "46%", paddingLeft: "35px"}}>
                    {state.list2.length} Selections <a>{item.item.navLink}</a>
                </label>
            </div>
            <div style={{display: "flex", flexDirection: "row"}}>
                <ListBox
                    style={{width: "46%"}}
                    data={state.list1}
                    textField={"labelText"}
                    onDragStart={handleDragStart}
                    onDrop={handleDrop}
                    onItemClick={handleItemClick1}
                    item={PlatformItem1}
                />
                <div style={{alignContent: "center"}}><img alt="" src={twoArrowsIcon}
                                                           style={{
                                                               width: "35px",
                                                               height: "auto",
                                                               padding: "0 5px 0 5px"
                                                           }}/></div>
                <ListBox
                    style={{width: "46%"}}
                    data={state.list2}
                    textField={"labelText"}
                    onDragStart={handleDragStart}
                    onDrop={handleDrop}
                    onItemClick={handleItemClick2}
                    item={PlatformItem2}
                />
            </div>
        </>
    );
}
export const dragDropListboxGroup = (item, formik, activateFormBase) => {

    return (
        <>
            <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="form-group">
                    {/*<label*/}
                    {/*    className={`form-label ${item.required ? "required-asterisk" : ""} `}*/}
                    {/*>*/}
                    {/*    {item.labelText} <a>{item.navLink}</a>*/}
                    {/*</label>*/}
                    <div>

                        {item.fieldName === "location_properties" && (
                            <div>
                                <LocationDragDropComponent item={item} formik={formik} activateFormBase={activateFormBase} />
                            </div>
                        )}
                        {item.fieldName === "platform_id_sampling" && (
                            <div>
                                <PlatformDragDropComponent item={item} formik={formik} activateFormBase={activateFormBase} />
                            </div>
                        )}
                    </div>

                    {formik.touched[item.fieldName] &&
                        (formik.errors[item.fieldName] && (
                            <span className="validation-error">
              {formik.errors[item.fieldName]}
            </span>
                        ))}
                </div>
            </div>
        </>
    );
};

export const checkboxGroup = (item, formik, activateFormBase) => {
    return (
        <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="form-group">
                <label
                    className={`form-label ${item.required ? "required-asterisk" : ""} `}
                >
                    {item.labelText} <a>{item.navLink}</a>
                </label>
                {item?.checkboxes?.length &&
                    item.checkboxes.map((c) => {
                        return (
                            <div className="form-check" key={c.value}>
                                <label
                                    className="form-check-label"
                                    readOnly={activateFormBase?.disabled || c.disabled}
                                    disabled={activateFormBase?.disabled || c.disabled}
                                >
                                    <input
                                        type="checkbox"
                                        className="form-check-input k-checkbox"
                                        readOnly={activateFormBase?.disabled || c.disabled}
                                        disabled={activateFormBase?.disabled || c.disabled}
                                        name={c.value}
                                        value={c.value}
                                        id={c.value}
                                        onBlur={formik.handleBlur}
                                        checked={formik.values[item.fieldName].includes(c.value)}
                                        onChange={(e) =>
                                            handleCheckboxChange(e, item.fieldName, formik, activateFormBase)
                                        }
                                    />
                                    {c.labelText}
                                </label>
                            </div>
                        );
                    }, this)}
                {formik.touched[item.fieldName] &&
                    (formik.errors[item.fieldName] && (
                        <span className="validation-error">
              {formik.errors[item.fieldName]}
            </span>
                    ))}
            </div>
        </div>
    );
};

export const trendingCheckboxGroup = (item, formik, activateFormBase, segmentData) => {
    return (
        <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="form-group">
                <label
                    className={`form-label ${item.required ? "required-asterisk" : ""} `}
                >
                    {item.labelText}
                </label>
                {item?.checkboxes?.length &&
                    item.checkboxes.map((c) => {
                        return (
                            <div className="form-check" key={c.value}>
                                <label
                                    className="form-check-label"
                                    readOnly={activateFormBase?.disabled || c.disabled}
                                    disabled={activateFormBase?.disabled || c.disabled}
                                >
                                    <input
                                        type="checkbox"
                                        className="form-check-input k-checkbox"
                                        readOnly={activateFormBase?.disabled || c.disabled}
                                        disabled={activateFormBase?.disabled || c.disabled}
                                        name={c.value}
                                        value={c.value}
                                        onBlur={formik.handleBlur}
                                        checked={formik.values[item.fieldName].includes(c.value)}
                                        onChange={(e) =>
                                            handleTrendingCheckboxChange(e, item.fieldName, formik, segmentData)
                                        }
                                    />
                                    {c.labelText}
                                </label>
                            </div>
                        );
                    }, this)}
                {formik.touched[item.fieldName] &&
                    (formik.errors[item.fieldName] && (
                        <span className="validation-error">
              {formik.errors[item.fieldName]}
            </span>
                    ))}
            </div>
        </div>
    );
};
export const checkbox = (item, formik, activateFormBase) => {
    let field_name = item.fieldName;
    // console.log("checkbox.formik.values=", activateFormBase);
    // console.log("checkboxItem=", item);
    // console.log("checkbox formik=", formik.values);
    let isDisabled = false;
    if (activateFormBase?.disabled) {
        isDisabled = true;
    } else if (activateFormBase?.disableSaveCredentialsCheckbox && item.fieldName === "save_credentials") {
        isDisabled = true;
    }

    return (
        <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="form-group">
                <div className="form-check form-check-inline">
                    <label
                        className="form-check-label"
                        readOnly={activateFormBase?.disabled}
                        disabled={activateFormBase?.disabled}
                    >
                        <input
                            type="checkbox"
                            className="form-check-input k-checkbox"
                            readOnly={activateFormBase?.disabled}
                            disabled={isDisabled}
                            name={item.fieldName}
                            value={item.value}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            checked={formik?.values[field_name]}
                        />
                        <label
                            className={`form-label ${item.required ? "required-asterisk" : ""}`} dangerouslySetInnerHTML={{
                                __html: item.labelText
                            }}
                        >
                            {/*{item.labelText}*/}
                        </label>

                    </label>
                    {formik.touched[item.fieldName] && formik.errors[item.fieldName] && (
                        <span className="validation-error">
              {formik.errors[item.fieldName]}
            </span>
                    )}
                </div>
            </div>
        </div>
    );
};
export const radioGroup = (item, formik, activateFormBase) => {
if(typeof formik.values[item.fieldName] === 'object'){
    formik.values[item.fieldName] = formik.values[item.fieldName][0]
}
    return (
        <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="form-group">
                <label
                    className={`form-label ${item.required ? "required-asterisk" : ""} `}
                >
                    {item.labelText}
                </label>
                {item?.radioButtons?.length &&
                    item.radioButtons.map((c) => {
                        return (
                            <div
                                className="form-check"
                                readOnly={activateFormBase?.disabled}
                                disabled={activateFormBase?.disabled}
                            >
                                <label className="form-check-label">
                                    <input
                                        type="radio"
                                        className="form-check-input k-radio"
                                        readOnly={activateFormBase?.disabled}
                                        disabled={activateFormBase?.disabled}
                                        name={item.fieldName}
                                        key={c.value}
                                        value={c.value}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        checked={c.value === formik.values[item.fieldName]}
                                    />
                                    {c.labelText}
                                </label>
                            </div>
                        );
                    }, this)}
                {
                    formik.touched[item.fieldName] && formik.errors[item.fieldName] && (
                        <span className="validation-error">
              {formik.errors[item.fieldName]}
            </span>
                    )
                }
            </div>
        </div>
    );
};

export const selectPickerLiveRamp = (item, formik, activateFormBase) => {
    const defaultItem = {text: "Please Select..."};
    return (
        <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">
            <label
                className={`form-label ${item.required ? "required-asterisk" : ""} `}
            >
                {item.labelText}
            </label>
            <div
                className="form-check-dropdown"
                readOnly={activateFormBase?.disabled}
                disabled={activateFormBase?.disabled}
            >
                <label className="form-check-label">
                    <div className="d-flex">
                        <DropDownList
                            style={{
                                width: "260px",
                            }}
                            data={item.options}
                            readOnly={activateFormBase?.disabled}
                            disabled={activateFormBase?.disabled}
                            dataItemKey="val"
                            textField="text"
                            defaultValue={defaultItem}
                            onChange={(e) =>
                                handleDropDownChange(e, item.fieldName, formik)}
                            name={item.fieldName}
                            onBlur={formik.handleBlur}
                            className="dashboard-select-controls k-rounded-md"
                        />
                    </div>
                </label>
            </div>
            {formik.touched[item.fieldName] && formik.errors[item.fieldName] && (
                <span className="validation-error">
          {formik.errors[item.fieldName]}
        </span>
            )}
        </div>
    );
};

export const selectPickerGoogle = (item, formik, activateFormBase) => {
    const defaultItem = {text: "Please Select..."};
    return (
        <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">
            <label
                className={`form-label ${item.required ? "required-asterisk" : ""} `}
            >
                {item.labelText}
            </label>
            <div
                className="form-check-dropdown"
                readOnly={activateFormBase?.disabled}
                disabled={activateFormBase?.disabled}
            >
                <label className="form-check-label">
                    <div className="d-flex">
                        <DropDownList
                            style={{
                                width: "260px",
                            }}
                            data={item.options}
                            readOnly={activateFormBase?.disabled}
                            disabled={activateFormBase?.disabled}
                            dataItemKey="val"
                            textField="text"
                            defaultValue={defaultItem}
                            onChange={(e) =>
                                handleDropDownChange(e, item.fieldName, formik)}
                            name={item.fieldName}
                            onBlur={formik.handleBlur}
                            className="dashboard-select-controls k-rounded-md"
                        />
                    </div>
                </label>
            </div>
            {formik.touched[item.fieldName] && formik.errors[item.fieldName] && (
                <span className="validation-error">
          {formik.errors[item.fieldName]}
        </span>
            )}
        </div>
    );
};

export const selectPickerSemcastingXandr = (item, formik, activateFormBase) => {
    const defaultItem = {name: "Please Select..."};
    const findObjectById = (id) => {
        return item.options.find(item => item.id === id);
    };
    return (
        <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">
            <label
                className={`form-label ${item.required ? "required-asterisk" : ""} `}
            >
                {item.labelText}
            </label>
            <div
                className="form-check-dropdown"
                readOnly={activateFormBase?.disabled}
                disabled={activateFormBase?.disabled}
            >
                <label className="form-check-label">
                    <div className="d-flex">
                        <DropDownList
                            style={{
                                width: "260px",
                            }}
                            data={item.options}
                            value={findObjectById(Number(formik.values.price_cpm))}
                            readOnly={activateFormBase?.disabled}
                            disabled={activateFormBase?.disabled}
                            dataItemKey="id"
                            textField="name"
                            defaultValue={defaultItem}
                            onChange={(e) =>
                                handleDropDownChangeSemcastingXandr(e, item.fieldName, formik)}
                            name={item.fieldName}
                            onBlur={formik.handleBlur}
                            className="dashboard-select-controls k-rounded-md"
                        />
                    </div>
                </label>
            </div>
            {formik.touched[item.fieldName] && formik.errors[item.fieldName] && (
                <span className="validation-error">
          {formik.errors[item.fieldName]}
        </span>
            )}
        </div>
    );
};

export const searchSelectField = (item, formik, activateFormBase) => {
    // console.log("searchSelectFieldActivateFormBase=", activateFormBase);
    // console.log("searchSelectFieldItem=", item);
    // console.log("searchSelectFieldFormik=", formik);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let isValueExists = activateFormBase?.savableFieldsPreFillData[name]?.some(field => field.value === value);

        //formik.setFieldValue(name, value);

        // Update activateFormBase to control the checkbox
        //If the checkbox has not been given a value yet
        if(activateFormBase?.disableSaveCredentialsCheckbox === undefined){
            activateFormBase.disableSaveCredentialsCheckbox = isValueExists;
        //For page with multiple searchSelect fields, as long as one field contains isValueExists = false, checkbox should be enabled.
        //If last searchSelect field's isValueExists = True, and the current searchSelect field's isValueExists = True as well, disable checkbox
        }else if (activateFormBase.disableSaveCredentialsCheckbox === true && isValueExists === true){
            activateFormBase.disableSaveCredentialsCheckbox = true;
            //If last searchSelect field's isValueExists = True, and the current searchSelect field's isValueExists = True as well, disable checkbox
        }else if (activateFormBase.disableSaveCredentialsCheckbox === true && isValueExists === false){
            activateFormBase.disableSaveCredentialsCheckbox = true;
       //If last searchSelect field's isValueExists = False, and the current searchSelect field's isValueExists = True , enable checkbox
        }else if (activateFormBase.disableSaveCredentialsCheckbox === false && isValueExists === true){
            activateFormBase.disableSaveCredentialsCheckbox = true;
        //If last searchSelect field's isValueExists = False, and the current searchSelect field's isValueExists = False , enable checkbox
        }else if(activateFormBase.disableSaveCredentialsCheckbox === false && isValueExists === false){
            activateFormBase.disableSaveCredentialsCheckbox = false;
        }
        // activateFormBase.disableSaveCredentialsCheckbox = isValueExists;
        // console.log("searchSelectFieldIsValueExist=", isValueExists);

        // Manually setting the Formik error if the value exists
        if (isValueExists) {
            // Disable and uncheck the checkbox if a duplicate value is entered
            formik.setFieldValue('save_credentials', false);
        } else {
            activateFormBase.disableSaveCredentialsCheckbox = false;
        }

        // Update the Formik value
        formik.handleChange(e);
    };

    return (
        <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">
            <label
                className={`form-label ${item.required ? "required-asterisk" : ""} `}
            >
                {item.labelText}
            </label>
            <div className="input-group w-25">
                <input
                    type="text"
                    className="act-seg form-control form-control-custom w-50"
                    readOnly={activateFormBase?.disabled}
                    disabled={activateFormBase?.disabled}
                    name={item.fieldName}
                    value={formik.values[item.fieldName]}
                    onBlur={formik.handleBlur}
                    onChange={handleInputChange}
                    list={item.fieldName}
                />
                <datalist id={item.fieldName}>
                    {activateFormBase?.savableFieldsPreFillData[item.fieldName]?.length &&
                        activateFormBase?.savableFieldsPreFillData[item.fieldName]
                            .filter((fieldEachValue, index, self) =>
                                    index === self.findIndex((t) => (
                                        t.value === fieldEachValue.value
                                    ))
                            )
                            .map((fieldEachValue) => (
                                <option
                                    value={fieldEachValue.value}
                                    key={fieldEachValue.value}
                                />
                            ))}
                </datalist>
            </div>
            {item.info ? (
                <div className={"subtext-info"}>{item.info}</div>
            ) : ("")}
            {formik.touched[item.fieldName] && formik.errors[item.fieldName] && (
                <div className="validation-error">
                    {formik.errors[item.fieldName]}
                </div>
            )}

            {/* Render the Checkbox component here if it's part of this component */}
            {/* Replace `CheckboxComponent` with your actual checkbox component name */}
            {/* <CheckboxComponent
                item={checkboxItem}
                formik={formik}
                activateFormBase={activateFormBase}
                disableSaveCredentials={disableSaveCredentials}
            /> */}
        </div>
    );
};
export const searchSelectStackAdaptField = (item, formik, activateFormBase) => {
    // console.log("searchSelectFieldActivateFormBase=", activateFormBase);
    // console.log("searchSelectFieldItem=", item);
    // console.log("searchSelectFieldFormik=", formik);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let isValueExists = activateFormBase?.savableFieldsPreFillData[name]?.some(field => field.value === value);


        // Update activateFormBase to control the checkbox
        //If the checkbox has not been given a value yet
        if(activateFormBase?.disableSaveCredentialsCheckbox === undefined){
            activateFormBase.disableSaveCredentialsCheckbox = isValueExists;
            //For page with multiple searchSelect fields, as long as one field contains isValueExists = false, checkbox should be enabled.
            //If last searchSelect field's isValueExists = True, and the current searchSelect field's isValueExists = True as well, disable checkbox
        }else if (activateFormBase.disableSaveCredentialsCheckbox === true && isValueExists === true){
            activateFormBase.disableSaveCredentialsCheckbox = true;
            //If last searchSelect field's isValueExists = True, and the current searchSelect field's isValueExists = True as well, disable checkbox
        }else if (activateFormBase.disableSaveCredentialsCheckbox === true && isValueExists === false){
            activateFormBase.disableSaveCredentialsCheckbox = true;
            //If last searchSelect field's isValueExists = False, and the current searchSelect field's isValueExists = True , enable checkbox
        }else if (activateFormBase.disableSaveCredentialsCheckbox === false && isValueExists === true){
            activateFormBase.disableSaveCredentialsCheckbox = true;
            //If last searchSelect field's isValueExists = False, and the current searchSelect field's isValueExists = False , enable checkbox
        }else if(activateFormBase.disableSaveCredentialsCheckbox === false && isValueExists === false){
            activateFormBase.disableSaveCredentialsCheckbox = false;
        }

        // Manually setting the Formik error if the value exists
        if (isValueExists) {
            // Disable and uncheck the checkbox if a duplicate value is entered
            formik.setFieldValue('save_credentials', false);
        } else {
            activateFormBase.disableSaveCredentialsCheckbox = false;
        }

        // Update the Formik value
        formik.handleChange(e);
    };

    return (
        // formik.values?.extraDataForFormOps?.[item.fieldName]?.show === true &&
        formik.values["advertiser_options"] === "single_advertiser" &&
        <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">
            <label
                className={`form-label ${item.required ? "required-asterisk" : ""} `}
            >
                {item.labelText}
            </label>
            <div className="input-group w-25">
                <input
                    type="text"
                    className="act-seg form-control form-control-custom w-50"
                    readOnly={activateFormBase?.disabled}
                    disabled={activateFormBase?.disabled}
                    name={item.fieldName}
                    value={formik.values[item.fieldName]}
                    onBlur={formik.handleBlur}
                    onChange={handleInputChange}
                    list={item.fieldName}
                />
                <datalist id={item.fieldName}>
                    {activateFormBase?.savableFieldsPreFillData[item.fieldName]?.length &&
                        activateFormBase?.savableFieldsPreFillData[item.fieldName]
                            .filter((fieldEachValue, index, self) =>
                                    index === self.findIndex((t) => (
                                        t.value === fieldEachValue.value
                                    ))
                            )
                            .map((fieldEachValue) => (
                                <option
                                    value={fieldEachValue.value}
                                    key={fieldEachValue.value}
                                />
                            ))}
                </datalist>
            </div>
            {item.info ? (
                <div className={"subtext-info"}>{item.info}</div>
            ) : ("")}
            {formik.touched[item.fieldName] && formik.errors[item.fieldName] && (
                <div className="validation-error">
                    {formik.errors[item.fieldName]}
                </div>
            )}

        </div>
    );
};
export const textField = (item, formik, activateFormBase) => {
    // const max = 256;
    return (
        <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">
            <label
                className={`form-label ${item.required ? "required-asterisk" : ""} `}
            >
                {item.labelText}
            </label>
            <div className="input-group w-25" id={item.fieldName + "_wrapper"} style={{ display: "flex", flexDirection: "column" }}>
                <TextArea
                    // type="text"
                    className="act-seg form-control form-control-custom"
                    readOnly={activateFormBase?.disabled}
                    name={item.fieldName}
                    id={item.fieldName}
                    placeholder={item.defaultValue}
                    value={formik.values[item.fieldName]}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    list={item.fieldName}
                    disabled={activateFormBase?.disabled || item.disabled}
                    style={{height:"auto", width:"auto"}}
                    autoSize={true}
                    // maxLenth={max}
                    rows={1}
                />
                {activateFormBase?.company === 'tvsci' &&
                    <Hint direction={'end'}>{formik.values[item.fieldName].length} / {256}</Hint>}
            </div>
            {item.info ? (
                <div className={"subtext-info"}>{item.info}</div>
            ) : ("")}
            {formik.touched[item.fieldName] && formik.errors[item.fieldName] && (
                <span className="validation-error">
          {formik.errors[item.fieldName]}
        </span>
            )}
        </div>
    );
};

export const searchSelectTTDPartnerField = (item, formik, activateFormBase) => {
    return (
        formik.values?.extraDataForFormOps?.[item.fieldName]?.show === true &&
        formik.values["audience_availability"] === "partner" && (
            <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">
                <label
                    className={`form-label ${item.required ? "required-asterisk" : ""} `}
                >
                    {item.labelText}
                </label>
                <div className="input-group w-25">
                    <input
                        type="text"
                        className="act-seg form-control form-control-custom"
                        readOnly={activateFormBase?.disabled}
                        disabled={activateFormBase?.disabled}
                        name={item.fieldName}
                        //placeholder={item.labelText}
                        value={formik.values[item.fieldName]}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        list={item.fieldName}
                    />
                    <datalist id={item.fieldName}>
                        {activateFormBase?.savableFieldsPreFillData[item.fieldName]
                                ?.length &&
                            activateFormBase?.savableFieldsPreFillData[item.fieldName].map(
                                (fieldEachValue) => {
                                    return (
                                        <option
                                            value={fieldEachValue.value}
                                            key={fieldEachValue.value}
                                        />
                                    );
                                }
                            )}
                    </datalist>
                </div>
                {formik.touched[item.fieldName] && formik.errors[item.fieldName] && (
                    <span className="validation-error">
            {formik.errors[item.fieldName]}
          </span>
                )}
            </div>
        )
    );
};

export const searchSelectTTDAdvertiserField = (
    item,
    formik,
    activateFormBase
) => {
    return (
        formik.values?.extraDataForFormOps?.[item.fieldName]?.show === true &&
        formik.values["audience_availability"] === "advertiser" && (
            <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">
                <label
                    className={`form-label ${item.required ? "required-asterisk" : ""} `}
                >
                    {item.labelText}
                </label>
                <div className="input-group w-25">
                    <input
                        type="text"
                        className="act-seg form-control form-control-custom"
                        readOnly={activateFormBase?.disabled}
                        disabled={activateFormBase?.disabled}
                        name={item.fieldName}
                        //placeholder={item.labelText}
                        value={formik.values[item.fieldName]}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        list={item.fieldName}
                    />
                    <datalist id={item.fieldName}>
                        {activateFormBase?.savableFieldsPreFillData[item.fieldName]
                                ?.length &&
                            activateFormBase?.savableFieldsPreFillData[item.fieldName].map(
                                (fieldEachValue) => {
                                    return (
                                        <option
                                            value={fieldEachValue.value}
                                            key={fieldEachValue.value}
                                        />
                                    );
                                }
                            )}
                    </datalist>
                </div>
                {formik.touched[item.fieldName] && formik.errors[item.fieldName] && (
                    <span className="validation-error">
            {formik.errors[item.fieldName]}
          </span>
                )}
            </div>
        )
    );
};
function parseDate(initialDateString) {
    let dateParts = initialDateString.split('-');
    let year = parseInt(dateParts[0], 10);
    let month = parseInt(dateParts[1], 10) - 1; // Months are 0-indexed, so subtract 1
    let day = parseInt(dateParts[2], 10);

    return new Date(year, month, day);
}
export const dateRange = (item, formik, activateFormBase) => {

    const today = new Date();

    // console.log("formik.values.campaign_range_start=", formik.values.campaign_range_start);
    //const addition = "T06:00:00.000Z";
    //var formikStartDate = new Date(formik.values.campaign_range_start + addition);
    var formikStartDate = parseDate(formik.values.campaign_range_start);

    if (isNaN(formikStartDate)) {
        formikStartDate = null;
    }
    var formikEndDate = parseDate(formik.values.campaign_range_end);
    if (isNaN(formikEndDate)) {
        formikEndDate = null;
    }
    // console.log("ActivateFormHelpersformikStartDate=", formikStartDate, "formikEndDate=", formikEndDate);

    var todayDate = moment(today).format('DD-MM-YYYY');

    return (
        <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="form-group">
                <label
                    className={`form-label ${item.required ? "required-asterisk" : ""} `}
                >
                    {item.labelText}
                </label>
                <div className="input-group w-50 gap-5 date-picker">
                    <DatePicker
                        defaultValue={todayDate}
                        readOnly={activateFormBase?.disabled}
                        disabled={activateFormBase?.disabled}
                        value={formikStartDate}
                        adaptive={true}
                        fieldName="campaign_range_start"
                        adaptiveTitle="Set date"
                        width={"inherit"}
                        min={today}
                        placeholder="Start Date"
                        onChange={(e) => {
                            console.log("DatePicker Start value changed e=", e);
                            handleDatePickerStartDateChange(e, item.fieldName, formik);
                        }}
                        className={"act-seg form-control form-control-custom"}
                    />
                    <DatePicker
                        defaultValue={todayDate}
                        readOnly={activateFormBase?.updateEndDateDisabled}
                        disabled={activateFormBase?.updateEndDateDisabled}
                        value={formikEndDate}
                        adaptive={true}
                        adaptiveTitle="Set date"
                        width={"inherit"}
                        fieldName="campaign_range_end"
                        min={today}
                        placeholder="End Date"
                        onChange={(e) => {
                            console.log("DatePicker End value changed");
                            handleDatePickerEndDateChange(e, item.fieldName, formik);
                        }}
                        className={"act-seg form-control form-control-custom"}
                    />
                </div>
                {((formik.touched.campaign_range_start &&
                        formik.errors.campaign_range_start) ||
                    (formik.touched.campaign_range_end &&
                        formik.errors.campaign_range_end)) && (
                    <span className="validation-error">
            {formik.errors.campaign_range_start ||
                formik.errors.campaign_range_end}
          </span>
                )}
            </div>
        </div>
    );
};

export const pricing = (item, formik, activateFormBase) => {
    // console.log("item=", item, "formik=", formik, "activateFormBase=", activateFormBase );

    const segmentParty = activateFormBase?.segment?.party;
    //Below condition was added because FD10731, and is removed because FD11196 (condition no longer needed)
    // const PriceValue = (prop) => {
    //
    //     switch (prop) {
    //         case 3:
    //             return (priceFormat(activateFormBase?.segment?.billing_rate));
    //         case 1:
    //             return (activateFormBase?.pricingData?.price === undefined ? priceFormat(activateFormBase?.segment?.billing_rate) :  priceFormat(activateFormBase?.pricingData?.price));
    //         default:
    //             return (priceFormat(activateFormBase?.segment?.billing_rate));
    //     }
    // };

    return (
        <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="form-group">
                <label
                    className={`form-label ${item.required ? "required-asterisk" : ""} `}
                >
                    {item.labelText}
                </label>
                <p>
                    <strong data-sync="pricing_structure">
                        {activateFormBase?.pricingData?.pricing_structure}
                    </strong>
                    <strong> Rate:</strong>{" "}
                    <span data-sync="price">
                     {priceFormat(activateFormBase?.pricingData?.price)}
          </span>
                </p>
            </div>
        </div>
    );
};

export const button = (item, formik, activateFormBase) => {
    return (
        <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="form-group">
                <label
                    className={`form-label ${item.required ? "required-asterisk" : ""} `}
                >
                    {item.labelText}
                </label>
                <div className="form-check grant-access">
                    <input
                        type="button"
                        className="btn btn-rounded btn-primary btn-block"
                        readOnly={activateFormBase?.disabled}
                        disabled={activateFormBase?.disabled}
                        name={item.fieldName}
                        value={item.labelText}
                        id={item.fieldName}
                        onBlur={formik.handleBlur}
                        onClick={(e) => handlePinterestAccessClick(e, item.fieldName, formik)}
                    />
                    {formik.touched[item.fieldName] && formik.values[item.fieldName] &&
                        <i className="fa fa-check-circle"></i>}
                    {formik.touched[item.fieldName] && !formik.values[item.fieldName] &&
                        <i className="fa fa-times-circle"></i>}
                    {/*{formik.values[item.fieldName]}*/}
                    {/*</label>*/}
                    {formik.touched[item.fieldName] && formik.errors[item.fieldName] && (
                        <span className="validation-error">
              {formik.errors[item.fieldName]}
            </span>
                    )}
                </div>
            </div>
        </div>
    );
};
export const percentOfMediaCostRateField = (item, formik, activateFormBase) => {
    return (
        formik.values?.extraDataForFormOps?.[item.fieldName]?.show === true &&
        formik.values["pricing_method"] === "hybrid_pricing" && (
        <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">
            <label
                className={`form-label ${item.required ? "required-asterisk" : ""} `}
            >
                {item.labelText}
            </label>
            <div className="input-group w-25">
                <input
                    type="text"
                    className="act-seg form-control form-control-custom w-50"
                    readOnly={activateFormBase?.disabled}
                    disabled={activateFormBase?.disabled}
                    name={item.fieldName}
                    id={item.fieldName}
                    placeholder={item.defaultValue}
                    value={formik.values[item.fieldName]}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    list={item.fieldName}
                />
            </div>
            {formik.touched[item.fieldName] && formik.errors[item.fieldName] && (
                <span className="validation-error">
          {formik.errors[item.fieldName]}
        </span>
                )}
            </div>
        )
    );
};
export const cpmCapField = (item, formik, activateFormBase) => {
    return (
        formik.values?.extraDataForFormOps?.[item.fieldName]?.show === true &&
        formik.values["pricing_method"] === "hybrid_pricing" && (
            <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">
                <label
                    className={`form-label ${item.required ? "required-asterisk" : ""} `}
                >
                    {item.labelText}
                </label>
                <div className="input-group w-25">
                    <input
                        type="text"
                        className="act-seg form-control form-control-custom w-50"
                        readOnly={activateFormBase?.disabled}
                        disabled={activateFormBase?.disabled}
                        name={item.fieldName}
                        id={item.fieldName}
                        placeholder={item.defaultValue}
                        value={formik.values[item.fieldName]}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        list={item.fieldName}
                    />
                </div>
                {formik.touched[item.fieldName] && formik.errors[item.fieldName] && (
                    <span className="validation-error">
          {formik.errors[item.fieldName]}
        </span>
                )}
            </div>
        )
    );
};
export const cpmField = (item, formik, activateFormBase) => {
    return (
        formik.values?.extraDataForFormOps?.[item.fieldName]?.show === true &&
        formik.values["pricing_method"] === "cpm_pricing" && (
            <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">
                <label
                    className={`form-label ${item.required ? "required-asterisk" : ""} `}
                >
                    {item.labelText}
                </label>
                <div className="input-group w-25">
                    <input
                        type="text"
                        className="act-seg form-control form-control-custom w-50"
                        readOnly={activateFormBase?.disabled}
                        disabled={activateFormBase?.disabled}
                        name={item.fieldName}
                        id={item.fieldName}
                        placeholder={item.defaultValue}
                        value={formik.values[item.fieldName]}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        list={item.fieldName}
                    />
                </div>
                {formik.touched[item.fieldName] && formik.errors[item.fieldName] && (
                    <span className="validation-error">
          {formik.errors[item.fieldName]}
        </span>
                )}
            </div>
        )
    );
};
export const accessCode = (item, formik, activateFormBase) => {
    // console.log("ActivateSegmentItem=", item);

    return (
        <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="form-group">
                <label
                    className={`form-label ${item.required ? "required-asterisk" : ""} `}
                >
                    {item.labelText}
                </label>
                <div className="form-check grant-access">
                    <button
                        // type="button"
                        className="btn btn-rounded btn-primary btn-block"
                        readOnly={activateFormBase?.disabled}

                        // disabled={item.disabled}
                        disabled={activateFormBase?.disabled}
                        name={item.fieldName}
                        id={item.fieldName}
                        onBlur={formik.handleBlur}
                        onClick={(e) => handleGrantAccessClick(e, item.fieldName, formik, activateFormBase)}
                    >Grant Access</button>
                    {activateFormBase?.isError() && <i className="fa fa-times-circle"></i>}
                    {/*{formik.touched[item.fieldName] && formik.values[item.fieldName] */}

                    {/*    &&*/}
                    {/*    <i className="fa fa-check-circle"></i>}*/}
                    {/*{formik.touched[item.fieldName] && !formik.values[item.fieldName] &&*/}
                    {/*    <i className="fa fa-times-circle"></i>}*/}
                    {/*{formik.values[item.fieldName]}*/}
                    {/*</label>*/}
                    {formik.touched[item.fieldName] && formik.errors[item.fieldName] && (
                        <span className="validation-error">
              {formik.errors[item.fieldName]}
            </span>
                    )}
                </div>
            </div>
        </div>
    );
};
export const selectPickerFTPConnection = (item, formik, activateFormBase, ftpConnections) => {

    const defaultItem = {
        text: "Please Select...",
    };
    const handleConnectionChange = (e) => {
        if (e.target.value) {
            formik.setFieldValue(item.fieldName, e.target.value);
        }
    };
    const itemRender = (li, itemProps) => {
        const id = itemProps.dataItem.id;
        const itemChildren = (
            <span>
        {itemProps.dataItem.text}
      </span>
        );
        return React.cloneElement(li, li.props, itemChildren);
    };
    return (
        <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">
            <label
                className={`form-label ${item.required ? "required-asterisk" : ""} `}
            >
                {item.labelText}
            </label>
            <div
                className="form-check-dropdown"
                readOnly={activateFormBase?.disabled}
                disabled={activateFormBase?.disabled}
            >
                    <div className="d-flex" style={{alignItems:"center"}}>
                        <DropDownList
                            style={{
                                width: "270px",
                            }}
                            className="dashboard-select-controls k-rounded-md"
                            data={ftpConnections}
                            itemRender={itemRender}
                            readOnly={activateFormBase?.disabled}
                            disabled={activateFormBase?.disabled}
                            dataItemKey="id"
                            textField="text"
                            defaultValue={defaultItem}
                            name={item.fieldName}
                            value={formik.values.selectPickerFTPConnection?formik.values.selectPickerFTPConnection:defaultItem}
                            onBlur={formik.handleBlur}
                            onChange={(e) => handleConnectionChange(e)}
                        />
                        <NavLink to={"/admin-settings"} className={"nav-link-to-admin"}
                                 onClick={() => sessionStorage.setItem('adminTabSelected', 2)}>
                            <Button
                                className={"button-submit"}
                                iconClass="k-icon k-i-track-changes-enable"
                            >
                                Manage Connections
                            </Button>
                        </NavLink>
                    </div>
            </div>
            {formik.touched[item.fieldName] && formik.errors[item.fieldName] && (
                <span className="validation-error">
          {formik.errors[item.fieldName]}
        </span>
            )}
        </div>
    );
};

export const CreativeTagDropDown = (props) => {
    const {item, formik, activateFormBase, creativeTags} = props;
    const [tagList, setTegList] = useState([]);
    const [tempTagList, setTempTagList] = useState([]);
    const [tagValue, setTagValue] = React.useState(null);
    let creativeTagDropdownValue = creativeTags.find(item => item.creativeTagId === activateFormBase?.aqData?.creative_tag_id);
    const comboBoxRef = useRef(null);
    useEffect(() => {
        if (activateFormBase?.company === "facebook"){
            creativeTagDropdownValue = "0"
        }
        setTagValue(creativeTagDropdownValue);
        setTegList(props.creativeTags);
        setTempTagList(props.creativeTags);
    }, [creativeTags]);
    const handleFocus = () => {
        setTimeout(() => {
            if (!comboBoxRef.current.opened) {
                comboBoxRef.current.toggleBtnClick();
            }
        }, 0);
    };
    const itemRender = (li, itemProps) => {
        const id = itemProps.dataItem.creativeTagId;
        const itemChildren = (
            <span>
        {li.props.children} ({id})
      </span>
        );
        return React.cloneElement(li, li.props, itemChildren);
    };

    const defaultItem = {
        segmentName: "Please Select...",
    };
    const filterChange = (event) => {
        console.log(event)
        const input = event.filter.value
        const allData = creativeTags;
        //to filter on either segmentName or creativeTagId
        const filter = {
            "logic": "or",
            "filters": [
                {
                    "field": "segmentName",
                    "operator": "contains",
                    "value": input
                },
                {
                    "field": "creativeTagId",
                    "operator": "contains",
                    "value": input
                },
            ]
        }
        const newData = input.length >= 1 ? filterByData(allData, filter) : allData;
        setTempTagList(newData);
    };
    useEffect(()=>{
    },[tempTagList, tagList])
  const handleDropDownChangeCreativeTags = (
        e,
        formik
    ) => {
        if (e.target.value) {
            let Id = e.target.value.creativeTagId.toString();
            //Encode tag to pass XSS issue
            let Tag = encodeURIComponent(e.target.value.creativeTag.toString());
            // let Tag = e.target.value.creativeTag.toString();
            formik.setFieldValue("creativeTagId", Id);
            formik.setFieldValue("creativeTag", Tag);
            formik.setFieldValue("creativeTagIdDropDown", Id);
            setTagValue(e.target.value);
        }else{
            formik.setFieldValue("creativeTagId", "");
            formik.setFieldValue("creativeTag", "");
            formik.setFieldValue("creativeTagIdDropDown", "");
            setTagValue(e.target.value);
        }
    };

    return(
    <ComboBox
        style={{
            width: "300px",
            height:"2rem"
        }}
        data={tempTagList}
        id={"creative-tag-dropdown"}
        value={tagValue}
        placeholder={"Please Select..."}
        itemRender={itemRender}
        readOnly={activateFormBase?.updateCreativeTagDisabled}
        disabled={activateFormBase?.updateCreativeTagDisabled}
        dataItemKey="creativeTagId"
        textField="segmentName"
        defaultValue={creativeTagDropdownValue}
        onFocus={handleFocus}
        ref={comboBoxRef}
        filterable={true}
        onFilterChange={filterChange}
        onChange={(e) =>
            handleDropDownChangeCreativeTags(e, formik)}
        name={item.fieldName}
        onBlur={formik.handleBlur}
        className="dashboard-select-controls k-rounded-md"
    />
)
}
export const selectPickerTag = (item, formik, activateFormBase, creativeTags) => {

    return activateFormBase?.activationStatus !== "COMPLETED" && activateFormBase?.company !== "facebook" && (

        <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">
            <label
                className={`form-label ${item.required ? "required-asterisk" : ""} `}
            >
                {item.labelText}
            </label>
            <div
                className="form-check-dropdown"
                readOnly={activateFormBase?.updateCreativeTagDisabled}
                disabled={activateFormBase?.updateCreativeTagDisabled}
            >
                <label className="form-check-label">
                    <div className="d-flex">
                        <CreativeTagDropDown
                            item={item}
                            formik={formik}
                            activateFormBase={activateFormBase}
                            creativeTags={creativeTags}
                        />
                    </div>
                </label>
            </div>
            {formik.touched[item.fieldName] && formik.errors[item.fieldName] && (
                <span className="validation-error">
          {formik.errors[item.fieldName]}
        </span>
            )}
        </div>
    );
};

export const creativeTagId = (item, formik, activateFormBase) => {

    const pixelReceived = activateFormBase?.creative_pixel_received;
    const display = activateFormBase?.updateCreativeTagDisabled && pixelReceived;

    return (activateFormBase?.activationStatus === "COMPLETED") && activateFormBase?.company !== "facebook" && (

        <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">
            <label
                className={`form-label ${item.required ? "required-asterisk" : ""} `}
            >
                {item.labelText}
            </label>
            <span> </span>
            <label className="form-check-label" style={{fontSize:"12px", display: display ? "":"none"}} > (If pixels are already received, this Tag Id cannot be changed)</label>
            <div className="d-flex" style={{color: "#585858"}}>
                ID: {activateFormBase?.aqData?.creative_tag_id !== undefined ? activateFormBase?.aqData?.creative_tag_id : "Not Available"}
            </div>
            {formik.touched[item.fieldName] && formik.errors[item.fieldName] && (
                <span className="validation-error">
          {formik.errors[item.fieldName]}
        </span>
            )}
        </div>
    );
};
export const creativeTag = (item, formik, activateFormBase) => {
// console.log(activateFormBase.aqData)
    function copyTag() {
        if(activateFormBase.aqData.creative_tag_id !== undefined && activateFormBase.aqData.creative_tag_id !== 0){
            document.getElementById("copy-button").innerHTML = "Copied!"
            // return navigator.clipboard.writeText("<img src=\""+ imgSrc + "\" alt=\"\" style=\"display:none !important;\" />");
            return navigator.clipboard.writeText(activateFormBase?.aqData?.creative_tag);
        }else{
            document.getElementById("copy-button").innerHTML = "Not Available"
            return  navigator.clipboard.writeText("Not Available");
        }

    }
    const imgSrc = "https://idcm.rtactivate.com/tagid/" + activateFormBase?.aqData?.creative_tag_id +"/"

    return (activateFormBase?.activationStatus === "COMPLETED") && activateFormBase?.company !== "facebook" && (
        <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">
            <label
                className={`form-label ${item.required ? "required-asterisk" : ""} `}
            >
                {item.labelText}
            </label>
            <div className="d-flex" style={{color: "#585858", alignItems: "baseline"}}>
                Tag:
                {/*<span id={"creative-tag"}>{activateFormBase?.aqData?.creative_tag_id !== undefined &&  activateFormBase?.aqData?.creative_tag_id !== 0 ? " <img src=\""+ imgSrc + "\" alt=\"\" style=\"display:none !important;\" />" : " Not Available"}</span>*/}
                {/*Displaying the tags directly from queue data (instead of manually create the string using id) because we have them now*/}
                <span id={"creative-tag"}>{activateFormBase?.aqData?.creative_tag !== undefined &&  activateFormBase?.aqData?.creative_tag !== null ? activateFormBase?.aqData?.creative_tag : " Not Available"}</span>
                <button id={"copy-button"} className={"btn btn-primary"} style={{padding: "1px 8px 1px 8px"}} onClick={copyTag}>Copy</button>
            </div>
            {formik.touched[item.fieldName] && formik.errors[item.fieldName] && (
                <span className="validation-error">
          {formik.errors[item.fieldName]}
        </span>
            )}
        </div>
    );
};
const subItemsField = 'children';
const RowRender = (properties) => {
    const { row, props, onDrop, onDragStart } = properties;
    const additionalProps = {
        onDragStart: (e) => onDragStart(e, props),
        onDragOver: (e) => {
            e.preventDefault();
        },
        // onDrop: (e) => onDrop(e, props),
        draggable: true,
    };
    return React.cloneElement(
        row,
        { ...row.props, ...additionalProps },
        row.props.children
    );
};


export const TreeComponent = (item, formik, activateFormBase) => {
    const isConsumer = item.activateFormBase.segment.segment_type === "CONSUMER";
    const isBusiness = item.activateFormBase.segment.segment_type === "BUSINESS";
    const isThirdPartySegment = item.activateFormBase.segment.party === 3;
    const isFirstPartySegment = item.activateFormBase.segment.party === 1;
    const [warningMessage, setWarningMessage] = React.useState(null);
    const [count, setCount] = React.useState(0);
    const subItemsField = "children";
    const expandField = "expanded";
    const columns1 = [
        {
            field: "labelText",
            title: " ",
            width: "100px",
            // filter: TreeListTextFilter,
            expandable: true,
            sortable: false,
        }
    ];
    const columns2 = [
        {
            field: "labelText",
            title: " ",
            width: "100px",
            // filter: TreeListTextFilter,
            expandable: true,
            sortable: false,
        }
    ];
    const removeItem = (tree, itemLevel) => {
        const itemPath = getItemPath(tree, itemLevel, subItemsField);
        const item = itemPath.pop();
        const parent = itemPath.pop();
        const itemIndex = itemLevel[itemLevel.length - 1];
        (parent ? parent[subItemsField] : tree).splice(itemIndex, 1);
        return item;
    };

    const insertItem = (tree, insertLevel, item) => {
        const destinationItemPath = getItemPath(tree, insertLevel, subItemsField);
        const destinationItem = destinationItemPath.pop();
        const destinationParent = destinationItemPath.pop();
        if (destinationParent) {
            const insertAt =
                destinationParent[subItemsField].indexOf(destinationItem) + 1;
            const destItems = destinationParent[subItemsField].slice();
            destItems.splice(insertAt, 0, item);
            destinationParent[subItemsField] = destItems;
        } else {
            tree.splice(insertLevel[0] + 1, 0, item);
        }
    };
    function preProcessedData1 (val){
        //pre-process data based on segment type and party
        //consumer 1st party segment has consumer pii, consumer demographics and consumer basics
        //consumer 3rd party segment has consumer basics only
        //business 1st party has business basics only
        //business 3rd party has business basics only
        return val.map(item => {
            // If the item is "Demographic"
            if (item.labelText === "Demographic") {
                if(isConsumer && isFirstPartySegment){
                    return {
                        ...item,
                        children: item.children.filter(child => child.value !== "business_basics")
                    };
                }
                if(isConsumer && isThirdPartySegment){
                    return {
                        ...item,
                        children: item.children.filter(child => child.value !== "business_basics" && child.value !== "consumer_demographics" && child.value !== "consumer_pii")
                    };
                }
                if(isBusiness){
                    return {
                        ...item,
                        children: item.children.filter(child => child.value !== "consumer_basics" && child.value !== "consumer_demographics" && child.value !== "consumer_pii")
                    };
                }

            }
            if(item.labelText === "Keys"){
                if(isConsumer && isThirdPartySegment){
                    return {
                        ...item,
                        children: item.children.filter(child => child.value !== "semcasting_id")
                    };
                }
            }
            // For other items, return them unchanged
            return item;
        });
    }
    function preProcessedData2 (val){
        //pre-process data based on segment type and party
        //consumer 3rd party segment has semcasting_id pre-selected
        if(isConsumer && isThirdPartySegment){
            const foundObj = val.find(item =>  item.labelText === "Keys");
            return [{
                id: foundObj.id,
                labelText: foundObj.labelText,
                value: foundObj.value,
                children: [foundObj.children.find(item => item.value === 'semcasting_id')]
            }];
        }else{
          return [];
        }

    }

    const [state, setState] = React.useState({
        data1: [...preProcessedData1(item.item.data)],
        data2: [...preProcessedData2(item.item.data)],
        dataState1: {
            sort: [],
            filter: [],
        },
        dataState2: {
            sort: [],
            filter: [],
        },
        expanded1: [1, 2, 3],
        expanded2: [1, 2, 3],
    });

    const onExpandChange1 = (e) => {
        setState({
            ...state,
            expanded1: e.value
                ? state.expanded1.filter((id) => id !== e.dataItem.id)
                : [...state.expanded1, e.dataItem.id],
        });
    };
    const onExpandChange2 = (e) => {
        setState({
            ...state,
            expanded2: e.value
                ? state.expanded2.filter((id) => id !== e.dataItem.id)
                : [...state.expanded2, e.dataItem.id],
        });
    };
    const handleDataState1Change = (event) => {
        setState({
            ...state,
            dataState1: event.dataState,
        });
    };
    const handleDataState2Change = (event) => {
        setState({
            ...state,
            dataState2: event.dataState,
        });
    };
    const addExpandField1 = (dataTree) => {
        const expanded = state.expanded1;
        return mapTree(dataTree, subItemsField, (item) =>
            extendDataItem(item, subItemsField, {
                [expandField]: expanded.includes(item.id),
            })
        );
    };
    const addExpandField2 = (dataTree) => {
        const expanded = state.expanded2;
        return mapTree(dataTree, subItemsField, (item) =>
            extendDataItem(item, subItemsField, {
                [expandField]: expanded.includes(item.id),
            })
        );
    };
    const processData1 = () => {
        let { data1, dataState1 } = state;
        let filteredData = filterBy(data1, dataState1.filter, subItemsField);
        let sortedData = orderBy(filteredData, dataState1.sort, subItemsField);
        return addExpandField1(sortedData);
    };
    const processData2 = () => {
        let { data2, dataState2 } = state;
        let filteredData = filterBy(data2, dataState2.filter, subItemsField);
        let sortedData = orderBy(filteredData, dataState2.sort, subItemsField);
        return addExpandField2(sortedData);
    };
    function findObjectByValue(arr, targetValue) {
        let result = null;

        // Recursive helper function to search through the object and its children
        function search(obj) {
            // Check if the current object has the target value
            if (obj.value === targetValue) {
                result = obj;
                return;
            }

            // If it has children, recursively search within them
            if (Array.isArray(obj.children)) {
                for (let child of obj.children) {
                    search(child);
                    // Stop searching once we find the result
                    if (result) {
                        break;
                    }
                }
            }
        }

        // Loop through each object in the array
        for (let item of arr) {
            search(item);
            // Stop searching once we find the result
            if (result) {
                break;
            }
        }

        return result;
    }
    const [consumer_pii, setConsumerPII] = React.useState(null);
    const [consumer_demographics, setConsumerDemographics] = React.useState(null);
    const [consumer_basics, setConsumerBasics] = React.useState(null);
    useEffect(()=>{
        setConsumerPII(findObjectByValue(state.data1, "consumer_pii"));
        setConsumerDemographics(findObjectByValue(state.data1, "consumer_demographics"))
        setConsumerBasics(findObjectByValue(state.data1, "consumer_basics"))
        console.log(consumer_pii)
        console.log(consumer_demographics)
        console.log(consumer_basics)

    },[])
    const onTreeListItemClick1 =(e)=> {
        setWarningMessage(null)
        let updatedData1 = state.data1;
        let updatedData2 = state.data2;

        console.log(state.data1)
        console.log(e)
        if(e.level.length === 1){
            if(e.dataItem.value === 'demographic' && e.dataItem.children.length > 1){
                setWarningMessage("Consumer PII, Consumer Demographics, and Consumer Basics cannot be selected together.")
                return
            }else{
                updatedData1 = updatedData1.filter((item) => item.id !== e.dataItem.id);
                if(!state.data2.find(item => item.id === e.dataItem.id)) {
                    updatedData2.push({
                        id: e.dataItem.id,
                        labelText: e.dataItem.labelText,
                        value: e.dataItem.value,
                        children: []
                    })

                }
                updatedData2.map(item => {
                    if(item.id === e.dataItem.id){
                        item.children.push(...e.dataItem.children)
                    }
                })
            }
        }else if(e.level.length === 2){
             updatedData1 = state.data1.map(item => {
                const filteredChildren = item.children.filter(child => child.value !== e.dataItem.value);
                return {
                    ...item,
                    children: filteredChildren
                };
            });

            const parentItem = state.data1.find(item => item.id === e.dataItem.parentId);
        if(!state.data2.find(item => item.id === e.dataItem.parentId)) {
            updatedData2.push({
                id: parentItem.id,
                labelText: parentItem.labelText,
                value: parentItem.value,
                children: []
            })
        }
            updatedData2.map(item => {
                if(item.id === e.dataItem.parentId){
                    item.children.push(e.dataItem)
                }
            })
        }

        if(e.dataItem.value === "consumer_pii" && isFirstPartySegment){
            updatedData2 = updatedData2.map(item => {
                const filteredChildren = item.children.filter(child => child.value !== "consumer_demographics" && child.value !== "consumer_basics");
                return {
                    ...item,
                    children: filteredChildren
                };
            });
       updatedData1.map(item => {
                if(item.id === e.dataItem.parentId){
                  if(!item.children.find(child => child.value === "consumer_demographics")){
                      item.children.push(consumer_demographics)
                  }
                    if(!item.children.find(child => child.value === "consumer_basics")){
                        item.children.push(consumer_basics)
                    }
                }
            })
        }
        if(e.dataItem.value === "consumer_demographics" && isFirstPartySegment){
            updatedData2 = updatedData2.map(item => {
                const filteredChildren = item.children.filter(child => child.value !== "consumer_pii" && child.value !== "consumer_basics");
                return {
                    ...item,
                    children: filteredChildren
                };
            });
            updatedData1.map(item => {
                if(item.id === e.dataItem.parentId){
                    if(!item.children.find(child => child.value === "consumer_pii")){
                        item.children.push(consumer_pii)
                    }
                    if(!item.children.find(child => child.value === "consumer_basics")){
                        item.children.push(consumer_basics)
                    }
                }
            })
        }
        if(e.dataItem.value === "consumer_basics" && isFirstPartySegment){
            updatedData2 = updatedData2.map(item => {
                const filteredChildren = item.children.filter(child => child.value !== "consumer_demographics" && child.value !== "consumer_pii");
                return {
                    ...item,
                    children: filteredChildren
                };
            });
            updatedData1.map(item => {
                if(item.id === e.dataItem.parentId){
                    console.log(item)
                    if(!item.children?.find(child => child.value === "consumer_demographics")){
                        item.children.push(consumer_demographics)
                    }
                    if(!item.children?.find(child => child.value === "consumer_pii")){
                        item.children.push(consumer_pii)
                    }
                }
            })
        }


        setState(prevState => ({
            ...prevState,
            data1: updatedData1.filter((item) => item.children.length !== 0).sort((a, b) => a.id - b.id),
            data2: updatedData2.filter((item) => item.children.length !== 0).sort((a, b) => a.id - b.id),
        }));
    }
    const onTreeListItemClick2 =(e)=> {
        setWarningMessage(null)
        let updatedData1 = state.data1;
        let updatedData2 = state.data2;
        if(e.level.length === 1){
            updatedData2 = updatedData2.filter((item) => item.id !== e.dataItem.id);

        if(!updatedData1.find(item => item.id === e.dataItem.id)){
            updatedData1.push({
                id: e.dataItem.id,
                labelText: e.dataItem.labelText,
                value: e.dataItem.value,
                children: []
            })
        }
            updatedData1.map(item => {
                if(item.id === e.dataItem.id){
                    item.children.push(...e.dataItem.children)
                }
            })


        }else if(e.level.length === 2){
            const parentItem = state.data2.find(item => item.id === e.dataItem.parentId);
            if(!updatedData1.find(item => item.id === e.dataItem.parentId)){
                updatedData1.push({
                    id: parentItem.id,
                    labelText: parentItem.labelText,
                    value: parentItem.value,
                    children: []
                })
            }
            updatedData1.map(item => {
                if(item.id === e.dataItem.parentId){
                    item.children.push(e.dataItem)
                }
            })
            updatedData2 = state.data2.map(item => {
                const filteredChildren = item.children.filter(child => child.value !== e.dataItem.value);
                return {
                    ...item,
                    children: filteredChildren
                };
            });

        }
        setState(prevState => ({
            ...prevState,
            data1: updatedData1.filter((item) => item.children.length !== 0).sort((a, b) => a.id - b.id),
            data2: updatedData2.filter((item) => item.children.length !== 0).sort((a, b) => a.id - b.id),
        }));
    }

    const rowForTreeList1 = (row, props) => {
        return (
            <RowRender
                props={props}
                row={row}
                // onDrop={handleOnDropFirst}
                onDragStart={handleDragStartFirst}
            />
        );
    };
    const rowForTreeList2 = (row, props) => {
        return (
            <RowRender
                props={props}
                row={row}
                // onDrop={handleOnDropSecond}
                onDragStart={handleDragStartSecond}
            />
        );
    };

    const handleDragStartFirst = (e, props) => {
        // e.preventDefault;
        const { dataItem, level } = props;
        setState({
            ...state,
            dragFrom: 'first',
            dragged: { dataItem, level },
        });
    };

    const handleDragStartSecond = (e, props) => {
        // e.preventDefault;
        const { dataItem, level } = props;
        setState({
            ...state,
            dragFrom: 'second',
            dragged: { dataItem, level },
        });
    };

    const handleDiv1 = () => {
        if (state.dragFrom === 'second') {
            const { dragged, data1, data2 } = state;
            const { dataItem, level } = dragged;
            let newData1 = [...data1];
            let newData2 = [...data2];

            const parentItem = state.data2.find(item => item.id === dataItem.parentId);

            let resultValues = [];
            state.data2.forEach(item => {
                let children = [...item.children];
                children.forEach(child => {
                    resultValues.push(child.value);
                })
            })
            //level 1
            if(level.length === 1){

                //if parent doesn't exist in newData1
                if(!newData1.find(item => item.id === dataItem.id)){
                    newData1.push(dataItem);

                    //if parent exist in newData1
                }else {
                    let additionalVal = newData2.find(item => item.id === dataItem.id).children;
                    newData1.map((item, i) => {
                        if(item.id === dataItem.id){
                            item.children.push(...additionalVal)
                        }
                    })
                }

                //remove this parent item from newData2
                newData2 = newData2.filter(child => child.id !== dataItem.id)

                //level 2
            } else if(level.length === 2){
                if(!newData1.find(item => item.id === dataItem.parentId)) {
                    newData1.push({
                        id: parentItem.id,
                        labelText: parentItem.labelText,
                        value: parentItem.value,
                        children: []
                    })
                    newData1.map((item, i) => {
                        if(item.id === dataItem.parentId){
                            item.children.push(dataItem)
                        }
                    })

                }else if(newData1.find(item => item.id === dataItem.parentId)){
                    newData1.map((item, i) => {
                        if(item.id === dataItem.parentId){
                            item.children.push(dataItem)
                        }
                    })
                }
                newData2 = newData2.map(item => {
                    const filteredChildren = item.children.filter(child => child.value !== dataItem.value);
                    return {
                        ...item,
                        children: filteredChildren
                    };
                });

            }
            setState({
                ...state,
                data1: newData1.filter((item) => item.children.length !== 0).sort((a, b) => a.id - b.id),
                data2: newData2.filter((item) => item.children.length !== 0).sort((a, b) => a.id - b.id),
            });

        }
    };
    const handleDiv2 = () => {
        if (state.dragFrom === 'first') {
            const { dragged, data1, data2 } = state;
            const { dataItem, level } = dragged;
            let newData1 = [...data1];
            let newData2 = [...data2];

            const parentItem = state.data1.find(item => item.id === dataItem.parentId);


            let resultValues = [];
            state.data2.forEach(item => {
                let children = [...item.children];
                children.forEach(child => {
                    resultValues.push(child.value);
                })
            })
            //level 1
            if(level.length === 1){
                if(dataItem.value === 'demographic' && dataItem.children.length > 1){
                    setWarningMessage("Consumer PII, Consumer Demographics, and Consumer Basics cannot be selected together.")
                    return
                }else{
                    //if parent doesn't exist in newData2
                    if(!newData2.find(item => item.id === dataItem.id)){
                        newData2.push(dataItem);

                        //if parent exist in newData2
                    }else {
                        let additionalVal = newData1.find(item => item.id === dataItem.id).children;
                        newData2.map((item, i) => {
                            if(item.id === dataItem.id){
                                item.children.push(...additionalVal)
                            }
                        })
                    }

                    //remove this parent item from newData1
                    newData1 = newData1.filter(child => child.id !== dataItem.id)
                }

            //level 2
            } else if(level.length === 2){
            if(!resultValues.find(item => item === dataItem.value) && !newData2.find(item => item.id === dataItem.parentId)) {
                newData2.push({
                        id: parentItem.id,
                        labelText: parentItem.labelText,
                        value: parentItem.value,
                        children: []
                    })
                newData2.map((item, i) => {
                    if(item.id === dataItem.parentId){
                        item.children.push(dataItem)
                    }
                })

            }else if(!resultValues.find(item => item === dataItem.value) && newData2.find(item => item.id === dataItem.parentId)){
                newData2.map((item, i) => {
                    if(item.id === dataItem.parentId){
                        item.children.push(dataItem)
                    }
                })
            }
            newData1 = newData1.map(item => {
                const filteredChildren = item.children.filter(child => child.value !== dataItem.value);
                return {
                    ...item,
                    children: filteredChildren
                };
            });

            }
            if(dataItem.value === "consumer_pii" && isFirstPartySegment){
                console.log("here")
                newData2 = newData2.map(item => {
                    const filteredChildren = item.children.filter(child => child.value !== "consumer_demographics" && child.value !== "consumer_basics");
                    return {
                        ...item,
                        children: filteredChildren
                    };
                });
                newData1.map(item => {
                    if(item.id === dataItem.parentId){
                        if(!item.children.find(child => child.value === "consumer_demographics")){
                            item.children.push(consumer_demographics)
                        }
                        if(!item.children.find(child => child.value === "consumer_basics")){
                            item.children.push(consumer_basics)
                        }
                    }
                })
            }
            if(dataItem.value === "consumer_demographics" && isFirstPartySegment){
                newData2 = newData2.map(item => {
                    const filteredChildren = item.children.filter(child => child.value !== "consumer_pii" && child.value !== "consumer_basics");
                    return {
                        ...item,
                        children: filteredChildren
                    };
                });
                newData1.map(item => {
                    if(item.id === dataItem.parentId){
                        if(!item.children.find(child => child.value === "consumer_pii")){
                            item.children.push(consumer_pii)
                        }
                        if(!item.children.find(child => child.value === "consumer_basics")){
                            item.children.push(consumer_basics)
                        }
                    }
                })
            }
            if(dataItem.value === "consumer_basics" && isFirstPartySegment){
                newData2 = newData2.map(item => {
                    const filteredChildren = item.children.filter(child => child.value !== "consumer_demographics" && child.value !== "consumer_pii");
                    return {
                        ...item,
                        children: filteredChildren
                    };
                });
                newData1.map(item => {
                    if(item.id === dataItem.parentId){
                        if(!item.children.find(child => child.value === "consumer_demographics")){
                            item.children.push(consumer_demographics)
                        }
                        if(!item.children.find(child => child.value === "consumer_pii")){
                            item.children.push(consumer_pii)
                        }
                    }
                })
            }
            setState({
                ...state,
                data1: newData1.filter((item) => item.children.length !== 0).sort((a, b) => a.id - b.id),
                data2: newData2.filter((item) => item.children.length !== 0).sort((a, b) => a.id - b.id),
            });

        }
    };

    const location_properties = ["consumer_pii",
        "consumer_demographics",
        "consumer_basics",
        "business_basics",
        "semcasting_id",
        "ip_sample",
        "email",
        "sha256_email",
        "sha1_email",
        "md5_email",
        "uid2_id",
        "mobile_devices",
        "internet_sites",
        "internet_keywords"];
    const platform_id_sampling = ["acxiom_id",
        "adform_id",
        // "adstra_id",
        "adstra_household_id",
        "adobe_id",
        "audienceacuity_id",
        "blockgraph_id",
        "datatrust_id",
        "freewheel_id",
        "hashed_semcasting_id",
        "inmobi_id",
        "l2_id",
        "mri_id",
        "nexxen_id",
        "npi_id",
        "pubmatic_id",
        "purplelabs_id",
        "purplelab_individual_id",
        "roku_id",
        "spring_serve_id",
        "tradedesk_id",
        "xandr_id"];

    useEffect(() => {

        let resultValues = [];
        state.data2.forEach(item => {
            let children = [...item.children];
            children.forEach(child => {
                resultValues.push(child.value);
            })
        })
        const location_properties_values = [];
        const platform_id_sampling_values = [];

        resultValues.forEach(value => {
            if (location_properties.includes(value)) {
                location_properties_values.push(value);
            }
            if (platform_id_sampling.includes(value)) {
                platform_id_sampling_values.push(value);
            }
        });
        item.formik.setFieldValue('location_properties', location_properties_values);
        item.formik.setFieldValue('platform_id_sampling', platform_id_sampling_values);

        setCount(resultValues.length)
    }, [state.data2]);

    return (
        <>
            {warningMessage && <span className="validation-error">{warningMessage}</span>}
            {/*<div style={{textAlign:'end'}}>Selected Counts: {count}</div>*/}
            <div className={"tree-component-wrapper"}>
                <div
                    style={{
                        display: 'flex',
                        zIndex: '100',
                        width: '40%'
                    }}
                    onDrop={handleDiv1}
                    onDragOver={(e) => {
                        e.preventDefault()
                    }}
                >
                    <TreeList
                        style={{
                            maxHeight: "510px",
                            overflow: "auto",
                            width: "600px",
                        }}
                        expandField={expandField}
                        subItemsField={subItemsField}
                        onExpandChange={onExpandChange1}
                        sortable={{
                            mode: "multiple",
                        }}
                        {...state.dataState1}
                        data={processData1()}
                        onDataStateChange={handleDataState1Change}
                        columns={columns1}
                        onRowClick={onTreeListItemClick1}
                        rowRender={rowForTreeList1}
                    />
                </div>
                <img alt="" src={angleLeftRightIcon}
                     style={{
                         width: "50px",
                         alignSelf: "center",
                         paddingLeft:'5px',
                         paddingRight: '5px'
                     }}
                />
                <div
                    style={{
                        display: 'flex',
                        zIndex: '100',
                        width: '40%'
                    }}
                    onDrop={handleDiv2}
                    onDragOver={(e) => {
                        e.preventDefault()
                    }}
                >
                    <TreeList
                        style={{
                            maxHeight: "510px",
                            overflow: "auto",
                            width: "600px",
                        }}
                        expandField={expandField}
                        subItemsField={subItemsField}
                        onExpandChange={onExpandChange2}
                        sortable={{
                            mode: "multiple",
                        }}
                        {...state.dataState2}
                        data={processData2()}
                        onDataStateChange={handleDataState2Change}
                        columns={columns2}
                        onRowClick={onTreeListItemClick2}
                        rowRender={rowForTreeList2}
                    />
                </div>
            </div>
        </>
    );
}

export const semcastingMeasurementTreeComponent = (item, formik, activateFormBase) => {
    // console.log(item)
    // console.log(formik)
    // console.log(activateFormBase)
    return (
        <>
            <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">
                <label
                    className={`form-label ${item.required ? "required-asterisk" : ""} `}
                >
                    {item.labelText}:
                </label>
                {formik.touched[item.fieldName] &&
                    (formik.errors[item.fieldName] && (
                        <span className="validation-error">
              {formik.errors[item.fieldName]}
            </span>
                    ))}
                <div className="form-group" style={{display: 'flex', flexDirection: 'column'}}>

                    {/*<div className={"tree-component-wrapper"}>*/}
                    <TreeComponent item={item} formik={formik} activateFormBase={activateFormBase}/>
                    {/*</div>*/}
                    {/*        {formik.touched[item.fieldName] &&*/}
                    {/*            (formik.errors[item.fieldName] && (*/}
                    {/*                <span className="validation-error">*/}
                    {/*  {formik.errors[item.fieldName]}*/}
                    {/*</span>*/}
                    {/*            ))}*/}
                </div>
            </div>
        </>
    );
};
export const layoutRender = (layout, formik, activateFormBase, segmentData, creativeTags, ftpConnections) => {
    return layout.map((item) => {
        return (
            <>
                <div
                    key={item}
                    className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12 mb-3"
                >
                    {item?.fieldType === "checkboxGroup" && checkboxGroup(item, formik, activateFormBase)}
                    {item?.fieldType === "semcastingMeasurementTreeComponent" && semcastingMeasurementTreeComponent(item, formik, activateFormBase)}
                    {item?.fieldType === "dragDropListboxGroup" && dragDropListboxGroup(item, formik, activateFormBase)}
                    {item?.fieldType === "trendingCheckboxGroup" && trendingCheckboxGroup(item, formik, activateFormBase, segmentData)}
                    {item?.fieldType === "checkbox" && checkbox(item, formik, activateFormBase)}
                    {item?.fieldType === "button" && button(item, formik, activateFormBase)}
                    {item?.fieldType === "accessCode" && accessCode(item, formik, activateFormBase)}
                    {item?.fieldType === "radioGroup" && radioGroup(item, formik, activateFormBase)}
                    {item?.fieldType === "selectPickerLR" && selectPickerLiveRamp(item, formik, activateFormBase)}
                    {item?.fieldType === "selectPickerG" && selectPickerGoogle(item, formik, activateFormBase)}
                    {item?.fieldType === "selectPickerSX" && selectPickerSemcastingXandr(item, formik, activateFormBase)}
                    {item?.fieldType === "searchSelect" && searchSelectField(item, formik, activateFormBase)}
                    {item?.fieldType === "searchSelectStackAdapt" && searchSelectStackAdaptField(item, formik, activateFormBase)}
                    {item?.fieldType === "selectPickerTag" && selectPickerTag(item, formik, activateFormBase, creativeTags)}
                    {item?.fieldType === "selectPickerFTPConnection" && selectPickerFTPConnection(item, formik, activateFormBase, ftpConnections)}
                    {item?.fieldType === "creativeTagId" && creativeTagId(item, formik, activateFormBase)}
                    {item?.fieldType === "creativeTag" && creativeTag(item, formik, activateFormBase)}
                    {item?.fieldType === "textField" && textField(item, formik, activateFormBase)}
                    {item?.fieldType === "percentOfMediaCostRate" && percentOfMediaCostRateField(item, formik, activateFormBase)}
                    {item?.fieldType === "cpmCap" && cpmCapField(item, formik, activateFormBase)}
                    {item?.fieldType === "cpmField" && cpmField(item, formik, activateFormBase)}
                    {item?.fieldType === "searchSelectTTDPartner" && searchSelectTTDPartnerField(item, formik, activateFormBase)}
                    {item?.fieldType === "searchSelectTTDAdvertiser" && searchSelectTTDAdvertiserField(item, formik, activateFormBase)}
                    {item?.fieldType === "dateRange" && dateRange(item, formik, activateFormBase)}
                    {item?.fieldType === "pricing" && pricing(item, formik, activateFormBase)}
                    {item?.fieldType === "savableFieldsWrapper" &&
                        layoutRender(item.layout, formik, activateFormBase)}
                    {item?.fieldType === "row" ? (
                        <div className="row col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">
                            {item.layout.map((childItem) => {
                                return (
                                    <div
                                        className={`float-left ${childItem.columnClass}`}
                                        key={childItem.value}
                                    >
                                        {layoutRender(childItem.layout, formik, activateFormBase)}
                                    </div>
                                );
                            })}
                        </div>
                    ) : null}
                    {item?.fieldType === "column" ? (
                        <div>
                            {item.layout.map((childItem) => {
                                return layoutRender(childItem.layout, formik, activateFormBase);
                            })}
                        </div>
                    ) : null}
                </div>
            </>
        );
    }, this);
};
