import React, {useEffect, useState} from "react";
import AboutAudienceEdit from "../Components/AudienceDesign/AboutAudienceEdit";
import AudienceSuppressions from "../Components/AudienceDesign/AudienceSuppressions";
import KeywordsSites from "../Components/AudienceDesign/KeywordsSites";
import UploadedRecords from "../Components/AudienceDesign/UploadedRecords";
import {
    getSegments,
    getPpkSegments,
    postSegments,
    putSegments,
    patchSegmentName
} from "../../actions/AudienceDesignActions";
import $, {isEmptyObject} from "jquery";
import "./AudienceDesign.css"
import {useFormik} from "formik";
import * as Yup from "yup";
import {Button, ButtonGroup} from "@progress/kendo-react-buttons";
import {semcastAxios, showErrorToastMessage} from "../../Utils/Common";

import {Card, CardBody, CardTitle} from "@progress/kendo-react-layout";
import {segmentNameValidator} from "./validators";
import {SEGMENT_DETAILS_API, SEGMENT_ID_PLACE_HOLDER, SEGMENTS, SUCCESS_STATUS} from "../../api/constants";
import {useHistory} from "react-router-dom";
import {isEqual, omit} from 'lodash';
import {Tooltip} from "@progress/kendo-react-tooltip";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import AdvancedTargetingTreeListEdit from "../Components/AudienceDesign/AdvancedTargetingTreeListEdit";
import {Label} from "@progress/kendo-react-labels";
import {FieldWrapper} from "@progress/kendo-react-form";
import {RadioGroup} from "@progress/kendo-react-inputs";
import LegislativeDistricts from "../Components/AudienceDesign/LegislativeDistricts";
import Naics from "../Components/AudienceDesign/Naics";
import Locations from "../Components/AudienceDesign/Locations";
import {DateInput, DateRangePicker, DateRangePickerPopupSettings} from "@progress/kendo-react-dateinputs";
import moment from "moment/moment";
// import testData from "./testResult.json"

const AudienceDesignEdit = () => {
    const searchParams = new URLSearchParams(document.location.search)
    const segmentId = searchParams.get('id');
    const type = searchParams.get('type');
    const name = searchParams.get("name");
    const [pageLoader, setPageLoader] = useState(false);
    const destinationUrl = "segmentation-dashboard";
    const [targetingData, setTargetingData] = useState({});
    const [consumerDatesWarningMsg, setConsumerDatesWarningMsg] = useState("");
    // Sets initial target type to the target type of the segment being edited.
    const [targetType, setTargetType] = useState("");
    const [originalTargetType, setOriginalTargetType] = useState("");
    const [segmentNameChanged, setSegmentNameChanged] = useState(false);
    const [saveOperation, setSaveOperation] = useState(false);
    const [saveAsOperation, setSaveAsOperation] = useState(false);
    const [segmentNameValid, setSegmentNameValid] = useState(true);
    const [segmentName, setSegmentName] = useState("");
    const [subText, setSubText] = useState(false);
    const history = useHistory();
    const [consumerVisitDateMax, setConsumerVisitDateMax] = useState(new Date());
    const anchor = React.useRef(null);
    const [stateEqual, setStateEqual] = useState(true);
    const targetTypes = [
        {label: "Consumer", value: "CONSUMER"},
        {label: "Business", value: "BUSINESS", disabled: originalTargetType === "CONSUMER"}
    ];
    const [selectionType, setSelectionType] = useState("DISTINCT");
    const selectionTypes = [
        {label: "Chosen value can be in ANY selection (OR statement)", value: "DISTINCT"},
        {label: "Chosen value can be in ALL selections (AND statement)", value: "COMMON"}
    ]
    const [originalState, setOriginalState] = useState({});
    const [confirmSaveOpen, setConfirmSaveOpen] = useState(null);
    const [confirmSaveAsOpen, setConfirmSaveAsOpen] = useState(null);
    const [audienceState, setAudienceState] = useState({
        segment_name: "",
        originalSegmentName: name,
        group_id: 0,
        notes: "",
        keywords: "",
        sites: "",
        exclude_segment_id: [],
        merge_type: "DISTINCT",
        target_type: "",
        original_target_type: "",
        age_range: [],
        digital_activity_index: [],
        first_party_location_focus: "",
        gender: [],
        home_value_cd: [],
        homeowner_flg: [],
        homeOwner: [],
        income_cd: [],
        inflation_sensitivity_index: [],
        social_matrix: [],
        reg_party_cd: [],
        sem_ethnic_cd: [],
        sem_ethnic_region_cd: [],
        districts: [],
        employees_total_cd: [],
        naics: [],
        revenue_cd: [],
        city: [],
        state: [],
        zip3: [],
        zip5: [],
        acxiomLifestyle: [],
        analyticsIQ: [],
        consumer: [],
        // healthWise: [],
        hcpspecialtySegmentsBusiness: [],
        // FD:10912
        // i360: [],
        // iqvia: [],
        knowWhoConsumer: [],
        l2Data: [],
        datatrust: [],
        smllearning: [],
        personalFinance: [],
        politicalAffiliations: [],
        purpleLab: [],
        purpleLabHCP: [],
        purpleLabHPD: [],
        salesintel: [],
        salesinteltechno: [],
        specialtySegments: [],
        specialtySegmentsBusiness: [],
        spectrumMedia: [],
        // qwarry: [],
        travelSegments: [],
        sportsAttendees: [],
        titleMatching: [],
        segment_date_start: "",
        segment_date_end: ""
    });
    const [primaryKeys] = useState({
        age_range: [],
        digital_activity_index: [],
        gender: [],
        home_value_cd: [],
        homeowner_flg: [],
        income_cd: [],
        inflation_sensitivity_index: [],
        social_matrix: [],
        reg_party_cd: [],
        sem_ethnic_cd: [],
        sem_ethnic_region_cd: [],
        districts: [],
        employees_total_cd: [],
        naics: [],
        revenue_cd: [],
        city: [],
        state: [],
        zip3: [],
        zip5: []
    })
    const [summaryState, setSummaryState] = useState({
        pct_served: 0,
        billing_rate: 0,
        total_business_devices: 0,
        total_business_network_ip_addresses: 0,
        total_business_wifi_ip_addresses: 0,
        total_businesses: 0,
        total_household_devices: 0,
        total_household_network_ip_addresses: 0,
        total_household_wifi_ip_addresses: 0,
        total_households: 0,
        total_people: 0,
        total_phones: 0,
        total_records_uploaded: 0,
        total_top_business_devices: 0,
        total_top_household_devices: 0,
        total_unique_addresses: 0,
        total_unique_emails: 0,
    });

    // const audienceDesignFormik = useFormik({
    //     initialValues: {
    //         segment_name: "",
    //     },
    //     validationSchema: Yup.object().shape({
    //         segment_name: Yup.string()
    //             .required("Segment Name is required")
    //             .matches(segmentNameRegExp, "Name must be at 3-64 characters and only contains letters, numbers, hyphens and underscores")
    //     }),
    // });

    const handleAudienceState = (state) => {
        setAudienceState(state);
    };

    const handleSegmentName = (name) => {
        setSegmentName(name);
    };

    const handleTargetType = (e) => {
        setTargetType(e.value);
        handleAudienceState(prevState => {
                return {...prevState, target_type: e.value}
            }
        );
    };

    function Redirect() {
        history.push("/segmentation-dashboard");
    }

    const fetchSegments = async () => {
        try{
            await semcastAxios.get(SEGMENT_DETAILS_API.replace(SEGMENT_ID_PLACE_HOLDER, segmentId),
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    }
                }).catch(function (error) {
                if (error.response) {
                    if(error.response.data.code === 401 || error.response.data.code === 403){
                        showErrorToastMessage(error + " Your account did not have the permission to proceed, please contact support", 10);
                        setTimeout(function() {
                            Redirect();
                        }, 7000);
                    }else{
                        showErrorToastMessage(error + " Please contact support", 10);
                        setTimeout(function() {
                            Redirect();
                        }, 7000);
                    }
                }
            }).then((res)=>{
                setEditableValues(res.data)
            });
        }catch (error) {
            console.log("fetchSegmentsError=", error);
        }finally{

        }
    };
    function processPairData(input) {
        let result = [];
        let pairs = {
            "01": "02",
            "03": "04",
            "05": "06",
            "07": "08",
            "09": "10"
        };

        for (let i = 0; i < input.length; i++) {
            if (input[i] === "00") {
                result.push(["00"]);
            } else if (pairs[input[i]] && input[i + 1] === pairs[input[i]]) {
                result.push([input[i], input[i + 1]]);
                i++; // Skip the next item since it's already paired
            } else {
                result.push([input[i]]);
            }
        }
        return result;
    }
    const setEditableValues = (res) => {
        let responseAudienceData = {
            group_id: res.data.group_id,
            segment_name: res.data.segment_name,
            originalSegmentName: res.data.segment_name,
            target_type: res.data.segment_design.target_type,
            original_target_type: res.data.segment_design.target_type,
            notes: res.data.notes ? res.data.notes : "",
            keywords: res.data.segment_design.keywords ? res.data.segment_design.keywords : "",
            sites: res.data.segment_design.sites ? res.data.segment_design.sites : "",
            exclude_segment_id: res.data.segment_design.exclude_segment_id,
            age_range: res.data.segment_design.primaryData && res.data.segment_design.primaryData.age_range ? res.data.segment_design.primaryData.age_range : [],
            city: res.data.segment_design.primaryData && res.data.segment_design.primaryData.city ? res.data.segment_design.primaryData.city : [],
            digital_activity_index: res.data.segment_design.primaryData && res.data.segment_design.primaryData.digital_activity_index ? processPairData(res.data.segment_design.primaryData.digital_activity_index) : [],
            districts: res.data.segment_design.primaryData && res.data.segment_design.primaryData.districts ? res.data.segment_design.primaryData.districts : [],
            employees_total_cd: res.data.segment_design.primaryData && res.data.segment_design.primaryData.employees_total_cd ? res.data.segment_design.primaryData.employees_total_cd : [],
            first_party_location_focus: res.data.first_party_location_focus ? res.data.first_party_location_focus : "",
            gender: res.data.segment_design.primaryData && res.data.segment_design.primaryData.gender ? res.data.segment_design.primaryData.gender : [],
            home_value_cd: res.data.segment_design.primaryData && res.data.segment_design.primaryData.home_value_cd ? res.data.segment_design.primaryData.home_value_cd : [],
            homeowner_flg: res.data.segment_design.primaryData && res.data.segment_design.primaryData.homeowner_flg ? res.data.segment_design.primaryData.homeowner_flg :[],
            income_cd: res.data.segment_design.primaryData && res.data.segment_design.primaryData.income_cd ? res.data.segment_design.primaryData.income_cd : [],
            inflation_sensitivity_index: res.data.segment_design.primaryData && res.data.segment_design.primaryData.inflation_sensitivity_index ? processPairData(res.data.segment_design.primaryData.inflation_sensitivity_index) : [],
            naics: res.data.segment_design.primaryData && res.data.segment_design.primaryData.naics ? res.data.segment_design.primaryData.naics : [],
            reg_party_cd: res.data.segment_design.primaryData && res.data.segment_design.primaryData.reg_party_cd ? res.data.segment_design.primaryData.reg_party_cd : [],
            revenue_cd: res.data.segment_design.primaryData && res.data.segment_design.primaryData.revenue_cd ? res.data.segment_design.primaryData.revenue_cd : [],
            sem_ethnic_cd: res.data.segment_design.primaryData && res.data.segment_design.primaryData.sem_ethnic_cd ? res.data.segment_design.primaryData.sem_ethnic_cd : [],
            sem_ethnic_region_cd: res.data.segment_design.primaryData && res.data.segment_design.primaryData.sem_ethnic_region_cd ? res.data.segment_design.primaryData.sem_ethnic_region_cd : [],
            social_matrix: res.data.segment_design.primaryData && res.data.segment_design.primaryData.social_matrix ? res.data.segment_design.primaryData.social_matrix : [],
            state: res.data.segment_design.primaryData && res.data.segment_design.primaryData.state ? res.data.segment_design.primaryData.state : [],
            zip3: res.data.segment_design.primaryData && res.data.segment_design.primaryData.zip3 ? res.data.segment_design.primaryData.zip3 : [],
            zip5: res.data.segment_design.primaryData && res.data.segment_design.primaryData.zip5 ? res.data.segment_design.primaryData.zip5 : [],
            acxiomLifestyle: res.data.segment_design.acxiomLifestyle ? res.data.segment_design.acxiomLifestyle : [],
            analyticsIQ: res.data.segment_design.analyticsIQ ? res.data.segment_design.analyticsIQ : [],
            consumer: res.data.segment_design.consumer ? res.data.segment_design.consumer : [],
            // healthWise: res.data.segment_design.healthWise ? res.data.segment_design.healthWise : [],
            hcpspecialtySegmentsBusiness: res.data.segment_design.hcpspecialtySegmentsBusiness ? res.data.segment_design.hcpspecialtySegmentsBusiness : [],
            homeOwner: res.data.segment_design.homeOwner ? res.data.segment_design.homeOwner : [],
            // FD:10912
            // i360: res.data.segment_design.i360 ? res.data.segment_design.i360 : [],
            // iqvia: res.data.segment_design.iqvia ? res.data.segment_design.iqvia : [],
            knowWhoConsumer: res.data.segment_design.knowWhoConsumer ? res.data.segment_design.knowWhoConsumer : [],
            l2Data: res.data.segment_design.l2Data ? res.data.segment_design.l2Data : [],
            datatrust: res.data.segment_design.datatrust ? res.data.segment_design.datatrust : [],
            smllearning: res.data.segment_design.smllearning ? res.data.segment_design.smllearning : [],
            merge_type: res.data.segment_design.merge_type ? res.data.segment_design.merge_type : "",
            personalFinance: res.data.segment_design.personalFinance ? res.data.segment_design.personalFinance : [],
            politicalAffiliations: res.data.segment_design.politicalAffiliations ? res.data.segment_design.politicalAffiliations : [],
            purpleLab: res.data.segment_design.purpleLab ? res.data.segment_design.purpleLab : [],
            purpleLabHCP: res.data.segment_design.purpleLabHCP ? res.data.segment_design.purpleLabHCP : [],
            purpleLabHPD: res.data.segment_design.purpleLabHPD ? res.data.segment_design.purpleLabHPD : [],
            salesintel: res.data.segment_design.salesintel ? res.data.segment_design.salesintel : [],
            salesinteltechno: res.data.segment_design.salesinteltechno ? res.data.segment_design.salesinteltechno : [],
            specialtySegments: res.data.segment_design.specialtySegments ? res.data.segment_design.specialtySegments : [],
            specialtySegmentsBusiness: res.data.segment_design.specialtySegmentsBusiness ? res.data.segment_design.specialtySegmentsBusiness : [],
            spectrumMedia: res.data.segment_design.spectrumMedia ? res.data.segment_design.spectrumMedia : [],
            // qwarry: res.data.segment_design.qwarry ? res.data.segment_design.qwarry : [],
            sportsAttendees: res.data.segment_design.sportsAttendees ? res.data.segment_design.sportsAttendees : [],
            titleMatching: res.data.segment_design.titleMatching ? res.data.segment_design.titleMatching : [],
            segment_date_start: res.data.segment_design.primaryData && res.data.segment_design.primaryData.segment_date_start ? res.data.segment_design.primaryData.segment_date_start : "",
            segment_date_end: res.data.segment_design.primaryData && res.data.segment_design.primaryData.segment_date_end ? res.data.segment_design.primaryData.segment_date_end : "",
            travelSegments: res.data.segment_design.travelSegments ? res.data.segment_design.travelSegments : [],
        }
        console.log(responseAudienceData)
        setOriginalState(responseAudienceData);
        setAudienceState(responseAudienceData);
        // setAudienceState(testData);
        setSelectionType(responseAudienceData.merge_type)
        setTargetType(responseAudienceData.target_type);
        setOriginalTargetType(responseAudienceData.original_target_type);
        setSegmentName(res.data.segment_name);

        // // Summary Data
        let responseSummaryData = {
            pct_served: res.data.pct_served ? res.data.pct_served : 0,
            billing_rate: res.data.billing_rate ? res.data.billing_rate : 0,
            total_business_devices: res.data.total_business_devices ? res.data.total_business_devices : 0,
            total_business_network_ip_addresses: res.data.total_business_network_ip_addresses ? res.data.total_business_network_ip_addresses : 0,
            total_business_wifi_ip_addresses: res.data.total_business_wifi_ip_addresses ? res.data.total_business_wifi_ip_addresses : 0,
            total_businesses: res.data.total_businesses ? res.data.total_businesses : 0,
            total_household_devices: res.data.total_household_devices ? res.data.total_household_devices : 0,
            total_household_network_ip_addresses: res.data.total_household_network_ip_addresses ?  res.data.total_household_network_ip_addresses : 0,
            total_household_wifi_ip_addresses: res.data.total_household_wifi_ip_addresses ? res.data.total_household_wifi_ip_addresses : 0,
            total_households: res.data.total_households ?  res.data.total_households : 0,
            total_people: res.data.total_people ?  res.data.total_people : 0,
            total_phones: res.data.total_phones ?  res.data.total_phones : 0,
            total_records_uploaded: res.data.total_records_uploaded ?  res.data.total_records_uploaded : 0,
            total_top_business_devices: res.data.total_top_business_devices ?  res.data.total_top_business_devices : 0,
            total_top_household_devices: res.data.total_top_household_devices ?  res.data.total_top_household_devices : 0,
            total_unique_addresses: res.data.total_unique_addresses ?  res.data.total_unique_addresses : 0,
            total_unique_emails: res.data.total_unique_emails ?  res.data.total_unique_emails : 0
        }
        setSummaryState(responseSummaryData);
    };

    useEffect(() => {
        if (Object.keys(originalState).length > 0 && Object.keys(audienceState).length > 0) {
            setStateEqual(isEqual(omit(originalState, ["segment_name"]), omit(audienceState, ["segment_name"])));
        }
    }, [originalState, audienceState]);
    useEffect(() => {
        console.log("originalState",originalState)
        console.log("audienceState",audienceState)
    }, [stateEqual]);

    useEffect(() => {
        if (segmentId) fetchSegments();
        setPageLoader(true);
    }, [segmentId]);

    const handleSubmit = async(e, formik) => {
        await formik.submitForm();
        //formik.isValid was returning true when should be false
        //maybe use setTimeout to wait 3 sec - aka ActivateSegmentForm.js
        if (formik.values.segment_name === "") return;
        if (formik.isValid) {
            try {
                setPageLoader(true);
                const data = getData();
                e.target.parentElement.disabled = true;

                postSegments(
                    data
                ).then((res) => {
                    setPageLoader(false);
                    if (res && res.status === 200) {
                        setTimeout(() => {
                            window.location.href = destinationUrl;
                            e.target.parentElement.disabled = false;
                        }, 3000);
                    } else {
                        e.target.parentElement.disabled = false;
                    }
                });
            } catch (error) {
                // Re-enable button if failure.
                e.target.parentElement.disabled = false;
                showErrorToastMessage("Error sending request. Request not sent.");
            }
        } else {
            showErrorToastMessage("Error sending request. Request not sent.");
            e.target.parentElement.disabled = false;
        }
    }

    const getData = () => {
        // const audienceFileId = audienceDesignFormik.values.audience_file_id;
        // const segmentName = audienceState.segment_name;
        const first_party_location_focus = audienceState.first_party_location_focus;
        const notes = audienceState.notes;
        const keywords = audienceState.keywords;
        const sites = audienceState.sites;
        const groupId = audienceState.group_id;
        const segmentDesign = buildSegmentDesign();
        let data = {
            first_party_location_focus: first_party_location_focus,
            // segment_name: audienceState.segment_name,
            segment_name: segmentName ? segmentName : audienceState.segment_name,
            notes: notes,
            keywords: keywords,
            sites: sites,
            group_id:groupId,
            // audience_file_id: audienceFileId,
            segment_design:segmentDesign
        };
        if (data.sites === '') delete data.sites;
        if (data.keywords === '') delete data.keywords;
        return data;
        // return {
        //     first_party_location_focus: first_party_location_focus,
        //     segment_name: audienceState.segment_name,
        //     notes: notes,
        //     keywords: keywords,
        //     sites: sites,
        //     group_id:groupId,
        //     // audience_file_id: audienceFileId,
        //     segment_design:segmentDesign
        // }
    };

    const buildSegmentDesign = () => {
        const consumer = audienceState.consumer;
        const datatrustData = audienceState.datatrust;
        const sportsAttendees = audienceState.sportsAttendees;
        const politicalAffiliations = audienceState.politicalAffiliations;
        const mergeType = audienceState.merge_type;
        const targetType = audienceState.target_type;
        const primaryData = buildPrimaryData(targetType);
        const homeOwnerData = audienceState.homeOwner;
        const personalFinanceData = audienceState.personalFinance;
        const titleMatchingData = audienceState.titleMatching;
        const specialtyData = audienceState.specialtySegments;
        const businessSpecialtyData = audienceState.specialtySegmentsBusiness;
        const travelData = audienceState.travelSegments;
        // const socialMatrixData = targetType === "CONSUMER" ? audienceDesignFormik.values.segment_design.socialMatrix : [];
        const spectrumMediaData = audienceState.spectrumMedia;
        // const qwarryData = audienceState.qwarry;
        const acxiomLifestyleData = audienceState.acxiomLifestyle;
        const analyticsIQData = audienceState.analyticsIQ;
        // const affinitySolutionsData = targetType === "CONSUMER" ? audienceDesignFormik.values.segment_design.affinitySolutions : [];
        // const healthWiseData = audienceState.healthWise;
        const hcpspecialtySegmentsData = audienceState.hcpspecialtySegmentsBusiness;
        const knowWhoConsumerData = audienceState.knowWhoConsumer;
        const purpleLabData = audienceState.purpleLab;
        const purpleLabHCPData = audienceState.purpleLabHCP;
        const purpleLabHPDData = audienceState.purpleLabHPD;
        const l2DataData = audienceState.l2Data;
        // FD:10912
        // const i360Data = audienceState.i360;
        // const throtleData = targetType === "CONSUMER" ? audienceDesignFormik.values.segment_design.throtle : [];
        // const iqviaData = audienceState.iqvia;
        const salesintelData = audienceState.salesintel;
        const salesinteltechnoData = audienceState.salesinteltechno;
        const smllearningData = audienceState.smllearning;
        const excludeSegmentIds = audienceState.exclude_segment_id;

        let fileData = targetType === "CONSUMER" ? {
            consumer: consumer,
            datatrust: datatrustData,
            smllearning:smllearningData,
            politicalAffiliations: politicalAffiliations,
            sportsAttendees: sportsAttendees,
            personalFinance: personalFinanceData,
            homeOwner: homeOwnerData,
            titleMatching:titleMatchingData,
            specialtySegments: specialtyData,
            merge_type: mergeType,
            target_type: targetType,
            primaryData: primaryData,
            acxiomLifestyle:acxiomLifestyleData,
            analyticsIQ:analyticsIQData,
            // healthWise:healthWiseData,
            // FD:10912
            // i360:i360Data,
            // iqvia:iqviaData,
            knowWhoConsumer:knowWhoConsumerData,
            l2Data:l2DataData,
            purpleLab:purpleLabData,
            purpleLabHCP:purpleLabHCPData,
            purpleLabHPD:purpleLabHPDData,
            salesintel:salesintelData,
            salesinteltechno:salesinteltechnoData,
            spectrumMedia: spectrumMediaData,
            // qwarry: qwarryData,
            // throtle:throtleData,
            exclude_segment_id: excludeSegmentIds,
        } : {
            primaryData: primaryData,
            merge_type: mergeType,
            target_type: targetType,
            hcpspecialtySegmentsBusiness: hcpspecialtySegmentsData,
            specialtySegmentsBusiness: businessSpecialtyData,
            titleMatching: titleMatchingData,
            travelSegments: travelData,
            purpleLabHCP: purpleLabHCPData,
            exclude_segment_id: excludeSegmentIds,
        }
        return stripEmptyValuesFromObject(fileData);
    };

    const buildPrimaryData = (targetType) => {
        if (targetType === "BUSINESS") {
            return buildBusinessSegments();
        } else {
            return buildPeopleSegments();
        }
    };

    const buildPeopleSegments = () => {
        return {
            age_range: audienceState.age_range,
            income_cd: audienceState.income_cd,
            gender: audienceState.gender,
            sem_ethnic_cd: audienceState.sem_ethnic_cd,
            sem_ethnic_region_cd: audienceState.sem_ethnic_region_cd,
            reg_party_cd: audienceState.reg_party_cd,
            districts: audienceState.districts,
            digital_activity_index: audienceState.digital_activity_index.flat(),
            inflation_sensitivity_index: audienceState.inflation_sensitivity_index.flat(),
            social_matrix: audienceState.social_matrix,
            home_value_cd: audienceState.home_value_cd,
            homeowner_flg: audienceState.homeowner_flg,
            zip3: audienceState.zip3,
            zip5: audienceState.zip5,
            state: audienceState.state,
            city: audienceState.city,
            segment_date_start: originalTargetType === "BUSINESS" && targetType === "CONSUMER" ? audienceState.segment_date_start : "",
            segment_date_end: originalTargetType === "BUSINESS" && targetType === "CONSUMER" ? audienceState.segment_date_end : ""
        }
    };

    const buildBusinessSegments = () => {
        return {
            revenue_cd: audienceState.revenue_cd,
            employees_total_cd: audienceState.employees_total_cd,
            naics: audienceState.naics,
            zip3: audienceState.zip3,
            zip5: audienceState.zip5,
            state: audienceState.state,
            city: audienceState.city,
            segment_date_start: originalTargetType === "BUSINESS" && targetType === "CONSUMER" ? audienceState.segment_date_start : "",
            segment_date_end: originalTargetType === "BUSINESS" && targetType === "CONSUMER" ? audienceState.segment_date_end : ""
        }
    };

    /**
     * removes: nulls, empty strings, empty objects, and empty arrays from obj
     * this is a recursive function if obj[propName] is an object this will
     * @param obj {Object} any object
     * @returns {Object} object with empty values removed
     */
    const stripEmptyValuesFromObject = (obj) => {
        for (let propName in obj) {
            if ($.isPlainObject(obj[propName])) {
                obj[propName] = stripEmptyValuesFromObject(obj[propName])
            }
            if (obj[propName] === null || obj[propName] === '' || $.isEmptyObject(obj[propName]) || ($.isArray(obj[propName]) && !obj[propName].length)) {
                delete obj[propName];
            }
        }
        return obj;
    };

    const handleSegmentNameChanged = (changed) => {
        setSegmentNameChanged(changed);
    };

    const handleSegmentNameValid = (valid) => {
        setSegmentNameValid(valid);
    };

    const isValidSegmentName = (e, state, saveAs) => {
        if (saveAs) {
            if (segmentName !== "" && state.originalSegmentName === segmentName) {
                setSubText(true);
                setSegmentNameChanged(false);
                handleSegmentNameValid(false);
                return false;
            }
            if (segmentName === "" && state.originalSegmentName === state.segment_name) {
                setSubText(true);
                setSegmentNameChanged(false);
                handleSegmentNameValid(false);
                return false;
            }
            setSegmentNameChanged(true);
        }
        const msg = segmentNameValidator(segmentName);
        if (msg.length > 0) {
            handleSegmentNameValid(false);
            return false;
        }
        return true;
    }
    useEffect(() => {
        // console.log(segmentName);
    }, [segmentName]);
    const handleConfirmationWindow = (e, state, saveAs) =>{
        // if(!saveAs){
        //     setConfirmSaveOpen(true)
        // }else{
        //     setConfirmSaveAsOpen(true)
        // }
        try {
            setPageLoader(true);
            const data = getData();
            console.log(data)
            console.log(segmentName)
            // if (!saveAs) {
            //     if (segmentName != "") {
            //         data.segment_name = segmentName;
            //     } else {
            //         data.segment_name = state.segment_name;
            //     }
            // }
            e.target.parentElement.disabled = true;
            /**
             * Notes from ADS 1x:
             * At Johns request: Force "Save As" to be used even if the user clicks "Save", when segment dates are chosen.
             */
            if (data.segment_design && data.segment_design.primaryData &&
                data.segment_design.primaryData.segment_date_start) {
                saveAs = true;
            }
            // if (!saveAs && segmentNameChanged && stateEqual) {
            //     patchSegmentName({"rename_to":segmentName}, segmentId)
            //         .then((res) => {
            //             setPageLoader(false);
            //             if (res && res.status === 200) {
            //                 setTimeout(() => {
            //                     window.location.href = destinationUrl;
            //                     e.target.parentElement.disabled = false;
            //                 }, 3000);
            //             } else {
            //                 e.target.parentElement.disabled = false;
            //             }
            //         });
            // } else {
            //     if (!segmentNameChanged && stateEqual)  {
            //         showErrorToastMessage("No changes have been made.");
            //         // e.target.parentElement.disabled = false;
            //     } else {
                    putSegments(
                        data, segmentId, saveAs
                    ).then((res) => {
                        setPageLoader(false);
                        if (res && res.status === 200) {
                            setTimeout(() => {
                                window.location.href = destinationUrl;
                                e.target.parentElement.disabled = false;
                            }, 3000);
                        } else {
                            e.target.parentElement.disabled = false;
                        }
                    });
                // }
            // }
        } catch (error) {
            // Re-enable button if failure.
            e.target.parentElement.disabled = false;
            showErrorToastMessage("Error sending request. Request not sent.");
        }finally {
            setConfirmSaveAsOpen(false);
            setConfirmSaveOpen(false);
        }
    }
    const handleSave = (e, state, saveAs) => {
        if (!isValidSegmentName(e, state, saveAs)) {
            console.log("NotValid segmentName=", segmentName)
            console.log("NotValid state.segment_name=", state.segment_name);
            document.getElementById("segment_name").focus();
            return;
        }
        if (!saveAs) {
            setSaveOperation(true);
            setSaveAsOperation(false);
        } else {
            setSaveAsOperation(true);
            setSaveOperation(false);
        }
        try {
            if(!saveAs){
                setConfirmSaveOpen(true)
            }else{
                setConfirmSaveAsOpen(true)
            }
        }catch (error){
            showErrorToastMessage("Error occured:", error);
        }
        
    };
    const handleSelectionType = (e) => {
        setSelectionType(e.value);
        handleAudienceState(prevState => {
                return {...prevState, merge_type: e.value}
            }
        );
    };

    const CustomStartDateInput = (props) => {
        return (
            <label>
                {/*<span>Start</span> <br/>*/}
                <DateInput {...props} format="yyyy-MM-dd" placeholder="Start yyyy/mm/dd" label={undefined}/>
            </label>
        )
    };
    const CustomEndDateInput = (props) => {
        let style = "";
        if(props.value > props.max){
            style = "invalid-date-range";
            setConsumerDatesWarningMsg("Date Selection Out Of Range (180 days).")
        }else{
            setConsumerDatesWarningMsg("")
        }
        return (
            <label>
                <DateInput className={style} {...props} format="yyyy-MM-dd" placeholder="End yyyy/mm/dd" label={undefined}/>
            </label>
        )
    };
    const onConsumerVisitDatesChange = (e) => {
        console.log(e);
        const start = e.value.start;
        // Dynamically set the maximum allowed end date
        if (start) {
            const maxDate = new Date(start);
            maxDate.setDate(maxDate.getDate() + 180); // Add 180 days to the start date
            setConsumerVisitDateMax(maxDate);
        } else {
            setConsumerVisitDateMax(null); // Reset if start date is cleared
        }
        handleAudienceState(prevState => {
            return {
                ...prevState,
                segment_date_start: moment(e.value.start).format("YYYY-MM-DD"),
                segment_date_end: moment(e.value.end).format("YYYY-MM-DD")
            };
        });
    };

    //Position settings for the calendar dropdown
    const popupSettings: DateRangePickerPopupSettings = {
        anchorAlign: {
            horizontal: 'right',
            vertical: 'bottom'
        },
        popupAlign: {
            horizontal: 'right',
            vertical: 'top'
        }
    };

    return (
        <>
            <div className={"audience-design-outer-div"}>
                <div className="audience-design-content">
                    {confirmSaveOpen &&
                        <div className="col-xl-12 col-lg-12 col-md-12 "
                             id="collapseMerge">
                            <Dialog
                                title={"Save"}
                                width={420}
                                closeIcon={false}
                                className={"dialog-standard"}
                            >
                                <div
                                    style={{
                                        paddingTop: "0.75rem",
                                        fontSize: "16px",
                                        maxHeight: "20rem",
                                        overflowY: "auto",
                                    }}
                                >
                                    <div>Save will modify the current segment.</div>
                                    <div style={{paddingTop: "0.75rem"}}>Do you wish to save the changes?</div>
                                    <div style={{
                                        paddingTop: "0.75rem",
                                        fontSize: "14px",
                                        paddingBottom: "0.75rem",
                                    }}>(If you wish to create a separate segment, use Save As instead.)
                                    </div>

                                </div>
                                <DialogActionsBar layout={"end"}>
                                        <Button
                                            // className="dialog-cancel"
                                            className={"button-standard button-white"}
                                            onClick={() => {
                                                setConfirmSaveOpen(undefined)
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            // className="dialog-save"
                                            className={"button-standard button-submit"}
                                            // disabled={}
                                            onClick={(e) => handleConfirmationWindow(e, audienceState, false)}
                                        >
                                            Save
                                        </Button>
                                    </DialogActionsBar>
                            </Dialog>
                        </div>
                    }
                    {confirmSaveAsOpen &&
                        <div className="col-xl-12 col-lg-12 col-md-12 "
                             id="collapseMerge">
                            <Dialog
                                title={"Save As"}
                                width={420}
                                closeIcon={false}
                                className={"dialog-standard"}
                            >
                                <div
                                    style={{
                                        paddingTop: "0.75rem",
                                        fontSize: "16px",
                                        maxHeight: "20rem",
                                        overflowY: "auto",
                                    }}
                                >
                                    <div>
                                        Save As will create a separate segment, by applying the filters to the original
                                        segment.
                                    </div>
                                    <div style={{
                                        paddingTop: "0.75rem",
                                        fontSize: "14px",
                                        paddingBottom: "0.75rem",
                                    }}>(i.e. Changing location filter from MA to NH will result in 0 records because the
                                        original segment only contains MA records.)
                                    </div>
                                    <div>
                                        Do you wish to continue Save As?
                                    </div>

                                </div>
                                <DialogActionsBar layout={"end"}>
                                    <Button
                                        // className="dialog-cancel"
                                        className={"button-standard button-white"}
                                        onClick={() => {
                                            setConfirmSaveAsOpen(undefined)
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        // className="dialog-save"
                                        className={"button-standard button-submit"}
                                        // disabled={}
                                        onClick={(e) => handleConfirmationWindow(e, audienceState, true)}
                                    >
                                        Save As
                                    </Button>
                                </DialogActionsBar>
                            </Dialog>
                        </div>
                    }
                    <UploadedRecords
                        targetType={targetType}
                        summaryState={summaryState}/>
                    <div className={"audience-design-spacer"}></div>
                    <AboutAudienceEdit
                        // formik={audienceDesignFormik}
                        saveOperation={saveOperation}
                        saveAsOperation={saveAsOperation}
                        audienceState={audienceState}
                        handleAudienceState={handleAudienceState}
                        segmentNameChanged={segmentNameChanged}
                        handleSegmentNameChanged={handleSegmentNameChanged}
                        handleSegmentNameValid={handleSegmentNameValid}
                        segmentNameValid={segmentNameValid}
                        setSegmentNameValid={setSegmentNameValid}
                        handleSegmentName={handleSegmentName}
                        subText={subText}
                        setSubText={setSubText}
                    />
                    <div className={"audience-design-spacer"}></div>
                    <Card className={"section-card"}>
                        <CardTitle>
                            <Label className="section-label">3RD PARTY TARGETING</Label>
                            <div className={"selection-type-wrapper"}>
                                <FieldWrapper>
                                    <Label>Selection Type</Label>
                                    {/*Moved here - demo feedback - 1/27/23*/}
                                    <RadioGroup
                                        data={selectionTypes}
                                        value={selectionType}
                                        onChange={handleSelectionType}
                                        layout="vertical"
                                    />
                                </FieldWrapper>
                            </div>
                        </CardTitle>
                        <CardBody>
                            <legend className={'k-form-legend spacingLine'} style={{margin: 0}}></legend>
                            <CardBody>
                                <div className="content-outer-wrapper">
                                    <div className={"above-tree-components-wrapper"} style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        flexDirection: "row",
                                        alignItems: "stretch"
                                    }}>
                                        <FieldWrapper className={"targeting-first-column-field"}>
                                            <Label className={"field-label"}>Target Type</Label>
                                            <RadioGroup
                                                data={targetTypes}
                                                value={targetType}
                                                onChange={handleTargetType}
                                                layout="vertical"
                                                style={{width: "100%", fontSize: ".9rem"}}
                                            />
                                        </FieldWrapper>
                                        <div className={"locations-wrapper"}>
                                            <FieldWrapper>
                                                <Label className={"field-label"}>Locations</Label>
                                                <Locations
                                                    audienceState={audienceState}
                                                    handleAudienceState={handleAudienceState}
                                                />
                                            </FieldWrapper>
                                        </div>
                                        {targetType === "CONSUMER" &&
                                            <div className="legislative-district-wrapper">
                                                <FieldWrapper>
                                   <span className={"tooltip-label-and-icon-wrapper"}>
                                                    <Label className={"field-label"}>Legislative Districts</Label>
                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "15rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                              <i className="fa fa-question-circle-o tooltip-question-icon"
                                                                 aria-hidden="true"
                                                                 title={"Legislative Districts refer to areas from which members are elected to state or equivalent entity legislatures."}></i>
                                                    </Tooltip>
                                     </span>
                                                    <LegislativeDistricts
                                                        audienceState={audienceState}
                                                        handleAudienceState={handleAudienceState}
                                                    />
                                                </FieldWrapper>
                                            </div>
                                        }
                                        {targetType === "BUSINESS" && <div className={"naics-code-wrapper"}>
                                            <FieldWrapper>
                                                <span className={"tooltip-label-and-icon-wrapper"}>
                                                    <Label className={"field-label"}>NAICS Codes</Label>
                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "15rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                              <i className="fa fa-question-circle-o tooltip-question-icon"
                                                                 aria-hidden="true"
                                                                 title={"NAICS Codes refer to 3 or 6 digit Industry Codes."}></i>
                                                    </Tooltip>
                                                </span>
                                                <Naics
                                                    audienceState={audienceState}
                                                    handleAudienceState={handleAudienceState}
                                                />
                                            </FieldWrapper>
                                        </div>
                                        }
                                        {audienceState.target_type === "CONSUMER" && audienceState.original_target_type === "BUSINESS" && (
                                            <div className={"consumer-visit-dates-wrapper"}>
                                                <FieldWrapper>
                                                    <Label className={"field-label"}>Consumer Visit Dates</Label>
                                                    <DateRangePicker
                                                        startDateInput={CustomStartDateInput}
                                                        endDateInput={CustomEndDateInput}
                                                        onChange={onConsumerVisitDatesChange}
                                                        max={consumerVisitDateMax}
                                                        popupSettings={popupSettings}
                                                    />
                                                    {consumerDatesWarningMsg.length   > 0 ? <Label className={"invalid-date-range"} >{consumerDatesWarningMsg}</Label>: <Label className={"hint-standard"} >Maximum date range is 180 days.</Label>}
                                                </FieldWrapper>
                                            </div>
                                        )}
                                    </div>
                                    <AdvancedTargetingTreeListEdit
                                        audienceState={audienceState}
                                        handleAudienceState={handleAudienceState}
                                        // originalType={type}
                                        segmentName={segmentName}
                                        handleSegmentNameChanged={handleSegmentNameChanged}
                                        handleSegmentNameValid={handleSegmentNameValid}
                                        handleSegmentName={handleSegmentName}
                                        originalTargetType={originalTargetType}
                                        targetType={targetType}
                                        handleTargetType={handleTargetType}
                                        targetingData={{targetingData}}
                                        primaryKeys={primaryKeys}/>

                                </div>
                            </CardBody>
                        </CardBody>
                    </Card>
                    <div className={"audience-design-spacer"}></div>
                    <AudienceSuppressions
                        audienceState={audienceState}
                        handleAudienceState={handleAudienceState}
                    />
                    <div className={"audience-design-spacer"}></div>
                    <KeywordsSites
                        audienceState={audienceState}
                        handleAudienceState={handleAudienceState}/>
                    <div style={{paddingTop: "1rem"}}></div>
                    {/*<AboutAudienceEdit*/}
                    {/*    // formik={audienceDesignFormik}*/}
                    {/*    audienceState={audienceState}*/}
                    {/*    handleAudienceState={handleAudienceState}*/}
                    {/*    segmentNameChanged={segmentNameChanged}*/}
                    {/*    handleSegmentNameChanged={handleSegmentNameChanged}*/}
                    {/*    handleSegmentNameValid={handleSegmentNameValid}*/}
                    {/*    handleSegmentName={handleSegmentName}*/}
                    {/*/>*/}
                    {/*<div style={{paddingTop: "0.5rem", float: "right"}}>*/}
                    {/*    <Button*/}
                    {/*        // onClick={handleSubmit(audienceDesignFormik)}*/}
                    {/*        onClick={(e) => handleSubmit(e, audienceDesignFormik)}*/}
                    {/*        className="submit-button">*/}
                    {/*        Submit*/}
                    {/*    </Button>*/}
                    {/*</div>*/}
                    {/*<Tooltip*/}
                    {/*    position="top"*/}
                    {/*    anchorElement="target"*/}
                    {/*>*/}
                    <div style={{
                        paddingTop: "0.5rem",
                        float: "right",
                        display: (originalTargetType === "BUSINESS" && targetType === "CONSUMER") ||
                        (type === "matchback-segment" || type === "tag-segment" || type === "IDX-Segment") ? "none" : ""
                    }}>
                        <Button
                            // onClick={handleSubmit(audienceDesignFormik)}
                            onClick={(e) => handleSave(e, audienceState, false)}
                            // onClick={(e) => handleConfirmationWindow(e, audienceState, false)}
                            className="submit-button">
                            Save
                        </Button>
                        {/*<i className="fa fa-question-circle-o tooltip-question-icon save-button"*/}
                        {/*   aria-hidden="true"*/}
                        {/*   title={"\"Save\" will modify the current segment."}></i>*/}
                    </div>
                    {/*</Tooltip>*/}
                    {/*<Tooltip*/}
                    {/*    position="top"*/}
                    {/*    anchorElement="target"*/}
                    {/*>*/}
                    <div style={{paddingTop: "0.5rem", float: "right", marginRight: ".5rem"}}>
                        <Button
                            // onClick={handleSubmit(audienceDesignFormik)}
                            onClick={(e) => handleSave(e, audienceState, true)}
                            // onClick={(e) => handleConfirmationWindow(e, audienceState, true)}
                            className="submit-button">
                            Save As
                        </Button>

                        {/*<i className="fa fa-question-circle-o tooltip-question-icon save-as-button"*/}
                        {/*   aria-hidden="true"*/}
                        {/*   title={"\"Save As\" creates a separate segment based on the changes from above."}></i>*/}
                    </div>
                    {/*</Tooltip>*/}
                </div>
            </div>
        </>
    );
}

export default AudienceDesignEdit;
