import React, { useEffect, useState } from "react";
import { semcastAxios } from "../../../Utils/Common";
import { SUCCESS_STATUS, USER_ACCOUNT_DETAILS } from "../../../api/constants";
import LoadOverlay from "../../Components/SharedComponents/LoadOverlay";
import { Grid, GridToolbar } from "@progress/kendo-react-grid";
// Using the standard import for GridColumn:
import { GridColumn as Column } from "@progress/kendo-react-grid/dist/npm/GridColumn";
import { process } from "@progress/kendo-data-query";
import AccountPricing from "./AccountPricing";
import AccountsDetailsSetup from "./AccountsDetailsSetup";
import { Button } from "@progress/kendo-react-buttons";
import { ExcelExport } from "@progress/kendo-react-excel-export";

const formatDate = (timestamp) => {
    const date  = new Date(timestamp);
    return date.toLocaleDateString()+" "+date.toLocaleTimeString();
};

const DataCell = (props) => {
    const { dataItem, field } = props;
    const formattedDate = dataItem[field] ? formatDate(dataItem[field]) : "";
    return <td>{formattedDate}</td>;
};

const PlatformCell = (props) => {
    const { dataItem, field } = props;
    let platform = "";
    if (dataItem.ads_type === "1") {
        platform = "ADS";
    } else if (dataItem.ads_type === "3") {
        platform = "Toolbox";
    }
    return (
        <td>
            {platform}
        </td>
    );
}

const NameTemplate = (props) => {
    const { dataItem } = props;
    const FirstName = dataItem.cs_first_name;
    const LastName = dataItem.cs_last_name;
    const formattedName =
      FirstName !== null || LastName !== null ? FirstName + " " + LastName : "";
    return <td>{formattedName}</td>;
};

function getCurrentDateTime() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");
    const hours = currentDate.getHours().toString().padStart(2, "0");
    const minutes = currentDate.getMinutes().toString().padStart(2, "0");
    const seconds = currentDate.getSeconds().toString().padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

function AccountDashboard() {
    const [overlayActive, setOverlayActive] = useState(false);
    const [showEditCreate, setShowEditCreate] = useState(false);
    const [showPricing, setShowPricing] = useState(false);
    const [accountId, setAccountId] = useState(null);
    const [accountCompany, setAccountCompany] = useState(null);
    const [data, setData] = useState([]);
    const [take, setTake] = useState(10);
    const [skip, setSkip] = useState(0);

  const [filter, setFilter] = useState({
      logic: "and",
      filters: [
        {
          field: "firstName",
          operator: "contains",
          value: ""
        }
      ]
  });

  const [sort, setSort] = useState([
    {
      field: "first_name",
      dir: "asc"
    }
  ]);

  const dataState = { take, skip, sort, filter };

  const onDataStateChange = React.useCallback(
      (event) => {
        setTake(event.dataState.take);
        setSkip(event.dataState.skip);
        setSort(event.dataState.sort);
        setFilter(event.dataState.filter);
    },
    [setTake, setSkip, setSort]
  );

  const processedData = process(data, dataState);

  const loadAccounts = async () => {
    try {
      const res = await semcastAxios.get(USER_ACCOUNT_DETAILS, {
          withCredentials: true,
      });
      if (res.data.status === SUCCESS_STATUS) {
          console.log("loadAccountsData=", res.data.data);
          setData(res.data.data);
      }
    } catch (error) {
        console.error("Error occurred:", error);
    } finally {
        setOverlayActive(false);
    }
  };

  const refreshTable = async () => {
      setOverlayActive(true);
      await Promise.allSettled([loadAccounts()]);
      setOverlayActive(false);
  };

  const openEdit = (dataItem) => {
      setShowPricing(false);
      setShowEditCreate(true);
      setAccountId(dataItem.id);
      setAccountCompany(dataItem.company);
  };

  const openPricing = (dataItem) => {
      setShowPricing(true);
      setShowEditCreate(false);
      setAccountId(dataItem.id);
  };

  const MyEditCommandCell = (props) => (
    <td>
      <Button
          className="button-standard button-submit button-no-left-margin"
          iconClass="icon-edit"
          onClick={() => openEdit(props.dataItem)}
      >
        Edit
      </Button>
    </td>
  );

  const MyPricingCommandCell = (props) => (
    <td>
      <Button
          className="button-standard button-submit button-no-left-margin"
          iconClass="icon-edit"
          onClick={() => openPricing(props.dataItem)}
      >
          Pricing
      </Button>
    </td>
  );

  const _export = React.useRef(null);

  // 1) PRE-FORMAT DATE FIELDS for Excel:
  const exportExport = () => {
    if (_export.current !== null) {
      const exportData = data.map((item) => ({
        ...item,
        ads_type: item.ads_type ==="1" ? item.ads_type = "ADS"
            : item.ads_type ==="3" ? item.ads_type = "Toolbox"
                : "",
        updated_at: item.updated_at
          ? formatDate(item.updated_at)
          : "",
        deactivated_at: item.deactivated_at
          ? formatDate(item.deactivated_at)
          : ""
      }));
      _export.current.save(exportData);
    }
  };

  useEffect(() => {
    (async () => {
      let companyFilterCell = document.querySelector('[title="company-filter"]');
      if (companyFilterCell) companyFilterCell.placeholder = "Search...";

      let firstNameFilterCell = document.querySelector('[title="first-name-filter"]');
      if (firstNameFilterCell) firstNameFilterCell.placeholder = "Search...";

      let lastNameFilterCell = document.querySelector('[title="last-name-filter"]');
      if (lastNameFilterCell) lastNameFilterCell.placeholder = "Search...";

      let emailFilterCell = document.querySelector('[title="email-filter"]');
      if (emailFilterCell) emailFilterCell.placeholder = "Search...";

      let saleforceFilterCell = document.querySelector('[title="salesforce_id-filter"]');
      if (saleforceFilterCell) saleforceFilterCell.placeholder = "Search...";

      let intacctFilterCell = document.querySelector('[title="int_acct_id-filter"]');
      if (intacctFilterCell) intacctFilterCell.placeholder = "Search...";

      await refreshTable();
    })();
  }, []);

  return (
    <>
      <div className="users-permissions-datagrid-container">
        {(showPricing || showEditCreate) && (
          <Button
            className="button-submit button-no-left-margin"
            onClick={() => {
              setShowPricing(false);
              setShowEditCreate(false);
              setAccountId(null);
            }}
          >
            Account Dashboard
          </Button>
        )}

        <LoadOverlay active={overlayActive} height={50} width={50}>
          {/* 2) WRAP IN DIV with horizontal overflow + set minWidth to avoid column squashing */}
          {(!showEditCreate && !showPricing) && (
            <div style={{ width: "100%", overflowX: "auto" }}>
              <ExcelExport ref={_export} fileName={`Accounts - ${getCurrentDateTime()}`}>
                <Grid
                  // A minimum width ensures columns won't compress too much on smaller screens
                  style={{ minWidth: "1000px" }}
                  {...dataState}
                  data={processedData}
                  filterable={true}
                  onDataStateChange={onDataStateChange}
                  sortable={true}
                  pageable={{
                    buttonCount: 4,
                    pageSizes: [5, 10, 25, 50, 100],
                  }}
                >
                  <GridToolbar>
                    <div className="perm-users-grid-toolbar-container">
                      <div className="permissions-users-grid-toolbar-buttons">
                        <Button
                          className="button-submit button-no-left-margin"
                          iconClass="icon-plus"
                          onClick={() => {
                            setShowPricing(false);
                            setShowEditCreate(true);
                            setAccountId(null);
                          }}
                        >
                          Account Create
                        </Button>
                      </div>
                      <div className="adx-grid-toolbar-right">
                        <Button
                          className="button-standard button-white"
                          iconClass="k-icon k-i-download"
                          onClick={exportExport}
                        >
                          as Excel
                        </Button>
                      </div>
                    </div>
                  </GridToolbar>

                  <Column field="company" title="Company" filterTitle="company-filter" />
                  <Column field="first_name" title="First Name" filterTitle="first-name-filter" />
                  <Column field="last_name" title="Last Name" filterTitle="last-name-filter" />
                  <Column field="email" title="Email" filterTitle="email-filter" />
                  <Column field="salesforce_id" title="Salesforce Id" filterTitle="salesforce_id-filter" />
                  <Column field="int_acct_id" title="Intacct ID" filterTitle="int_acct_id-filter" />
                  <Column
                    field="cs_first_name"
                    title="Customer Success User"
                    filterTitle="customer_success_user-filter"
                    filterable={false}
                    cell={NameTemplate}
                  />
                  <Column
                    field="updated_at"
                    title="Updated"
                    cell={DataCell}
                    filterable={false}
                    filterTitle="updated_at-filter"
                  />
                  <Column
                    field="deactivated_at"
                    title="Deactivated"
                    cell={DataCell}
                    filterable={false}
                    filterTitle="deactivated_at-filter"
                  />
                  <Column field="ads_type" title="Platform" cell={PlatformCell} filterable={false}/>
                  <Column cell={MyEditCommandCell} filterable={false} width={121} />
                  <Column cell={MyPricingCommandCell} filterable={false} width={121} />
                </Grid>
              </ExcelExport>
            </div>
          )}

          {showEditCreate && (
            <AccountsDetailsSetup
              id={accountId}
              accountCompany={accountCompany}
              refreshTable={refreshTable}
              setShowEditCreate={setShowEditCreate}
              isAccountCreate={!accountId}
              setOverlayActive={setOverlayActive}
              isEditable={true}
            />
          )}

          {showPricing && (
            <AccountPricing
              id={accountId}
              setShowPricing={setShowPricing}
            />
          )}
        </LoadOverlay>
      </div>
    </>
  );
}

export default AccountDashboard;
