export const API_SERVER = window.env.REACT_APP_API_SERVER;
export const API_VERSION = window.env.REACT_APP_API_VERSION;
export const PRODUCT = window.env.REACT_APP_PRODUCT;
export const GOOGLE_MAPS_API_KEY = window.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const ADMIN_EMAILS = [
  "bheath@semcasting.com",
  "cj.kumar@mediamint.com",
  "channan@semcasting.com",
];

export const FTP_STRING = "FTP";
export const S3_STRING = "S3";
export const ASCENDING_ORDER = "Ascending";
export const DESCENDING_ORDER = "Descending";
export const YYYY_MM_DD_DATEFORMAT = "YYYY-MM-DD";
export const MM_DD_YY_H_MM_A_DATEFORMAT = "MM/DD/YY h:mm A";
export const YYYY_MM_DD_H_MM_SS_A_DATEFORMAT = "YYYY-MM-DD h:mm:ss A";
export const ROUTE_ID_PLACE_HOLDER = ":id";
export const ID_PLACE_HOLDER = "<%ID%>";
export const USER_ID_PLACE_HOLDER = "<%USER_ID%>";
export const ROLE_ID_PLACE_HOLDER = "<%ROLE_ID%>";
export const SEGMENT_ID_PLACE_HOLDER = "<%SEGMENT_ID%>";
export const ACTIVATION_ID_PLACE_HOLDER = "<%ACTIVATION_ID%>";
export const COMPANY_ID_PLACE_HOLDER = "<%COMPANY_ID%>";
export const PLATFORM_NAME_PLACE_HOLDER = "<%PLATFORM_NAME%>";
export const FIELD_ID_PLACE_HOLDER = "<%FIELD_ID%>";
export const NOT_A_MEMBER = "Not a Member";
export const NO_DATA_TO_SHOW = "No data available in table";
export const LOADING_TEXT = "Loading...";
export const SOMETHING_WENT_WRONG =
  "Something Went Wrong!, Please Try Again Later";
export const ADMIN_SETUP_DOWNLOADED_CSV_FILE_NAME =
  "AudienceDesigner - Permission Manager.csv";

//session related
export const AUTHORIZE_API =
  API_SERVER + "/" + API_VERSION + "/" + PRODUCT + "/authorize";
export const SIGN_OUT_API =
  API_SERVER + "/" + API_VERSION + "/" + PRODUCT + "/signout";

//profile & permissions
export const USER_INFO_API =
  API_SERVER + "/" + API_VERSION + "/secure/" + PRODUCT + "/user";
export const UPDATE_PASSWORD_API =
  API_SERVER +
  "/" +
  API_VERSION +
  "/secure/" +
  PRODUCT +
  "/user/update-password";
export const GROUPS_ACCESSIBLE_API =
  API_SERVER + "/" + API_VERSION + "/secure/" + PRODUCT + "/group/accessible";
export const ROLES_ACCESSIBLE_API =
  API_SERVER + "/" + API_VERSION + "/secure/" + PRODUCT + "/roles/accessible";
export const MANAGE_USERS_ACCESSIBLE_API =
  API_SERVER +
  "/" +
  API_VERSION +
  "/secure/" +
  PRODUCT +
  "/manage-user/accessible";

export const AUDIENCE_FILE =
  API_SERVER +
  "/" +
  API_VERSION +
  "/secure/" +
  PRODUCT +
  "/onboarding/audience-file";
export const GROUP_CREATE_API =
  API_SERVER + "/" + API_VERSION + "/secure/" + PRODUCT + "/group";
export const FROM_FILE =
  API_SERVER + "/" + API_VERSION + "/secure/" + PRODUCT + "/segments/from-file";
export const FTP_SETTINGS =
  API_SERVER + "/" + API_VERSION + "/secure/ftpsettings";
export const FTP_SETTINGS_NON_MATCHED =
  API_SERVER + "/" + API_VERSION + "/secure/ftpsettingsn";
export const FTP_NAV = API_SERVER + "/" + API_VERSION + "/secure/ftpnav";

//user
export const USERS_PERMISSIONS_API =
  API_SERVER + "/" + API_VERSION + "/secure/" + PRODUCT + "/permissions"; //GET
export const USERS_BASIC_INFO_UPDATE_API =
  API_SERVER + "/" + API_VERSION + "/secure/" + PRODUCT + "/manage-user";
export const USERS_GROUPS_EDIT_GET_API =
  API_SERVER + "/" + API_VERSION + "/secure/" + PRODUCT + "/assignments/user/";
export const USERS_GROUPS_EDIT_PUT_API =
  API_SERVER + "/" + API_VERSION + "/secure/" + PRODUCT + "/assignments/assign";
export const PASSWORD_RESET_API =
  API_SERVER +
  "/" +
  API_VERSION +
  "/secure/" +
  PRODUCT +
  "/password-reset/user/"; //PATCH
export const USER_DELETE_API =
  API_SERVER + "/" + API_VERSION + "/secure/" + PRODUCT + "/manage-user/";
export const USER_SUSPEND_API =
  API_SERVER +
  "/" +
  API_VERSION +
  "/secure/" +
  PRODUCT +
  "/manage-user/" +
  USER_ID_PLACE_HOLDER +
  "/suspend";
export const USER_REINSTATE_API =
  API_SERVER +
  "/" +
  API_VERSION +
  "/secure/" +
  PRODUCT +
  "/manage-user/" +
  USER_ID_PLACE_HOLDER +
  "/unsuspend";

export const GROUPS_ACCESSIBLE_PIXELS =
    API_SERVER + "/" + API_VERSION + "/secure/" + PRODUCT + "/group/accessiblepx";

// ADX TAG MGR
export const TRACKING_PIXELS_API =
    API_SERVER + "/" + PRODUCT + "/tracking-pixels";
export const TRACKING_PIXELS_DATA_API =
    API_SERVER + "/" + API_VERSION + "/secure/" + PRODUCT + "/tracking-pixels?deleted_at=null";
export const TRANSFER_LOG_API =
    API_SERVER + "/" + API_VERSION + "/secure/" + PRODUCT + "/transfer-log/latest?pixel_deleted_at=null";

// IDX TAG MGR
export const IDX_PIXELS_DATA_API =
    API_SERVER + "/" + API_VERSION + "/secure/" + PRODUCT + "/idx-pixels?deleted_at=null";
export const IDX_PIXELS_API =
    API_SERVER + "/" + API_VERSION + "/secure/" + PRODUCT + "/idx-pixels";

//groups
export const GROUPS_CREATE_API =
  API_SERVER + "/" + API_VERSION + "/secure/" + PRODUCT + "/group";
export const GROUPS_UPDATE_PATCH_API =
  API_SERVER + "/" + API_VERSION + "/secure/" + PRODUCT + "/group/";
export const GROUPS_DELETE_API =
  API_SERVER + "/" + API_VERSION + "/secure/" + PRODUCT + "/group/";
export const GROUPS_EDIT_GET_API =
  API_SERVER + "/" + API_VERSION + "/secure/" + PRODUCT + "/assignments/group/";

//roles
export const ROLE_CREATE_API =
  API_SERVER + "/" + API_VERSION + "/secure/" + PRODUCT + "/roles"; // POST
export const ROLE_UPDATE_PATCH_API =
  API_SERVER + "/" + API_VERSION + "/secure/" + PRODUCT + "/roles/"; // PATCH
export const ROLE_DELETE_API =
  API_SERVER + "/" + API_VERSION + "/secure/" + PRODUCT + "/roles/"; //DELETE
export const ROLE_EDIT_GET_API =
  API_SERVER +
  "/" +
  API_VERSION +
  "/secure/" +
  PRODUCT +
  "/roles/" +
  ROLE_ID_PLACE_HOLDER +
  "?withpermissions=true"; //GET
export const ROLE_PERMISSIONS_API =
  API_SERVER +
  "/" +
  API_VERSION +
  "/secure/" +
  PRODUCT +
  "/permissions/all?orderby=resource_type,scope,action"; //GET

//accounts
export const ACCOUNT_DETAILS_API =
  API_SERVER + "/" + API_VERSION + "/secure/account/details";

//segmentation active
export const ACCOUNT_PERMISSIONS =
  API_SERVER +
  "/" +
  API_VERSION +
  "/secure/" +
  PRODUCT +
  "/account/permissions"; //GET
// FD:11907 - Needed to take into account status=PROFILING in addition to status=AVAILABLE
export const SEGMENT_UPLOADED_RECORDS_API =
  API_SERVER +
  "/" +
  API_VERSION +
  "/secure/" +
  PRODUCT +
  "/segments/" +
  SEGMENT_ID_PLACE_HOLDER +
  "?status=AVAILABLE,PROFILING&deleted_at=null"; //GET
export const SEGMENT_ACTIVATION_DATA_API =
  API_SERVER + "/" + API_VERSION + "/secure/" + PRODUCT + "/activate/data/"; //GET
export const ACCOUNT_PRICING =
  API_SERVER + "/" + API_VERSION + "/secure/" + PRODUCT + "/account/pricing"; // GET
//FD#11196
export const ACCOUNT_PRICING_WITH_SEGMENT_ID =
    API_SERVER + "/" + API_VERSION + "/secure/" + PRODUCT + "/account/pricing/segment/" + SEGMENT_ID_PLACE_HOLDER; // GET
export const COMPANY_FIELDS_PREFILLED_DATA =
  API_SERVER +
  "/" +
  API_VERSION +
  "/secure/" +
  PRODUCT +
  "/activate-credentials/" +
  COMPANY_ID_PLACE_HOLDER +
  "/" +
  FIELD_ID_PLACE_HOLDER; // GET
export const DELETE_COMPANY_FIELDS_PREFILLED_DATA =
  API_SERVER +
  "/" +
  API_VERSION +
  "/secure/" +
  PRODUCT +
  "/activate-credentials/" +
  COMPANY_ID_PLACE_HOLDER +
  "/" +
  FIELD_ID_PLACE_HOLDER +
  "/" +
  ID_PLACE_HOLDER; // DELETE
export const ACTIVATE_SEGMENT =
  API_SERVER +
  "/" +
  API_VERSION +
  "/secure/" +
  PRODUCT +
  "/activate/" +
  COMPANY_ID_PLACE_HOLDER +
  "/"; // POST ACT
export const MERGE_SEGMENT =
  API_SERVER + "/" + API_VERSION + "/secure/" + PRODUCT + "/segments/merge";

export const HIDDEN_ONBOARDING_COMPANIES = "hidden_onboarding_companies";
export const SEMCASTING_TILES = "semcastingTiles";

export const SEGMENTS_ACCESSIBLE_API = API_SERVER + "/" + API_VERSION + "/secure/attributor/segments?rpp=100&p=0&deleted_at=null&aq_data=true";

export const DASHBOARD_TREE = API_SERVER + "/" + API_VERSION + "/secure/attributor/dashboard-tree"
//export const SEGMENTS_ACCESSIBLE_API = API_SERVER + '/' + API_VERSION + '/secure/attributor/segments?rpp=100&p=0&orderby=created_at&orderdir=desc&user_id=289&status=AVAILABLE&deleted_at=null&aq_data=true';
export const SEGMENT_DETAILS_API =
    API_SERVER + "/" + API_VERSION + "/secure/attributor/segments/" + SEGMENT_ID_PLACE_HOLDER;
//Segment Report Regenerate
export const SEGMENTS_REPORT_DETAIL = API_SERVER + "/" + API_VERSION + "/secure/attributor/segments/" + SEGMENT_ID_PLACE_HOLDER;
export const SEGMENTS_ACTIVATION_QUEUE_DATA = API_SERVER + "/" + API_VERSION + "/secure/attributor/activation/" + SEGMENT_ID_PLACE_HOLDER;//use activation ID
export const ACTIVATION_CREATIVE_TAGs =  API_SERVER + "/" + API_VERSION + "/secure/attributor/creative-tags?platform=" + PLATFORM_NAME_PLACE_HOLDER;
export const SEGMENT_REPORT_REGENERATE_API =
    API_SERVER + "/" + API_VERSION + "/secure/attributor/segments/";
export const SEGMENT_DELETE_API =
  API_SERVER + "/" + API_VERSION + "/secure/attributor/segments/delete";
//Deactivate all activations under the selected segment
export const SEGMENT_DEACTIVATE_API =
  API_SERVER + "/" + API_VERSION + "/secure/attributor/segments/" + SEGMENT_ID_PLACE_HOLDER + "/deactivate";
//Deactivate the selected activation
export const ACTIVATION_DEACTIVATE_API =
    API_SERVER + "/" + API_VERSION + "/secure/attributor/segments/" + ACTIVATION_ID_PLACE_HOLDER + "/deactivateq";
export const SEGMENT_REACTIVATE_API =
  API_SERVER + "/" + API_VERSION + "/secure/attributor/segments/";
export const SEGMENT_COPY_API =
  API_SERVER + "/" + API_VERSION + "/secure/attributor/segments/";
export const SEGMENT_APPEND_REPLACE_API =
    API_SERVER + "/" + API_VERSION + "/secure/attributor/segments/upsert";
export const SEGMENT_MERGE_API =
  API_SERVER + "/" + API_VERSION + "/secure/attributor/segments/";
export const SEGMENT_SPLIT_API =
  API_SERVER + "/" + API_VERSION + "/secure/attributor/segments/split";
export const SEGMENT_LOOKALIKE_API =
  API_SERVER + "/" + API_VERSION + "/secure/attributor/segments/";

export const IDENTITY_REPORT_ACTIVATION_API =
    API_SERVER + "/" + API_VERSION + "/secure/attributor/activation/identity-report/" + ACTIVATION_ID_PLACE_HOLDER;

export const IDENTITY_REPORT_TRACKING_PIXEL_API =
    API_SERVER + "/" + API_VERSION + "/secure/attributor/tracking-pixels/identity-report/" + SEGMENT_ID_PLACE_HOLDER;
export const IDENTITY_REPORT_IDX_PIXEL_API =
    API_SERVER + "/" + API_VERSION + "/secure/attributor/idx-pixels/identity-report";
export const SEGMENT_LOCATIONS_API =
  API_SERVER + "/v3/secure/geos/types/states";
export const SEGMENT_LOCATIONS_CITIES_API =
  API_SERVER + "/v3/secure/geos/types/cities";
export const SEGMENT_LOCATIONS_ZIP3_API =
  API_SERVER + "/v3/secure/geos/types/zip3s";
export const SEGMENT_LOCATIONS_ZIP5_API =
  API_SERVER + "/v3/secure/geos/types/zip5s";
export const SEGMENT_LOCATIONS_STATES_API =
  API_SERVER + "/v3/secure/geos/types/states";
export const FILE_VALIDATE_API = API_SERVER + "/v3/secure/geos/validate";

// Reverse Geocode (Maps Onboarding)
export const GEO =
    "https://maps.googleapis.com/maps/api/geocode/json";
// Get points for locations (addresses)
export const LOCATION_POINTS =
    API_SERVER +
    "/" +
    API_VERSION +
    "/secure/attributor/points";
export const AUDIENCE_FILE_GEO =
  API_SERVER +
  "/" +
  API_VERSION +
  "/secure/attributor/onboarding/audience-file/geo";
//contact us
export const CONTACT_US_API =API_SERVER + "/v2/secure/attributor/contactus";
  //Salesforce: "https://go.pardot.com/l/137711/2017-07-18/284mwn";
//APP Strings
export const SUCCESS_STATUS = "success";

export const FAIL_STATUS = "fail";

export const AUTH_COOKIE = "auth";
export const USER = "user";
export const FILE_TYPE_UNKNOWN = "file_type_unknown";

//ADMIN SETUP
export const ALL_ACCOUNT_DETAILS =
  API_SERVER +
  "/" +
  API_VERSION +
  "/secure/admin/users?deleted_at=null&founding_group_id=not null&orderby=company&_=1636615995370";

export const USER_ACCOUNT_DETAILS =
    API_SERVER +
    "/" +
    API_VERSION +
    "/secure/admin/users_accounts?deleted_at=null&founding_group_id=not null&orderby=company&_=1636615995370";


export const ADMIN_ACCOUNT_DETAILS_API =
  API_SERVER + "/" + API_VERSION + "/secure/admin/account/details";

export const FOUNDER_DETAILS_API =
    ADMIN_ACCOUNT_DETAILS_API + "/" + ID_PLACE_HOLDER;
export const LOGIN_AS =
  API_SERVER + "/" + API_VERSION + "/secure/admin/users/loginas/";

export const PRICING =
  API_SERVER + "/" + API_VERSION + "/secure/admin/pricing/";

export const ADMIN_ACCOUNT_PERMISSIONS =
  API_SERVER + "/" + API_VERSION + "/secure/admin/account/permissions/";

export const BILLING =
  API_SERVER + "/" + API_VERSION + "/secure/admin/billing/";

//job statuses
export const SEGMENT_ERRORS_API =
  API_SERVER +
  "/" +
  API_VERSION +
  "/secure/admin/segments?status=ERROR&deleted_at=null";
export const SEGMENT_IN_PROGRESS_API =
  API_SERVER +
  "/" +
  API_VERSION +
  "/secure/admin/segments?status=CONSTRUCTING%2CCOUNTING%2CLOADING%2CMERGING%2CCONVERTING%2CPROFILING&deleted_at=null";

export const ACTIVATIONS_ERRORS_API =
  API_SERVER +
  "/" +
  API_VERSION +
  "/secure/admin/activation-jobs?status=ERROR&deleted_at=null";
export const ACTIVATIONS_IN_PROGRESS_API =
  API_SERVER +
  "/" +
  API_VERSION +
  "/secure/admin/activation-jobs?status=QUEUED%2CRUNNING&deleted_at=null";

export const ATTRIBUTIONS_ERRORS_API =
  API_SERVER +
  "/" +
  API_VERSION +
  "/secure/admin/attribution-jobs?status=ERROR&deleted_at=null";
export const ATTRIBUTIONS_IN_PROGRESS_API =
  API_SERVER +
  "/" +
  API_VERSION +
  "/secure/admin/attribution-jobs?status=CONSTRUCTING%2CRUNNING&deleted_at=null";

//app status
export const ACTIVE_USER_COUNT_API =
  API_SERVER + "/" + API_VERSION + "/secure/admin/users/count"; //last_login_at_start=2021-11-28&last_login_at_end=2021-12-05&deleted_at=null

export const ACTIVATED_SEGMENTS_TOTALS_BY_ACCOUNT_API =
  API_SERVER +
  "/" +
  API_VERSION +
  "/secure/admin/analytics/activations-by-account"; //?completed_at_start=2021-11-21&completed_at_end=2021-11-28"
export const ACTIVATED_SEGMENTS_TOTALS_BY_USER_API =
  API_SERVER +
  "/" +
  API_VERSION +
  "/secure/admin/analytics/activations-by-user"; //completed_at_start=2021-11-21&completed_at_end=2021-11-28
export const ACTIVATED_SEGMENTS_TOTALS_BY_PLATFORM_API =
  API_SERVER +
  "/" +
  API_VERSION +
  "/secure/admin/analytics/activations-by-platform"; //completed_at_start=2021-11-21&completed_at_end=2021-11-28
export const ACTIVATED_SEGMENTS_TOTALS_BY_PARTY_API =
  API_SERVER +
  "/" +
  API_VERSION +
  "/secure/admin/analytics/activations-by-party"; //completed_at_start=2021-11-21&completed_at_end=2021-11-28

export const CREATED_SEGMENTS_TOTALS_BY_ACCOUNT_API =
  API_SERVER +
  "/" +
  API_VERSION +
  "/secure/admin/analytics/segments-by-account"; //created_at_start=2021-11-21&created_at_end=2021-11-28
export const CREATED_SEGMENTS_TOTALS_BY_USER_API =
  API_SERVER + "/" + API_VERSION + "/secure/admin/analytics/segments-by-user"; //created_at_start=2021-11-21&created_at_end=2021-11-28

export const ATTRIBUTIONS_TOTALS_BY_ACCOUNT_API =
  API_SERVER +
  "/" +
  API_VERSION +
  "/secure/admin/analytics/attributions-by-account"; //created_at_start=2021-11-21&created_at_end=2021-11-28
export const ATTRIBUTIONS_TOTALS_BY_USER_API =
  API_SERVER +
  "/" +
  API_VERSION +
  "/secure/admin/analytics/attributions-by-user"; //created_at_start=2021-11-21&created_at_end=2021-11-28

export const DORMANT_USERS_API =
  API_SERVER + "/" + API_VERSION + "/secure/admin/users"; //last_login_at_end=2021-10-24&deleted_at=null

// audience - design

export const PPK_SEGMENTS =
  API_SERVER + "/" + API_VERSION + "/secure/attributor";
export const DEMOGRAPHIC_INCOME =
  API_SERVER + "/" + API_VERSION + "/secure/attributor/demographic/income";
export const DEMOGRAPHIC_HOME_VALUE =
  API_SERVER + "/" + API_VERSION + "/secure/attributor/demographic/home_value";
export const DEMOGRAPHIC_SOCIAL_MATRIX_VALUE =
    API_SERVER + "/" + API_VERSION + "/secure/attributor/demographic/social_matrix";
export const SEGMENTS =
  API_SERVER + "/" + API_VERSION + "/secure/attributor/segments";

export const SEGMENTS_SUPPRESSION =
    API_SERVER + "/" + API_VERSION + "/secure/attributor/suppressions";
export const NAICS_CODES =
  API_SERVER + "/" + API_VERSION + "/secure/naics-codes";

export const GEO_TYPES = API_SERVER + "/v3/secure/geos/types";

export const PPK_DISTRICT_SEGMENTS =
  API_SERVER + "/" + API_VERSION + "/secure/attributor/ppk-segments";
//ROUTES
export const ACTIVATE_SEGMENT_ROUTE_PREFIX = "/segmentation-activate";
// export const ACTIVATE_SEGMENT_ROUTE =
//   ACTIVATE_SEGMENT_ROUTE_PREFIX + "/" + ROUTE_ID_PLACE_HOLDER;
export const ACTIVATE_SEGMENT_ROUTE = ACTIVATE_SEGMENT_ROUTE_PREFIX;
export const ACTIVATE_FORM_ROUTE = "/activate-form";
export const EDIT_SEGMENT_ROUTE = "/audience-design-edit";
export const AUDIENCE_DESIGN_ROUTE = "/audience-design";
export const USER_PROFILE_ROUTE = "/user-profile";
export const PERMISSIONS_ROUTE = "/permission";
export const ACCOUNT_DETAILS_ROUTE = "/account-details";
export const FILE_TRANSFER_SETTINGS = "/file-transfer-settings";
export const CONTACT_US_ROUTE = "/contact-us";
export const HELP_CENTER_ROUTE = "/help-center";

//ADMIN
export const JOB_STATUSES_ROUTE = "/job-statuses";
export const APP_STATUS_ROUTE = "/app-status";

export const COMPANY_BASE_PROPERTIES = {};

export const DELETE_MODE_SINGLE = "DELETE_MODE_SINGLE";
export const DELETE_MODE_MULTI = "DELETE_MODE_MULTI";

export const DEACTIVATE_MODE_ACTIVATION = "DEACTIVATE_MODE_ACTIVATION";

export const DEACTIVATE_MODE_SEGMENT = "DEACTIVATE_MODE_SEGMENT";

//Delete tracking-pixels
export const DELETE_TRACKING_PIXELS =
    API_SERVER + "/" + API_VERSION + "/secure/attributor/tracking-pixels/" + SEGMENT_ID_PLACE_HOLDER;
//Update Activation (End date, creative tag)
export const UPDATE_ACTIVATION = API_SERVER + "/" + API_VERSION + "/secure/attributor/activation/" + ACTIVATION_ID_PLACE_HOLDER;
//report-map
export const MARKER_MAP =API_SERVER + "/v2/secure/attributor/maps-markers/";

export const BUBBLE_MAP =API_SERVER + "/v2/secure/attributor/maps-bubbles/";

export const NOTIFICATION_API =
    API_SERVER + "/" + API_VERSION + "/secure/" + PRODUCT + "/user/notifications";

export const NOTIFICATION_OUTSTANDING_API =
    API_SERVER + "/" + API_VERSION + "/secure/" + PRODUCT + "/user/notifications/outstanding";

/*
 * downloads a segment report
 */
export const DOWNLOAD_PROFILE = API_SERVER + "/" + API_VERSION + "/secure/attributor/segments/" + SEGMENT_ID_PLACE_HOLDER + "/download";

export const USERS_ACCOUNT_RESTORE = API_SERVER + "/" + API_VERSION + "/secure/" + PRODUCT + "/manage-user/" + USER_ID_PLACE_HOLDER + "/undelete";
//Account Tab
export const CUSTOMER_SUCCESS_IDs = API_SERVER + "/" + API_VERSION + "/secure/" + PRODUCT + "/manage-user/customer-success";

//FD#11956
export const DOWNLOAD_DELIMITED_DATA = API_SERVER + "/" + API_VERSION + "/secure/attributor/segments/" + SEGMENT_ID_PLACE_HOLDER + "/profileupload";

//Anzu sync Dashboard dialogue on submit
export const ACTIVATION_SYNCHRONIZE = API_SERVER + "/" + API_VERSION + "/secure/attributor/activate/synchronize/" + ACTIVATION_ID_PLACE_HOLDER;
export const APPEND_TREE = API_SERVER + "/" + API_VERSION + "/secure/attributor/segments/append-tree/" + SEGMENT_ID_PLACE_HOLDER;
export const APPEND_TREE_EXPORT = API_SERVER + "/" + API_VERSION + "/secure/attributor/segments/export-append/" + SEGMENT_ID_PLACE_HOLDER;
export const TRACKING_PIXELS = API_SERVER + "/" + API_VERSION + "/secure/" + PRODUCT + "/idx-pixels/" + ID_PLACE_HOLDER;

export const TRACKING_PIXELS_LIST = API_SERVER + "/" + API_VERSION + "/secure/" + PRODUCT + "/idx-pixels";
export const TRACKING_PIXELS_BY_USER = API_SERVER + "/" + API_VERSION + "/secure/" + PRODUCT + "/idx-pixels/populated";
