import {TreeList} from "@progress/kendo-react-treelist";
import angleLeftRightThemeColorIcon
    from "../Components/Segmentation/temporaryresources/images/arrow-left-right-theme-color.svg";
import {CardBody} from "@progress/kendo-react-layout";
import React, {useEffect, useState} from "react";
import {APPEND_TREE, APPEND_TREE_EXPORT, SEGMENT_ID_PLACE_HOLDER, SUCCESS_STATUS} from "../../api/constants";
import {semcastAxios, showErrorToastMessage, showSuccessToastMessage} from "../../Utils/Common";
import {extendDataItem, mapTree} from "@progress/kendo-react-common";
import {Button, ButtonGroup} from "@progress/kendo-react-buttons";
import {Tooltip} from "@progress/kendo-react-tooltip";
import LoadOverlay from "../Components/SharedComponents/LoadOverlay";
import './DataAppendTree.css'
import {Input} from "@progress/kendo-react-inputs";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import Loader from "../Components/Loader";
import {getFTPSettings} from "../../actions/OnBoardingActions";
import {Label} from "@progress/kendo-react-labels";

//This function needs to be outside of the main component to prevent rerender
const RowRender = (properties) => {
    const { row, props, onDrop, onDragStart } = properties;
    const additionalProps = {
        onDragStart: (e) => onDragStart(e, props),
        onDragOver: (e) => {
            e.preventDefault();
        },
        // onDrop: (e) => onDrop(e, props),
        draggable: true,
    };
    return React.cloneElement(
        row,
        { ...row.props, ...additionalProps },
        row.props.children
    );
};

const DataAppendTree = (props) => {
    const {selectedSeg} = props;
    const [topCategory, setTopCategory] = useState("");
    const [originalTree, setOriginalTree] = useState({});
    const [loadOverlay, setLoadOverlay] = useState(true);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const [allSavedSettings, setAllSavedSettings] = useState([]);
    const [treeList2Count, setTreeList2Count] = React.useState([]);
    const [currentConnection, setCurrentConnection] = useState({text:"Please Select...", id: 0});
    const [tempSearchValues1, setTempSearchValues1] = useState([]);
    const [tempSearchValues2, setTempSearchValues2] = useState([]);
    const [expand1, setExpand1] = useState(true);
    const [expand2, setExpand2] = useState(true);
    const [tabList, setTablist] = useState([]);
    const [selectedTab, setSelectedTab] = React.useState("");
    const subItemsField = 'items';
    const expandField = 'expanded';
    const dataItemKey = 'treeId';
    const [treeState, setTreeState] = React.useState({
        data1: [],
        data2: [],
        expanded1: [],
        expanded2: [],
        dataState1: {
            sort: [],
            filter: [],
        },
        dataState2: {
            sort: [],
            filter: [],
        },
    });
    const columns = [
        {
            field: "text",
            title: " ",
            width: "250px",
            expandable: true,
        },
    ];
    function transformToNestedArray(obj) {
        let idCounter = 1; // Initialize the ID counter

        function createObject(key, value) {
            return {
                treeId: idCounter++, // Assign unique ID and increment the counter
                text: key,
                value: value,
                items: []
            };
        }

        function recursiveTransform(obj) {
            return Object.entries(obj).map(([key, value]) => {
                if (Array.isArray(value)) {
                    // Handle array values by creating an item for each array element
                    return {
                        treeId: idCounter++,
                        text: key,
                        value: key,
                        items: value.map(item => createObject(item, item))
                    };
                } else if (typeof value === 'object' && value !== null) {
                    // Handle nested objects by processing them recursively
                    return {
                        treeId: idCounter++,
                        text: key,
                        value: key,
                        items: recursiveTransform(value)
                    };
                } else {
                    // Handle string or direct mapping values
                    return createObject(key, value);
                }
            });
        }

        return recursiveTransform(obj);
    }

    useEffect( () => {
        setLoadOverlay(true);
        initialTree().catch(error => console.log(error));

    }, [selectedSeg]);
    useEffect(() => {
        const getSettings = async() => {
            try {
                const settings = await getFTPSettings();
                console.log(settings)
                if (settings && settings.data.length) {
                    setAllSavedSettings(settings.data);
                }
            } catch {
                showErrorToastMessage("Could not access Connection information.", 10);
            } finally {
                setIsLoaded(true);
            }
        }
        getSettings();
    }, []);

    // useEffect(() => {
        // let tempList = []
        // treeState.data1.forEach(item => {
        //     tempList.push(item.text);
        // })
        // setTablist(tempList.sort((a, b) => a.localeCompare(b)));
    //     setTempSearchValues1(treeState.data1);
    //     setTempSearchValues2(treeState.data2);
    // }, [treeState.data1.length > 0]);

    useEffect(() => {
        if(tabList.length > 0 && treeState.data1.length > 0){
            setLoadOverlay(false)
        }

    }, [tabList,treeState]);
    useEffect(() => {
        if(treeState.data2.length > 0 && currentConnection.id !== 0){
            setDisableSubmit(false);
        }else{
            setDisableSubmit(true);
        }
    }, [treeState,currentConnection]);
    useEffect(() => {
        //Select the first tab by default
        setSelectedTab(tabList[0])
    }, [tabList]);
    function removeDigitalIdentifierEntry(jsonObj, keyToRemove) {
        // Check if the "Digital Identifier" key exists
        if (jsonObj["Digital Identifiers"]) {
            let digitalIdentifiers = jsonObj["Digital Identifiers"];
            // Check if the subKeyToRemove exists and remove it
            if (digitalIdentifiers[keyToRemove]) {
                delete digitalIdentifiers[keyToRemove];
            }
        }
        if (jsonObj["Business Information"]) {
            let businessInformation = jsonObj["Business Information"];
            // Check if the subKeyToRemove exists and remove it
            if (businessInformation[keyToRemove]) {
                delete businessInformation[keyToRemove];
            }
        }
        if (jsonObj["Employee Details"]) {
            let employeeDetails = jsonObj["Employee Details"];
            // Check if the subKeyToRemove exists and remove it
            if (employeeDetails[keyToRemove]) {
                delete employeeDetails[keyToRemove];
            }
        }
        console.log(jsonObj)
        return jsonObj;
    }
    function tree2InitObj(jsonObj) {
        let digitalIdentifiers = jsonObj["Digital Identifiers"];
        let businessInformation = jsonObj["Business Information"];
        let employeeDetails = jsonObj["Employee Details"];
        if (digitalIdentifiers) {
            // Check if the Client ID exists and remove it
            if (digitalIdentifiers["Client ID"]) {
                return {
                    "Digital Identifiers": {
                        "Hashed Semcasting ID": "ha.hashed_location_id, ha.hashed_person_id",
                        "Client ID": "i.client_id"
                    },
                }
            }
            if(!digitalIdentifiers["Client ID"] && digitalIdentifiers["Hashed Semcasting ID"]){
                return {
                    "Digital Identifiers": {
                        "Hashed Semcasting ID": "ha.hashed_location_id, ha.hashed_person_id",
                    },
                }
            }
        }
        if(businessInformation){
            if (businessInformation["Client ID"]){
                return {
                    "Business Information": {
                        "Client ID": "i.client_id"
                    },
                    "Employee Details": {
                        "Hashed Semcasting ID": "be.hashed_location_id, be.hashed_person_id",
                    },

                }
            }
            if(!businessInformation["Client ID"] && employeeDetails["Hashed Semcasting ID"]){
                return {
                    "Employee Details": {
                        "Hashed Semcasting ID": "be.hashed_location_id, be.hashed_person_id",
                    },
                }
            }
        }
    }
    const initialTree = async () => {
        setTreeState({
            ...treeState,
            data1: [],
            data2: [],
        });
        setSelectedTab("");
        await semcastAxios.get(APPEND_TREE.replace(SEGMENT_ID_PLACE_HOLDER, selectedSeg.id), {withCredentials: true})
            .then(result => result)
            .then(data => {
                let jsonObjLeft;
                let jsonObjRight;

                if (data.data['Household Segment']){
                    //Remove Hashed Semcasting ID and Client ID (if exists) and add them to the right side of the tree
                    jsonObjRight = tree2InitObj(data.data['Household Segment']);
                    jsonObjLeft = removeDigitalIdentifierEntry(data.data['Household Segment'], "Hashed Semcasting ID");
                    jsonObjLeft = removeDigitalIdentifierEntry(jsonObjLeft, "Client ID");
                    setTopCategory('Household Segment');
                }
                if(data.data['Business Segment']){
                    jsonObjRight = tree2InitObj(data.data['Business Segment']);
                    jsonObjLeft = removeDigitalIdentifierEntry(data.data['Business Segment'], "Hashed Semcasting ID");
                    jsonObjLeft = removeDigitalIdentifierEntry(jsonObjLeft, "Client ID");
                    setTopCategory('Business Segment');
                }

                setOriginalTree(jsonObjLeft);
                if(jsonObjLeft){

                    // Call the function to remove the nested object

            setTreeState({
                ...treeState,
                data1: transformToNestedArray(jsonObjLeft),
                data2: transformToNestedArray(jsonObjRight),
                expanded1: transformToNestedArray(jsonObjLeft).map((i) => i.treeId),
                expanded2: transformToNestedArray(jsonObjRight).map((i) => i.treeId),
                dataState1: {
                    sort: [],
                    filter: [],
                },
                dataState2: {
                    sort: [],
                    filter: [],
                },
            })
                    let tempList = []
                    transformToNestedArray(jsonObjLeft).forEach(item => {
                        tempList.push(item.text);
                    })
                    setTablist(tempList.sort((a, b) => a.localeCompare(b)));
                    setTempSearchValues1(transformToNestedArray(jsonObjLeft));
                    setTempSearchValues2([]);
                }else{
                    showErrorToastMessage("Error: Tree object is null or undefined. ", 10);
                }

            }).catch((error) => {
                console.log(error.response)
                if (error.response) {
                    console.log('Response Error:', error.response.data);
                    showErrorToastMessage("Response Error: " + error.message + ". " + error.response.data.data, 10);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log('Request Error:', error.request);
                    showErrorToastMessage("Request Error: " + error.message + ". " + error.request.data.data, 10);
                }
            })
    }

    const handleConnectionChange = (e) => {
        console.log(e)
        setCurrentConnection({
            ...currentConnection,
            ['text']: e.value.text,
            ['id']: e.value.id,
            ['protocol']: e.value.protocol,
        });
    };

    //Process data for adding items to tree
    function addToParent(A, B, input) {
        // Helper function to find the parent node by its value
        function findParent(array, value) {
            for (const node of array) {
                if (node.items && node.items.some(item => item.value === value)) {
                    return node; // Parent found
                }
                if (node.items) {
                    const parent = findParent(node.items, value);
                    if (parent) return parent;
                }
            }
            return null;
        }

        // Helper function to find or create the parent chain in B
        function findOrCreateParentChainInB(B, parentChain) {
            let currentLevel = B;

            for (const parent of parentChain) {
                let existingNode = currentLevel.find(node => node.value === parent.value);

                if (!existingNode) {
                    // Create a new node in B for this part of the chain
                    existingNode = { ...parent, items: [] };
                    currentLevel.push(existingNode);
                }

                currentLevel = existingNode.items;
            }

            return currentLevel; // Return the items array where the input should be added
        }

        // Helper function to sort an array alphabetically by the 'text' property
        function sortArray(array) {
            return array.sort((a, b) => a.text.localeCompare(b.text));
        }

        // Recursive function to sort objects and nested arrays
        function sortObjectAndArrays(node) {
            if (Array.isArray(node)) {
                node.forEach(sortObjectAndArrays); // Recursively sort each item in the array
                sortArray(node); // Sort the array itself
            } else if (node && typeof node === "object") {
                if (node.items) {
                    sortObjectAndArrays(node.items); // Recursively sort items in the object
                }
            }
        }

        // Helper function to merge items
        function mergeItems(existingNode, inputNode) {
            if (inputNode.items && inputNode.items.length > 0) {
                inputNode.items.forEach(inputItem => {
                    const existingItem = existingNode.items.find(item => item.value === inputItem.value);
                    if (existingItem) {
                        // Recursively merge items
                        mergeItems(existingItem, inputItem);
                    } else {
                        // Add the new item
                        existingNode.items.push({ ...inputItem });
                    }
                });
            }
        }

        // Step 1: Find the parent chain for the input object in A
        const parentChain = [];
        let currentParent = findParent(A, input.value);

        while (currentParent) {
            parentChain.unshift(currentParent); // Build the chain from root to the immediate parent
            currentParent = findParent(A, currentParent.value);
        }

        // Step 2: Ensure the parent chain exists in B
        const parentItemsInB = findOrCreateParentChainInB(B, parentChain);

        // Step 3: Add or merge the input object into B
        const existingNode = parentItemsInB.find(node => node.value === input.value);

        if (existingNode) {
            // Merge items if input has additional items
            mergeItems(existingNode, input);
        } else {
            // Add input as a new node
            parentItemsInB.push({ ...input });
        }

        // Step 4: Sort B and all nested arrays
        sortObjectAndArrays(B);

        return B;
    }
    function removeObjectAndParentByValueWithException(A, input) {
        // Deep copy the array A to avoid modifying the original array
        const copyA = JSON.parse(JSON.stringify(A));

        // Helper function to remove an object from its parent's 'items' array
        function removeObjectFromParent(array, value) {
            for (let i = 0; i < array.length; i++) {
                const node = array[i];

                // Skip removing if the item's value is "ha.hashed_location_id, ha.hashed_person_id"
                if (node.value === "ha.hashed_location_id, ha.hashed_person_id" || node.value === "i.client_id") {
                    continue;
                }

                // If we find the node with the matching value
                if (node.value === value) {
                    // If the node has siblings (i.e., more than one item in the parent array)
                    if (array.length > 1) {
                        // Remove the node from the parent's 'items' array
                        array.splice(i, 1);
                        return true;
                    } else {
                        // If the node is the only one in the array, remove it
                        array.splice(i, 1);
                        return true;
                    }
                }

                // If the node has children (items), search in those
                if (node.items && node.items.length > 0) {
                    const result = removeObjectFromParent(node.items, value);
                    if (result) {
                        // If we found and removed the object, return true to stop further search
                        if (node.items.length === 0) {
                            // If the node's items are empty after removal, remove the parent node
                            array.splice(i, 1);
                        }
                        return true;
                    }
                }
            }
            return false; // Return false if no match was found in this array level
        }

        // Call the helper function on the copy of A
        removeObjectFromParent(copyA, input.value);

        return copyA; // Return the modified copy of array A
    }

    function mergeInputIntoA(A, input) {
        // Helper function to merge `items` recursively
        const mergeItems = (originalItems, newItems) => {
            const merged = [...originalItems];

            newItems.forEach(newItem => {
                const match = merged.find(item => item.value === newItem.value);

                if (match) {
                    // If the item exists, merge its `items` recursively
                    match.items = mergeItems(match.items || [], newItem.items || []);
                } else {
                    // If the item does not exist, add it to the merged array
                    merged.push(newItem);
                }
            });

            return merged;
        };

        // Iterate through the input array
        input.forEach(inputNode => {
            // Find the matching node in array A
            const match = A.find(node => node.value === inputNode.value);

            if (match) {
                // Merge the `items` arrays for the matched node
                match.items = mergeItems(match.items || [], inputNode.items || []);
            } else {
                // If no match, add the input node to array A
                A.push(inputNode);
            }
        });

        return A;
    }
    function treeListItemClickUtil(tempSegList1, tempSegList2, dataItem, onClickList1) {


        //On TreeList1 Item Click
        if (onClickList1) {
            tempSegList2 = addToParent(treeState.data1, treeState.data2, dataItem);
            tempSegList1 = removeObjectAndParentByValueWithException(treeState.data1, dataItem);

            //On TreeList2 Item Click
        } else {

            tempSegList1 = addToParent(treeState.data2, treeState.data1, dataItem);
            tempSegList2 = removeObjectAndParentByValueWithException(treeState.data2, dataItem);
        }
        // console.log("tempSegList1", tempSegList1);
        // console.log("tempSegList2", tempSegList2)
        return {list1: tempSegList1, list2: tempSegList2}
    }
    const onTreeListItemClick1 = (e) => {
        let tempSegList2 = treeState.data2;
        let tempSegList1 = treeState.data1;

        // if (e.level.length === 1) {
        //     if(!tempSegList2.find(item => item.value === e.dataItem.value)) {
        //         tempSegList2.push(e.dataItem);
        //     }else{
        //         tempSegList2.map(item => {
        //             if (item.value === e.dataItem.value) {
        //                 item.items.push(...e.dataItem.items);
        //             }
        //         })
        //     }
        //     tempSegList1 = treeState.data1.filter(item => item.value !== e.dataItem.value);
        // }else {
        //     const values = treeListItemClickUtil(tempSegList1, tempSegList2, e.dataItem, true);
        //     tempSegList1 = values.list1;
        //     tempSegList2 = values.list2;
        // }
        if (e.level.length === 1) {
            if(!tempSegList2.find(item => item.value === e.dataItem.value)) {
                tempSegList2.push(e.dataItem);
            }else{
                tempSegList2.map(item => {
                    if (item.value === e.dataItem.value && item.value !== "Digital Identifiers") {
                        // item.items.push(...e.dataItem.items);
                        item.items = mergeInputIntoA(item.items,e.dataItem.items);
                    }else if (item.value === e.dataItem.value && item.value === "Digital Identifiers"){
                        const filteredItems = e.dataItem.items.filter(
                            subItem => subItem.text !== "Hashed Semcasting ID" && subItem.text !== "Client ID"
                        );
                        // if(item.items.find(item => item.text === 'Sampling')){
                        item.items = mergeInputIntoA(item.items,filteredItems);
                        // }else{
                        //     item.items.push(...filteredItems);
                        // }
                    }
                })
            }
            if(e.dataItem.value === "Digital Identifiers") {
                // const filteredItems = treeState.data2[0].items.filter(item => item.text !== "Signal Matched Semcasting ID");
                tempSegList1.map(item => {
                    if (item.value === e.dataItem.value) {
                        // Filter out values with "ha.hashed_location_id, ha.hashed_person_id"
                        // Push only the filtered items
                        item.items = e.dataItem.items.filter(
                            subItem => subItem.text === "Hashed Semcasting ID"  || subItem.text === "Client ID"
                        );
                    }

                });
                tempSegList1 = treeState.data1.filter(item => item.value !== e.dataItem.value);
            }else{
                tempSegList1 = treeState.data1.filter(item => item.value !== e.dataItem.value);
            }
        }else if (e.level.length !== 1){
            //Prevent user from de-selecting Signal Matched Semcasting ID
            if(e.dataItem.text === "Hashed Semcasting ID" || e.dataItem.text === "Client ID"){
                return;
            }
            const values = treeListItemClickUtil(tempSegList1, tempSegList2, e.dataItem, true);
            tempSegList1 = values.list1;
            tempSegList2 = values.list2;
        }
        let allExpandedList2 = [];
        mapTree(treeState.data2.slice(), subItemsField, (item) => {
            allExpandedList2.push(item[dataItemKey]);
        });

        setTreeState(
            {
                ...treeState,
                data1: tempSegList1,
                data2: tempSegList2,
                expanded2: allExpandedList2
            });

        setTempSearchValues1(tempSegList1);
        setTempSearchValues2(tempSegList2);
    }
    const onTreeListItemClick2 = (e) => {
        let tempSegList2 = treeState.data2;
        let tempSegList1 = treeState.data1;
        const currentItem = e.dataItem;

        if (e.level.length === 1) {
            if(!tempSegList1.find(item => item.value === currentItem.value)) {
                if(currentItem.value === "Digital Identifiers" || currentItem.text === "Business Information" ||currentItem.text === "Employee Details"){
                    let tempItem = currentItem;
                    tempItem.items = tempItem.items.filter(item => item.text !== "Hashed Semcasting ID" && item.text !== "Client ID");
                    tempSegList1.push(tempItem);
                }else{
                    tempSegList1.push(currentItem);
                }

            }else{
                tempSegList1.forEach(item => {
                    if (item.value === currentItem.value) {
                        let filteredItems = currentItem.items;

                        if (["Digital Identifiers", "Business Information", "Employee Details"].includes(item.value)) {
                            filteredItems = currentItem.items.filter(
                                subItem => !["Hashed Semcasting ID", "Client ID"].includes(subItem.text)
                            );
                        }

                        item.items = mergeInputIntoA(item.items, filteredItems);
                    }
                });
            }
            if(currentItem.value === "Digital Identifiers" || currentItem.value === "Employee Details") {
                // const filteredItems = treeState.data2[0].items.filter(item => item.text !== "Signal Matched Semcasting ID");
                tempSegList2.map(item => {
                    if (item.value === currentItem.value) {
                        // Filter out values with "ha.hashed_location_id, ha.hashed_person_id"
                        // Push only the filtered items
                        item.items = item.items.filter(
                            subItem => subItem.text === "Hashed Semcasting ID"  || subItem.text === "Client ID"
                        );
                    }
                })
                tempSegList2 = tempSegList2.filter(obj => !(obj.value === "Digital Identifiers" && obj.items.length === 0));
            }else if(currentItem.value === "Business Information" ){
                if(tempSegList2.some(obj => obj.text === "Business Information" && Array.isArray(obj.items) && obj.items.some(item => item.text === "Client ID"))) {
                    tempSegList2.map(item => {
                        if (item.value === currentItem.value) {
                            // Filter out values with "ha.hashed_location_id, ha.hashed_person_id"
                            // Push only the filtered items
                            item.items = item.items.filter(
                                subItem => subItem.text === "Client ID"
                            );
                        }
                    })
                }else{
                    tempSegList2 = treeState.data2.filter(item => item.value !== e.dataItem.value);
                }
            }else{
                tempSegList2 = treeState.data2.filter(item => item.value !== currentItem.value);
            }
        }else if (e.level.length !== 1){
            //Prevent user from de-selecting Signal Matched Semcasting ID
            if(currentItem.text === "Hashed Semcasting ID" || currentItem.text === "Client ID"){
                    return;
            }
            const values = treeListItemClickUtil(tempSegList1, tempSegList2, currentItem, false);
            tempSegList1 = values.list1;
            tempSegList2 = values.list2;
        }


        setTreeState(
            {
                ...treeState,
                data1: tempSegList1,
                data2: tempSegList2
            });

        setTempSearchValues1(tempSegList1);
        setTempSearchValues2(tempSegList2);
    }
    const handleDragStartFirst = (e, props) => {
        const { dataItem, level } = props;
        setTreeState({
            ...treeState,
            dragFrom: 'first',
            dragged: { dataItem, level },
        });
    };

    const handleDragStartSecond = (e, props) => {
        const { dataItem, level } = props;
        //Prevent user from de-selecting Hashed Semcasting ID
        if(dataItem.text === "Hashed Semcasting ID"){
            return;
        }
        if(dataItem.text === "Business Information"){
            if (dataItem.items.length === 1 && dataItem.items.some(item => item.text === "Client ID")) {
                return;
            }

        }
        if(dataItem.text === "Employee Details"){
            if (dataItem.items.length === 1 && dataItem.items.some(item => item.text === "Hashed Semcasting ID")) {
                return;
            }

        }
        setTreeState({
            ...treeState,
            dragFrom: 'second',
            dragged: { dataItem, level },
        });
    };

    const rowForTreeList1 = (row, props) => {
        return (
            <RowRender
                props={props}
                row={row}
                // onDrop={handleOnDropFirst}
                onDragStart={handleDragStartFirst}
            />
        );
    };
    const rowForTreeList2 = (row, props) => {
        return (
            <RowRender
                props={props}
                row={row}
                // onDrop={handleOnDropSecond}
                onDragStart={handleDragStartSecond}
            />
        );
    };
    const handleDiv1 = () => {
        if (treeState.dragFrom === 'second') {
            let tempSegList2 = treeState.data2;
            let tempSegList1 = treeState.data1;
            const { dragged } = treeState;
            const { dataItem, level } = dragged;
            // if (e.level.length === 1) {
            //     if(currentItem.value === "Digital Identifiers" || currentItem.value === "Employee Details") {
            //         // const filteredItems = treeState.data2[0].items.filter(item => item.text !== "Signal Matched Semcasting ID");
            //         tempSegList2.map(item => {
            //             if (item.value === currentItem.value) {
            //                 // Filter out values with "ha.hashed_location_id, ha.hashed_person_id"
            //                 // Push only the filtered items
            //                 item.items = item.items.filter(
            //                     subItem => subItem.text === "Hashed Semcasting ID"  || subItem.text === "Client ID"
            //                 );
            //             }
            //         })
            //     }else if(currentItem.value === "Business Information" ){
            //         if(tempSegList2.some(obj => obj.text === "Business Information" && Array.isArray(obj.items) && obj.items.some(item => item.text === "Client ID"))) {
            //             tempSegList2.map(item => {
            //                 if (item.value === currentItem.value) {
            //                     // Filter out values with "ha.hashed_location_id, ha.hashed_person_id"
            //                     // Push only the filtered items
            //                     item.items = item.items.filter(
            //                         subItem => subItem.text === "Client ID"
            //                     );
            //                 }
            //             })
            //         }else{
            //             tempSegList2 = treeState.data2.filter(item => item.value !== e.dataItem.value);
            //         }
            //     }else{
            //         tempSegList2 = treeState.data2.filter(item => item.value !== currentItem.value);
            //     }
            // }else if (e.level.length !== 1){
            //     //Prevent user from de-selecting Signal Matched Semcasting ID
            //     if(currentItem.text === "Hashed Semcasting ID" || currentItem.text === "Client ID"){
            //         return;
            //     }
            //     const values = treeListItemClickUtil(tempSegList1, tempSegList2, currentItem, false);
            //     tempSegList1 = values.list1;
            //     tempSegList2 = values.list2;
            // }

            // if (level.length === 1) {
            //     if(!tempSegList1.find(item => item.value === dataItem.value)) {
            //         tempSegList1.push(dataItem);
            //     }else{
            //         tempSegList1.map(item => {
            //             if (item.value === dataItem.value && item.value !== "Digital Identifiers") {
            //                 // item.items.push(...e.dataItem.items);
            //                 item.items = mergeInputIntoA(item.items,dataItem.items);
            //             }else if (item.value === dataItem.value && item.value === "Digital Identifiers"){
            //                 const filteredItems = dataItem.items.filter(
            //                     subItem => subItem.text !== "Hashed Semcasting ID" && subItem.text !== "Client ID"
            //                 );
            //                 // if(item.items.find(item => item.text === 'Sampling')){
            //                 item.items = mergeInputIntoA(item.items,filteredItems);
            //                 // }else{
            //                 //     item.items.push(...filteredItems);
            //                 // }
            //             }
            //         })
            //     }
            if (level.length === 1) {
                if(!tempSegList1.find(item => item.value === dataItem.value)) {
                    if(dataItem.value === "Digital Identifiers" || dataItem.text === "Business Information" ||dataItem.text === "Employee Details"){
                        let tempItem = dataItem;
                        tempItem.items = tempItem.items.filter(item => item.text !== "Hashed Semcasting ID" && item.text !== "Client ID");
                        tempSegList1.push(tempItem);
                    }else{
                        tempSegList1.push(dataItem);
                    }

                }else{
                    tempSegList1.forEach(item => {
                        if (item.value === dataItem.value) {
                            let filteredItems = dataItem.items;

                            if (["Digital Identifiers", "Business Information", "Employee Details"].includes(item.value)) {
                                filteredItems = dataItem.items.filter(
                                    subItem => !["Hashed Semcasting ID", "Client ID"].includes(subItem.text)
                                );
                            }

                            item.items = mergeInputIntoA(item.items, filteredItems);
                        }
                    });
                }
                if(dataItem.value === "Digital Identifiers" || dataItem.value === "Employee Details") {
                    // const filteredItems = treeState.data2[0].items.filter(item => item.text !== "Signal Matched Semcasting ID");
                    tempSegList2.map(item => {
                        if (item.value === dataItem.value) {
                            // Filter out values with "ha.hashed_location_id, ha.hashed_person_id"
                            // Push only the filtered items
                            item.items = item.items.filter(
                                subItem => subItem.text === "Hashed Semcasting ID"  || subItem.text === "Client ID"
                            );
                        }
                    })
                }else if(dataItem.value === "Business Information" ){
                    if(tempSegList2.some(obj => obj.text === "Business Information" && Array.isArray(obj.items) && obj.items.some(item => item.text === "Client ID"))) {
                        tempSegList2.map(item => {
                            if (item.value === dataItem.value) {
                                // Filter out values with "ha.hashed_location_id, ha.hashed_person_id"
                                // Push only the filtered items
                                item.items = item.items.filter(
                                    subItem => subItem.text === "Client ID"
                                );
                            }
                        })
                    }else{
                        tempSegList2 = treeState.data2.filter(item => item.value !== dataItem.value);
                    }
                }else{
                    tempSegList2 = treeState.data2.filter(item => item.value !== dataItem.value);
                }
            }else if (level.length !== 1){
                //Prevent user from de-selecting Signal Matched Semcasting ID
                if(dataItem.text === "Hashed Semcasting ID" || dataItem.text === "Client ID"){
                    return;
                }
                const values = treeListItemClickUtil(tempSegList1, tempSegList2, dataItem, false);
                tempSegList1 = values.list1;
                tempSegList2 = values.list2;
            }

            let allExpandedList2 = [];
            mapTree(treeState.data2.slice(), subItemsField, (item) => {
                allExpandedList2.push(item[dataItemKey]);
            });

            setTreeState(
                {
                    ...treeState,
                    data1: tempSegList1,
                    data2: tempSegList2,
                    expanded2: allExpandedList2
                });

            setTempSearchValues1(tempSegList1);
            setTempSearchValues2(tempSegList2);

        }
    };
    const handleDiv2 = () => {

        if (treeState.dragFrom === 'first') {
            const { dragged } = treeState;
            const { dataItem, level } = dragged;
            let tempSegList2 = treeState.data2;
            let tempSegList1 = treeState.data1;


            if (level.length === 1) {
                if(!tempSegList2.find(item => item.value === dataItem.value)) {
                    tempSegList2.push(dataItem);
                }else{
                    tempSegList2.map(item => {
                        if (item.value === dataItem.value) {
                            item.items.push(...dataItem.items);
                        }
                    })
                }
                tempSegList1 = treeState.data1.filter(item => item.value !== dataItem.value);
            }else {
                const values = treeListItemClickUtil(tempSegList1, tempSegList2, dataItem, true);
                tempSegList1 = values.list1;
                tempSegList2 = values.list2;
            }


            setTreeState(
                {
                    ...treeState,
                    data1: tempSegList1,
                    data2: tempSegList2,
                    expanded2: tempSegList2.map((item) => item.treeId)
                });

            setTempSearchValues1(tempSegList1);
            setTempSearchValues2(tempSegList2);

        }
    };
    const onTreeList1ExpandChange = (e) => {
        setTreeState({
            ...treeState,
            expanded1: e.value
                ? treeState.expanded1.filter((id) => id !== e.dataItem.treeId)
                : [...treeState.expanded1, e.dataItem.treeId],
        });
    };
    const onTreeList2ExpandChange = (e) => {
        setTreeState({
            ...treeState,
            expanded2: e.value
                ? treeState.expanded2.filter((id) => id !== e.dataItem.treeId)
                : [...treeState.expanded2, e.dataItem.treeId],
        });
    };
    const addExpandField1 = (dataTree) => {
        const expanded = treeState.expanded1;
        return mapTree(dataTree, subItemsField, (item) =>
            extendDataItem(item, subItemsField, {
                [expandField]: expanded.includes(item.treeId)
            })
        );
    };
    const addExpandField2 = (dataTree) => {
        const expanded = treeState.expanded2;
        return mapTree(dataTree, subItemsField, (item) =>
            extendDataItem(item, subItemsField, {
                [expandField]: expanded.includes(item.treeId)
            })
        );
    };
    const handleTreeList1StateChange = (event) => {
        setTreeState({
            ...treeState,
            dataState1: event.dataState,
        });
    };
    const handleTreeList2StateChange = (event) => {
        setTreeState({
            ...treeState,
            dataState2: event.dataState,
        });
    };
    function sortTreeOrder (data){
        return data.sort((a, b) => {
            const indexA = tabList.indexOf(a.text);
            const indexB = tabList.indexOf(b.text);

            // If text is not found in B, it will return -1. You can adjust sorting for such cases if needed.
            return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
        });
    }
    const processData1 = () => {
        let {data1, dataState1} = treeState;
        console.log(data1)
        const sortedData = sortTreeOrder(data1);
        if (selectedTab !== "") {
            data1 = sortedData.filter(item => item.text === selectedTab);
        }
        return addExpandField1(data1);
    };
    const processData2 = () => {
        let {data2, dataState2} = treeState;
        const sortedData = sortTreeOrder(data2);
        return addExpandField2(sortedData);
    };
    const expandAll = (e, tree) => {
        e.preventDefault();
        //Expand all search result
        let allExpanded = [];
        if (tree === '1') {
            mapTree(treeState.data1.slice(), subItemsField, (item) => {
                allExpanded.push(item[dataItemKey]);
            });
            setExpand1(false);
            setTreeState({
                ...treeState,
                expanded1: allExpanded
            })
        } else {
            mapTree(treeState.data2.slice(), subItemsField, (item) => {
                allExpanded.push(item[dataItemKey]);
            });
            setExpand2(false);
            setTreeState({
                ...treeState,
                expanded2: allExpanded
            })
        }
    };
    const collapseAll = (e, tree) => {
        e.preventDefault();
        //Collapse all search result
        if (tree === '1') {
            setExpand1(true)
            setTreeState({
                ...treeState,
                expanded1: []
            })
        } else {
            setExpand2(true)
            setTreeState({
                ...treeState,
                expanded2: []
            })
        }
    }
    const handleTabOnClick = (e, val) => {
        e.preventDefault();
        setTreeState(
            {
                ...treeState,
                expanded1: treeState.data1.map((i)=>i.treeId)
            })
        if (selectedTab === val) {
            setSelectedTab("")
        } else {
            switch (val) {
                case selectedTab !== "":
                    setSelectedTab("");
                    break;
                case val:
                    setSelectedTab(val);
                    break;
                default:
                // code block
            }
        }
    }
    function contains(text, term) {
        return text.toLowerCase().indexOf(term.toLowerCase()) >= 0;
    }
    function search(items, term) {
        return items.reduce((acc, item) => {
            if (contains(item.text, term)) {
                acc.push(item);
            } else if (item.items && item.items.length > 0) {
                let newItems = search(item.items, term);
                if (newItems && newItems.length > 0) {
                    acc.push({text: item.text, items: newItems, treeId:item.treeId, value:item.value /*, expanded: item.expanded*/});
                }
            }
            return acc;
        }, []);
    }
    const handleSearch1 = () => {
        let allExpanded = [];
        let value = document.querySelector('.dragndrop-search-box1').value;
        let newData = search(tempSearchValues1, value);
        //Expand all search result if search value is not empty
        if (value.length !== 0) {
            mapTree(newData.slice(), subItemsField, (item) => {
                allExpanded.push(item[dataItemKey]);
            });
            setTreeState({
                ...treeState,
                data1: newData,
                expanded1: allExpanded
            })
        }else{
            setTreeState({
                ...treeState,
                data1: newData,
                expanded1: newData.map((i) => i.treeId)
            })
        }


    }
    const handleSearch2 = () => {
        let allExpanded = [];
        let value = document.querySelector('.dragndrop-search-box2').value;
        let newData = search(tempSearchValues2, value);
        //Expand all search result if search value is not empty
        if (value.length !== 0) {
            mapTree(newData.slice(), subItemsField, (item) => {
                allExpanded.push(item[dataItemKey]);
            });
            setTreeState({
                ...treeState,
                data2: newData,
                expanded2: allExpanded
            })
        }else{
            setTreeState({
                ...treeState,
                data2: newData,
                expanded2: newData.map((i) => i.treeId)
            })
        }
    }

    function countObjectsWithoutItems(arr) {
        let count = 0;

        arr.forEach(obj => {
            // Check if the object has no 'items' property or if 'items' is an empty array
            if (!obj.items || obj.items.length === 0) {
                count++;
            } else {
                // Recursively check for nested items
                count += countObjectsWithoutItems(obj.items);
            }
        });

        return count;
    }


    useEffect(() => {
        const result = countObjectsWithoutItems(treeState.data2)
        setTreeList2Count(result);
    }, [treeState]);



    const connections = allSavedSettings?.map((item, i) => {
        if (allSavedSettings.length > 0) {
            return (
                {text: item.connection_name, id: item.id, protocol: item.transfer_protocol}
            )
        } else {
            return (
                {text: "No Saved Connections", id: 0}
            )
        }
    });
    function findKeysWithNonEmptyArray(obj) {
        const result = [];

        function traverse(currentObj, currentKey) {
            if (Array.isArray(currentObj) && currentObj.length > 0) {
                result.push(currentKey);
            } else if (typeof currentObj === 'object' && currentObj !== null) {
                for (const key in currentObj) {
                    traverse(currentObj[key], key);
                }
            }
        }

        traverse(obj, null);
        return result;
    }
    function transformToNestedObject(arr) {
        const result = {};
        let temp = findKeysWithNonEmptyArray(originalTree);



        // console.log(pushKeyValuesToArray(originalTree['Retail Store Visits']))
        arr.forEach(item => {
            if (item.items && item.items.length > 0) {
                if (temp.includes(item.text)) {
                    // If item.text is in temp, directly assign item.items array as value
                    result[item.text] = item.items.map(subItem => subItem.value);
                } else {
                    // Otherwise, recursively transform the nested items
                    result[item.text] = transformToNestedObject(item.items);
                }
            } else {
                // If no nested items, map the value directly
                result[item.text] = item.value;
            }
        });

        return result;
    }
    useEffect(() => {
        console.log(transformToNestedObject(treeState.data1))
        console.log(transformToNestedObject(treeState.data2))
    }, [treeState]);

    const dataAppendSubmit = async () => {
        setDisableSubmit(true);
        try {
            const payload = {
                selectPickerFTPConnection: {id:currentConnection.id},
                [topCategory]: transformToNestedObject(treeState.data2)
            };
            let url = APPEND_TREE_EXPORT.replace(SEGMENT_ID_PLACE_HOLDER, selectedSeg.id);
            console.log(payload)
             await semcastAxios.post(url,
                payload,
                {withCredentials: true})
                .then(async (res) => {
                    if (res.data.status === SUCCESS_STATUS) {
                        showSuccessToastMessage("Selection Exported Successfully.", 3);
                        setTimeout(() => {
                            window.location.href = "/segmentation-dashboard";
                        }, 3000);
                    }
                }).catch((error) => {
                    if (error.response) {
                        showErrorToastMessage("Response Error: " + error.response.data.data, 10);
                        console.log(error.response);
                    }
                    if (error.request) {
                        showErrorToastMessage("Request Error: " + error.request.data.data, 10);
                        console.log(error.request);
                    }
                     setDisableSubmit(false);
                });
        } catch (error) {
            showErrorToastMessage(error.response.data.data, 10);
            console.log(error.response.data.data);
            setDisableSubmit(false);
        } finally {
            // setDisableSubmit(false);
        }
    }

    const clearFields = async () =>{
        await initialTree();
        setCurrentConnection({text:"Please Select...", id: 0})
    }
    return (
        <>
            <CardBody>
                <div className="data-append-container">
                    <LoadOverlay active={loadOverlay} width={'100%'} height={'100px'}>
                    <div className="data-append-inner-wrapper">
                        <Label>Append the following to the output file: </Label>
                    <div className={"tree-table-wrapper mt-1 mb-2"}>
                        <div className={"tab-button-group-wrapper"}
                             style={{marginBottom: "20px", marginTop: "10px"}}>
                            <div className="tab-button-group">
                                <ButtonGroup>
                                    {tabList.map((item, index) => {
                                        return (
                                            item !== "" ?
                                                <Button
                                                    className={`${selectedTab === item && "btn-primary"}`}
                                                    togglable={true} selected={selectedTab === item}
                                                    onClick={(e) => handleTabOnClick(e, item)}>
                                                    {item}
                                                </Button> :
                                                ""
                                        )
                                    })}
                                </ButtonGroup>
                                <Tooltip
                                    position="right"
                                    anchorElement="target"
                                    tooltipStyle={{
                                        width: "15rem",
                                        borderRadius: "0.25rem",
                                    }}
                                >
                                    <i className="fa fa-question-circle-o tooltip-question-icon"
                                       aria-hidden="true"
                                       title={"Select any tab to see the filtered list. Deselect the selected tab to see the full list."}></i>
                                </Tooltip>
                            </div>
                        </div>
                        <div className={"tree-outter-wrapper"}>
                            <div className={"left-tree-wrapper"} style={{width: "-webkit-fill-available"}}>
                                <div className={"tree-tool-bar"}>
                                    <Input className={"dragndrop-search-box1"} onChange={handleSearch1}
                                           placeholder={'Search Segment...'}/>
                                    <Button
                                        onClick={e => expand1 ? expandAll(e, '1') : collapseAll(e, '1')}
                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                        style={{marginLeft: '10px'}}
                                    >
                                        {expand1 ? "Expand all" : "Collapse all"}
                                    </Button>
                                </div>
                                <div
                                    // style={{
                                    //     display: 'flex',
                                    //     zIndex: '1000',
                                    //     width: '100%'
                                    // }}
                                    className={"drag-panel1"}
                                    onDrop={handleDiv1}
                                    onDragOver={(e) => {
                                        e.preventDefault()
                                    }}
                                >
                                <TreeList
                                    {...treeState.dataState1}
                                    style={{
                                        height: "50vh",
                                        overflow: "auto",
                                        marginTop: "20px",
                                    }}
                                    expandField={expandField}
                                    subItemsField={subItemsField}
                                    data={processData1()}
                                    columns={columns}
                                    onDataStateChange={handleTreeList1StateChange}
                                    onExpandChange={onTreeList1ExpandChange}
                                    onRowClick={onTreeListItemClick1}
                                    rowRender={rowForTreeList1}
                                />
                                </div>
                            </div>
                            <img alt="" src={angleLeftRightThemeColorIcon}
                                 style={{
                                     width: "50px",
                                     alignSelf: "center",
                                     paddingLeft: '5px',
                                     paddingRight: '5px'
                                 }}
                            />
                            <div className={"right-tree-wrapper"} style={{width: "-webkit-fill-available"}}>

                                <div className={"selected-count"}>Selected Attributes
                                    ({treeList2Count})
                                </div>
                                <div className={"tree-tool-bar"}>
                                    <Input className={"dragndrop-search-box2"} onChange={handleSearch2}
                                           placeholder={'Search Segment...'}/>
                                    <Button
                                        onClick={e => expand2 ? expandAll(e, '2') : collapseAll(e, '2')}
                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                        style={{marginLeft: '10px'}}
                                    >
                                        {expand2 ? "Expand all" : "Collapse all"}
                                    </Button>
                                </div>
                                <div
                                    // style={{
                                    //     display: 'flex',
                                    //     zIndex: '1000',
                                    //     width: '100%'
                                    // }}
                                    className={"drag-panel2"}
                                    onDrop={handleDiv2}
                                    onDragOver={(e) => {
                                        e.preventDefault()
                                    }}
                                >
                                <TreeList
                                    style={{
                                        height: "50vh",
                                        overflow: "auto",
                                        marginTop: "20px",
                                    }}
                                    expandField={expandField}
                                    subItemsField={subItemsField}
                                    data={processData2()}
                                    columns={columns}
                                    onDataStateChange={handleTreeList2StateChange}
                                    onExpandChange={onTreeList2ExpandChange}
                                    onRowClick={onTreeListItemClick2}
                                    rowRender={rowForTreeList2}
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                        <div className={"destination-dropdown-wrapper"}>
                            Save to connection:
                                {isLoaded ? (
                                    <DropDownList
                                        data={connections}
                                        textField="text"
                                        dataItemKey="id"
                                        onChange={(e) => handleConnectionChange(e)}
                                        value={currentConnection}
                                        defaultItem={{text:"Please Select...", id: 0}}
                                        disabled={allSavedSettings.length === 0}
                                        style={{fontSize: "14px"}}
                                    />
                                ) : (
                                    <Loader height={"100%"}/>
                                )}
                        </div>
                        <div className={"data-append-btn-wrapper"}>
                            <Button
                                // className="dialog-cancel"
                                className={"button-standard button-white"}
                                onClick={clearFields}>
                                Cancel
                            </Button>
                            <Button
                                disabled={disableSubmit}
                                className={"button-standard button-submit"}
                                onClick={dataAppendSubmit}>
                                Submit
                            </Button>
                        </div>
                    </div>
                    </LoadOverlay>
                </div>
            </CardBody>
        </>
    )
}

export default DataAppendTree;