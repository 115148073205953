import React, {useEffect, useRef, useState} from "react";
import LoadOverlay from "../SharedComponents/LoadOverlay";
import {Card, CardBody, CardTitle} from "@progress/kendo-react-layout";
import {Label} from "@progress/kendo-react-labels";
import {Checkbox, Input, TextArea} from "@progress/kendo-react-inputs";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {FieldWrapper, Form, FormElement} from "@progress/kendo-react-form";
import {loadAccessibleGroups, loadUsers} from "../../../actions/UserActions";
import {useFormik} from "formik";
import * as Yup from "yup";
import "./AboutAudience.css";
import NONMATCHED_FTPSS3 from "./NONMATCHED_FTPSS3";
import PermissionsEditGroup from "../Permission/PermissionsEditGroup"
import {UsersHelpers} from "../../../helpers/UsersHelpers";
import {segmentNameValidator} from "../../Pages/validators";
import {UserContext} from "../../../contexts/UserContext";


const AboutAudience = (props) => {

    const [accessibleGroups, setAccessibleGroups] = useState([]);
    const [groupError, setGroupError] = useState("");
    const [segmentName, setSegmentName] = useState(props.formik && props.formik.values ? props.formik.values.segment_name : "");
    const [generatePdfReports, setGeneratePdfReports] = useState(props.formik && props.formik.values ? props.formik.values.generate_pdf_reports : true);
    // const [generateNoMatchFile, setGenerateNoMatchFile] = useState(props.formik && props.formik.values ? props.formik.values.generate_nomatch_connection_id : 0);
    const [notes, setNotes] = useState(props.formik && props.formik.values ? props.formik.values.notes : "")
    const [openEditCreateDialog, setOpenEditCreateDialog] = useState(false);
    const [overlayActive, setOverlayActive] = useState(false);
    const [newGroupName, setNewGroupName] = useState("");
    const [groupsWithNewOption, setGroupsWithNewOption] = useState([]);
    const [segmentNameValid, setSegmentNameValid] = useState(true);
    const [msg, setMsg] = useState("");
    const NOTES_OPTIONAL_MESSAGE = "(Optional) Write a brief description about this segment.";
    const { user, setUser } = React.useContext(UserContext);
    // props.formik.values.generate_pdf_reports = true;

    useEffect(() => {
        setGeneratePdfReports(user.generate_onboard_profile);
        props.formik.values.generate_pdf_reports = user.generate_onboard_profile;
    }, [user]);

    let groups = accessibleGroups?.map((item, i) => {
        return (
            { text: item.group_name, id: item.resource_group_id }
        );
    });

    // Helper function to find group by id
    const findGroupById = (id) => {
        return groupsWithNewOption.find(group => group.id === id);
    };

    const [createGroupDialogOpen, setCreateGroupDialogOpen] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [createdGroupId, setCreatedGroupId] = useState(null);

    const handleNewGroupCreated = async (newGroupName) => {
        console.log("handleNewGroupCreated1 newGroupName=", newGroupName);
        setNewGroupName(newGroupName);
        setOpenEditCreateDialog(false); // Close the dialog
        setOverlayActive(true);

        const newGroupArray = await fetchAccessibleGroups(); // Assume this refreshes the groups list and includes the new group

        if (newGroupArray.length > 0) {
            console.log("handleNewGroupCreated newGroupArray fetched and used immediately:", newGroupArray);
            const groups = newGroupArray.map(item => ({
                text: item.group_name,
                id: item.resource_group_id
            }));
            console.log("handleNewGroupCreated2 groups=", groups);
            // Assuming the newly created group is included in the newly fetched list
            const newGroup = groups.find(group => group.text === newGroupName);
            console.log("handleNewGroupCreated3 newGroup=", newGroup);
            if (newGroupName) {
                props.formik.setFieldValue('group_id', newGroup.id);

                setCreatedGroupId(newGroup.id);
                console.log("handleNewGroupCreated4 BeforeNewOption newGroup=", newGroupName);
                const newGroupIndex = groups.findIndex(group => group.text === newGroupName);
                console.log("handleNewGroupCreated5 BeforeNewOption newGroupIndex=", newGroupIndex);

                let newGroupsList;
                if (newGroupIndex !== -1) {
                    const [removed] = groups.splice(newGroupIndex, 1);
                    console.log("handleNewGroupCreated6 Removed group:", removed);
                    newGroupsList = [removed, { text: "Create New Group", id: "new" }, ...groups];
                } else {
                    newGroupsList = [{ text: "Create New Group", id: "new" }, ...groups];
                }
                console.log("handleNewGroupCreated7 AfterNewGroupGroups=", groups);

                console.log("handleNewGroupCreated8 newGroupsList=", newGroupsList);
                if (JSON.stringify(groupsWithNewOption) !== JSON.stringify(newGroupsList)) {
                    setGroupsWithNewOption(newGroupsList);
                    console.log("handleNewGroupCreated8 NewGroupsWithNewOption=", groupsWithNewOption);
                    setSelectedGroup(findGroupById(newGroup.id));
                    console.log("selectedGroup1=", selectedGroup);
                }
            }
        } else {
            console.error("newGroupArrayNotFetched");
        }
        setOverlayActive(false);
    };
    useEffect(() => {
        console.log("Updated groupsWithNewOption=", groupsWithNewOption);
    }, [groupsWithNewOption]);

//insert for Group Roles
    const [users, setUsers] = useState([]);
    const [allUsersRoles, setAllUsersRoles] = useState([])
    let userHelpers = new UsersHelpers();
    const groupEditFormik = useFormik({
        initialValues: {
            group_name: '',
            group_id: '',
            userNRoles: []
        },
// We used Yup here.
        validationSchema: Yup.object().shape({
            group_name: Yup.string().required('Group Name is required'),
            userNRoles: Yup.array().of(
                Yup.object().shape({
                    user_id: Yup.string(),
                    user_name: Yup.string(),
                    role_id: Yup.number(),
                    group_id: Yup.number(),
                    founding_assignment: Yup.boolean()
                })
            )
        }),
        onSubmit: values => {
        },
    });
    const usersLoad = async () => {
        try {
            console.log("StartingUserSLoad");
            const values = await Promise.allSettled([loadUsers()]);
            console.log("UsersLoadVALUE:", values[0].value);

            setUsers(values[0].value);

            await resetGroupUserRolesForm(values[0].value);
            return values;

        } catch (e) {
        }
    }
    const resetGroupUserRolesForm = async (usersList) => {

        console.log("StartingrResetGroupUserRolesForm usersList=", usersList);
        let usersArr = usersList
        if (usersArr) {
            let userNRoles = usersArr.map(user => {
                return {
                    user_id: user.id,
                    user_name: `${user.first_name} ${user.last_name}`,
                    role_id: '',
                    group_id: '',
                    founding_assignment: false,
                    role_name: "Not a Member",
                }
            });

            console.log("ResetGroupUserRolesForm userNRoles=", userNRoles);
            setAllUsersRoles(userNRoles);

            await groupEditFormik.setValues({group_name: '', group_id: '', userNRoles})
            console.log("ResetGroupUserRolesForm userNRoles=", userNRoles);
        }
        // console.error("ResetGroupUserRolesForm userNRoles=", userNRoles);
    }
//end insert Group roles


    const onChangeSegmentName = (e) => {
        const value = e.value;
        setSegmentName(e.value);
        props.formik.values.segment_name = value;

        const msg = segmentNameValidator(e.value);
        console.log(msg)
        if (msg.length > 0) {
            setSegmentNameValid(false);
            setMsg(msg);
            setSegmentName(e.value);
            props.formik.values.segment_name = value;
            inputRef.current.focus();
        } else {
            setSegmentNameValid(true);
            setMsg("");
            setSegmentName(e.value);
            props.formik.values.segment_name = value;

        }
    };
    const onChangeGroup = async (e) => {
        console.log("onChangeGroup e.target.value=", e.target.value)
        const value = e.value;
        if (value.id === "new") {
            // Open modal to create a new group
            setOverlayActive(true);

            try {
                await usersLoad();
                setOpenEditCreateDialog(true);
            } catch (error) {
                console.error("Failed to load users and reset roles:", error);
                // Optionally handle the error, e.g., show an error message to the user
            }
            setOverlayActive(false);

            // You will need to pass a prop or use context to open the dialog from the second file
        } else {
            props.formik.values.group_id = value.id;
            console.log("onChangeGroup value.id=", value.id)
            setSelectedGroup(e.target.value);
        }
    };
    useEffect(() => {
        setSelectedGroup(findGroupById(props.formik?.values?.group_id) || (groupsWithNewOption.length ? groupsWithNewOption[0] : null));
        console.log("selectedGroup2=", selectedGroup);
    }, [props.formik?.values?.group_id, groupsWithNewOption]);

    const onChangeGenerateReport = (e) => {
        const value = e.value;
        setGeneratePdfReports(e.value);
        props.formik.values.generate_pdf_reports = value;
    };

    const onChangeNotes = (e) => {
        const value = e.value;
        setNotes(value);
        props.formik.values.notes = value;
    };

    const onSegmentNameEnter = (e) => {
        const key = e.code;
        if (key === "Enter") e.preventDefault();
    };
    const fetchAccessibleGroups = async () => {
        console.log("fetchAccessibleGroups0")
        const values = await Promise.allSettled([loadAccessibleGroups()]);
        console.log("fetchAccessibleGroups1Values=", values);
        if (values?.length > 0 && values[0].value.length > 0) {
            setAccessibleGroups(values[0].value);
            props.formik.values.group_id = values[0].value[0].resource_group_id;
            console.log("fetchAccessibleGroups2=", props.formik.values.group_id);
            return values[0].value;  // Return the fetched groups
        } else {
            console.error("Could not retrieve groups.");
            setGroupError("Could not retrieve groups.");
            return [];
        }
    };

    useEffect(() => {
        console.log("fetchAccessibleGroups from UseEffect")
        fetchAccessibleGroups();
    }, []);

    useEffect(() => {
        let newGroupsList = [{text: "Create New Group", id: "new"}, ...groups];

        if (!newGroupName) {
            console.log("OriginalSetting");
            if (JSON.stringify(groupsWithNewOption) !== JSON.stringify(newGroupsList)) {
                setGroupsWithNewOption(newGroupsList);
            }
        }
    }, [groups, newGroupName]);


    const inputRef = useRef();

    // useEffect(() => {
    //     console.log(props.formik.errors)
    //     if (props.formik.errors['segment_name']|| !segmentNameValid) {
    //         inputRef.current.focus();
    //         // props.setSegmentNameValid(true);
    //     }
    //
    // }, [segmentNameValid], props.formik.errors);

    useEffect(() => {

    }, [segmentName]);


    return (
        <div className={"about-audience-outer-div"}>
            <LoadOverlay active={overlayActive} height={50} width={50}>
                <Card className={"about-audience-card"}>
                    <CardTitle>
                        <Label className="section-label">ABOUT AUDIENCE</Label>
                    </CardTitle>
                    <CardBody>
                        {/*<Targeting handleSubmit={handleSubmit} />*/}
                        <Form render={formRenderProps => <FormElement style={{
                            width: "100%"
                        }}>
                            <legend className={'k-form-legend spacingLine'}></legend>
                            <div className={"about-audience-container"}>
                                <div className={"about-audience-column-1"}>
                                    <Form render={formRenderProps => <FormElement style={{
                                        width: "100%"
                                    }}>
                                        {/*<FieldWrapper style={{marginLeft: "2rem", width:"38rem"}}>*/}
                                        <FieldWrapper>
                                            <Label
                                                // className={props.formik.touched && props.formik.touched.segment_name && props.formik.errors.segment_name ? "labels-error" : "labels"}
                                                // className={"field-label"}
                                                className={props.formik.touched.segment_name && !segmentNameValid? "labels-error" : "field-label"}
                                            >
                                                Segment Name
                                            </Label>
                                            <Input
                                                ref={inputRef}
                                                onKeyDown={onSegmentNameEnter}
                                                name="segment_name"
                                                id={"segment_name"}
                                                className={"k-input"}
                                                defaultValue={props.formik.values.segment_name}
                                                onChange={onChangeSegmentName}
                                                onBlur={props.formik.handleBlur}
                                                style={{borderColor: props.formik.touched.segment_name && !segmentNameValid? "red" : ''}}/>
                                            {/*{props.formik.touched.segment_name && props.formik.errors.segment_name && (*/}
                                            {/*    // <span style={{color: "red"}}>{props.formik.errors.segment_name}</span>*/}
                                            {/*    <span style={{*/}
                                            {/*        color: "red",*/}
                                            {/*        fontSize: "14px"*/}
                                            {/*    }}>{props.formik.errors.segment_name}</span>*/}
                                            {/*)}*/}
                                            {!segmentNameValid && (
                                                <span style={{color: "red"}}>{msg}</span>
                                            )}
                                        </FieldWrapper>
                                        {/*<FieldWrapper style={{marginLeft: "2rem", width:"38rem"}}>*/}
                                        <FieldWrapper className={"about-audience-save-to-group"}>
                                            {/*<Label className={"labels"} >Save to Group</Label>*/}
                                            <Label className={"field-label"}>Save to Group</Label>
                                            {console.log('CurrentFormikGroupId=', props.formik.values.group_id)}
                                            {console.log('selectedGroup3=', selectedGroup)}

                                            <DropDownList
                                                data={groupsWithNewOption}
                                                textField="text"
                                                dataItemKey="id"
                                                //onClose={onChangeGroup}
                                                onChange={onChangeGroup}
                                                //defaultValue={findGroupById(props.formik?.values?.group_id) || (groupsWithNewOption.length ? groupsWithNewOption[0] : null)}
                                                //value={findGroupById(props.formik?.values?.group_id) || (groupsWithNewOption.length ? groupsWithNewOption[0] : null)}
                                                value={selectedGroup}
                                                className="fields dropdown-standard"
                                            />

                                        </FieldWrapper>
                                        {/*{props.formik.values.generate_pdf_reports !== undefined && (<FieldWrapper className={"about-audience-generate-pdf-reports"}>*/}
                                        {/*    <Label className={"field-label"} >Generate Insight Report</Label>*/}
                                        {/*    <Checkbox*/}
                                        {/*        size="large"*/}
                                        {/*        defaultChecked={props.formik && props.formik.values ? props.formik.values.generate_pdf_reports : ""}*/}
                                        {/*        onChange={onChangeGenerateReport}/>*/}
                                        {/*</FieldWrapper>)}*/}
                                    </FormElement>}/>
                                </div>
                                <div className="about-audience-column-2">
                                    <Form render={formRenderProps => <FormElement style={{
                                        width: "100%"
                                    }}>
                                        {/*<FieldWrapper style={{marginLeft: "2rem", width:"45.5%"}}>*/}
                                        {/*    <Label className={"labels"} >Generate Insight Report</Label>*/}
                                        {/*    <Checkbox*/}
                                        {/*        size="large"*/}
                                        {/*        defaultChecked={props.formik && props.formik.values ? props.formik.values.generate_pdf_reports : ""}*/}
                                        {/*        onChange={onChangeGenerateReport}/>*/}
                                        {/*</FieldWrapper>*/}
                                        <FieldWrapper>
                                            <Label className={"field-label"}>Notes</Label>
                                            <TextArea
                                                className={"fields"}
                                                defaultValue={props.formik && props.formik.values ? props.formik.values.notes : ""}
                                                // style={{width: "85%"}}
                                                maxLength={5000}
                                                placeholder={"Targeting new customers from mailing list..."}
                                                rows={1}
                                                onChange={onChangeNotes}
                                            />
                                            <span
                                                // style={{paddingTop: ".8rem"}}
                                                className={"field-label about-audience-notes-optional"}
                                            >
                                            {NOTES_OPTIONAL_MESSAGE}
                                        </span>
                                        </FieldWrapper>
                                        {/*{(props.formik.values.generate_pdf_reports !== undefined || props.formik.values.generate_nomatch_connection_id !== undefined) && (*/}
                                        {props.isOnboarding && (
                                            <FieldWrapper>
                                                <div className={"about-audience-row"}>
                                                    <div className={"about-audience-column"}>
                                                        <Label className={"field-label"}>Generate Insight Report</Label>
                                                        <Checkbox
                                                            size="large"
                                                            // defaultChecked={props.formik && props.formik.values ? generatePdfReports : false}
                                                            value={generatePdfReports}
                                                            onChange={onChangeGenerateReport}/>
                                                    </div>
                                                    <div className={"about-audience-column"}>
                                                        <Label className={"field-label"}>Direct Non-Match File to Connection</Label>
                                                        <NONMATCHED_FTPSS3 formik={props.formik}/>
                                                        {/*<Checkbox*/}
                                                        {/*    size="large"*/}
                                                        {/*    defaultChecked={props.formik && props.formik.values ? props.formik.values.generate_nomatch_file : ""}*/}
                                                        {/*    onChange={onChangeGenerateNoMatchFile}/>*/}
                                                    </div>
                                                </div>
                                            </FieldWrapper>
                                        )}
                                    </FormElement>}/>
                                </div>
                            </div>
                        </FormElement>}/>
                    </CardBody>
                </Card>
                {openEditCreateDialog && (
                    <PermissionsEditGroup
                        dialogMode={"new"}
                        editGroupObj={{}}
                        refreshTable={async () => false}
                        allUsersRoles={allUsersRoles}
                        // usersLoad = {usersLoad}
                        userHelpers={userHelpers}
                        openEditCreateDialog={createGroupDialogOpen}
                        setOpenEditCreateDialog={setOpenEditCreateDialog}
                        groupEditFormik={groupEditFormik}
                        onGroupCreated={handleNewGroupCreated} // Add this callback
                    />
                )}
            </LoadOverlay>
        </div>
    )
}

export default AboutAudience;