import * as Yup from "yup";
import { COMPANY_BASE_PROPERTIES } from "../../../../api/constants";

export const semcastingttdConfig = {
  ...COMPANY_BASE_PROPERTIES,

  /**
   * User Readable name of company the user is onBoarding with
   */
  companyText: "Onboarding With The Trade Desk via Semcasting",

  /**
   * lowercase no spaces or underscores name of company
   * This will be passed to the activate model and
   * used when saving to create the correct submission url
   */
  company: "semcasting_tradedesk",

  /**
   * HTML that explains how to use the form
   */
  instructions:
    "You have selected a platform that automatically reports usage for targeting. " +
    "You will be billed for data usage by the platform unless the segment is applied for suppression purposes. " +
    "Suppression segments are billed by Semcasting directly based on the discounted deployed record formula. " +
    "Activating this audience means you agree to this billing method. ",

  /**
   * data used to create a company activation block
   */
  companyBlockOptions: {
    val: "semcasting_tradedesk",
    text: "The Trade Desk via Semcasting",
    img: "semcasting-tradedesk-logo.svg",
  },

  /**
   * anchor tag used to login to a specific platform
   */
  loginLink:
    '<a href="https://auth.thetradedesk.com/Account/Login?ReturnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fresponse_type%3Did_token%26response_mode%3Dform_post%26scope%3Dopenid%2520profile%26client_id%3Ddesk%26nonce%3D733520271c7af38289e092f210a2bfd4%26state%3DGBUGoL1Q3aLFIbvGDJPQZDFg7rpc4lEv79%252F6rS%252Fdj0RMq4rZdShJ%252BQ81%252FnKkL0EWkzkB10V8Ct0mARYRI9uwzDngzyJTZNEoHJYTWCOyqrCmpJMz14ibuqfx7R8X9UEF5pTLPiUtSDjIu7eT6MC6A9GMoTXpXYXZ8jWxyPcs1sP5W%252BZilcMPv0knZJOCqd7d7Pq0hb4fRN4eOl0DfKLc%252Fxa2ZUPTiIh1xBohGqAv5yE%253D%26redirect_uri%3Dhttps%253A%252F%252Fdesk.thetradedesk.com%252Fsignin-oidc%26appReturnUrl%3Dhttps%25253A%25252F%25252Fdesk.thetradedesk.com%25252F%26appUrl%3Dhttps%25253A%25252F%25252Fdesk.thetradedesk.com%25252FAccount%25252FLogOn" target="_blank">Log in to The Trade Desk</a>',

  layout: [
    {
      fieldType: "radioGroup",
      labelText: "Audience Type",
      fieldName: "audience_type",
      required: true,
      radioButtons: [
        {
          labelText: "First Party",
          fieldName: "first_party",
          value: "first_party",
          checked: true,
        },
      ],
    },
    {
      fieldType: "checkboxGroup",
      labelText: "IP Addresses",
      fieldName: "device_type",
      required: false,
      checkboxes: [
        {
          labelText: "Web Network / WiFi",
          fieldName: "web_network_wifi",
          value: "web_network_wifi",
          checked: true,
          disabled: false,
        },
      ],
    },
    {
      fieldType: "radioGroup",
      labelText: "Mobile Devices",
      fieldName: "mobile_devices",
      required: true,
      radioButtons: [
        {
          labelText: "Top Devices",
          fieldName: "top_devices",
          value: "top_ios_android",
          checked: false,
        },
        {
          labelText: "All Devices",
          fieldName: "all_devices",
          value: "ios_android",
          checked: false,
        },{
          labelText: "No Devices",
          fieldName: "no_devices",
          value: "no_devices",
          checked: true,
        },
      ],
    },
    // FD:13804
    {
      fieldType: "checkboxGroup",
      labelText: "UID2s",
      fieldName: "device_type",
      required: false,
      checkboxes: [
        {
          labelText: "UID2",
          fieldName: "UID2",
          value: "UID2",
          checked: false,
        },
      ],
    },
    {
      fieldType: "dateRange",
      fieldName: "campaign_range",
      labelText: "Campaign Date Range",
      required: "dateRangeRequired",
    },

    {
      fieldType: "savableFieldsWrapper",
      layout: [
        {
          fieldType: "searchSelect",
          fieldName: "owner_id",
          labelText: "Account ID",
          url: "/v2/secure/attributor/activate_credentials/semcasting_tradedesk/owner_id",
          placeholder: "Select an Account ID or add a new one",
          namePropName: "value",
          textPropName: "value",
          valuePropName: "id",
          required: true,
        },
        {
          fieldType: "searchSelect",
          fieldName: "secret_key",
          labelText: "Secret Key",
          url: "/v2/secure/attributor/activate_credentials/semcasting_tradedesk/secret_key",
          placeholder: "Select an Secret Key or add a new one",
          namePropName: "value",
          textPropName: "value",
          valuePropName: "id",
          required: true,
        },
        {
          fieldType:'checkbox',
          fieldName:'save_credentials',
          labelText:'Save Account ID and Secret Key for future use',
          checked: false
        }
      ],
    },
    {
      fieldType: "radioGroup",
      labelText: "Planned Usage of this Segment:",
      fieldName: "audience_usage",
      required: true,
      radioButtons: [
        {
          labelText: "Targeting",
          fieldName: "use_type_inclusion",
          value: "I",
          checked: false,
        },
        {
          labelText: "Suppression",
          fieldName: "use_type_exclusion",
          value: "X",
          checked: false,
        },
      ],
    },{
      fieldType: "selectPickerTag",
      fieldName: "creativeTagIdDropDown",
      labelText: "Creative Tag ID",
      url: "/v2/secure/attributor/creative-tags",
      placeholder: "Select a New or existing Tag for your creative",
      namePropName: "creativeTagId",
      textPropName: "creativeTagId",
      valuePropName: "creativeTagId",
      required: true,
    },
    {
      fieldType: "creativeTagId",
      fieldName: "creativeTagId",
      labelText: "Creative Tag ID",
      required: true,
    },
    {
      fieldType: "creativeTag",
      fieldName: "creativeTag",
      labelText: "Creative Tag",
      required: true,
    },
  ],

  /**
   * an array of property names that can be saved and repeatedly used to "Activate/OnBoard" with a specific company
   */
  savableDataNames: ["owner_id", "secret_key"],
  formik: {
    initialValues: {
      device_type: "",
      audience_type: "first_party",
      mobile_devices: "no_devices",
      campaign_range_start: "",
      campaign_range_end: "",
      save_credentials: false,
      audience_usage:"",
      creativeTagIdDropDown: "",
      creativeTagId:"",
    },
    // We used Yup here.
    validationSchema: Yup.object().shape({
      campaign_range_start: Yup.date().required(
        "Campaign Date Range is required"
      ),
      campaign_range_end: Yup.date().required(
        "Campaign Date Range is required"
      ),
      owner_id: Yup.string().required("Account ID is required"),
      secret_key: Yup.string().required("Secret Key is required"),
      audience_usage: Yup.string().required("Planned Usage is required"),
      creativeTagIdDropDown: Yup.string().required("Creative Tag ID is required"),
      creativeTagId: Yup.string().required("Creative Tag ID is required"),
      mobile_devices: Yup.string().when(["device_type"], {
        is: (device_type) => !device_type,
        then: Yup.string().required("One of these fields must be filled"),
      }),
      device_type: Yup.array().when(["mobile_devices"], {
        is: (mobile_devices) => !mobile_devices || mobile_devices === 'no_devices',
        then: Yup.array().required("One of these fields must be filled"),
      }),
    },
        [
          //Included for validation
          ["mobile_devices", "device_type"],
        ]),
    onSubmit: (values) => {},
  },
};
