import React, {useEffect, useState} from "react";
import {Button, ButtonGroup} from "@progress/kendo-react-buttons";
import {Label} from "@progress/kendo-react-labels";
import "./AdvancedTargetingTreeList.css";
import {Form, FormElement} from "@progress/kendo-react-form";
import {Input} from "@progress/kendo-react-inputs";
import audienceDesignConfig from "../../../config/audienceDesignConfig";
import {getPpkSegments} from "../../../actions/AudienceDesignActions";
import LoadOverlay from "../SharedComponents/LoadOverlay";
import {extendDataItem, mapTree} from "@progress/kendo-react-common";
import {TreeList} from "@progress/kendo-react-treelist";
import coreDemographicData from "../../../config/coreDemographic.json";
import angleLeftRightThemeColorIcon from "../Segmentation/temporaryresources/images/arrow-left-right-theme-color.svg";
import {Tooltip} from "@progress/kendo-react-tooltip";


const AdvancedTargetingTreeListEdit = (props) => {

    const [options, setOptions] = useState([]);
    // const [filteredOptions, setFilteredOptions] = useState([]);
    const [targetingData, setTargetingData] = useState({});
    // const [activeTiles, setActiveTiles] = useState([]);
    // const [value, setValue] = useState([]);
    const [selectedTab, setSelectedTab] = React.useState("");
    const [msg, setMsg] = useState("");
    const [consumerList, setConsumerList] = useState([]);
    const [businessList, setBusinessList] = useState([]);
    const [loadOverlay, setLoadOverlay] = useState(true);
    const [preTransformTreeList1, setPreTransformTreeList1] = React.useState([]);
    const [preTransformTreeList2, setPreTransformTreeList2] = React.useState([]);
    const [tempSearchValues1, setTempSearchValues1] = useState([]);
    const [tempSearchValues2, setTempSearchValues2] = useState([]);
    const [expand1, setExpand1] = useState(true);
    const [expand2, setExpand2] = useState(true);
    const subItemsField = 'items';
    const expandField = 'expanded';
    const dataItemKey = 'treeId';

    const columns2 = [
        {
            field: "text",
            title: "Segment Name",
            width: "250px",
            expandable: true,
        },
    ];
    const columns1 = [
        {
            field: "text",
            title: "Segment Name",
            width: "250px",
            expandable: true,
            // filter: TreeListTextFilter,
        },
    ];
    const [treeListState1, setTreeListState1] = useState({
        data: [],
        expanded: [],
        dataState: {
            sort: [],
            filter: [],
        },
    });
    const [treeListState2, setTreeListState2] = useState({
        data: [],
        expanded: [],
        dataState: {
            sort: [],
            filter: [],
        }
    });
    // function removeDuplicates(array) {
    //     const seen = new Set();
    //
    //     return array.filter(obj => {
    //         const id = obj.value.segment_id;
    //         if (!seen.has(id)) {
    //             seen.add(id);
    //             return true;
    //         }
    //         return false;
    //     });
    // }
    function splitAnalyticsIQ(array) {
        const targetIndex = array.findIndex(obj => obj.text === "Analytics IQ");
        if (targetIndex === -1) return array;

        const targetObject = array[targetIndex];
        const groupedItems = {};

        targetObject.items.forEach(item => {
            const groupKey = item.text.split('>')[0].trim(); // Extract the first part before '>'
            const newText = `Analytics IQ - ${groupKey}`;
            if (!groupedItems[groupKey]) {
                groupedItems[groupKey] = { text: newText, items: [] };
            }
            groupedItems[groupKey].items.push({ text: item.text, items: item.items });
        });

        const newObjects = Object.values(groupedItems);

        return [...array.slice(0, targetIndex), ...newObjects, ...array.slice(targetIndex + 1)];
    }
    const filterAnalyticsIQ = (inputObj, searchString) => {
        if (!inputObj || !Array.isArray(inputObj)) {
            return [];
        }
// let outputObj = {};
//         outputObj["groupName"] = "Analytics IQ";
//         outputObj["targetType"] = "CONSUMER";
//         outputObj = inputObj.filter(item => item.segment_category.startsWith(searchString))
//         return outputObj;
        return inputObj.filter(item => item.segment_category.startsWith(searchString))
    };
    useEffect(async () => {
        options.length = 0;
        // filteredOptions.length = 0;
        // value.length = 0;
        // setActiveTiles([]);
        await fetchData();
        //Select the first tab by default
        setSelectedTab(tabList[1]);
        if (props.originalTargetType === "BUSINESS" && props.targetType === "CONSUMER") {
            props.handleAudienceState(prevState => {
                return {...prevState, segment_name: "Customers of ".concat(
                        props.segmentName !== ""  ? props.segmentName : props.audienceState.originalSegmentName, )};
            });
            props.handleSegmentName("Customers of ".concat(props.segmentName));
        } else {
            props.handleAudienceState(prevState => {
                return {...prevState, segment_name: props.audienceState.originalSegmentName};
            });
            props.handleSegmentName( props.audienceState.originalSegmentName);
        }
    }, [props.targetType]);

    useEffect(() => {
        let dataObjs = [];
        for (let property in targetingData) {
            // console.log(property);
            targetingData[property].map((obj) => {
                obj["groupName"] = targetingData[property].groupName;
                let s = !(obj.segment_name.includes(":")) ? obj.segment_name : obj.description;
                dataObjs.push({label: s, value: obj});
            })
        }
        // console.log(dataObjs)
        setOptions(dataObjs);
        // dataObjs = removeDuplicates(dataObjs);
    }, [targetingData]);

    useEffect(() => {
        if ((targetingData && Object.keys(targetingData).length > 0) &&
            (props.audienceState && Object.keys(props.audienceState).length > 0) && (targetingData["coreDemographic"] || targetingData["coreFirmographic"])) {
            determineTargetedSegments(options);
        }
    }, [targetingData, options])

    useEffect(() => {
        // console.log("props.audienceState",props.audienceState)
        // console.log(treeListState1.data)
    }, [treeListState1, treeListState2, expand1, expand2, msg, props.audienceState]);

    function filterByGroupType(A, B) {
        // Create a filtered object
        return A.reduce((result, aItem) => {
            // console.log(aItem.groupType)
            const groupType = aItem.groupType;
            if (B[groupType]) {
                // If the key matches, add it to the result
                result[groupType] = B[groupType];
            }
            return result;
        }, {});
    }
    const fetchData = async () => {
        setLoadOverlay(true);
        const allReq = [];
        let cList = [];
        let bList = [];
        for (const key in audienceDesignConfig) {
            //manually adding coreDemographic below for the tree
            if (key !== 'coreDemographic' && key !== 'coreFirmographic') {
                allReq.push(
                    await getPpkSegments(
                        audienceDesignConfig[key].endPoint,
                        audienceDesignConfig[key].groupType
                    )
                );
            }
            // console.log(audienceDesignConfig[key]);
            if ((audienceDesignConfig[key].targetType === "CONSUMER" ||
                audienceDesignConfig[key].targetType === "BOTH")) {
                cList.push(audienceDesignConfig[key]);
            }
            if ((audienceDesignConfig[key].targetType === "BUSINESS" ||
                audienceDesignConfig[key].targetType === "BOTH")) {
                bList.push(audienceDesignConfig[key]);
            }
        }

        //manually adding coreDemographic data (doing this to avoid backend changes)
        const businessCoreFirmographicDataObj = {
            code: 0,
            data: coreDemographicData.business,
            count: 0,
            status: '',
            groupType: 'coreFirmographic'
        };
        const consumerCoreDemographicDataObj = {
            code: 0,
            data: coreDemographicData.consumer,
            count: 0,
            status: '',
            groupType: 'coreDemographic'
        };
        if (props.targetType === 'CONSUMER') {
            allReq.push(consumerCoreDemographicDataObj);
        } else {
            allReq.push(businessCoreFirmographicDataObj);
        }

        // FD#11981
        // cList = cList.filter((key) => {
        //     return key.groupType !== 'smllearning'
        // })
        // console.log("cList", cList)
        // console.log("bList", bList)
        setConsumerList(cList);
        setBusinessList(bList);

        await Promise.all(allReq).then((res) => {
            const allData = {};
            // console.log(res);
            res.forEach((reqData) => {
                const {data, groupType} = reqData;
                // It appears in 1.x that targeting data (ex: Travel & Entertainment) can
                // appear when we switch to CONSUMER - with a message informing the user that
                // the target would be consumers visiting the businesses.
                // if (audienceDesignConfig[groupType].targetType === "BOTH" ||
                //     audienceDesignConfig[groupType].targetType === props.targetType) {
                //FD#12181 A Business segment should only have BUSINESS options, when switching to CONSUMER, you can have all options
                if (audienceDesignConfig[groupType].targetType === "BOTH" ||
                    audienceDesignConfig[groupType].targetType === "BUSINESS" ||
                    audienceDesignConfig[groupType].groupName === "Net Worth" ||
                    audienceDesignConfig[groupType].groupName === "Discretionary Income") {
                    allData[groupType] = data;
                    allData[groupType].groupName = audienceDesignConfig[groupType].groupName;
                    allData[groupType].targetType = audienceDesignConfig[groupType].targetType;
                }
                if (audienceDesignConfig[groupType].targetType === "BOTH" ||
                    audienceDesignConfig[groupType].targetType === props.targetType ||
                    audienceDesignConfig[groupType].groupName === "Net Worth" ||
                    audienceDesignConfig[groupType].groupName === "Discretionary Income") {
                    allData[groupType] = data;
                    allData[groupType].groupName = audienceDesignConfig[groupType].groupName;
                    allData[groupType].targetType = audienceDesignConfig[groupType].targetType;
                }
                // }
            });
            if (allData['personalFinance']) {
                handleDeciles(allData);
            }
            // //Sepcial case for AnalyticsIQ FD#13642
            // if(allData["analyticsIQ"]){
            //     allData["analyticsIQHealthcare"] = filterAnalyticsIQ(allData["analyticsIQ"], "Health & Wellness > CBD > Consumption Method");
            //     allData["analyticsIQHealthcare"].groupName = "Analytics IQ";
            //     allData["analyticsIQHealthcare"].targetType = "CONSUMER";
            //     allData["analyticsIQB2B"] = filterAnalyticsIQ(allData["analyticsIQ"], "B2B");
            //     allData["analyticsIQB2B"].groupName = "Analytics IQ";
            //     allData["analyticsIQB2B"].targetType = "CONSUMER";
            // }
            //FD#12181 Removing these 2 groups because tiles not found under Business Target Type. Same group can still be found under Consumer-personalFinance.
            delete allData["discretionaryIncome"];
            delete allData["netWorth"];
            //FD#11981
            // delete allData["smllearning"];
            //filter data based on target type
            // console.log(allData)
            const filteredAllData = props.targetType === "CONSUMER" ? filterByGroupType(cList, allData) : filterByGroupType(bList, allData);
            console.log(filteredAllData)
            setTargetingData(filteredAllData);
            setLoadOverlay(false);
            // setPageLoader(false);
            // setIsDataLoaded(true);
        });
    };

    //FD:10151 - Special case for decile categories
    const handleDeciles = (data) => {
        // console.log(data);
        let personalFinance = data['personalFinance'];
        if (personalFinance === undefined) return;
        let netWorth = data['netWorth'];
        let discretionaryIncome = data['discretionaryIncome'];
        netWorth.map((obj) => {
            obj.segment_group = 'Personal Finance';
            obj.segment_name = obj.description;
        })
        discretionaryIncome.map((obj) => {
            obj.segment_group = 'Personal Finance';
            obj.segment_name = obj.description;
        })
        // discretionaryIncome.sort((a, b) => a.segment_id.localeCompare(b.segment_id, 'en', {numeric: true}))
        data['personalFinance'] = personalFinance.concat(netWorth).concat(discretionaryIncome);
        // console.log(data['personalFinance']);
    }


    const infoSheetAvailable = ['Analytics IQ', 'Meds & Treatment', 'HealthPlan', 'PurpleLabHCP', 'SalesIntel Techno.', 'SalesIntel Dept.']; //FD#11854

    // const mergedArray = [...consumerList, ...businessList].reduce((acc, current) => {
    //     const x = acc.find(item => item.groupType === current.groupType);
    //     if (!x) {
    //         acc.push(current);
    //     }
    //     return acc;
    // }, []);
    const mergedArray = [...consumerList, ...businessList];
    // console.log(mergedArray)
    // console.log(targetingData)
    const determineTargetedSegments = (options) => {
        let targetedData = [];
        let primaryKeyValues = props.primaryKeys;
        const keys = Object.keys(props.audienceState);
        // console.log(targetingData)
        for (let key of keys) {
            if (targetingData[key]) {
                // console.log(key);
                const existing = props.audienceState[key];
                if (existing && existing.length === 0) continue;
                let targetedEntry;
                for (const segId of existing) {
                    // console.log(segId)
                    targetedEntry = targetingData[key].find(obj => {

                        return (obj.segment_id === segId);
                    });
                    let label = "";

                    if (targetedEntry?.groupName === "Retail" ||
                        targetedEntry?.groupName === "Personal Finance" ||
                        targetedEntry?.groupName === "Sports Attendees") {
                        label = targetedEntry?.description;
                    } else {
                        label = targetedEntry?.segment_name;
                    }
                    // console.log(targetedEntry)
                    targetedEntry.label = label;
                    targetedEntry.text = label;
                    targetedEntry.value = targetedEntry?.segment_id;
                    targetedData.push({label: label, value: targetedEntry})
                }
                // console.log(targetedData)
            }else if (key === 'travelSegments' && props.targetType === 'BUSINESS'){
                console.log(props.targetType)
                const existing = props.audienceState[key];
                if (existing && existing.length === 0) continue;
                let targetedEntry;
                for (const segId of existing) {
                    // console.log(segId)
                    console.log(targetingData)
                    targetedEntry = targetingData['travelAndEntertainment']?.find(obj => {

                        return (obj.segment_id === segId);
                    });

                    let label = targetedEntry?.segment_name;
                    // console.log(targetedEntry)
                    targetedEntry.label = label;
                    targetedEntry.text = label;
                    targetedEntry.value = targetedEntry?.segment_id;
                    targetedData.push({label: label, value: targetedEntry})
                }
            }else{
                //conditional rendering for Core Demographic/Core Firmographic (primaryData)
                let targetedEntry;
                for (const subKey in primaryKeyValues) {
                    if (subKey === key){
                        primaryKeyValues[subKey] = props.audienceState[key];
                        // console.log(subKey, primaryKeyValues[subKey], targetingData)
                        if(props.audienceState[key].length > 0){
                            // console.log(key, props.audienceState[key])
                            let label = "";
                            if(targetingData["coreDemographic"] && (subKey !== 'digital_activity_index') && (subKey !== 'inflation_sensitivity_index')){
                                props.audienceState[key].forEach((item) => {
                                    targetedEntry = targetingData["coreDemographic"].filter(subItem => item === subItem.segment_id && key === subItem.segment_group)[0];
                                    if(targetedEntry){
                                        label = targetedEntry?.description;
                                        targetedEntry.label = label;
                                        targetedEntry.text = label;
                                        targetedEntry.value = targetedEntry?.segment_id;
                                        targetedData.push({label: label, value: targetedEntry});
                                    }
                                })
                            }else if (targetingData["coreDemographic"] && (subKey === 'digital_activity_index' || subKey === 'inflation_sensitivity_index')) {
                                props.audienceState[key].forEach((item) => {
                                    //Special cases for 'digital_activity_index' and 'inflation_sensitivity_index'
                                    targetedEntry = targetingData["coreDemographic"].filter(subItem => (subKey === subItem.segment_group )&& JSON.stringify(subItem.segment_id) === JSON.stringify(item))[0];
                                    if(targetedEntry){
                                        label = targetedEntry?.description;
                                        targetedEntry.label = label;
                                        targetedEntry.text = label;
                                        targetedEntry.value = targetedEntry?.segment_id;
                                        targetedData.push({label: label, value: targetedEntry});
                                    }
                                    //remove duplicate values
                                    // targetedData = Array.from(
                                    //     new Map(targetedData.map(item => [item.label, item])).values()
                                    // );
                                })
                            }else if(targetingData["coreFirmographic"]){

                                props.audienceState[key].forEach((item) => {
                                    targetedEntry = targetingData["coreFirmographic"].filter(subItem => item === subItem.segment_id && key === subItem.segment_group)[0];
                                    if(targetedEntry){
                                        label = targetedEntry?.segment_name;
                                        targetedEntry.label = label;
                                        targetedEntry.text = label;
                                        targetedEntry.value = targetedEntry?.segment_id;
                                        targetedData.push({label: label, value: targetedEntry});
                                    }
                                })
                            }
                        }
                    }
                }

            }
            // console.log(targetedData.length > 0 ? targetedData:"")
            options = options.filter(itemA => {
                // Check if array B does not contain an object with the same groupType
                return !targetedData.some(itemB => itemA.value.label === itemB.label) && itemA.label !== "All" && itemA.label !== '';
            });
            // console.log(targetedData)
            // console.log(options)
            setPreTransformTreeList1(options)
            // console.log(targetedData)
            setPreTransformTreeList2(targetedData)
            // console.log(transformObject(targetedData))
            let tree2data = splitAnalyticsIQ(transformObject(targetedData));
            let tree1data = splitAnalyticsIQ(transformObject(options));
            // console.log(tree1data)
            // console.log(consumerList, businessList)
            let allExpanded = [];

            mapTree(mergeArrays(mergedArray, tree2data).slice(), subItemsField, (item) => {
                allExpanded.push(item[dataItemKey]);
            });
            // console.log(mergedArray)
            // console.log(tree2data)
            // console.log(mergeArrays(mergedArray, tree2data))
            setExpand2(false);
            setTreeListState2({
                ...treeListState2,
                data: mergeArrays(mergedArray, tree2data),
                expanded: allExpanded
            });
            setTempSearchValues2(mergeArrays(mergedArray, tree2data));
            if (props.targetType === 'CONSUMER') {
                setTreeListState1({
                    ...treeListState1,
                    data: mergeArrays(consumerList, tree1data),
                    expanded: mergeArrays(consumerList, tree1data).map((i)=>i.treeId)
                });
                setTempSearchValues1(mergeArrays(consumerList, tree1data));
            } else {
                setTreeListState1({
                    ...treeListState1,
                    data: mergeArrays(businessList, tree1data),
                    expanded: mergeArrays(businessList, tree1data).map((i)=>i.treeId)
                });
                setTempSearchValues1(mergeArrays(businessList, tree1data));
            }
            // console.log(tempSegList1, tempSegList2, dataItem, onClickList1)
            // targetedData.forEach(item => {
            //     treeListItemClickUtil(options, targetedData,item.value, false)
            // })

        }

    };

    function getValueFromKey(obj, str) {
        // console.log(obj, str)
        const normalizedStr = str.toLowerCase().replace(/[\s-_]/g, '');

        for (const key in obj) {
            const normalizedKey = key.toLowerCase().replace(/[\s-_]/g, '');
            if (normalizedKey === normalizedStr) {
                return key;
            } else if (normalizedStr === 'healthcarespecialist') {
                return 'hcpspecialtySegmentsBusiness'
            }
        }
        return undefined; // Return undefined if the key is not found
    }

    function transformObject(arr) {
        const result = {};
        const comboList = [...consumerList, ...businessList];
        // let Id = 1;
        arr.forEach(item => {
            const {segment_category, segment_group} = item.value;
            let {groupName} = item.value;
            if (groupName === undefined) {
                groupName = segment_group
            }

            // If groupName doesn't exist in result, initialize it with empty items array
            if (!result[groupName]) {
                result[groupName] = {
                    text: comboList.find(item => item.groupName === groupName).title,
                    // treeId: Id++,
                    items: []
                };
            }
            // Find or create the matching segment_category within the Items of the current groupName
            let categoryGroup = result[groupName].items.find(subItem => subItem.text === segment_category);
            if (!categoryGroup) {
                categoryGroup = {
                    text: segment_category,
                    // treeId: Id++,
                    items: []
                };
                result[groupName].items.push(categoryGroup);
                // console.log(result[groupName].items)
                result[groupName]?.items?.sort((a, b) => a.text.toString().localeCompare(b.text.toString()));
            }
            // Add the item with label as text to the items of the matching segment_category
            categoryGroup.items.push({
                ...item.value,
                label: item.label ? item.label : item.value.label,
                text: item.label ? item.label : item.value.label,
            });
            if(categoryGroup.items){
                // console.log(categoryGroup?.items)
                categoryGroup?.items?.sort((a, b) => a.segment_id.toString().localeCompare(b.segment_id.toString()))
            }

        });
        // Convert the result object to an array
        return Object.values(result);
    }

    function mergeByText(array) {

        const map = new Map();

        // Function to recursively merge items
        function mergeItems(existingItems, newItems) {
            const existingMap = new Map();

            // Convert existing items to a map for easy lookup
            existingItems.forEach(item => {
                existingMap.set(item.text, item);
            });

            newItems.forEach(item => {
                if (existingMap.has(item.text)) {
                    // Merge existing item with new item
                    const existingItem = existingMap.get(item.text);
                    if(existingItem.items){
                        existingItem.items = mergeItems(existingItem.items, item.items);
                    }
                } else {
                    // Add new item to the map
                    existingMap.set(item.text, item);
                }
            });
            // Convert the map values to an array
            return Array.from(existingMap.values());
        }

        array.forEach(item => {
            if (map.has(item.text)) {
                // Item exists, merge the children
                const existingItem = map.get(item.text);
                if(existingItem.items){
                    existingItem.items = mergeItems(existingItem.items, item.items);
                }
            } else {
                // Item doesn't exist, add it to the map
                map.set(item.text, item);
            }
        });

        // Convert the map values to an array
        return Array.from(map.values());
    }

    function mergeArrays(a, b) {
        let Id = 1;

        return mergeByText(a.map(aItem => {
            // Find the corresponding item in array b
            const bItem = b.find(bItem => {
                const normalize = str => str.replace(/[^a-zA-Z]/g, "").toLowerCase(); // Remove non-alphabetic characters and convert to lowercase
                return normalize(bItem.text) === normalize(aItem.title) ? normalize(bItem.text) === normalize(aItem.title) : normalize(bItem.text) === normalize(aItem.groupName);
            });
            if (bItem && bItem.text.startsWith("Analytics IQ")) {
                return {
                    text: aItem.displayType,
                    treeId: Id++,
                    value: aItem.displayType,
                    items: [{
                        text: aItem.title.concat(" (", aItem.price, ")"),
                        treeId: Id++,
                        price: aItem.price,
                        value: bItem.text,
                        items: bItem.items.map(bSubItem => ({
                            text: bSubItem.text,
                            treeId: Id++,
                            value: bSubItem.text,
                            items: bSubItem.items.map(bSegment => ({
                                ...bSegment,
                                value: bSegment.segment_id,
                                treeId: Id++,
                            }))
                        }))
                    }]
                };
            }else if (bItem && bItem.text !== "Core Demographic" && bItem.text !== "Core Firmographic" && !bItem.text.startsWith("Analytics IQ")) {
                return {
                    text: aItem.displayType,
                    treeId: Id++,
                    value: aItem.displayType,
                    items: [{
                        text: bItem.text.concat(" (", aItem.price, ")"),
                        treeId: Id++,
                        price: aItem.price,
                        value: bItem.text,
                        items: bItem.items.map(bSubItem => ({
                            text: bSubItem.text,
                            treeId: Id++,
                            value: bSubItem.text,
                            items: bSubItem.items.map(bSegment => ({
                                ...bSegment,
                                value: bSegment.segment_id,
                                treeId: Id++,
                            }))
                        }))
                    }]
                };
            } else if (bItem && (bItem.text === "Core Demographic" || bItem.text === "Core Firmographic")) {
                return {
                    text: aItem.displayType,
                    treeId: Id++,
                    value: aItem.displayType,
                    items: bItem.items.map(bSubItem => ({
                        text: bSubItem.text,
                        treeId: Id++,
                        value: bSubItem.text,
                        items: bSubItem.items.map(bSegment => ({
                            ...bSegment,
                            value: bSegment.segment_id,
                            treeId: Id++,
                        }))
                    }))
                };
            }
            return null; // Return null if no matching item is found
        }).filter(item => item !== null)); // Filter out any nulls
    }


    const expandAll = (e, tree) => {
        e.preventDefault();
        //Expand all search result
        let allExpanded = [];
        if (tree === '1') {
            // treeListState1.data.forEach((item) => {
            //     allExpanded.push(item.treeId);
            //     item.items.forEach(index => {
            //         allExpanded.push(index.treeId);
            //         index.items.forEach(i => {
            //             allExpanded.push(i.treeId);
            //         })
            //     });
            // });
            mapTree(treeListState1.data.slice(), subItemsField, (item) => {
                allExpanded.push(item[dataItemKey]);
            });
            setExpand1(false);
            setTreeListState1({
                ...treeListState1,
                expanded: allExpanded
            })
        } else {
            // treeListState2.data.forEach((item) => {
            //     allExpanded.push(item.treeId);
            //     item.items.forEach(index => {
            //         allExpanded.push(index.treeId);
            //         index.items.forEach(i => {
            //             allExpanded.push(i.treeId);
            //         })
            //     });
            // });
            mapTree(treeListState2.data.slice(), subItemsField, (item) => {
                allExpanded.push(item[dataItemKey]);
            });
            setExpand2(false);
            setTreeListState2({
                ...treeListState2,
                expanded: allExpanded
            })
        }
    };

    const collapseAll = (e, tree) => {
        e.preventDefault();
        //Collapse all search result
        if (tree === '1') {
            setExpand1(true)
            setTreeListState1({
                ...treeListState1,
                expanded: []
            })
        } else {
            setExpand2(true)
            setTreeListState2({
                ...treeListState2,
                expanded: []
            })
        }
    }
    const handleSearch1 = () => {
        let titles = [];
        let allExpanded = [];
        let value = document.querySelector('.dragndrop-search-box1').value;
        // let data = transformObject(options.filter((i)=>{return i.label !== "All" && i.label !== ''}))

        let newData = search(tempSearchValues1, value);
        //Expand all search result if search value is not empty
        if (value.length !== 0) {
            newData.forEach((item) => {
                titles.push(item.text);
                allExpanded.push(item.treeId);
                item.items.forEach(index => {
                    titles.push(index.text);
                    allExpanded.push(index.treeId);
                    index.items.forEach(i => {
                        titles.push(i.text);
                        allExpanded.push(i.treeId);
                    })
                });
            });
        }
        setTreeListState1({
            ...treeListState1,
            data: newData,
            expanded: allExpanded

        })
    }
    const handleSearch2 = () => {
        let titles = [];
        let allExpanded = [];
        let value = document.querySelector('.dragndrop-search-box2').value;

        let newData = search(tempSearchValues2, value);
        //Expand all search result if search value is not empty
        if (value.length !== 0) {
            newData.forEach((item) => {
                titles.push(item.text);
                allExpanded.push(item.treeId);
                item.items.forEach(index => {
                    titles.push(index.text);
                    allExpanded.push(index.treeId);
                    index.items.forEach(i => {
                        titles.push(i.text);
                        allExpanded.push(i.treeId);
                    })
                });
            });
        }

        setTreeListState2({
            ...treeListState2,
            data: newData,
            expanded: allExpanded
        })
    }
    function contains(text, term) {
        return text.toLowerCase().indexOf(term.toLowerCase()) >= 0;
    }

    function search(items, term) {
        return items.reduce((acc, item) => {
            if (contains(item.text, term)) {
                acc.push(item);
            } else if (item.items && item.items.length > 0) {
                let newItems = search(item.items, term);
                if (newItems && newItems.length > 0) {
                    acc.push({text: item.text, items: newItems, treeId: item.treeId /*, expanded: item.expanded*/});
                }
            }
            return acc;
        }, []);
    }



    function treeListItemClickUtil(tempSegList1, tempSegList2, dataItem, onClickList1) {
        // console.log(tempSegList1, tempSegList2, dataItem, onClickList1)
        let targetType = props.targetType;
        let keyFound;
        if (targetType === "CONSUMER") {
            keyFound = getValueFromKey(props.audienceState, dataItem.segment_group) ? getValueFromKey(props.audienceState, dataItem.segment_group) : getValueFromKey(props.audienceState, dataItem.groupName);
        } else {
            if (businessList.find(item => item.groupName === dataItem.groupName).groupType === 'travelAndEntertainment') {
                //not sure why naming pattern changed
                keyFound = 'travelSegments';
            } else if (dataItem.groupName === "Core Demographic" || dataItem.groupName === "Core Firmographic") {
                keyFound = getValueFromKey(props.audienceState, dataItem.segment_group)
            } else {
                keyFound = businessList.find(item => item.groupName === dataItem.groupName).groupType;
            }

        }

        //On TreeList1 Item Click
        if (onClickList1) {
            // console.log(props.audienceState)
            // console.log({label: dataItem.label, value: dataItem})
            // console.log(dataItem)
            tempSegList2.push({label: dataItem.label, value: dataItem});
            if (dataItem.groupName === "Core Demographic" || dataItem.groupName === "Core Firmographic") {
                tempSegList1 = tempSegList1.filter(item => item.value.segment_name !== dataItem.segment_name);
            } else {
                tempSegList1 = tempSegList1.filter(item => item.value.segment_id !== dataItem.segment_id);
            }
            // if (!props.audienceState[keyFound].find(item => item === dataItem.segment_id)) {
            //     props.audienceState[keyFound].push(dataItem.segment_id);
            // }
            console.log(keyFound, props.audienceState)
            if (!props.audienceState[keyFound]) {
                //some values are in arrays (digital activity index and inflation sensitivity index)
                if (Array.isArray(dataItem.segment_id)) {
                    // console.log("here1")
                    if (!props.audienceState[keyFound].find(item => dataItem.segment_id.includes(item))) {
                        // props.audienceState[keyFound].push(...dataItem.segment_id);
                        props.handleAudienceState(prevState => {
                            return {...prevState, [keyFound]: prevState[keyFound].push(dataItem.segment_id)};
                        });
                    }
                } else {
                    // console.log("here2")
                    if (!props.audienceState[keyFound].find(item => item === dataItem.segment_id)) {
                        // props.audienceState[keyFound].push(dataItem.segment_id);
                        props.handleAudienceState(prevState => {
                            return {...prevState, [keyFound]: prevState[keyFound].push(dataItem.segment_id)};
                        });
                    }
                }
            } else {
                // console.log(keyFound, props.audienceState)
                if (props.audienceState[keyFound] && !props.audienceState[keyFound].find(item => item === dataItem.segment_id)) {
                    props.audienceState[keyFound].push(dataItem.segment_id);
                    // props.handleAudienceState(prevState => {
                    //     return {...prevState, [keyFound]: prevState[keyFound].push(dataItem.segment_id)};
                    // });
                }
            }

            //On TreeList2 Item Click
        } else {
            tempSegList1.push({label: dataItem.label, value: dataItem});
            if (dataItem.groupName === "Core Demographic" || dataItem.groupName === "Core Firmographic") {
                // console.log(keyFound)
                tempSegList2 = tempSegList2.filter(item => item.value.segment_name !== dataItem.segment_name);
            } else {
                // console.log(keyFound)
                tempSegList2 = tempSegList2.filter(item => item.value.segment_id !== dataItem.segment_id);
            }

            if (props.audienceState[keyFound].find(item => item === dataItem.segment_id) || props.audienceState[keyFound].find(item => dataItem.segment_id.includes(item))) {
                // props.audienceState[keyFound].filter(item => item !== dataItem.segment_id);
                // console.log(keyFound)
                // if(dataItem.segment_id.length > 1){
                //     props.handleAudienceState(prevState => {
                //             return {...prevState, [keyFound]: prevState[keyFound].filter(item => !arraysEqual(item, dataItem.segment_id)) };
                //         }
                //     );
                // } else
                if(Array.isArray(dataItem.segment_id)){
                    props.handleAudienceState(prevState => {
                            return {...prevState, [keyFound]: prevState[keyFound].filter(item => JSON.stringify(item) !== JSON.stringify(dataItem.segment_id)) };
                        }
                    );
                }else{
                    props.handleAudienceState(prevState => {
                            return {...prevState, [keyFound]: prevState[keyFound].filter(item => !dataItem.segment_id.includes(item)) };
                        }
                    );
                }

                // Remove arrayC from arrayB if found
                // const updatedArrayB = arrayB.filter(item => !arraysEqual(item, arrayC));
            }
        }
        // segment_id contains duplicate values but unique items in Core Demographic//remove duplicate values
        // tempSegList2 = Array.from(
        //     new Map(tempSegList2.map(item => [item.value.segment_id, item])).values()
        // );
        // tempSegList1 = Array.from(
        //     new Map(tempSegList1.map(item => [item.value.segment_id, item])).values()
        // );

        return {list1: tempSegList1, list2: tempSegList2}
    }

    const onTreeListItemClick1 = (e) => {
        let tempSegList2 = preTransformTreeList2;
        let tempSegList1 = preTransformTreeList1;
        // if (props.targetType === "CONSUMER") {
        if (e.level.length === 4) {
            const values = treeListItemClickUtil(tempSegList1, tempSegList2, e.dataItem, true);
            tempSegList1 = values.list1;
            tempSegList2 = values.list2;
        }
        if (e.level.length === 3) {
            if(e.dataItem.items){
                e.dataItem.items.forEach(i => {
                    const values = treeListItemClickUtil(tempSegList1, tempSegList2, i, true);
                    tempSegList1 = values.list1;
                    tempSegList2 = values.list2;
                })
            } else {
                const values = treeListItemClickUtil(tempSegList1, tempSegList2, e.dataItem, true);
                tempSegList1 = values.list1;
                tempSegList2 = values.list2;
            }

        }
        if (e.level.length === 2) {
            e.dataItem.items.forEach(i => {
                if(i.items){
                    i.items.forEach(item => {
                        const values = treeListItemClickUtil(tempSegList1, tempSegList2, item, true);
                        tempSegList1 = values.list1;
                        tempSegList2 = values.list2;
                    })
                }else {
                    const values = treeListItemClickUtil(tempSegList1, tempSegList2, i, true);
                    tempSegList1 = values.list1;
                    tempSegList2 = values.list2;
                }
            })
        }
        if (e.level.length === 1) {
            e.dataItem.items.forEach(i => {
                i.items.forEach(item => {
                    if (item.items) {
                        item.items.forEach(element => {
                            const values = treeListItemClickUtil(tempSegList1, tempSegList2, element, true);
                            tempSegList1 = values.list1;
                            tempSegList2 = values.list2;
                        })
                    }else {
                        const values = treeListItemClickUtil(tempSegList1, tempSegList2, item, true);
                        tempSegList1 = values.list1;
                        tempSegList2 = values.list2;
                    }
                })
            })
        }
        console.log("tempSegList1", tempSegList1);
        console.log("tempSegList2", tempSegList2);
        setPreTransformTreeList1(tempSegList1);
        setPreTransformTreeList2(tempSegList2);


        let allExpanded = [];
        setExpand2(false)
        mergeArrays(mergedArray, splitAnalyticsIQ(transformObject(tempSegList2))).forEach((item) => {
            allExpanded.push(item.treeId);
            item.items.forEach(index => {
                allExpanded.push(index.treeId);
                index.items.forEach(i => {
                    allExpanded.push(i.treeId);
                })
            });
        });
        // console.log(mergeArrays(mergedArray, transformObject(tempSegList2)));
        const tree1Data = mergeArrays(props.targetType === 'CONSUMER' ? consumerList : businessList, splitAnalyticsIQ(transformObject(tempSegList1)));
        const tree2Data = mergeArrays(mergedArray, splitAnalyticsIQ(transformObject(tempSegList2)));
        // console.log(tree2Data)
        setTreeListState1(
            {
                ...treeListState1,
                data: tree1Data,
                // expanded: tree1Data.map((i)=>i.treeId)
            });
        setTreeListState2(
            {
                ...treeListState2,
                data: tree2Data,
                expanded: allExpanded
            });

        setTempSearchValues1(tree1Data)
        setTempSearchValues2(tree2Data);

    }


    const onTreeListItemClick2 = (e) => {
//Remove items onClick, from Tree List and formik
        let tempSegList2 = preTransformTreeList2;
        let tempSegList1 = preTransformTreeList1;

        // if (props.targetType === "CONSUMER") {
        if (e.level.length === 4) {
            const values = treeListItemClickUtil(tempSegList1, tempSegList2, e.dataItem, false);
            tempSegList1 = values.list1;
            tempSegList2 = values.list2;

        }
        if (e.level.length === 3) {
            if (e.dataItem.items) {
                e.dataItem.items.forEach(i => {
                    const values = treeListItemClickUtil(tempSegList1, tempSegList2, i, false);
                    tempSegList1 = values.list1;
                    tempSegList2 = values.list2;
                })
            }else{
                const values = treeListItemClickUtil(tempSegList1, tempSegList2, e.dataItem, false);
                tempSegList1 = values.list1;
                tempSegList2 = values.list2;
            }

        }
        if (e.level.length === 2) {
            e.dataItem.items.forEach(i => {
                if (i.items) {
                    i.items.forEach(item => {
                        const values = treeListItemClickUtil(tempSegList1, tempSegList2, item, false);
                        tempSegList1 = values.list1;
                        tempSegList2 = values.list2;
                    })
                }else {
                    const values = treeListItemClickUtil(tempSegList1, tempSegList2, i, false);
                    tempSegList1 = values.list1;
                    tempSegList2 = values.list2;
                }
            })
        }
        if (e.level.length === 1) {
            e.dataItem.items.forEach(i => {
                i.items.forEach(item => {
                    if (item.items) {
                        item.items.forEach(element => {
                            const values = treeListItemClickUtil(tempSegList1, tempSegList2, element, false);
                            tempSegList1 = values.list1;
                            tempSegList2 = values.list2;
                        })
                    }else {
                        const values = treeListItemClickUtil(tempSegList1, tempSegList2, item, false);
                        tempSegList1 = values.list1;
                        tempSegList2 = values.list2;
                    }

                })
            })
        }
        setPreTransformTreeList1(tempSegList1);
        setPreTransformTreeList2(tempSegList2);

        const tree1Data = mergeArrays(props.targetType === 'CONSUMER' ? consumerList : businessList, splitAnalyticsIQ(transformObject(tempSegList1)));
        const tree2Data = mergeArrays(mergedArray, splitAnalyticsIQ(transformObject(tempSegList2)));

        let allExpanded = [];
        setExpand2(false);

        tree2Data.forEach((item) => {
            allExpanded.push(item.treeId);
            item.items.forEach(index => {
                allExpanded.push(index.treeId);
                index.items.forEach(i => {
                    allExpanded.push(i.treeId);
                })
            });
        });
        setTreeListState1(
            {
                ...treeListState1,
                data: tree1Data,
                // expanded: tree1Data.map((i)=>i.treeId)
            });
        setTreeListState2(
            {
                ...treeListState2,
                data: tree2Data,
                expanded: allExpanded
            });

        setTempSearchValues1(tree1Data);
        setTempSearchValues2(tree2Data);

    }
    const addExpandField1 = (dataTree) => {
        const expanded = treeListState1.expanded;
        return mapTree(dataTree, subItemsField, (item) =>
            extendDataItem(item, subItemsField, {
                [expandField]: expanded.includes(item.treeId),
            })
        );
    };
    const addExpandField2 = (dataTree) => {
        const expanded = treeListState2.expanded;
        return mapTree(dataTree, subItemsField, (item) =>
            extendDataItem(item, subItemsField, {
                [expandField]: expanded.includes(item.treeId),
            })
        );
    };
    function sortTreeOrder (data){
        return data.sort((a, b) => {
            // Condition to place 'Core Demographic' at the first position
            if (a.text === "Core Demographic" || a.text === "Core Firmographic") return -1;
            if (b.text === "Core Demographic" || b.text === "Core Firmographic") return 1;
            const indexA = tabList.indexOf(a.text);
            const indexB = tabList.indexOf(b.text);

            // If text is not found in B, it will return -1. You can adjust sorting for such cases if needed.
            return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
        });
    }
    const processData1 = () => {
        let {data, dataState} = treeListState1;
        const sortedData = sortTreeOrder(data);
        //select tab and filter values
        // if (selectedTab !== "" && selectedTab !== "Demographic" && selectedTab !== "Firmographic") {
        //     data = sortedData.filter(item => item.text === selectedTab);
        // } else if (selectedTab === "Demographic") {
        //     data = [...data.filter(item => item.text === "Core Demographic"), ...data.filter(item => item.text === "Demographic")];
        // } else if (selectedTab === "Firmographic") {
        //     data = [...data.filter(item => item.text === "Core Firmographic"), ...data.filter(item => item.text === "Firmographic")];
        // }
        if (selectedTab !== "") {
            data = sortedData.filter(item => item.text === selectedTab);
        }
        return addExpandField1(data);
    };
    const processData2 = () => {
        let {data, dataState} = treeListState2;
        const sortedData = sortTreeOrder(data);
        return addExpandField2(sortedData);
    };
    const onTreeList2ExpandChange = (e) => {
        setTreeListState2({
            ...treeListState2,
            expanded: e.value
                ? treeListState2.expanded.filter((id) => id !== e.dataItem.treeId)
                : [...treeListState2.expanded, e.dataItem.treeId],
        });
    };
    const onTreeList1ExpandChange = (e) => {
        setTreeListState1({
            ...treeListState1,
            expanded: e.value
                ? treeListState1.expanded.filter((id) => id !== e.dataItem.treeId)
                : [...treeListState1.expanded, e.dataItem.treeId],
        });
    };
    const handleTreeList1StateChange = (event) => {
        setTreeListState1({
            ...treeListState1,
            dataState: event.dataState,
        });
    };
    const handleTreeList2StateChange = (event) => {
        setTreeListState2({
            ...treeListState2,
            dataState: event.dataState,
        });
    };


    const tabList = props.targetType === "CONSUMER" ? ["", "Core Demographic", "Demographic", "Retailers", "Healthcare", "B2B", "Home Services", "Travel/Entertainment", "Contextual", "Politics"] : ["", "Core Firmographic", "Firmographic", "Healthcare", "B2B", "Travel/Entertainment"]

    const handleTabOnClick = (e, val) => {
        e.preventDefault();
        setTreeListState1(
            {
                ...treeListState1,
                expanded: treeListState1.data.map((i)=>i.treeId)
            })
        if (selectedTab === val) {
            setSelectedTab("")
        } else {
            switch (val) {
                case selectedTab !== "":
                    setSelectedTab("");
                    break;
                case val:
                    setSelectedTab(val);
                    break;
                // case "Core Firmographic":
                //     setSelectedTab("Core Firmographic");
                //     break;
                // case "Firmographic":
                //     setSelectedTab("Firmographic");
                //     break;
                // case "Core Demographic":
                //     setSelectedTab("Core Demographic");
                //     break;
                // case "Demographic":
                //     setSelectedTab("Demographic");
                //     break;
                // case "Retailers":
                //     setSelectedTab("Retailers");
                //     break;
                // case "Healthcare":
                //     setSelectedTab("Healthcare");
                //     break;
                // case "B2B":
                //     setSelectedTab("B2B");
                //     break;
                // case "Home Services":
                //     setSelectedTab("Home Services");
                //     break;
                // case "Travel/Entertainment":
                //     setSelectedTab("Travel/Entertainment");
                //     break;
                // case "Contextual":
                //     setSelectedTab("Contextual");
                //     break;
                // case "Politics":
                //     setSelectedTab("Politics");
                //     break;
                default:
                // code block
            }
        }
    }

    const displayWarning = () => {
        if (props.originalTargetType === "BUSINESS" &&
            props.targetType === "CONSUMER" && props.audienceState["titleMatching"].length > 0) {
            if (props.audienceState.first_party_location_focus === "S") {
                setMsg("You will now be creating a new segment targeting Consumers where the industry and title match the Industry Naics Codes of these businesses.");
            } else if (props.audienceState.first_party_location_focus === "B") {
                setMsg("You will now be creating a new segment targeting Consumers where the title matches are from these specific businesses.");
            }
            return true;
        } else if (props.originalTargetType === "BUSINESS" &&
            props.targetType === "CONSUMER" && props.audienceState["titleMatching"].length === 0) {
            setMsg("You will now be targeting Consumers seen at these businesses.");
            return true;
        } else {
            return false;
        }
    }

    return (
        <>
            <Form render={formRenderProps => <FormElement style={{
                width: "100%"
            }}>
                {/*<legend className={'k-form-legend spacingLine'}></legend>*/}
                <div className="advanced-targeting-container">
                    <Form render={formRenderProps => <FormElement style={{
                        width: "100%"
                    }}>
                        <div className={`notification-div ${displayWarning() ? "" : "d-none"}`}
                            // style={{display: displayWarning() ? "" : "none"}}
                        >
                            {msg}
                        </div>
                        <LoadOverlay active={loadOverlay} width={'100%'} height={'100px'}>
                            <div className={"tree-table-wrapper"}>
                                <div className={"tab-button-group-wrapper"}
                                     style={{marginBottom: "20px", marginTop: "20px"}}>
                                    <div className="tab-button-group">
                                        <ButtonGroup>
                                            {tabList.map((item, index) => {

                                                return (
                                                    item !== "" ?
                                                        <Button
                                                            className={`${selectedTab === item && "btn-primary"}`}
                                                            togglable={true} selected={selectedTab === item}
                                                            onClick={(e) => handleTabOnClick(e, item)}>
                                                            {item}
                                                        </Button> :
                                                        ""
                                                )
                                            })}
                                        </ButtonGroup>
                                        <Tooltip
                                            position="right"
                                            anchorElement="target"
                                            tooltipStyle={{
                                                width: "15rem",
                                                borderRadius: "0.25rem",
                                            }}
                                        >
                                            <i className="fa fa-question-circle-o tooltip-question-icon"
                                               aria-hidden="true"
                                               title={"Select any tab to see the filtered list. Deselect the selected tab to see the full list."}></i>
                                        </Tooltip>
                                    </div>
                                    <div className={"cost-msg-div"} style={{marginTop: "0", alignSelf: "center"}}>
                                        <Label className={"cost-msg-label"}>
                                            <span>All selections under Core Demographic and Core Firmographic are computed as AND statement</span>
                                        </Label>
                                    </div>
                                </div>
                                <div className={"tree-outter-wrapper"}>
                                    <div className={"left-tree-wrapper"} style={{width: "-webkit-fill-available"}}>
                                        <div className={"tree-tool-bar"}>
                                            <Input className={"dragndrop-search-box1"} onChange={handleSearch1}
                                                   placeholder={'Search Segment...'}/>
                                            <Button
                                                onClick={e => expand1 ? expandAll(e, '1') : collapseAll(e, '1')}
                                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                                style={{marginLeft: '10px'}}
                                            >
                                                {expand1 ? "Expand all" : "Collapse all"}
                                            </Button>
                                        </div>
                                        <TreeList
                                            {...treeListState1.dataState}
                                            style={{
                                                height: "30vh",
                                                overflow: "auto",
                                                marginTop: "20px",
                                            }}
                                            expandField={expandField}
                                            subItemsField={subItemsField}
                                            data={processData1()}
                                            columns={columns1}
                                            onDataStateChange={handleTreeList1StateChange}
                                            onExpandChange={onTreeList1ExpandChange}
                                            onRowClick={onTreeListItemClick1}
                                        />
                                    </div>
                                    <img alt="" src={angleLeftRightThemeColorIcon}
                                         style={{
                                             width: "50px",
                                             alignSelf: "center",
                                             paddingLeft: '5px',
                                             paddingRight: '5px'
                                         }}
                                    />
                                    <div className={"right-tree-wrapper"} style={{width: "-webkit-fill-available"}}>

                                        <div className={"selected-count"}>Selected Segments
                                            ({preTransformTreeList2.length})
                                        </div>
                                        <div className={"tree-tool-bar"}>
                                            <Input className={"dragndrop-search-box2"} onChange={handleSearch2}
                                                   placeholder={'Search Segment...'}/>
                                            <Button
                                                onClick={e => expand2 ? expandAll(e, '2') : collapseAll(e, '2')}
                                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                                style={{marginLeft: '10px'}}
                                            >
                                                {expand2 ? "Expand all" : "Collapse all"}
                                            </Button>
                                        </div>
                                        <TreeList
                                            style={{
                                                height: "30vh",
                                                overflow: "auto",
                                                marginTop: "20px",
                                            }}
                                            // rowRender={rowRender}
                                            expandField={expandField}
                                            subItemsField={subItemsField}
                                            data={processData2()}
                                            columns={columns2}
                                            onDataStateChange={handleTreeList2StateChange}
                                            onExpandChange={onTreeList2ExpandChange}
                                            onRowClick={onTreeListItemClick2}
                                        />
                                    </div>
                                </div>
                            </div>
                        </LoadOverlay>
                        {/*<div className={"basic-segment-div"}>*/}
                        {/*    <Label className={"basic-segment-label"}>*/}
                        {/*        <span>Basic Segments are $1.00 per thousand</span>*/}
                        {/*    </Label>*/}
                        {/*</div>*/}
                        {/*FD:13383*/}
                        {/*<div className={"cost-msg-div"}>*/}
                        {/*    <Label className={"cost-msg-label"}>*/}
                        {/*        <span>Basic Segments are $1.00 per thousand. Cost is capped at the highest listed CPM value. When multiple segments are used the CPM value is proportionately applied.</span>*/}
                        {/*    </Label>*/}
                        {/*</div>*/}
                    </FormElement>}/>
                </div>
            </FormElement>}/>
        </>
    )
}

export default AdvancedTargetingTreeListEdit;