import {useHistory} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {UserContext} from "../../contexts/UserContext";
import {filterBy, orderBy} from "@progress/kendo-data-query";
import {semcastAxios, showErrorToastMessage, showSuccessToastMessage} from "../../Utils/Common";
import {GROUPS_ACCESSIBLE_PIXELS, IDX_PIXELS_API, IDX_PIXELS_DATA_API} from "../../api/constants";
import {Button, DropDownButton, DropDownButtonItem} from "@progress/kendo-react-buttons";
import LoadOverlay from "../Components/SharedComponents/LoadOverlay";
import {ExcelExport} from "@progress/kendo-react-excel-export";
import {Grid, GridColumn, GridToolbar} from "@progress/kendo-react-grid";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import gearIcon from "../images/gearIcon.PNG";
import NewIdxTagDialog from "./NewIdxTagDialog";
import IdxTagInstuctions from "./IdxTagInstructions";
import {getFTPSettings} from "../../actions/OnBoardingActions";
import EditIdxTagDialog from "./EditIdxTagDialog";
import {InputClearValue, InputSeparator, TextBox} from "@progress/kendo-react-inputs";
import {SvgIcon} from "@progress/kendo-react-common";
import {xIcon} from "@progress/kendo-svg-icons";
const initialFilter = {
    logic: "and",
    filters: [
        {
            field: "domain",
            operator: "contains",
            value: ""
        }
    ]
};

const initialSort = [
    {
        field: "domain",
        dir: "asc"
    }
];

function formatUnixTime(unixTime) {
    if(unixTime == null) return null;

    const date = new Date(unixTime);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear().toString().slice(-2);
    return `${month}/${day}/${year}`;
}

function getCurrentDateTime() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    const seconds = currentDate.getSeconds().toString().padStart(2, '0');

    const currentDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return currentDateTime;
}
const IdxTagManager = () => {
    const history = useHistory();
    const [overlayActive, setOverlayActive] = useState(false);
    const [deleteOverlay, setDeleteOverlay] = useState(false);
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState(initialFilter);
    const [sort, setSort] = useState(initialSort);
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [groupsArr, setGroupsArr] = useState([]);
    const [showTagCode, setShowTagCode] = useState(false);
    const [pixelDomain, setPixelDomain] = useState("");
    const [pixelTagId, setPixelTagId] = useState("");
    const [pixelTagObj, setPixelTagObj] = useState({});
    const [deletePixelObj, setDeletePixelObj] = useState([]);
    const [editPixelObj, setEditPixelObj] = useState({});
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [availableFTPConnections,setAvailableFTPConnections] = useState([]);
    const [deleteConfirm, setDeleteConfirm] = useState("");

    const {user, setUser} = React.useContext(UserContext);

    const exportExcell = React.useRef(null);
    const exportExport = () => {
        if (exportExcell.current !== null) {
            exportExcell.current.save(orderBy(filterBy(data, filter), sort));
        }
    };
    const getGroupNameById = (array, id) => {
        const matchedGroup = array.find(item => item.id === id);
        return matchedGroup ? matchedGroup.group_name : null;
    };
    const getFTPById = (array, id) => {
        const matchedFTP = array.find(item => item.id === id);
        return matchedFTP ? matchedFTP.connection_name : null;
    };
    // const getTrackingPixels = async () => {
    //     let response = await semcastAxios.get(TRACKING_PIXELS_LIST, {withCredentials: true})
    //         .then((res) => {
    //             console.log(res)
    //         }).then((data) => {
    //             console.log(data)
    //         });
    // }

    const getIDXPixelsData = async () => {
        try {
            let response = await semcastAxios.get(IDX_PIXELS_DATA_API, {withCredentials: true})
                .then((res) => {
                    let dataTemp = res.data.data;
                    dataTemp.forEach((adxTag) => {
                        adxTag.created_at = formatUnixTime(adxTag.created_at);
                        adxTag.updated_at = formatUnixTime(adxTag.updated_at);
                        adxTag.deleted_at = formatUnixTime(adxTag.deleted_at);
                    })
                    setData(dataTemp);
                    console.log("dataTemp ", dataTemp)
                }).catch((error) => {
                    if (error.response) {
                        showErrorToastMessage("Response Error: " + error.response.data.data, 10);
                        console.log(error.response);
                    }
                    if (error.request) {
                        showErrorToastMessage("Request Error: " + error.request.data.data, 10);
                        console.log(error.request);
                    }
                });
        } catch (error) {
            console.log(error)
            showErrorToastMessage("Error accessing Tracking Pixel information. " + error);
        }


    }

    // const getTransferLog = async () => {
    //     let response = await semcastAxios.get("/v2/secure/attributor/transfer-log/latest?pixel_deleted_at=null", {withCredentials: true})
    //         .then((res) => {
    //         });
    // }

    const getAvailableGroups = async () => {
        try {
            let response = await semcastAxios.get(GROUPS_ACCESSIBLE_PIXELS, {withCredentials: true})
                .then((res) => {
                    setGroupsArr(res.data.data);
                }).catch((error) => {
                    if (error.response) {
                        showErrorToastMessage("Response Error: " + error.response.data.data, 10);
                        console.log(error.response);
                    }
                    if (error.request) {
                        showErrorToastMessage("Request Error: " + error.request.data.data, 10);
                        console.log(error.request);
                    }
                });
        } catch (error) {
            console.log(error)
            showErrorToastMessage("Error accessing Group information. " + error);
        }

    }


    const getFTPDetails = async() => {
        try {
            const settings = await getFTPSettings();
            if (settings && settings.data.length) {
                setAvailableFTPConnections(settings.data);
            }
        } catch (error) {
            console.log(error)
            showErrorToastMessage("Error accessing Connection information. " + error);
        }
    }

    const refreshTable = async () => {
        // await Promise.allSettled([getAvailableGroups(), getTrackingPixels(), getIDXPixelsData(), getTransferLog()]);
        await Promise.allSettled([getAvailableGroups(), getIDXPixelsData(), getFTPDetails()]);
    }

    useEffect(async () => {

        setOverlayActive(true);
        // let groupNameFilterCell = document.querySelector('[title="domain-filter"]');
        // groupNameFilterCell.placeholder = "Search..."

        await Promise.allSettled([refreshTable()]).then(() => {
            setOverlayActive(false);
        })

    }, []);


    const handleDropdownSelect = (e, dataItem) => {
        switch (e.item.text) {
            case "Analytics Pixel Code":
                setPixelDomain(dataItem.domain);
                setPixelTagId(dataItem.id);
                setPixelTagObj(dataItem);
                setShowTagCode(true);
                break;
            case "Delete Tag":
                setDeletePixelObj([dataItem]);
                setOpenConfirmDelete(true);
                break;
            case "Edit Tag":
                setEditDialogOpen(true);
                setEditPixelObj(dataItem);
                break;
            // case "Edit Business Segment":
            //
            //     history.push(`${EDIT_SEGMENT_ROUTE}?id=${dataItem.business_segment_id}&name=${dataItem.business_segment_name}`);
            //
            //     break;

            default:
                break;
        }
    }
    const updatedAtCell = (props) => {
        // console.log("updatedCell ", props);
        const updated = props.dataItem.updated_at ? props.dataItem.updated_at : "-" ;
        return (
            <td key={props.dataItem.id} className={"k-text-center"}>
                {updated}
            </td>
        )
    };
    const createdAtCell = (props) => {
        // console.log("updatedCell ", props);
        const created = props.dataItem.created_at ? props.dataItem.created_at : "-" ;
        return (
            <td key={props.dataItem.id} className={"k-text-center"}>
                {created}
            </td>
        )
    };
    const labelCell = (props) => {
        // console.log("updatedCell ", props);
        const label = props.dataItem.label ? props.dataItem.label : "-" ;
        return (
            <td key={props.dataItem.id} className={"k-text-center"}>
                {label}
            </td>
        )
    };
    const typeCell = (props) => {
        let tagType = "";
        const segmentNamingType = props.dataItem.segment_naming_type ? props.dataItem.segment_naming_type : "-" ;
        switch (segmentNamingType) {
            case "CATEGORY":
                tagType = "Category";
                break;
            case "URL":
                tagType = "Website";
                break;
            case "NONE":
                tagType = "No Segments";
                break;
            default:
                tagType = "-";
        }

        return (
            <td key={props.dataItem.id} className={"k-text-center"}>
                {tagType}
            </td>
        )
    };

    const groupNameCell = (props) => {
        // console.log("groupNameCell ", props);
        // const groupName = getGroupNameById(groupsArr,props.dataItem.group_id);
        const groupName = props.dataItem.group_name
        return (
            <td key={props.dataItem.id} className={"k-text-center"}>
                {groupName}
            </td>
        )
    };
    const ftpCell = (props) => {
        // console.log("ftpCell ", props);
        // const ftpName = getFTPById(availableFTPConnections,props.dataItem.ftp_settings_id);
        const ftpName = props.dataItem.ftp_settings_name
        return (
            <td key={props.dataItem.id} className={"k-text-center"}>
                {ftpName}
            </td>
        )
    };
    const userIdCell = (props) => {
        return (
            <td key={props.dataItem.id} className={"k-text-center"}>
                {props.dataItem.user_id}
            </td>
        )
    };
    const headerCell = props => {
        return <div style={{
            textAlign: '-webkit-center'
        }}>
            <span style={{
                textAlign: '-webkit-center'
            }}>{props.title}</span>
        </div>;
    };
    const MyEditCommandCell = (props) => {
        return (
            <td className={"idx-tag-gear-dropdown-div"} key={props.dataItem.id}>
                <DropDownButton
                    className={"btn btn-primary shadow btn-xs sharp idx-gear-button"}
                    id={"idxDropdownMenuButton" + props.dataItem.id}
                    textField={"text"}
                    icon="cog"
                    title={"IDX Tag Options"}
                    onItemClick={(e) => handleDropdownSelect(e, props.dataItem)}
                >
                    <DropDownButtonItem text="Analytics Pixel Code"/>
                    <DropDownButtonItem text="Edit Tag" disabled={props.dataItem.user_id !== user.id}/> {/*disable option when the current user is not the creator*/}
                    <DropDownButtonItem text="Delete Tag" disabled={props.dataItem.user_id !== user.id}/> {/*disable option when the current user is not the creator*/}
                </DropDownButton>

            </td>
        )
    };

    const closePixelDialog = () => {
        setShowTagCode(false);
    }

    const deletePixel = async (pixelID, pixelLabel) => {
        setDeleteOverlay(true);
        try {
            let response = await semcastAxios.delete(IDX_PIXELS_API + "/" + pixelID,
                {withCredentials: true})
                .then(async (res) => {

                    if(res.data.code === 200) {
                        showSuccessToastMessage(`IDX Tag for ${pixelLabel} deleted`, 5);
                    }
                    setDeleteOverlay(false);
                    await refreshTable().then(() => {
                        setOpenConfirmDelete(false);
                    })

                }).catch((error) => {
                    if (error.response) {
                        showErrorToastMessage("Response Error: " + error.response.data.data, 10);
                        console.log(error.response);
                    }
                    if (error.request) {
                        showErrorToastMessage("Request Error: " + error.request.data.data, 10);
                        console.log(error.request);
                    }
                });
        } catch (error) {
            showErrorToastMessage(error.response.data.data, 10);
            console.log(error.response.data.data);

        }


    }
    const handleDeleteConfirmClear = React.useCallback(() => {
        setDeleteConfirm("");
    }, []);

    const handleDeleteConfirmChange = React.useCallback(event => {
        setDeleteConfirm(event.target.value);
    }, []);

    return (
        <div>
            <LoadOverlay active={overlayActive} height={50} width={50}>

                <div className={"idx-tag-manager-outer-container"}>
                    <div className={"idx-grid"}>
                        <ExcelExport ref={exportExcell} fileName={`IDX Tags - ${user.first_name} ${user.last_name} ${getCurrentDateTime()}`}>
                            <Grid
                                data={orderBy(filterBy(data, filter), sort)}
                                filterable={true}
                                filter={filter}
                                onFilterChange={(e) => setFilter(e.filter)}
                                sortable={true}
                                sort={sort}
                                onSortChange={(e) => {
                                    setSort(e.sort);
                                }}
                                resizable={true}
                            >
                                <GridToolbar>
                                    <div className={"idx-grid-toolbar-container"}>
                                        <div className={"idx-grid-toolbar-left"}>
                                            <Button
                                                className={"button-standard button-submit button-no-left-margin"}
                                                iconClass={"icon-plus"}
                                                onClick={() => {
                                                    setCreateDialogOpen(true);
                                                }}
                                            >
                                                New
                                            </Button>
                                        </div>
                                        <div className={"idx-grid-toolbar-right"}>
                                            <Button
                                                className={"button-standard button-white"}
                                                iconClass={"k-icon k-i-download"}
                                                onClick={exportExport}
                                            >
                                                as CSV
                                            </Button>
                                        </div>
                                    </div>
                                </GridToolbar>
                                <Column field={"id"} title={"Tag ID"}/>
                                <Column field={"segment_naming_type"} title={"Tag Type"} headerCell={headerCell} cell={typeCell}/>
                                <Column field={"label"} title={"Description"} headerCell={headerCell} cell={labelCell}/>
                                <Column field={"created_at"} title={"Created"} headerCell={headerCell} cell={createdAtCell} filterable={false}/>
                                <Column field={"updated_at"} title={"Updated"} headerCell={headerCell} cell={updatedAtCell} filterable={false}/>
                                <Column field={"group_name"} title={"Group"} headerCell={headerCell} cell={groupNameCell}/>
                                <Column field={"ftp_settings_name"} title={"FTP"} headerCell={headerCell}  cell={ftpCell}/>
                                {/*<Column field={"user_id"} title={"User"}  headerCell={headerCell} cell={userIdCell} filterable={true}/>*/}
                                <Column cell={MyEditCommandCell} filterable={false} width={"50px"}/>
                            </Grid>
                        </ExcelExport>
                    </div>
                </div>

                {createDialogOpen && (
                    <NewIdxTagDialog dialogOpen={createDialogOpen} setDialogOpen={setCreateDialogOpen}
                                     groupsArr={groupsArr} refreshTable={refreshTable} setShowTagCode={setShowTagCode}
                                     setPixelTagId={setPixelTagId} user={user} availableFTPConnections={availableFTPConnections} setPixelDomain={setPixelDomain}/>
                )}
                {editDialogOpen && (
                    <EditIdxTagDialog dialogOpen={editDialogOpen} setDialogOpen={setEditDialogOpen}
                                      groupsArr={groupsArr} editPixelObj={editPixelObj} availableFTPConnections={availableFTPConnections} refreshTable={refreshTable}
                                      setShowTagCode={setShowTagCode} user={user} setPixelTagId={setPixelTagId} setPixelDomain={setPixelDomain}/>
                )}
                {showTagCode && <IdxTagInstuctions pixelTagId={pixelTagId} setShowTagCode={setShowTagCode} pixelTagObj={pixelTagObj}/>}

                {openConfirmDelete && (
                    <div>
                        <Dialog
                            className={"idx-pixel-confirm-delete dialog-standard"}
                            title={"Confirm Delete"}
                            closeIcon={false}
                            width={550}
                        >
                            <LoadOverlay active={deleteOverlay}>
                                <div className={"pixel-dialog-text-container"}>
                                    <div className={"pixel-dialog-text pixel-dialog-text-2 mb-3"}>
                                        {`Deleting Tag `}<strong>{`${deletePixelObj[0].id}`}</strong>{` will `}<strong>{`permanently remove `}</strong>{`it from your account and it will no longer be available.`}
                                    </div>
                                    <div className={"mb-4"}>
                                        <Grid data={deletePixelObj}>
                                            <Column field="id" title="ID" />
                                            <Column field="label" title="Description" />
                                        </Grid>
                                    </div>
                                    <strong>{`Type "delete" below to verify that you want to delete this Tag.`}</strong>
                                    <TextBox style={{height:"50px", fontSize:"40px", marginTop:"20px", width:"170px"}} value={deleteConfirm} onChange={handleDeleteConfirmChange} placeholder="delete" suffix={() => <React.Fragment>
                                        {deleteConfirm !== "" && <InputClearValue onClick={handleDeleteConfirmClear}>
                                            <SvgIcon icon={xIcon} />
                                        </InputClearValue>}
                                        <InputSeparator />
                                    </React.Fragment>} />
                                </div>
                                <DialogActionsBar>
                                    <div className={"idx-dialog-submit-button-div"}>
                                        <Button
                                            className={"button-standard button-white"}
                                            type={'button'}
                                            disabled={false}
                                            onClick={() => {
                                                setOpenConfirmDelete(false);
                                                setDeleteConfirm("");
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            className={"button-standard button-red"}
                                            type={'button'}
                                            onClick={async () => {
                                                await deletePixel(deletePixelObj[0].id, deletePixelObj[0].label)}}
                                            disabled={deleteConfirm !== "delete"}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                </DialogActionsBar>
                            </LoadOverlay>
                        </Dialog>

                    </div>
                )}
            </LoadOverlay>
        </div>
    )
}
export default IdxTagManager;