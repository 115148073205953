import * as React from "react";
import {useState} from "react";
import {NavLink, useHistory} from "react-router-dom";
import scheduledIcon from "./temporaryresources/images/clock-icon.png"
import threeIcon from "./temporaryresources/images/blackOutline3.png";
import oneIcon from "./temporaryresources/images/blackOutline1.png";
import businessIcon from "./temporaryresources/images/business.png";
import consumerIcon from "./temporaryresources/images/consumer.png";
import adformIcon from "./temporaryresources/images/adform-icon.png";
import amazonIcon from "./temporaryresources/images/amazon-icon.png";
import adobeIcon from "./temporaryresources/images/adobe-icon.png";
import anzuIcon from "./temporaryresources/images/anzu-logo.png";
import amobeeIcon from "./temporaryresources/images/amobee-icon.png";
import aolIcon from "./temporaryresources/images/aol-icon.png";
import beeswaxIcon from "./temporaryresources/images/beeswax-icon.png";
import basisIcon from "./temporaryresources/images/basis-icon.png";
import digitalRemedyIcon from "./temporaryresources/images/digital-remedy-icon.png";
import dv360Icon from "./temporaryresources/images/dv360-icon.png";
import facebookIcon from "./temporaryresources/images/facebook-icon.png";
import fundProgressIcon from "./temporaryresources/images/fund-progress-icon.png";
import googleIcon from "./temporaryresources/images/google-icon.png";
import googleadsIcon from "./temporaryresources/images/googleads-icon.png";
import iqmIcon from "./temporaryresources/images/iqm-logo.png";
import liverampIcon from "./temporaryresources/images/liveramp-icon.png";
import rokuIcon from "./temporaryresources/images/roku-icon.png";
import springServeIcon from "./temporaryresources/images/springserve-icon.png";
import stackAdaptIcon from "./temporaryresources/images/stackadapt-icon.png";
import tiktokIcon from "./temporaryresources/images/tiktok-icon.png";
import tradedeskIcon from "./temporaryresources/images/tradedesk-icon.png";
import tvscientificIcon from "./temporaryresources/images/tvscientific-icon.png";
import xandrIcon from "./temporaryresources/images/xandr-icon.png";
import yahooIcon from "./temporaryresources/images/yahoo-icon.jpg";
import pubmaticIcon from "./temporaryresources/images/pubmatic-icon.png";
import semcastingAdobeIcon from "./temporaryresources/images/semcasting-adobe-icon.png";
import semcastingAmobeeIcon from "./temporaryresources/images/semcasting-amobee-icon.png";
import semcastingFacebookIcon from "./temporaryresources/images/semcasting-facebook-icon.png";
import semcastingTradedeskIcon from "./temporaryresources/images/semcasting-tradedesk-icon.png";
import semcastingTradedesk3rdPartyIcon from "./temporaryresources/images/semcasting-tradedesk-icon.png";
import semcastingXandrIcon from "./temporaryresources/images/semcasting-xandr-icon.png";
import semcastingDirectMailIcon from "./temporaryresources/images/postal-icon.png";
import trendingIcon from "./temporaryresources/images/trending-icon.png";
import xandrttdIcon from "./temporaryresources/images/xandrttd-icon.png";
import nexxenIcon from "./temporaryresources/images/nexxen-icon.png";
import greenlineIcon from "./temporaryresources/images/greenline_icon.svg";
import oneviewsnowflakeIcon from "./temporaryresources/images/snowflake.png";
import jamloopIcon from "./temporaryresources/images/logo-jamloop-dashboard.png";
import freewheelIcon from "./temporaryresources/images/freewheel-icon.png";
import openxIcon from "./temporaryresources/images/openx-icon.svg";
import grabitmediaIcon from "./temporaryresources/images/grabit-media-icon.png";
import adelphicIcon from "./temporaryresources/images/adelphic_icon.jpeg";
import ambioIcon from "./temporaryresources/images/ambio-icon.svg";
import {semcastAxios, showErrorToastMessage, showSuccessToastMessage} from "../../../Utils/Common";
import {SegmentTopRightBlockActions} from "../../../helpers/SegmentationHelpers";
import {deactivateSegment, reactivateSegment} from "../../../actions/TreeDashboardActions";
import {
    ACTIVATE_FORM_ROUTE, ACTIVATE_SEGMENT_ROUTE,
    ACTIVATE_SEGMENT_ROUTE_PREFIX,
    EDIT_SEGMENT_ROUTE, SEGMENT_DEACTIVATE_API,
    SEGMENT_DETAILS_API,
    SEGMENT_ID_PLACE_HOLDER,
    SEGMENT_REPORT_REGENERATE_API,
    SEGMENTS_REPORT_DETAIL,
    SUCCESS_STATUS
} from "../../../api/constants";
import {Button, DropDownButton, DropDownButtonItem} from "@progress/kendo-react-buttons";
import {formatNumber} from '@progress/kendo-intl';
import CopySegment from "./CopySegment";
import SplitSegment from "./SplitSegment";
import LookalikeSegment from "./LookalikeSegment";
import {useFormik} from "formik";
import * as Yup from "yup";
import {UserContext} from "../../../contexts/UserContext";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import LoadOverlay from "../SharedComponents/LoadOverlay";
import './TreeSegmentsTable.css'
import {Grid, GridColumn} from '@progress/kendo-react-grid';
import {ExcelExport, ExcelExportColumn,} from "@progress/kendo-react-excel-export";
import TreeDeleteSegments from "./TreeDeleteSegments";
import TreeMergeSegments from "./TreeMergeSegments";
import {twitterIcon} from "@progress/kendo-svg-icons";
import spotlightBlk from "../Segmentation/temporaryresources/images/spotlight-blk.svg";

function segmentationUploadedRecords(segment) {
    return [
        {
            text: "Count of File Records",
            size: segment["total_records_uploaded"] ?  segment["total_records_uploaded"].toLocaleString("en-US") : 0,
        },
        {
            text: "Duplicate Records",
            size: segment["duplicates_ct"] ? segment["duplicates_ct"].toLocaleString("en-US") : 0,
        },
        {
            text: "Unmatched Records",
            size: segment["unmatched_ct"] ? segment["unmatched_ct"].toLocaleString("en-US") : 0,
        },
        {
            text: "File Matched",
            size: segment["matched_ct"] ? segment["matched_ct"].toLocaleString("en-US") : 0,
        },
        {
            text: "Matched",
            size: segment["total_households"] ? segment["total_households"].toLocaleString("en-US") : 0,
        },
        {
            text: "People",
            size: segment["total_people"] ? segment["total_people"].toLocaleString("en-US") : 0,
        },
        {
            text: "Matched",
            size: segment["total_businesses"]? segment["total_businesses"].toLocaleString("en-US") : 0,
        },
        {
            text: "Unique Emails",
            size: segment["total_unique_emails"]? segment["total_unique_emails"].toLocaleString("en-US") : 0,
        },
        // {
        //     text: "Unique Phones",
        //     size: segment["total_phones"] ? segment["total_phones"].toLocaleString("en-US") : 0,
        // },
        {
            text: "Network IP Ranges ",
            size: segment["total_household_network_ip_addresses"] ? segment["total_household_network_ip_addresses"].toLocaleString("en-US") : 0,
        },
        {
            text: "Wifi IP Ranges",
            size: segment["total_household_wifi_ip_addresses"] ? segment["total_household_wifi_ip_addresses"].toLocaleString("en-US") : 0,
        },
        {
            text: "ATVs",
            size: segment["total_household_atv"] ? segment["total_household_atv"].toLocaleString("en-US") : 0,
        },
        // {
        //     text: "Top Device Count",
        //     size: segment[""] || 0,
        // },
        // {
        //     text: "Total Device Count",
        //     size: segment["total_device"] || 0,
        // },
        {
            text: "Device Count",
            size: segment["total_household_devices"] ? segment["total_household_devices"].toLocaleString("en-US") : 0,
        },
        {
            text: "Top Devices",
            size: segment["total_top_household_devices"] ? segment["total_top_household_devices"].toLocaleString("en-US") : 0,
        },
        {
            text: "IP Ranges",
            size: segment["total_business_network_ip_addresses"] ? segment["total_business_network_ip_addresses"].toLocaleString("en-US") : 0,
        },
        {
            text: "Wifi IP Ranges",
            size: segment["total_business_wifi_ip_addresses"] ? segment["total_business_wifi_ip_addresses"].toLocaleString("en-US") : 0,
        },
        {
            text: "Devices",
            size: segment["total_business_devices"] ? segment["total_business_devices"].toLocaleString("en-US") : 0,
        },
        {
            text: "Top Devices",
            size: segment["total_top_business_devices"] ? segment["total_top_business_devices"].toLocaleString("en-US") : 0,
        },
        // {
        //     text: "Segment Price (CPM)",
        //     size: segment["segment_price"] || 0,
        // },
        {
            text: "Billing Rate",
            size: segment["billing_rate"] ? segment["billing_rate"].toLocaleString("en-US") : 0,
        },
    ];
}
export const SegmentTableNameCell = (props) => {
    const SUB_ITEMS_FIELD = "children";
    const _exporter = React.createRef();
    const [visible, setVisible] = React.useState(false);
    const [records, setRecords] = React.useState([]);
    const [pageLoader, setPageLoader] = useState(false)
    let history = useHistory();
    const [disabled, setDisabled] = useState(false);
    const toggleDialog = () => {
        setVisible(!visible);
    };
    const excelExport = () => {
        if (_exporter.current) {
            _exporter.current.save();
        }
    };
    const getRecordsNumbers = async () => {
        setVisible(!visible);
        setPageLoader(true);
        try {
            let response = await semcastAxios.get(SEGMENT_DETAILS_API.replace(SEGMENT_ID_PLACE_HOLDER, props.dataItem.id), {withCredentials: true});
            if (response.data.status === SUCCESS_STATUS) {
                showSuccessToastMessage("Get Report Process Started", 10);
                setRecords(segmentationUploadedRecords(response.data.data))
            }
        } catch (error) {
            if(error.response.status === 401 || error.response.status === 403){
                showErrorToastMessage(error + " You did not have the permission to proceed, please contact support", 15);
            }else{
                showErrorToastMessage(error,15);
            }
        }finally {
            setPageLoader(false)
        }
    };



    let isEmpty = false;
    if (props.dataItem.children) {
        if (!props.dataItem.children[0].objectName) {
            isEmpty = true;
        }
    }
    let className =
        props.dataItem[SUB_ITEMS_FIELD] &&
        props.dataItem[SUB_ITEMS_FIELD].length > 0
            ? props.expanded
                ? "k-icon k-i-caret-alt-down k-zindex-auto"
                : "k-icon k-i-caret-alt-right k-zindex-auto"
            : "k-icon";


    const handleGenerateReportBtn = async (segmentId) => {
        try {
            setDisabled(true);
            let response = await semcastAxios.post(SEGMENT_REPORT_REGENERATE_API + segmentId + '/regenerate' , {
                withCredentials: true,
            });
            if (response.data.status === SUCCESS_STATUS) {
                showSuccessToastMessage("Regenerating Report", 30);
                setDisabled(false);
                return response.data;
            }
        } catch (error) {
            if(error.response.status === 401 || error.response.status === 403){
                showErrorToastMessage(error + " You did not have the permission to proceed, please contact support", 15);
            }else{
                showErrorToastMessage(error, 15);
            }
            setDisabled(false);
        }
    }
    const handleGetReportBtn = async (segmentId) => {
        setDisabled(true);
        let updatedAt = convertUTCDateToLocalDate(new Date(props.dataItem.updatedAt)).toLocaleDateString();
        let today = new Date().toLocaleDateString();

        if(today !== updatedAt){
            await handleGenerateReportBtn(segmentId);
        }else{
            try {
                let response = await semcastAxios.get(SEGMENTS_REPORT_DETAIL.replace(SEGMENT_ID_PLACE_HOLDER, segmentId), {withCredentials: true})
                if (response.data.status === SUCCESS_STATUS) {
                    // showSuccessToastMessage("Getting Report", 10);
                    setDisabled(false);
                    return (
                        history.push(response.data.data.segment_type === "CONSUMER" ? (
                            {
                                pathname: `/dashboard`,
                                state: {
                                    notes: response.data.data.notes, // BKR
                                    segment_name: response.data.data.segment_name,
                                    data: response.data.data.profile_report_details,
                                    segment_id: response.data.data.id,//MZHU
                                }
                            }
                        ) : (
                            {
                                pathname: `/business-dashboard`,
                                state: {
                                    notes: response.data.data.notes, // BKR
                                    segment_name: response.data.data.segment_name,
                                    data: response.data.data.profile_report_details,
                                    segment_id: response.data.data.id,//MZHU
                                },
                            }
                        ))
                    )
                }
            } catch (error) {
                if(error.response.status === 401 || error.response.status === 403){
                    showErrorToastMessage(error + " You did not have the permission to proceed, please contact support", 15);
                }else{
                    showErrorToastMessage(error,15);
                }
            }
        }
    }



    //Based on props.dataItem.objectType, assign links to different pages to the Names.
    const segmentNameLink = (type)=> {

        switch (type) {
            case 'Segment':
                return (
                    <NavLink
                        to={`${ACTIVATE_SEGMENT_ROUTE}?id=${props.dataItem.id}&size=${props.dataItem.objectSize}`}
                        title="Segment"
                    >
                        {props.dataItem['objectName']}
                    </NavLink>
                );
            case 'Matchback-Segment':
                return (
                    <NavLink
                        to={`${ACTIVATE_SEGMENT_ROUTE}?id=${props.dataItem.id}`}
                        title="Matchback-Segment"
                    >
                       {props.dataItem['objectName']}
                    </NavLink>
                );
            case 'Tag-Segment':
                return (
                    <NavLink
                        to={`${ACTIVATE_SEGMENT_ROUTE}?id=${props.dataItem.id}`}
                        title="Tag-Segment"
                    >
                        {props.dataItem['objectName']}
                    </NavLink>
                );
            case 'IDX-Segment':
                return (
                    <NavLink
                        to={`${ACTIVATE_SEGMENT_ROUTE}?id=${props.dataItem.id}&size=${props.dataItem.objectSize}`}
                        title="IDX-Segment"
                    >
                        {props.dataItem['objectName']}
                    </NavLink>
                );
            case 'Activation':
                return (
                    <NavLink
                        to={`${ACTIVATE_FORM_ROUTE}?id=${props.dataItem.parentId}&company=${props.dataItem['objectName']}`}
                        title="Activation"
                    >

                       {props.dataItem['objectName']}
                    </NavLink>
                );
            case 'Impression-Activation':
                return (
                    <NavLink
                        to={`${ACTIVATE_FORM_ROUTE}?id=${props.dataItem.parentId}&company=${props.dataItem['objectName']}`}
                        title="Impression-Activation"
                    >
                        {props.dataItem['objectName']}
                    </NavLink>
                );
            case 'Site-Visitor-Tag':
                return (
                    <NavLink
                        to={"/admin-settings"}
                        title="Site Visitor Tag"
                        onClick={() => sessionStorage.setItem('adminTabSelected', 3)}
                    >
                        {props.dataItem['objectName']}
                    </NavLink>
                );
            case 'IDX-Tag':
                return (
                    <NavLink
                        to={"/admin-settings"}
                        title="IDX Tag"
                        onClick={() => sessionStorage.setItem('adminTabSelected', 3)}
                    >
                        {props.dataItem['objectName']}
                    </NavLink>
                );
            default:
                return (
                    props.dataItem['objectName']
                );
        }
    }
    const onClick = (ev) => {
        props.onExpandChange(ev, props.dataItem, props.level);
    };

    //Condition to disable/enable segment/activation names
    let enableName = true;
    if((props.dataItem.objectType !== 'Activation' && props.dataItem.objectType !== 'Impression-Activation') && props.dataItem.status !== 'AVAILABLE' &&  props.dataItem.status !== 'COMPLETED' && props.dataItem.status !== 'ACTIVE'){
        enableName = false;
    }else if ((props.dataItem.objectType === 'Activation' || props.dataItem.objectType === 'Impression-Activation') &&  (props.dataItem.status !== 'COMPLETED' && props.dataItem.status !== 'ERROR' && props.dataItem.status !== 'PENDING' && props.dataItem.status !== 'DELAYED')){
        enableName = false;
    }

            return (
                props.dataItem.objectName && (
                    <td
                        className={"segment-name"}
                        style={enableName ? null : {pointerEvents: "none",color: "rgba(66, 66, 66, 0.5)",cursor:"not-allowed"}}
                        aria-colindex={props.ariaColumnIndex}
                        role={"gridcell"}
                    >
                        <div className={"k-zindex-0"}>
                        {[...Array(props.level.length - 1)].map((e, i) => (
                            <span className="k-icon k-i-none" role="presentation"></span>
                        ))}
                        <span
                            onClick={onClick}
                            data-prevent-selection="true"
                            className={!isEmpty ? className : {}}
                            role="presentation"
                            style={{cursor:"pointer"}}
                        ></span>
                        {segmentNameLink(props.dataItem.objectType)}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                    </td>
                )
            )
};

export const SegmentTableTypeCell = (props) => {
    if (props.rowType === "groupHeader") {
        return null;
    }

    switch (props.dataItem.subType) {
        case 'Activation':
        case 'Impression-Activation':
            switch (props.dataItem.objectName) {
                case 'adform':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={adformIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Adform"}/>
                        </td>
                    )
                case 'adobe':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={adobeIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Adobe"}/>
                        </td>
                    )
                case 'amazon':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={amazonIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Amazon"}/>
                        </td>
                    )
                case 'amobee':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            {/*Amobee changed name to Nexxen FD#9916 but Database still uses amobee*/}
                            <img alt="" src={nexxenIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Nexxen"}/>
                        </td>
                    )
                case 'anzu':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={anzuIcon} style={{maxWidth:"45px", maxHeight:"25px"}} title={"Anzu"}/>
                        </td>
                    )
                case 'aol':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={aolIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"AOL"}/>
                        </td>
                    )
                case 'basis':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={basisIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Basis"}/>
                        </td>
                    )
                case 'beeswax':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={beeswaxIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"BeesWax"}/>
                        </td>
                    )
                case 'digitalremedy':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={digitalRemedyIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Digital Remedy"}/>
                        </td>
                    )
                case 'xandrdv360':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={dv360Icon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"DV360"}/>
                        </td>
                    )
                case 'facebook':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={facebookIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Facebook"}/>
                        </td>
                    )
                case 'freewheel':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={freewheelIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"FreeWheel"}/>
                        </td>
                    )
                case 'fundprogress':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={fundProgressIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Fund Progress"}/>
                        </td>
                    )
                case 'google':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={googleIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Google"}/>
                        </td>
                    )
                case 'iqm':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={iqmIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"IQM"}/>
                        </td>
                    )
                case 'liveramp':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={liverampIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"LiveRamp"}/>
                        </td>
                    )
                case 'pubmatic':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={pubmaticIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Pubmatic"}/>
                        </td>
                    )
                case 'roku':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={rokuIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Roku"}/>
                        </td>
                    )
                case 'semcasting_adobe':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={semcastingAdobeIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Semcasting Adobe"}/>
                        </td>
                    )
                case 'semcasting_amobee':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={semcastingAmobeeIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Semcasting Amobee"}/>
                        </td>
                    )
                case 'semcasting_facebook':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={semcastingFacebookIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Semcasting Facebook"}/>
                        </td>
                    )
                case 'semcasting_tradedesk':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={semcastingTradedeskIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Semcasting Tradedesk"}/>
                        </td>
                    )
                case 'semcasting_ttd_third_party':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={semcastingTradedesk3rdPartyIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Semcasting Tradedesk 3rd Party"}/>
                        </td>
                    )
                case 'semcasting_xandr':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={semcastingXandrIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Semcasting Xandr"}/>
                        </td>
                    )
                case 'springserve':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={springServeIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"SpringServe"}/>
                        </td>
                    )
                case 'stackadapt':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={stackAdaptIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Stack Adapt"}/>
                        </td>
                    )
                case 'tiktok':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={tiktokIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Tiktok"}/>
                        </td>
                    )
                case 'tradedesk':
                case 'ttd':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={tradedeskIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Tradedesk"}/>
                        </td>
                    )
                case 'tvsci':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={tvscientificIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"TVScientific"}/>
                        </td>
                    )
                case 'twitter':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={twitterIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Twitter"}/>
                        </td>
                    )
                case 'xandr':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={xandrIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Xandr"}/>
                        </td>
                    )
                case 'yahoo':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={yahooIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Yahoo"}/>
                        </td>
                    )
                case 'trending':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={trendingIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Trending"}/>
                        </td>
                    )
                case 'xandrttd':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={xandrttdIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Trending"}/>
                        </td>
                    )
                case 'googleads':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={googleadsIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Trending"}/>
                        </td>
                    )
                case 'semcasting_directmail':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={semcastingDirectMailIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Semcasting DirectMail"}/>
                        </td>
                    )
                case 'nexxen':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={nexxenIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Nexxen"}/>
                        </td>
                    )
                case 'greenline':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={greenlineIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Greenline"}/>
                        </td>
                    )
                case 'oneviewsnowflake':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={oneviewsnowflakeIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"OneView-Snowflake"}/>
                        </td>
                    )
                case 'jamloop':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={jamloopIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"JamLoop"}/>
                        </td>
                    )
                case 'openx':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={openxIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"OpenX"}/>
                        </td>
                    )
                case 'grabitmedia':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={grabitmediaIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"GrabItMedia"}/>
                        </td>
                    )
                case 'adelphic':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={adelphicIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Adelphic"}/>
                        </td>
                    )
                case 'ambio':
                    return (
                        <td className={"text-center"}>
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <img alt="" src={ambioIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Ambio"}/>
                        </td>
                    )
                default:
                    return (
                        <td style={{textAlign: "center"}}>
                            {props.dataItem.subType}
                        </td>
                    )
            }
        case 'Consumer':
            return (
                <td className={"text-center"}>
                    <img alt="" src={consumerIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Consumer"}/>
                </td>
            )
        case 'IDX-Consumer':
            return (
                <td className={"text-center"}>
                    <i className="fa fa-bullseye" aria-hidden="true"></i>
                    <img alt="" src={consumerIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Consumer"}/>
                </td>
            )
        case 'IDX-Business':
            return (
                <td className={"text-center"}>
                    <i className="fa fa-bullseye" aria-hidden="true"></i>
                    <img alt="" src={businessIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Business"}/>
                </td>
            )
        case 'Consumer-Visitor':
            return (
                <td className={"text-center"}>
                    {/*<i className="fa fa-eye" aria-hidden="true"></i>*/}
                    <img src={spotlightBlk}
                         style={{width: "28px", height: "28px", position: "relative", left: "-3px"}}/>
                    <img alt="" src={consumerIcon}
                         style={{maxWidth: "25px", maxHeight: "25px", position: "relative", left: "-11px"}}
                         title={"Consumer"}/>
                </td>
            )
        case 'Consumer-Served':
            return (
                <td className={"text-center"}>
                    <i className="fa fa-bullseye" aria-hidden="true"></i>
                    <img alt="" src={consumerIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Consumer"}/>
                </td>
            )
        case 'Consumer-Remaining':
            return (
                <td className={"text-center"}>
                    <i className="fa fa-battery-quarter" aria-hidden="true"></i>
                    <img alt="" src={consumerIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Consumer"}/>
                </td>
            )
        case 'Business':
            return (
                <td className={"text-center"}>
                    <img alt="" src={businessIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Business"}/>
                </td>
            )
        case 'Business-Visitor':
            return (
                <td className={"text-center"}>
                    {/*<i className="fa fa-eye" aria-hidden="true"></i>*/}
                    <img src={spotlightBlk} style={{width: "28px", height: "28px", position: "relative", left: "-3px"}}/>
                    <img alt="" src={businessIcon}
                         style={{maxWidth: "25px", maxHeight: "25px", position: "relative", left: "-11px"}}
                         title={"Business"}/>
                </td>
            )
        case 'Business-Served':
            return (
                <td className={"text-center"}>
                <i className="fa fa-bullseye" aria-hidden="true"></i>
                    <img alt="" src={businessIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Business"}/>
                </td>
            )
        case 'Business-Remaining':
            return (
                <td className={"text-center"}>
                    <i className="fa fa-battery-quarter" aria-hidden="true"></i>
                    <img alt="" src={businessIcon} style={{maxWidth:"25px", maxHeight:"25px"}} title={"Business"}/>
                </td>
            )
        case 'Site-Visitor-Tag':
            return (
                <td className={"text-center"}>
                    <i className="fa fa-exchange" aria-hidden="true"></i>
                </td>
            )
        case 'IDX-Tag':
            return (
                <td className={"text-center"}>
                    <i className="fas fa-sync" aria-hidden="true"></i>
                </td>
            )
        default:
            return (
                <td style={{textAlign: "center"}}>
                    {props.dataItem.subType}
                </td>
            )
    }
};
export const SegmentTypeCell = (props) => {

    return (
        <td style={{ textAlign: "center"}}>
            {props.dataItem.objectType}
        </td>
    );
};

export const SegmentSizeCell = (props) => {
    // const sizeNum = parseInt(props.dataItem.objectSize);

    switch (props.dataItem.objectSize) {
        case undefined:
            return (
                <td className={"text-center segment-size"} title={"Segment-Size"} style={{minWidth:"max-content !important"}}>
                    --
                </td>
            )
        default:
            return (
                <td className={"text-center segment-size"}>
                    {props.dataItem.objectSize.toLocaleString()}
                </td>
            )
    }

};

export const GroupIdCell = (props) => {

    return (
        <td style={{ textAlign: "center" }}>
            {props.dataItem.groupId}
        </td>
    );
};

export const GroupNameCell = (props) => {

    return (
        <td style={{ textAlign: "center"}}>
            {props.dataItem.groupName}
        </td>
    );
};

export const UserNameCell = (props) => {

    return (
        <td style={{ textAlign: "center"}}>
            {props.dataItem.userName}
        </td>
    );
};
export const UserIdCell = (props) => {
    const [accessibleUsers, setAccessibleUsers] = useState([props.accessibleUsers]);
    return (
        <td style={{ textAlign: "center" }}>
            {props.dataItem.userId}
        </td>
    );
};

export const StatusMessageCell = (props) => {

    return (
        <td style={{ textAlign: "center" }}>
            {props.dataItem.statusMessage}
        </td>
    );
};

export const PctServedCell = (props) => {

    return (
        <td style={{ textAlign: "center" }}>
            {props.dataItem.pctServed > 0 ? props.dataItem.pctServed + "%" : "--"}
        </td>
    );
};

export const ProfileDataObsoleteCell = (props) => {

    return (
        <td style={{ textAlign: "center" }}>
            {props.dataItem.profileDataObsolete}
        </td>
    );
};
export const IdentityReportCell = (props) => {

    return (
        <td style={{ textAlign: "center" }}>
            {props.dataItem.identityReport}
        </td>
    );
};

export const ScheduledCell = (props) => {

    if (props.rowType === "groupHeader") {
        return null;
    }
    switch (props.dataItem.scheduled) {
        case undefined:
            return (
                <td className={"text-center segment-scheduled-type"}>
                 --
                </td>
            )
        default:
            return (
                <td className={"text-center segment-scheduled-type"}>
                    <img alt="" src={scheduledIcon} className={"segment-scheduled-icon"} title={props.dataItem.scheduled}/>
                </td>
            )
    }
};

export const SegmentPartyTypeCell = (props) => {

    if (props.rowType === "groupHeader") {
        return null;
    }

    switch (props.dataItem.party) {
        case 3:
            return (
                <td className={"text-center segment-party-type"}>
                    <img alt="" src={threeIcon} className={"segment-party-icon"} title={"3rd Party"}/>
                </td>
            )
        case 1:
            return (
                <td className={"text-center segment-party-type"}>
                    <img alt="" src={oneIcon} className={"segment-party-icon"} title={"1st Party"}/>
                </td>
            )
        default:
            return (
                <td className={"text-center segment-party-type"}>
                    -
                </td>
            )
    }
};

export const RecordsCell = (props) => {

    if (props.rowType === "groupHeader") {
        return null;
    }

    return (
        <td style={{ textAlign: "center" }}>
            {formatNumber(props.dataItem.total_unique_addresses === 0 ? props.dataItem.total_records_uploaded : props.dataItem.total_unique_addresses, "n")}
        </td>
    );
};
function convertUTCDateToLocalDate(date) {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
}
export const CreatedAtCell = (props) => {
    let date = convertUTCDateToLocalDate(new Date(props.dataItem.createdAt));
    let dateString = "";
    if (date != null && !isNaN(date)) {
        dateString = date.toLocaleString();
    }
    if (dateString === "Invalid Date") {
        console.log("Invalid Date=", date);
    }
    if (props.rowType === "groupHeader") {
        return null;
    }

    return (
        <td style={{ textAlign: "center" }}>
            {dateString}
        </td>
    );
};

export const LastUpdatedCell = (props) => {
    let date = convertUTCDateToLocalDate(new Date(props.dataItem.updatedAt));
    let dateString = "";
    if (date != null && !isNaN(date)) {
        dateString = date.toLocaleString();
    }
    if (dateString === "Invalid Date") {
        console.log("Invalid Date=", date);
    }
    if (props.rowType === "groupHeader") {
        return null;
    }

    return (
        <td style={{ textAlign: "center" }}>
            {dateString}
        </td>
    );
};

export const OnBoardFileDeletionCell = (props) => {
    let d = "";

    if (props.rowType === "groupHeader") {
        return null;
    }else if (props.dataItem.onboard_file_deleted_at !== null){
        d = new Date(props.dataItem.onboard_file_deleted_at).toLocaleDateString();
    }

    return (
        <td style={{ textAlign: "center" }}>
            {d}
        </td>
    );
};


export const StatusCell = (props) => {
    if (props.rowType === "groupHeader") {
        return null;
    }
    switch (props.dataItem.status) {
        case 'AVAILABLE':
            return (
                <td className={"segment-table-status text-success text-center"}>
                    <i className="fa fa-circle text-success mr-1" title={"Available"}></i>
                    &nbsp;Available
                </td>
            );
        case 'PENDING':
            return (
                <td className={"segment-table-status text-center"} style={{color:"#1976D2"}}>
                    <i className="fa fa-circle mr-1" title={"Pending"} style={{color:"#1976D2"}}></i>
                    &nbsp;Pending
                </td>
            );
        case 'DELAYED':
            return (
                <td className={"segment-table-status text-center"} style={{color:"#1976D2"}}>
                    <i className="fa fa-circle mr-1" title={"Delayed"} style={{color:"#1976D2"}}></i>
                    &nbsp;Delayed
                </td>
            );
        case 'COMPLETED':
            return (
                <td className={"segment-table-status text-success text-center"}>
                    <i className="fa fa-circle text-success mr-1" title={"Completed"}></i>
                    &nbsp;Completed
                </td>
            );
        case 'ACTIVE':
            return (
                <td className={"segment-table-status text-success text-center"}>
                    <i className="fa fa-circle text-success mr-1" title={"Active"}></i>
                    &nbsp;Active
                </td>
            );
        case 'BUSY':
            return (
                <td className={"segment-table-status text-warning text-center"}>
                    {/*<i className="fa fa-circle mr-1" title={"Loading"} />*/}
                    Busy
                    <div className={"dot-flashing"}></div>
                </td>
            );
        case 'LOADING':
            return (
                <td className={"segment-table-status text-warning text-center"}>

                    {/*<i className="fa fa-circle mr-1" title={"Loading"} />*/}
                    Loading
                    <div className={"dot-flashing"}></div>
                </td>
            );
        case 'MERGING':
            return (
                <td className={"segment-table-status text-warning text-center"}>
                    {/*<i className="fa fa-circle mr-1" title={"Merging"}></i>*/}
                    Merging
                    <div className={"dot-flashing"}></div>
                </td>
            );
        case 'COUNTING':
            return (
                <td className={"segment-table-status text-warning text-center"}>
                    {/*<i className="fa fa-circle mr-1" title={"Counting"}></i>*/}
                    Counting
                    <div className={"dot-flashing"}></div>
                </td>
            );
        case 'PROFILING':
            return (
                <td className={"segment-table-status text-warning text-center"} >
                    {/*<i className="fa fa-circle mr-1" title={"Profiling"}></i>*/}
                    Profiling
                    <div className={"dot-flashing"}></div>
                </td>
            );
        case 'RUNNING':
            return (
                <td className={"segment-table-status text-warning text-center"} >
                    {/*<i className="fa fa-circle mr-1" title={"Profiling"}></i>*/}
                    Running
                    <div className={"dot-flashing"}></div>
                </td>
            );
        case 'APPENDING':
            return (
                <td className={"segment-table-status text-warning text-center"} >
                    {/*<i className="fa fa-circle mr-1" title={"Profiling"}></i>*/}
                    Appending
                    <div className={"dot-flashing"}></div>
                </td>
            );
        case "QUEUED":
            return (
                <td className={"segment-table-status text-warning text-center"} >
                    {/*<i className="fa fa-circle mr-1" title={"Profiling"}></i>*/}
                    Queued
                    <div className={"dot-flashing"}></div>
                </td>
            );
        case "ERROR":
            return (
                <td className={"segment-table-status text-danger text-center error-status-icon"}>
                    <span title={props.dataItem.statusMessage}>
                    <i className="fa fa-circle mr-1"></i>
                        &nbsp;Error
                        </span>
                </td>
            );
        default:
            return (
                <td className={"segment-table-status text-center"}>
                    {props.dataItem.status}
                </td>
            );
    }

};


