import React, {useEffect, useState} from "react";
import audienceDesignConfig from "../../../config/audienceDesignConfig";
import {getPpkSegments} from "../../../actions/AudienceDesignActions";
import {Label} from "@progress/kendo-react-labels";
import {Button, ButtonGroup} from "@progress/kendo-react-buttons";
import {Card, CardBody, CardTitle, TabStrip, TabStripTab} from "@progress/kendo-react-layout";
import {FieldWrapper, Form, FormElement} from "@progress/kendo-react-form";
import {Input, RadioGroup} from "@progress/kendo-react-inputs";
import LoadOverlay from "../SharedComponents/LoadOverlay";
import {TreeList, TreeListTextFilter, orderBy, filterBy,} from "@progress/kendo-react-treelist";
import './AdvancedTargetingTreeList.css';
import {extendDataItem, mapTree} from "@progress/kendo-react-common";
import angleLeftRightIcon from "../Segmentation/temporaryresources/images/arrow-left-right.svg";
import angleLeftRightThemeColorIcon from "../Segmentation/temporaryresources/images/arrow-left-right-theme-color.svg";
import coreDemographicData from "../../../config/coreDemographic.json";
import {hasAtLeastOneInAnyGroup} from "../../../helpers/UsersHelpers";
import Locations from "./Locations";
import {Tooltip} from "@progress/kendo-react-tooltip";
import Naics from "../Onboarding/Naics";
import LegislativeDistricts from "../Onboarding/LegislativeDistricts";

const AdvancedTargetingTreeList = (props) => {
    const [options, setOptions] = useState([]);
    // const [filteredOptions, setFilteredOptions] = useState([]);
    const [targetingData, setTargetingData] = useState({});
    // const [activeTiles, setActiveTiles] = useState([]);
    // const [value, setValue] = useState([]);
    // const [segmentsState, setSegmentsState] = useState({});
    // const [selectedTileConfig, setSelectedTileConfig] = useState({});
    const [targetType, setTargetType] = useState("CONSUMER");
    // const [selectedTargetType, setSelectedTargetType] = useState("CONSUMER");
    const targetTypes = [
        {label: "Consumer", value: "CONSUMER"},
        {label: "Business", value: "BUSINESS"}
    ]
    // const [selectionType, setSelectionType] = useState(props.formik.values.segment_design.merge_type || "DISTINCT"
    // );
    const [selectedTab, setSelectedTab] = React.useState("");
    // const selectionTypes = [
    //     {label: "Chosen value can be in ANY selection (OR statement)", value: "DISTINCT"},
    //     {label: "Chosen value must be in ALL selections (AND statement)", value: "COMMON"}
    // ]
    const [consumerList, setConsumerList] = useState([]);
    const [businessList, setBusinessList] = useState([]);
    const [loadOverlay, setLoadOverlay] = useState(true);
    const [preTransformTreeList1, setPreTransformTreeList1] = React.useState([]);
    const [preTransformTreeList2, setPreTransformTreeList2] = React.useState([]);
    const [tempSearchValues1, setTempSearchValues1] = useState([]);
    const [tempSearchValues2, setTempSearchValues2] = useState([]);
    const [expand1, setExpand1] = useState(true);
    const [expand2, setExpand2] = useState(true);
    const [expandedState1, setExpandedState1] = useState([]);
    const subItemsField = 'items';
    const expandField = 'expanded';
    const dataItemKey = 'treeId';
    // const TreeNameCell1 = () => {
    //     return (
    //         <Tooltip
    //              position="right"
    //              anchorElement="target"
    //              tooltipStyle={{
    //                  width: "15rem",
    //                  borderRadius: "0.25rem",
    //              }}
    //          >
    //              <i className="fa fa-question-circle-o tooltip-question-icon" aria-hidden="true" title={"Select any tab to see the filtered list. Deselect the selected tab to see the full list."}></i>
    //          </Tooltip>
    //     )
    // }
    const columns2 = [
        {
            field: "text",
            title: "Segment Name",
            width: "250px",
            expandable: true,
            // filter: TreeListTextFilter,
        },
    ];
    const columns1 = [
        {
            field: "text",
            title: "Segment Name",
            width: "250px",
            expandable: true,
            // cell: TreeNameCell1
            // filter: TreeListTextFilter,
        },
    ];
    const [treeListState1, setTreeListState1] = useState({
        data: [],
        expanded: [],
        dataState: {
            sort: [],
            filter: [],
        },
    });
    const [treeListState2, setTreeListState2] = useState({
        data: [],
        expanded: [],
        dataState: {
            sort: [],
            filter: [],
        }
    });

    // const handleSelectionType = (e) => {
    //     setSelectionType(e.value);
    //     props.formik.values.segment_design.merge_type = e.value;
    // };
    // function removeDuplicates(array) {
    //     const seen = new Set();
    //
    //     return array.filter(obj => {
    //         const id = obj.value.segment_id;
    //         if (!seen.has(id)) {
    //             seen.add(id);
    //             return true;
    //         }
    //         return false;
    //     });
    // }

    const handleTargetType = (e) => {
        // setSelectedTargetType(e.value);
        props.formik.values.target_type = e.value;
        props.formik.values.segment_design.target_type = e.value;
        props.formik.values.segment_design.consumer = [];
        props.formik.values.segment_design.sportsAttendees = [];
        props.formik.values.segment_design.politicalAffiliations = [];
        props.formik.values.segment_design.homeOwner = [];
        props.formik.values.segment_design.personalFinance = [];
        props.formik.values.segment_design.specialtySegments = [];
        props.formik.values.segment_design.specialtySegmentsBusiness = [];
        props.formik.values.segment_design.titleMatching = [];
        props.formik.values.segment_design.acxiomLifestyle = [];
        props.formik.values.segment_design.hcpspecialtySegmentsBusiness = [];
        // props.formik.values.segment_design.healthWise = [];
        // props.formik.values.segment_design.i360 = [];
        // props.formik.values.segment_design.iqvia = [];
        props.formik.values.segment_design.knowWhoConsumer = [];
        props.formik.values.segment_design.l2Data = [];
        props.formik.values.segment_design.purpleLab = [];
        props.formik.values.segment_design.purpleLabHCP = [];
        props.formik.values.segment_design.purpleLabHPD = [];
        props.formik.values.segment_design.salesintel = [];
        props.formik.values.segment_design.salesinteltechno = [];
        props.formik.values.segment_design.spectrumMedia = [];
        props.formik.values.segment_design.travelSegments = [];
        setTargetType(e.value);
        // setSelectedTab("");
    };

    // useEffect(() => {
    //     if (props.formik.values.target_type === "BUSINESS" &&
    //         props.formik.values.segment_design.titleMatching.length > 0) {
    //         props.setMsg("You will now be creating a new segment targeting Consumers where the industry and title match the Industry Naics Codes of these businesses.");
    //     } else {
    //         props.setMsg("");
    //     }
    // }, [props.formik.values.segment_design.titleMatching.length])
    //
    // useEffect(() => {
    //     if (JSON.stringify(segmentsState) === '{}' && selectedTileConfig.groupType === "titleMatching") {
    //         props.setMsg("");
    //     }
    // }, [JSON.stringify(segmentsState) === '{}' && selectedTileConfig.groupType === "titleMatching"])

    useEffect(() => {
        options.length = 0;
        // filteredOptions.length = 0;
        // value.length = 0;
        // setActiveTiles([]);
        fetchData();
        setTreeListState1({
            ...treeListState1,
            expanded: treeListState1.data.map((i) => i.treeId)
        })
        // setTreeListState2({
        //     ...treeListState2,
        //     expanded: treeListState2.data.map((i) => i.treeId)
        // })
        setSelectedTab("");
    }, [targetType]);
    function splitAnalyticsIQ(array) {
        const targetIndex = array.findIndex(obj => obj.text === "Analytics IQ");
        if (targetIndex === -1) return array;

        const targetObject = array[targetIndex];
        const groupedItems = {};

        targetObject.items.forEach(item => {
            const groupKey = item.text.split('>')[0].trim(); // Extract the first part before '>'
            const newText = `Analytics IQ - ${groupKey}`;
            if (!groupedItems[groupKey]) {
                groupedItems[groupKey] = { text: newText, items: [] };
            }
            groupedItems[groupKey].items.push({ text: item.text, items: item.items });
        });

        const newObjects = Object.values(groupedItems);

        return [...array.slice(0, targetIndex), ...newObjects, ...array.slice(targetIndex + 1)];
    }
    useEffect(() => {
        //To load segment values when switching target type/target data
        let tempSegId = [];
        let tempSegObj1 = [];
        let tempSegObj2 = [];
        let dataObjs = []
        Object.keys(props.formik.values.segment_design).forEach(key => {
            if (Array.isArray(props.formik.values.segment_design[key]) && props.formik.values.segment_design[key].length > 0) {
                tempSegId.push(...props.formik.values.segment_design[key])
            }
        });
        if (tempSegId.length < 1) {
            for (let property in targetingData) {
                targetingData[property].map((obj) => {
                    obj["groupName"] = targetingData[property].groupName;
                    let s = !(obj.segment_name.includes(":")) ? obj.segment_name : obj.description;
                    dataObjs.push({label: s, value: obj});
                })
            }
            // console.log("dataObjs", dataObjs)

            setOptions(dataObjs)
            // dataObjs = dataObjs;
            // console.log(dataObjs)
            let data = transformObject(dataObjs.filter((i) => {
                return i.label !== "All" && i.label !== ''
            }))
            setPreTransformTreeList1(dataObjs.filter((i) => {
                return i.label !== "All" && i.label !== ''
            }))
            if (targetType === 'CONSUMER') {

                const treeData1 = mergeArrays(consumerList, splitAnalyticsIQ(data));

                // console.log(mergeArrays(consumerList, data))
                setTreeListState1({
                    ...treeListState1,
                    data: treeData1,
                    expanded: treeData1.map((i) => i.treeId)
                });

                setTempSearchValues1(treeData1);
            } else {
                const treeData1 = mergeArrays(businessList, data);
                setTreeListState1({
                    ...treeListState1,
                    data: treeData1,
                    expanded: treeData1.map((i) => i.treeId)
                });

                setTempSearchValues1(treeData1);
            }
            setPreTransformTreeList2([]);
            setTreeListState2({
                ...treeListState2,
                data: []
            })
            setTempSearchValues2([]);
        } else {
            for (let property in targetingData) {
                targetingData[property].map((obj) => {
                    obj["groupName"] = targetingData[property].groupName;
                    let s = !(obj.segment_name.includes(":")) ? obj.segment_name : obj.description;
                    dataObjs.push({label: s, value: obj});
                })
            }

            let filteredData = dataObjs.filter((i) => {
                return i.label !== "All" && i.label !== ''
            });


            tempSegObj2 = filteredData.filter(item => tempSegId.includes(item.value.segment_id));
            tempSegObj1 = filteredData.filter(item => !tempSegId.includes(item.value.segment_id));
            // console.log(tempSegObj1)
            let data = transformObject(tempSegObj1)
            setPreTransformTreeList1(tempSegObj1)
            if (targetType === 'CONSUMER') {
                const treeData1 = mergeArrays(consumerList, splitAnalyticsIQ(data));
                setTreeListState1({
                    ...treeListState1,
                    data: treeData1,
                    expanded: treeData1.map((i) => i.treeId)
                });

                setTempSearchValues1(treeData1);
            } else {
                const treeData1 = mergeArrays(businessList, data);
                setTreeListState1({
                    ...treeListState1,
                    data: treeData1,
                    expanded: treeData1.map((i) => i.treeId)
                });

                setTempSearchValues1(treeData1);
            }
            setPreTransformTreeList2(tempSegObj2);
            setExpand2(false);
            setTreeListState2({
                ...treeListState2,
                data: []
            })
            setTempSearchValues2([]);
        }
        //Select the first tab by default
        setSelectedTab(tabList[1])
    }, [targetingData])
    const filterAnalyticsIQ = (inputObj, searchString) => {
        if (!inputObj || !Array.isArray(inputObj)) {
            return [];
        }
// let outputObj = {};
//         outputObj["groupName"] = "Analytics IQ";
//         outputObj["targetType"] = "CONSUMER";
//         outputObj = inputObj.filter(item => item.segment_category.startsWith(searchString))
//         return outputObj;
        return inputObj.filter(item => item.segment_category.startsWith(searchString))
    };
    const fetchData = async () => {
        setLoadOverlay(true);
        let allReq = [];
        let cList = [];
        let bList = [];
        for (const key in audienceDesignConfig) {
            //manually adding coreDemographic below for the tree
            if (key !== 'coreDemographic' && key !== 'coreFirmographic') {
                allReq.push(
                    await getPpkSegments(
                        audienceDesignConfig[key].endPoint,
                        audienceDesignConfig[key].groupType
                    )
                );
            }
            // console.log(audienceDesignConfig[key]);
            if ((audienceDesignConfig[key].targetType === "CONSUMER" ||
                audienceDesignConfig[key].targetType === "BOTH")) {
                cList.push(audienceDesignConfig[key]);
            }
            if ((audienceDesignConfig[key].targetType === "BUSINESS" ||
                audienceDesignConfig[key].targetType === "BOTH")) {
                bList.push(audienceDesignConfig[key]);
            }
        }

        //manually adding coreDemographic data (doing this to avoid backend changes)
        const businessCoreFirmographicDataObj = {
            code: 0,
            data: coreDemographicData.business,
            count: 0,
            status: '',
            groupType: 'coreFirmographic'
        };
        const consumerCoreDemographicDataObj = {
            code: 0,
            data: coreDemographicData.consumer,
            count: 0,
            status: '',
            groupType: 'coreDemographic'
        };

        if (targetType === 'CONSUMER') {
            allReq.push(consumerCoreDemographicDataObj);
        } else {
            allReq.push(businessCoreFirmographicDataObj);
        }

        // FD#11981
        // cList = cList.filter((key) => {
        //     return key.groupType !== 'smllearning'
        // })
        setConsumerList(cList);
        setBusinessList(bList);

        await Promise.all(allReq).then((res) => {
            const allData = {};
            // console.log(res);
            res.forEach((reqData) => {
                const {data, groupType} = reqData;
                // console.log(groupType)
                // console.log(audienceDesignConfig)
                if (audienceDesignConfig[groupType].targetType === "BOTH" ||
                    audienceDesignConfig[groupType].targetType === targetType ||
                    audienceDesignConfig[groupType].groupName === "Net Worth" ||
                    audienceDesignConfig[groupType].groupName === "Discretionary Income") {
                    allData[groupType] = data;
                    allData[groupType].groupName = audienceDesignConfig[groupType].groupName;
                    allData[groupType].targetType = audienceDesignConfig[groupType].targetType;
                }
            });
            if (allData['personalFinance']) {
                handleDeciles(allData);
            }
            //Sepcial case for AnalyticsIQ FD#13642
            // if(allData["analyticsIQ"]){
            //     allData["analyticsIQHealthcare"] = filterAnalyticsIQ(allData["analyticsIQ"], "Health & Wellness > CBD > Consumption Method");
            //     allData["analyticsIQHealthcare"].groupName = "Analytics IQ";
            //     allData["analyticsIQHealthcare"].targetType = "CONSUMER";
            //     allData["analyticsIQB2B"] = filterAnalyticsIQ(allData["analyticsIQ"], "B2B");
            //     allData["analyticsIQB2B"].groupName = "Analytics IQ";
            //     allData["analyticsIQB2B"].targetType = "CONSUMER";
            // }
            //FD#12181 Removing these 2 groups because tiles not found under Business Target Type. Same group can still be found under Consumer-personalFinance.
            delete allData["discretionaryIncome"];
            delete allData["netWorth"];
            //FD#11981
            // delete allData["smllearning"];
            // console.log("allData",allData)
            setTargetingData(allData);
            setLoadOverlay(false);
        });
    };

    function getValueFromKey(obj, str) {
        // console.log(obj)
        const normalizedStr = str.toLowerCase().replace(/[\s-_]/g, '');

        for (const key in obj) {

            if (key === "primaryData") {
                for (const primeKey in obj.primaryData) {
                    const normalizedKey = primeKey.toLowerCase().replace(/[\s-_]/g, '');
                    // console.log(normalizedKey, normalizedStr)
                    if (normalizedKey === normalizedStr) {
                        return primeKey;
                    }
                }
            } else {
                const normalizedKey = key.toLowerCase().replace(/[\s-]/g, '');
                if (normalizedKey === normalizedStr) {
                    return key;
                }
            }
        }
        return undefined; // Return undefined if the key is not found
    }

    //FD:10151 - Special case for decile categories
    const handleDeciles = (data) => {
        // console.log(data);
        let personalFinance = data['personalFinance'];
        if (personalFinance === undefined) return;
        let netWorth = data['netWorth'];
        let discretionaryIncome = data['discretionaryIncome'];
        netWorth.map((obj) => {
            obj.segment_group = 'Personal Finance';
            obj.segment_name = obj.description;
        })
        discretionaryIncome.map((obj) => {
            obj.segment_group = 'Personal Finance';
            obj.segment_name = obj.description;
        })
        discretionaryIncome.sort((a, b) => a.segment_id.localeCompare(b.segment_id, 'en', {numeric: true}))
        data['personalFinance'] = personalFinance.concat(netWorth).concat(discretionaryIncome);
        // console.log(data['personalFinance']);
    }


    function transformObject(arr) {
        const result = {};
        const comboList = [...consumerList, ...businessList];
        // let Id = 1;
        arr.forEach(item => {
            const {segment_category, segment_group} = item.value;
            let {groupName} = item.value;
            if (groupName === undefined) {
                groupName = segment_group
            }

            // If groupName doesn't exist in result, initialize it with empty items array
            if (!result[groupName]) {
                result[groupName] = {
                    text: comboList.find(item => item.groupName === groupName).title,
                    // treeId: Id++,
                    items: []
                };
            }
            // Find or create the matching segment_category within the Items of the current groupName
            let categoryGroup = result[groupName].items.find(subItem => subItem.text === segment_category);
            if (!categoryGroup) {
                categoryGroup = {
                    text: segment_category,
                    // treeId: Id++,
                    items: []
                };
                result[groupName].items.push(categoryGroup);
                result[groupName].items.sort((a, b) => a.text.localeCompare(b.text));
            }
            // Add the item with label as text to the items of the matching segment_category
            categoryGroup.items.push({
                ...item.value,
                label: item.label,
                text: item.label,
            });
            categoryGroup.items.sort((a, b) => a.segment_id.toString().localeCompare(b.segment_id.toString()));
        });
        // Convert the result object to an array
        return Object.values(result);
    }

    function mergeByText(array) {

        const map = new Map();

        // Function to recursively merge items
        function mergeItems(existingItems, newItems) {
            const existingMap = new Map();

            // Convert existing items to a map for easy lookup
            existingItems.forEach(item => {
                existingMap.set(item.text, item);
            });

            newItems.forEach(item => {
                if (existingMap.has(item.text)) {
                    // Merge existing item with new item
                    const existingItem = existingMap.get(item.text);
                    existingItem.items = mergeItems(existingItem.items, item.items);
                } else {
                    // Add new item to the map
                    existingMap.set(item.text, item);
                }
            });
            // Convert the map values to an array
            return Array.from(existingMap.values());
        }

        array.forEach(item => {
            if (map.has(item.text)) {
                // Item exists, merge the children
                const existingItem = map.get(item.text);
                existingItem.items = mergeItems(existingItem.items, item.items);
            } else {
                // Item doesn't exist, add it to the map
                map.set(item.text, item);
            }
        });

        // Convert the map values to an array
        return Array.from(map.values());
    }

    // console.log(treeListState1.data)
    function mergeArrays(a, b) {
        let Id = 1;

        return mergeByText(a.map(aItem => {
            // Find the corresponding item in array b
            const bItem = b.find(bItem => {
                const normalize = str => str.replace(/[^a-zA-Z]/g, "").toLowerCase(); // Remove non-alphabetic characters and convert to lowercase
                return normalize(bItem.text) === normalize(aItem.title) ? normalize(bItem.text) === normalize(aItem.title) : normalize(bItem.text) === normalize(aItem.groupName);
            });
            // console.log(bItem)
            //conditional rendering for core demographic because it has 1 less level
            if (bItem && bItem.text.startsWith("Analytics IQ")) {
                return {
                    text: aItem.displayType,
                    treeId: Id++,
                    value: aItem.displayType,
                    items: [{
                        text: aItem.title.concat(" (", aItem.price, ")"),
                        treeId: Id++,
                        price: aItem.price,
                        value: bItem.text,
                        items: bItem.items.map(bSubItem => ({
                            text: bSubItem.text,
                            treeId: Id++,
                            value: bSubItem.text,
                            items: bSubItem.items.map(bSegment => ({
                                ...bSegment,
                                value: bSegment.segment_id,
                                treeId: Id++,
                            }))
                        }))
                    }]
                };
            }else
            if (bItem && bItem.text !== "Core Demographic" && bItem.text !== "Core Firmographic" && !bItem.text.startsWith("Analytics IQ")) {
                return {
                    text: aItem.displayType,
                    treeId: Id++,
                    value: aItem.displayType,
                    items: [{
                        text: bItem.text.concat(" (", aItem.price, ")"),
                        treeId: Id++,
                        price: aItem.price,
                        value: bItem.text,
                        items: bItem.items.map(bSubItem => ({
                            text: bSubItem.text,
                            treeId: Id++,
                            value: bSubItem.text,
                            items: bSubItem.items.map(bSegment => ({
                                ...bSegment,
                                value: bSegment.segment_id,
                                treeId: Id++,
                            }))
                        }))
                    }]
                };
            } else if (bItem && bItem.text === "Core Demographic" || bItem && bItem.text === "Core Firmographic") {
                return {
                    text: aItem.displayType,
                    treeId: Id++,
                    value: aItem.displayType,
                    items: bItem.items.map(bSubItem => ({
                        text: bSubItem.text,
                        treeId: Id++,
                        value: bSubItem.text,
                        items: bSubItem.items.map(bSegment => ({
                            ...bSegment,
                            value: bSegment.segment_id,
                            treeId: Id++,
                        }))
                    }))
                };
            }
            return null; // Return null if no matching item is found
        }).filter(item => item !== null)) // Filter out any nulls
    }

    const expandAll = (e, tree) => {
        e.preventDefault();
        //Expand all search result
        let allExpanded = [];
        if (tree === '1') {
            // treeListState1.data.forEach((item) => {
            //     allExpanded.push(item.treeId);
            //     item.items.forEach(index => {
            //         allExpanded.push(index.treeId);
            //         index.items.forEach(i => {
            //             allExpanded.push(i.treeId);
            //         })
            //     });
            // });
            mapTree(treeListState1.data.slice(), subItemsField, (item) => {
                allExpanded.push(item[dataItemKey]);
            });
            setExpand1(false);
            setTreeListState1({
                ...treeListState1,
                expanded: allExpanded
            })
        } else {
            // treeListState2.data.forEach((item) => {
            //     allExpanded.push(item.treeId);
            //     item.items.forEach(index => {
            //         allExpanded.push(index.treeId);
            //         index.items.forEach(i => {
            //             allExpanded.push(i.treeId);
            //         })
            //     });
            // });
            mapTree(treeListState2.data.slice(), subItemsField, (item) => {
                allExpanded.push(item[dataItemKey]);
            });
            setExpand2(false);
            setTreeListState2({
                ...treeListState2,
                expanded: allExpanded
            })
        }
    };

    const collapseAll = (e, tree) => {
        e.preventDefault();
        //Collapse all search result
        if (tree === '1') {
            setExpand1(true)
            setTreeListState1({
                ...treeListState1,
                expanded: []
            })
        } else {
            setExpand2(true)
            setTreeListState2({
                ...treeListState2,
                expanded: []
            })
        }
    }
    const handleSearch1 = () => {
        let titles = [];
        let allExpanded = [];
        let value = document.querySelector('.dragndrop-search-box1').value;

        let newData = search(tempSearchValues1, value);
        //Expand all search result if search value is not empty
        if (value.length !== 0) {
            // newData.forEach((item) => {
            //     titles.push(item.text);
            //     allExpanded.push(item.treeId);
            //     item.items.forEach(index => {
            //         titles.push(index.text);
            //         allExpanded.push(index.treeId);
            //         index.items.forEach(i => {
            //             titles.push(i.text);
            //             allExpanded.push(i.treeId);
            //         })
            //     });
            // });
            mapTree(newData.slice(), subItemsField, (item) => {
                allExpanded.push(item[dataItemKey]);
            });
        }

        setTreeListState1({
            ...treeListState1,
            data: newData,
            expanded: allExpanded

        })
    }
    const handleSearch2 = () => {
        let titles = [];
        let allExpanded = [];
        let value = document.querySelector('.dragndrop-search-box2').value;
        // let data = transformObject(options.filter((i)=>{return i.label !== "All" && i.label !== ''}))
        let newData = search(tempSearchValues2, value);
        //Expand all search result if search value is not empty
        if (value.length !== 0) {
            // newData.forEach((item) => {
            //     titles.push(item.text);
            //     allExpanded.push(item.treeId);
            //     item.items.forEach(index => {
            //         titles.push(index.text);
            //         allExpanded.push(index.treeId);
            //         index.items.forEach(i => {
            //             titles.push(i.text);
            //             allExpanded.push(i.treeId);
            //         })
            //     });
            // });
            mapTree(newData.slice(), subItemsField, (item) => {
                allExpanded.push(item[dataItemKey]);
            });
        }

        setTreeListState2({
            ...treeListState2,
            data: newData,
            expanded: allExpanded

        })
    }

    function contains(text, term) {
        return text.toLowerCase().indexOf(term.toLowerCase()) >= 0;
    }

    function search(items, term) {
        return items.reduce((acc, item) => {
            if (contains(item.text, term)) {
                acc.push(item);
            } else if (item.items && item.items.length > 0) {
                let newItems = search(item.items, term);
                if (newItems && newItems.length > 0) {
                    acc.push({text: item.text, items: newItems, treeId: item.treeId /*, expanded: item.expanded*/});
                }
            }
            return acc;
        }, []);
    }


    const onTreeList2ExpandChange = (e) => {
        setTreeListState2({
            ...treeListState2,
            expanded: e.value
                ? treeListState2.expanded.filter((id) => id !== e.dataItem.treeId)
                : [...treeListState2.expanded, e.dataItem.treeId],
        });
    };
    const onTreeList1ExpandChange = (e) => {
        setTreeListState1({
            ...treeListState1,
            expanded: e.value
                ? treeListState1.expanded.filter((id) => id !== e.dataItem.treeId)
                : [...treeListState1.expanded, e.dataItem.treeId],
        });
    };

    function treeListItemClickUtil(tempSegList1, tempSegList2, dataItem, onClickList1) {
        // console.log(dataItem)
        // let targetType = targetType;
        let keyFound;
        if (targetType === "CONSUMER") {
            keyFound = getValueFromKey(props.formik.values.segment_design, dataItem.segment_group) ? getValueFromKey(props.formik.values.segment_design, dataItem.segment_group) : getValueFromKey(props.formik.values.segment_design, dataItem.groupName);
        } else {
            if (businessList.find(item => item.groupName === dataItem.groupName).groupType === 'travelAndEntertainment') {
                //not sure why naming pattern changed
                keyFound = 'travelSegments';
            } else if (dataItem.groupName === "Core Demographic" || dataItem.groupName === "Core Firmographic") {
                keyFound = getValueFromKey(props.formik.values.segment_design, dataItem.segment_group)
            } else {
                keyFound = businessList.find(item => item.groupName === dataItem.groupName).groupType;
            }

        }

        //On TreeList1 Item Click
        if (onClickList1) {
            tempSegList2.push({label: dataItem.label, value: dataItem});
            if (dataItem.groupName === "Core Demographic" || dataItem.groupName === "Core Firmographic") {
                tempSegList1 = tempSegList1.filter(item => item.value.segment_name !== dataItem.segment_name);
            } else {
                tempSegList1 = tempSegList1.filter(item => item.value.segment_id !== dataItem.segment_id);
            }

            if (!props.formik.values.segment_design[keyFound]) {
                //some values are in arrays (digital activity index and inflation sensitivity index)
                if (Array.isArray(dataItem.segment_id)) {
                    // console.log("here1")
                    if (!props.formik.values.segment_design.primaryData[keyFound].find(item => dataItem.segment_id.includes(item))) {
                        props.formik.values.segment_design.primaryData[keyFound].push(...dataItem.segment_id);
                    }
                } else {
                    if (!props.formik.values.segment_design.primaryData[keyFound].find(item => item === dataItem.segment_id)) {
                        props.formik.values.segment_design.primaryData[keyFound].push(dataItem.segment_id);
                    }
                }
            } else {
                if (!props.formik.values.segment_design[keyFound].find(item => item === dataItem.segment_id)) {
                    props.formik.values.segment_design[keyFound].push(dataItem.segment_id);
                }
            }


            //On TreeList2 Item Click
        } else {
            tempSegList1.push({label: dataItem.label, value: dataItem});
            if (dataItem.groupName === "Core Demographic" || dataItem.groupName === "Core Firmographic") {
                tempSegList2 = tempSegList2.filter(item => item.value.segment_name !== dataItem.segment_name);
            } else {
                tempSegList2 = tempSegList2.filter(item => item.value.segment_id !== dataItem.segment_id);
            }
            // let { segment_design } = props.formik.values;
            let targetData = props.formik.values.segment_design[keyFound];
            let targetPrimeData = props.formik.values.segment_design.primaryData[keyFound];

            const segmentIds = Array.isArray(dataItem.segment_id) ? dataItem.segment_id : [dataItem.segment_id];
            const targetToFilter = targetData || targetPrimeData;

            if (targetToFilter) {
                const updatedData = targetToFilter.filter(item => !segmentIds.includes(item));

                if (!targetData) {
                    props.formik.values.segment_design.primaryData[keyFound] = updatedData;
                } else {
                    props.formik.values.segment_design[keyFound] = updatedData;
                }
            }

        }
        // console.log(props.formik.values.segment_design)
        // console.log(tempSegList1, tempSegList2);
        return {list1: tempSegList1, list2: tempSegList2}
    }

    // useEffect(() => {
    //     console.log(props.formik.values.segment_design);
    // }, [props.formik.values.segment_design]);
    const onTreeListItemClick1 = (e) => {
        let tempSegList2 = preTransformTreeList2;
        let tempSegList1 = preTransformTreeList1;
        // if (targetType === "CONSUMER") {
        if (e.level.length === 4) {
            const values = treeListItemClickUtil(tempSegList1, tempSegList2, e.dataItem, true);
            tempSegList1 = values.list1;
            tempSegList2 = values.list2;

        }
        if (e.level.length === 3) {
            if (e.dataItem.items) {
                e.dataItem.items.forEach(i => {
                    const values = treeListItemClickUtil(tempSegList1, tempSegList2, i, true);
                    tempSegList1 = values.list1;
                    tempSegList2 = values.list2;
                })
            } else {
                const values = treeListItemClickUtil(tempSegList1, tempSegList2, e.dataItem, true);
                tempSegList1 = values.list1;
                tempSegList2 = values.list2;
            }

        }
        if (e.level.length === 2) {
            e.dataItem.items.forEach(i => {
                if (i.items) {
                    i.items.forEach(item => {
                        const values = treeListItemClickUtil(tempSegList1, tempSegList2, item, true);
                        // console.log(values)
                        tempSegList1 = values.list1;
                        tempSegList2 = values.list2;
                    })
                } else {
                    const values = treeListItemClickUtil(tempSegList1, tempSegList2, i, true);
                    tempSegList1 = values.list1;
                    tempSegList2 = values.list2;
                }

            })
        }
        if (e.level.length === 1) {
            e.dataItem.items.forEach(i => {
                i.items.forEach(item => {
                    if (item.items) {
                        item.items.forEach(element => {
                            const values = treeListItemClickUtil(tempSegList1, tempSegList2, element, true);
                            tempSegList1 = values.list1;
                            tempSegList2 = values.list2;
                        })
                    } else {
                        const values = treeListItemClickUtil(tempSegList1, tempSegList2, item, true);
                        tempSegList1 = values.list1;
                        tempSegList2 = values.list2;
                    }
                })
            })
        }
        setPreTransformTreeList1(tempSegList1);
        setPreTransformTreeList2(tempSegList2);

        let allExpanded = [];
        setExpand2(false);
        const tree1Data = mergeArrays(targetType === 'CONSUMER' ? consumerList : businessList, splitAnalyticsIQ(transformObject(tempSegList1)));
        const tree2Data = mergeArrays(targetType === 'CONSUMER' ? consumerList : businessList, splitAnalyticsIQ(transformObject(tempSegList2)));

        // tree2Data.forEach((item) => {
        //     allExpanded.push(item.treeId);
        //     item.items.forEach(index => {
        //         allExpanded.push(index.treeId);
        //         index.items.forEach(i => {
        //             allExpanded.push(i.treeId);
        //         })
        //     });
        // });
        mapTree(tree2Data.slice(), subItemsField, (item) => {
            allExpanded.push(item[dataItemKey]);
        });
        setTreeListState1(
            {
                ...treeListState1,
                data: tree1Data,
                expanded: treeListState1.expanded
            });
        setTreeListState2(
            {
                ...treeListState2,
                data: tree2Data,
                expanded: allExpanded
            })

        setTempSearchValues1(tree1Data);
        setTempSearchValues2(tree2Data);
    }
    useEffect(() => {
        // console.log("treeListState1.data",treeListState1.data)
        // console.log("treeListState1.expanded",treeListState1.expanded)
    }, [treeListState1.expanded]);
    const onTreeListItemClick2 = (e) => {
        //Remove items onClick, from Tree List and formik
        let tempSegList2 = preTransformTreeList2;
        let tempSegList1 = preTransformTreeList1;

        // if (targetType === "CONSUMER") {
        if (e.level.length === 4) {
            const values = treeListItemClickUtil(tempSegList1, tempSegList2, e.dataItem, false);
            tempSegList1 = values.list1;
            tempSegList2 = values.list2;

        }
        if (e.level.length === 3) {
            if (e.dataItem.items) {
                e.dataItem.items.forEach(i => {
                    const values = treeListItemClickUtil(tempSegList1, tempSegList2, i, false);
                    tempSegList1 = values.list1;
                    tempSegList2 = values.list2;
                })
            } else {
                const values = treeListItemClickUtil(tempSegList1, tempSegList2, e.dataItem, false);
                tempSegList1 = values.list1;
                tempSegList2 = values.list2;
            }


        }
        if (e.level.length === 2) {
            e.dataItem.items.forEach(i => {
                if (i.items) {
                    i.items.forEach(item => {
                        const values = treeListItemClickUtil(tempSegList1, tempSegList2, item, false);
                        tempSegList1 = values.list1;
                        tempSegList2 = values.list2;
                    })
                } else {
                    const values = treeListItemClickUtil(tempSegList1, tempSegList2, i, false);
                    tempSegList1 = values.list1;
                    tempSegList2 = values.list2;
                }

            })
        }
        if (e.level.length === 1) {
            e.dataItem.items.forEach(i => {
                i.items.forEach(item => {
                    if (item.items) {
                        item.items.forEach(element => {
                            const values = treeListItemClickUtil(tempSegList1, tempSegList2, element, false);
                            tempSegList1 = values.list1;
                            tempSegList2 = values.list2;
                        })
                    } else {
                        const values = treeListItemClickUtil(tempSegList1, tempSegList2, item, false);
                        tempSegList1 = values.list1;
                        tempSegList2 = values.list2;
                    }
                })
            })
        }
        setPreTransformTreeList1(tempSegList1);
        setPreTransformTreeList2(tempSegList2);
        const allExpanded = [];
        const tree1Data = mergeArrays(targetType === 'CONSUMER' ? consumerList : businessList, splitAnalyticsIQ(transformObject(tempSegList1)));
        const tree2Data = mergeArrays(targetType === 'CONSUMER' ? consumerList : businessList, splitAnalyticsIQ(transformObject(tempSegList2)));

        // tree2Data.forEach((item) => {
        //     allExpanded.push(item.treeId);
        //     item.items.forEach(index => {
        //         allExpanded.push(index.treeId);
        //         index.items.forEach(i => {
        //             allExpanded.push(i.treeId);
        //         })
        //     });
        // });
        mapTree(tree2Data.slice(), subItemsField, (item) => {
            allExpanded.push(item[dataItemKey]);
        });
        setExpand2(false)
        setTreeListState1(
            {
                ...treeListState1,
                data: tree1Data,
                // expanded: tree1Data.map((i)=>i.treeId)
            })
        setTreeListState2(
            {
                ...treeListState2,
                data: tree2Data,
                expanded: allExpanded
            })

        setTempSearchValues1(tree1Data)
        setTempSearchValues2(tree2Data);
    }
    const addExpandField1 = (dataTree) => {
        const expanded = treeListState1.expanded;
        return mapTree(dataTree, subItemsField, (item) =>
            extendDataItem(item, subItemsField, {
                [expandField]: expanded.includes(item.treeId)
            })
        );
    };
    const addExpandField2 = (dataTree) => {
        const expanded = treeListState2.expanded;
        return mapTree(dataTree, subItemsField, (item) =>
            extendDataItem(item, subItemsField, {
                [expandField]: expanded.includes(item.treeId)
            })
        );
    };
    useEffect(() => {
    }, [treeListState1.dataState.filter, expand1, expand2]);

    function sortTreeOrder (data){
        return data.sort((a, b) => {
            // Condition to place 'Core Demographic' at the first position
            if (a.text === "Core Demographic" || a.text === "Core Firmographic") return -1;
            if (b.text === "Core Demographic" || b.text === "Core Firmographic") return 1;
            const indexA = tabList.indexOf(a.text);
            const indexB = tabList.indexOf(b.text);

            // If text is not found in B, it will return -1. You can adjust sorting for such cases if needed.
            return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
        });
    }
    const processData1 = () => {
        let {data, dataState} = treeListState1;

        const sortedData = sortTreeOrder(data);

        if (selectedTab !== "") {
            data = sortedData.filter(item => item.text === selectedTab);
        }
        // else if (selectedTab === "Demographic") {
        //     data = [...data.filter(item => item.text === "Core Demographic"), ...data.filter(item => item.text === "Demographic")];
        // } else if (selectedTab === "Firmographic") {
        //     data = [...data.filter(item => item.text === "Core Firmographic"), ...data.filter(item => item.text === "Firmographic")];
        // }
        // let filteredData = filterBy(data, dataState.filter, subItemsField);
        return addExpandField1(data);
    };
    const processData2 = () => {
        let {data, dataState} = treeListState2;
        const sortedData = sortTreeOrder(data);
        return addExpandField2(sortedData);
    };

    const handleTreeList1StateChange = (event) => {
        setTreeListState1({
            ...treeListState1,
            dataState: event.dataState,
        });
    };
    const handleTreeList2StateChange = (event) => {
        setTreeListState2({
            ...treeListState2,
            dataState: event.dataState,
        });
    };
    const tabList = targetType === "CONSUMER" ? ["", "Core Demographic", "Demographic", "B2B", "Home Services", "Retailers", "Healthcare", "Travel/Entertainment", "Contextual", "Politics"] : ["", "Core Firmographic", "Firmographic", "B2B",  "Healthcare", "Travel/Entertainment"]

    const handleTabOnClick = (e, val) => {
        e.preventDefault();
        setTreeListState1(
            {
                ...treeListState1,
                expanded: treeListState1.data.map((i)=>i.treeId)
            })
        if (selectedTab === val) {
            setSelectedTab("")
        } else {
            switch (val) {
                case selectedTab !== "":
                    setSelectedTab("");
                    break;
                case val:
                    setSelectedTab(val);
                    break;
                default:
                // code block
            }
        }
    }


    return (
        <>
            {/*<Card>*/}
            {/*<CardTitle>*/}
            {/*    <Label className="section-label">ADVANCED TARGETING</Label>*/}
            {/*</CardTitle>*/}
            <CardBody>
                <Form render={formRenderProps => <FormElement style={{
                    width: "100%"
                }}>
                    {/*<legend className={'k-form-legend spacingLine'}></legend>*/}
                    <div className="advanced-targeting-container">
                        <Form render={formRenderProps => <FormElement style={{
                            width: "100%"
                        }}>
                            <div className={"above-tree-components-wrapper"}>
                                <FieldWrapper className={"targeting-first-column-field"}>
                                    <Label className={"field-label"}>Target Type</Label>
                                    <RadioGroup
                                        data={targetTypes}
                                        value={targetType}
                                        onChange={handleTargetType}
                                        layout="vertical"
                                        style={{width: "100%", fontSize: ".9rem"}}
                                    />
                                </FieldWrapper>
                                <div className={"locations-wrapper"}>
                                    <FieldWrapper>
                                        <Label className={"field-label"}>Locations</Label>
                                        <Locations
                                            formik={props.formik}
                                            controlsState={props.controlsState}
                                            handleControlsState={props.handleControlsState}
                                            segmentEditInfo={props.segmentEditInfo}/>
                                    </FieldWrapper>
                                </div>
                                {targetType === "CONSUMER" &&
                                    <div className="legislative-district-wrapper">
                                        <FieldWrapper>
                                   <span className={"tooltip-label-and-icon-wrapper"}>
                                                    <Label className={"field-label"}>Legislative Districts</Label>
                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "15rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                              <i className="fa fa-question-circle-o tooltip-question-icon"
                                                                 aria-hidden="true"
                                                                 title={"Legislative Districts refer to areas from which members are elected to state or equivalent entity legislatures."}></i>
                                                    </Tooltip>
                                     </span>
                                            <LegislativeDistricts
                                                formik={props.formik}
                                                controlsState={props.controlsState}
                                                handleControlsState={props.handleControlsState}
                                                segmentEditInfo={props.segmentEditInfo}/>
                                        </FieldWrapper>
                                    </div>
                                }
                                {targetType === "BUSINESS" && <div className={"naics-code-wrapper"}>
                                    <FieldWrapper>
                                                <span className={"tooltip-label-and-icon-wrapper"}>
                                                    <Label className={"field-label"}>
                                                        NAICS Codes
                                                    </Label>
                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "15rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                              <i className="fa fa-question-circle-o tooltip-question-icon"
                                                                 aria-hidden="true"
                                                                 title={"NAICS Codes refer to 3 or 6 digit Industry Codes."}></i>
                                                    </Tooltip>
                                                </span>
                                        <Naics
                                            formik={props.formik}
                                            controlsState={props.controlsState}
                                            handleControlsState={props.handleControlsState}
                                            segmentEditInfo={props.segmentEditInfo}/>
                                    </FieldWrapper>
                                </div>
                                }
                                {/*<div className={"selection-type-wrapper"}>*/}
                                {/*    <FieldWrapper>*/}
                                {/*        <Label>Selection Type</Label>*/}
                                {/*        /!*Moved here - demo feedback - 1/27/23*!/*/}
                                {/*        <RadioGroup*/}
                                {/*            data={selectionTypes}*/}
                                {/*            value={selectionType}*/}
                                {/*            onChange={handleSelectionType}*/}
                                {/*            layout="vertical"*/}
                                {/*        />*/}
                                {/*    </FieldWrapper>*/}
                                {/*</div>*/}
                            </div>
                            {/*<legend className={'k-form-legend spacingLine'} style={{marginTop:"30px", marginBottom:"30px", width:"70%", alignSelf:"center"}}></legend>*/}
                            {/*<legend className={'k-form-legend spacingLine'}></legend>*/}
                            <LoadOverlay active={loadOverlay} width={'100%'} height={'100px'}>
                                <div className={"tree-table-wrapper"}>
                                    <div className={"tab-button-group-wrapper"}
                                         style={{marginBottom: "20px", marginTop: "20px"}}>
                                        <div className="tab-button-group">
                                            <ButtonGroup>
                                                {tabList.map((item, index) => {
                                                    return (
                                                        item !== "" ?
                                                            <Button
                                                                className={`${selectedTab === item && "btn-primary"}`}
                                                                togglable={true} selected={selectedTab === item}
                                                                onClick={(e) => handleTabOnClick(e, item)}>
                                                                {item}
                                                            </Button> :
                                                            ""
                                                    )
                                                })}
                                            </ButtonGroup>
                                            <Tooltip
                                                position="right"
                                                anchorElement="target"
                                                tooltipStyle={{
                                                    width: "15rem",
                                                    borderRadius: "0.25rem",
                                                }}
                                            >
                                                <i className="fa fa-question-circle-o tooltip-question-icon"
                                                   aria-hidden="true"
                                                   title={"Select any tab to see the filtered list. Deselect the selected tab to see the full list."}></i>
                                            </Tooltip>
                                        </div>
                                        <div className={"cost-msg-div"} style={{marginTop: "0", alignSelf:"center"}}>
                                            <Label className={"cost-msg-label"}>
                                                <span>All selections under Core Demographic and Core Firmographic are computed as AND statement</span>
                                            </Label>
                                        </div>
                                    </div>
                                    <div className={"tree-outter-wrapper"}>
                                        <div className={"left-tree-wrapper"} style={{width: "-webkit-fill-available"}}>
                                            <div className={"tree-tool-bar"}>
                                                <Input className={"dragndrop-search-box1"} onChange={handleSearch1}
                                                       placeholder={'Search Segment...'}/>
                                                <Button
                                                    onClick={e => expand1 ? expandAll(e, '1') : collapseAll(e, '1')}
                                                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                                    style={{marginLeft: '10px'}}
                                                >
                                                    {expand1 ? "Expand all" : "Collapse all"}
                                                </Button>
                                            </div>
                                            <TreeList
                                                {...treeListState1.dataState}
                                                style={{
                                                    height: "30vh",
                                                    overflow: "auto",
                                                    marginTop: "20px",
                                                }}
                                                // rowRender={rowRender}
                                                expandField={expandField}
                                                subItemsField={subItemsField}
                                                data={processData1()}
                                                columns={columns1}
                                                onDataStateChange={handleTreeList1StateChange}
                                                onExpandChange={onTreeList1ExpandChange}
                                                onRowClick={onTreeListItemClick1}
                                            />
                                        </div>
                                        <img alt="" src={angleLeftRightThemeColorIcon}
                                             style={{
                                                 width: "50px",
                                                 alignSelf: "center",
                                                 paddingLeft: '5px',
                                                 paddingRight: '5px'
                                             }}
                                        />
                                        <div className={"right-tree-wrapper"} style={{width: "-webkit-fill-available"}}>

                                            <div className={"selected-count"}>Selected Segments
                                                ({preTransformTreeList2.length})
                                            </div>
                                            <div className={"tree-tool-bar"}>
                                                <Input className={"dragndrop-search-box2"} onChange={handleSearch2}
                                                       placeholder={'Search Segment...'}/>
                                                <Button
                                                    onClick={e => expand2 ? expandAll(e, '2') : collapseAll(e, '2')}
                                                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                                    style={{marginLeft: '10px'}}
                                                >
                                                    {expand2 ? "Expand all" : "Collapse all"}
                                                </Button>
                                            </div>
                                            <TreeList
                                                style={{
                                                    height: "30vh",
                                                    overflow: "auto",
                                                    marginTop: "20px",
                                                }}
                                                expandField={expandField}
                                                subItemsField={subItemsField}
                                                data={processData2()}
                                                columns={columns2}
                                                onDataStateChange={handleTreeList2StateChange}
                                                onExpandChange={onTreeList2ExpandChange}
                                                onRowClick={onTreeListItemClick2}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </LoadOverlay>
                            {/*FD:13383*/}
                            {/*<div className={"cost-msg-div"}>*/}
                            {/*    <Label className={"cost-msg-label"}>*/}
                            {/*        <span>Basic Segments are $1.00 per thousand. Cost is capped at the highest listed CPM value. When multiple segments are used the CPM value is proportionately applied. <br/></span>*/}
                            {/*    </Label>*/}
                            {/*</div>*/}
                        </FormElement>}/>
                    </div>
                </FormElement>}/>
            </CardBody>
            {/*</Card>*/}
        </>
    )

}

export default AdvancedTargetingTreeList;