import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {patchAudienceFile} from "../../../actions/OnBoardingActions";
import {mappingTypes} from "../../../Utils/ColumnMapTypes";
import {Button} from "@progress/kendo-react-buttons";
import {Card} from "@progress/kendo-react-layout";
import {Dialog, DialogActionsBar, Window} from '@progress/kendo-react-dialogs';
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {GridLayout, GridLayoutItem} from "@progress/kendo-react-layout";
import {Label} from "@progress/kendo-react-labels";
import MatchKeys from "./MatchKeys";
import "./ColumnMapping.css";
import {OnBoardingContext} from "../../Pages/OnBoarding";

const ColumnMapping = (props) => {
    const widjetProps = useContext(OnBoardingContext);
    const [multipleTypesMsg, setMultipleTypesMsg] = useState("Multiple match keys qualify - please confirm selection.");
    const [data, setData] = useState({});
    const [savedDetails, setSavedDetails] = useState({});
    const [showTemplateExample, setShowTemplateExample] = useState(false);
    const [columnMapTypes, setColumnMapTypes] = useState([]);
    const [invalidColumnType, setInvalidColumnType] = useState([false, false, false, false, false]);
    const [pageLoader, setPageLoader] = useState(false);
    const [currentFileType, setCurrentFileType] = useState(null);
    const [currentHeaderRowCount, setCurrentHeaderRowCount] = useState(
        {text: '1', val: "1"}
    );
    const [currentDelimiter, setCurrentDelimiter] = useState(
        {text: 'Comma', val: "COMMA"}
    );
    const [currentTimeZone, setCurrentTimeZone] = useState(
        {text: '', val: ""}
    );
    const [currentDateFormat, setCurrentDateFormat] = useState(
        {text: 'Month - Day - Year', val: "mdy"}
    );

    const [disableSave, setDisableSave] = useState(false);

    const templateExamplePopupOffset = {
        left: 1050,
        top: 380,
    };

    useEffect(() => {
        setCurrentTimeZone({text: "UTC", val: "UTC"});
    }, []);

    // useEffect(() => {
    //     if (props.savedConfig && props.isOpenModel) {
    //         setSavedDetails({});
    //     }
    // }, [props.savedConfig, props.isOpenModel]);

    useEffect(() => {
        if (props.modelData.id && props.isOpenModel) {
            // mappingFileTypes = fileTypesFromColumnMap(props.modelData.data.column_map);
            // if (mappingFileTypes) mappingFileTypes  = mappingFileTypes.sort((a, b) => {
            //     const typeA = a.text.toLowerCase();
            //     const typeB = b.text.toLowerCase();
            //     if (typeA < typeB) return -1;
            //     if (typeA > typeB) return 1;
            //     return 0;
            // });
            // setFileTypes(mappingFileTypes);
            if (savedDetails.file_type === props.modelData.data.file_type ) {
                setData({...props.modelData.data, ...savedDetails});

            } else if (!props.fileChanged && savedDetails.file_type !== props.modelData.data.file_type ){
                setData({...props.modelData.data, ...savedDetails});

            } else {
                setData({...props.modelData.data});
            }
            // setData({...props.modelData.data, ...savedDetails});
            let result = fileTypes.find(fileType => fileType.val === props.modelData.data.file_type);
            // let result = mappingFileTypes.find(fileType => fileType.val === props.modelData.data.file_type);
            if(currentFileType && currentFileType.text === props.modelData.data.file_type){
                setCurrentFileType({
                    ...currentFileType,
                    text: currentFileType.text,
                    val: currentFileType.val
                });
            }else if(currentFileType && currentFileType.text !== props.modelData.data.file_type && !props.fileChanged){
                setCurrentFileType({
                    ...currentFileType,
                    text: currentFileType.text,
                    val: currentFileType.val
                });
            }else{
                setCurrentFileType({
                    ...currentFileType,
                    text: result.text,
                    val: result.val
                });
            }

            if (!props.modelData.data.delimiter) {
                setCurrentDelimiter({
                    ...currentDelimiter,
                    ['text']: "None",
                    ['val']: "None"
                });
            } else {
                result = delimiters.find(delimiter => delimiter.val === props.modelData.data.delimiter);
                setCurrentDelimiter({
                    ...currentDelimiter,
                    ['text']: result.text,
                    ['val']: result.val
                });
            }
            const format = dateFormats.find(obj => obj.val === props.modelData.data.date_format);
            if (format === null || format === undefined) {
                setCurrentDateFormat({
                    ...currentDateFormat,
                    ['text']: "Date Format",
                    ['val']: "Date Format"
                });
            } else {
                setCurrentDateFormat({
                    ...currentDateFormat,
                    ['text']: format.text,
                    ['val']: format.val
                });
            }
        }
    }, [props.isOpenModel, props.modelData]);

    useEffect(() => {
        if (data.file_type) {
            const mapType = {...mappingTypes(data.file_type)};
            // console.log(mapType);
            let arrayMapType = [];
            let errorCnt = 0;
            let idx = -1;
            Object.keys(mapType).forEach((type) => {
                idx++;
                mapType[type]["columnIndex"] = props.modelData.data["column_map"][type] ?? "";
                mapType[type]["value"] = type;
                arrayMapType.push(mapType[type]);
                if (mapType[type]["columnIndex"] === "" && mapType[type]["optional"] === false) {
                    errorCnt++;
                    invalidColumnType[idx] = true;
                    setInvalidColumnType(invalidColumnType);
                }  else {
                    invalidColumnType[idx] = false;
                    setInvalidColumnType(invalidColumnType);
                }
            });
            if (errorCnt > 0) {
                setDisableSave(true);
            } else {
                setDisableSave(false);
            }
            // console.log(arrayMapType);
            setColumnMapTypes(arrayMapType);
            if (data.columnValues.length && Array.isArray(data.columnValues[0]) && data.columnNames.length < data.columnValues[0].length) {
                data.columnNames = data.columnValues[0].slice();
            }
        }
    }, [data.file_type]);

    useEffect(() => {
        // console.log(props.modelData?.data?.file_type);
        setCurrentHeaderRowCount({text: data.header_row_count, val: data.header_row_count});
    }, [data.columnValues && data.columnValues.length > 0]);
    useEffect(()=>{
        // console.log(columnMapTypes);
    },[columnMapTypes])

    // let mappingFileTypes = [];

    const toggleShowTemplateExample = () => {
        setShowTemplateExample(!showTemplateExample);
    };

    const fileTypes = [
        {text: "Postal Address", val: "ADDRESS"},
        {text: "H* Address", val: "HUMANA_ADDRESS"},
        {text: "Company Name and Address", val: "COMPANY_NAME_ADDRESS"},
        {text: "Email", val: "EMAIL"},
        {text: "Sha256 Email", val: "SHA256_EMAIL"},
        {text: "Sha1 Email", val: "SHA1_EMAIL"},
        {text: "Md5 Email", val: "MD5_EMAIL"},
        {text: "Xandr ID", val: "XANDR_ID"},
        {text: "IP and Timestamp", val: "IMPRESSIONS"},
        {text: "Device ID", val: "DEVICE_ID"},
        {text: "NPI ID", val: "NPI_ID"},
        {text: "Phone Number", val: "US_PHONE"},
        {text: "Domain Name", val: "DOMAIN_NAME"},
        {text: "Company Name and Naics Code", val: "COMPANY_NAME"},
        {text: "Website_Traffic", val: "Web Log"},
        {text: "5 Digit Zip Codes", val: "ZIP5"},
        {text: "9 Digit Zip Codes", val: "ZIP9"},
        {text: "11 Digit Zip Codes", val: "ZIP11"},
        {text: "3 Digit DMA Code", val: "DMA_ID"},
        {text: "DataTrust ID", val: "DATATRUST_ID"},
        {text: "FreeWheel ID", val: "FREEWHEEL_ID"},
        {text: "Lat/Lng", val: "LAT_LONG"},
        {text: "Purple Md5 ID", val: "PURPLE_ID"},
        // {text: "Voter ID", val: "VOTER_ID"},
        {text: "L2Data Voter ID", val: "L2DATA_VOTER_ID"},
        // {text: "Healthwise ID", val: "HEALTHWISE_ID"},
        // {text: "i360 ID", val: "I360_ID"},
        {text: "MRI ID", val: "MRI_ID"},
        {text: "PDI ID", val: "PDI_ID"},
        {text: "Pubmatic ID", val: "PUBMATIC_ID"},
        {text: "Acxiom ID", val: "ACXIOM_ID"},
        {text: "Semcasting ID", val: "PERSISTENT_ID"},
        // {text: "Audience Acuity ID", val: "AUDIENCE_ACUITY_ID"},
        {text: "Internet Keyword", val: "KEYWORD"},
        {text: "Internet Site", val: "SITES"},
        {text: "Sha256 Semcasting ID", val: "SHA256_PERSISTENT_ID"},
        {text: "Blockgraph ID", val: "BLOCKGRAPH_ID"},
        // {text: "Adstra ID", val: "ADSTRA_ID"},
        {text: "First Name and Last Name and Zip5", val: "FIRST_LAST_ZIP5"},
        {text: "DataAxle ID", val: "DATA_AXLE_ID"},
        {text: "Person Key", val: "PERSON_KEY"},

    ].sort((a, b) => {
        const typeA = a.text.toLowerCase();
        const typeB = b.text.toLowerCase();
        if (typeA < typeB) return -1;
        if (typeA > typeB) return 1;
        return 0;
    });

    if (widjetProps.matchKeys.length === 0) widjetProps.setMatchKeys(fileTypes);

    const onFileTypeChange = (event) => {
        setInvalidColumnType([false, false, false, false, false]);
        setCurrentFileType({
            ...currentFileType,
            text: event.value.text,
            val: event.value.val
        });
        // setData({
        //     ...data,
        //     ['file_type']: event.value.val,
        // })
        handleMetaData(event.value.val, "file_type");
    };

    const headerRowCount = [
        {text: "0", val: "0"},
        {text: "1", val: "1"},
        {text: "2", val: "2"},
        {text: "3", val: "3"},
        {text: "4", val: "4"},
        {text: "5", val: "5"}
    ];

    const onHeaderRowCountChange = (event) => {
        setCurrentHeaderRowCount({
            ...currentHeaderRowCount,
            ['text']: event.value.text,
            ['val']: event.value.val
        });
        handleMetaData(parseInt(event.value.val), "header_row_count");
    };

    const delimiters = [
        {text: "Comma", val: "COMMA"},
        {text: "None", val: "None"},
        {text: "Pipe", val: "PIPE"},
        {text: "Tab", val: "TAB"},
    ];

    const onDelimiterChange = (event) => {
        setCurrentDelimiter({
            ...currentDelimiter,
            ['text']: event.value.text,
            ['val']: event.value.val
        });
        handleMetaData(event.value.val, "delimiter");
    };

    const timeZones = [
        {text: "UTC", val: "UTC"},
        {text: "US/Eastern", val: "US/Eastern"},
        {text: "US/Central", val: "US/Central"},
        {text: "US/Mountain", val: "US/Mountain"},
        {text: "US/Pacific", val: "US/Pacific"}
    ];

    const onTimeZoneChange = (event) => {
        setCurrentTimeZone({
            ...currentTimeZone,
            ['text']: event.value.text,
            ['val']: event.value.val
        });
    };

    const dateFormats = [
        {text: "Date Format", val: "Date Format"},
        {text: "Month - Day - Year", val: "mdy"},
        {text: "Day - Month - Year", val: "dmy"},
        {text: "Year - Month - Day", val: "ymd"},
        {text: "Seconds", val: "seconds"},
        {text: "Milliseconds", val: "milliseconds"}
    ];

    const onDateFormatChange = (event) => {
        setCurrentDateFormat({
            ...currentDateFormat,
            ['text']: event.value.text,
            ['val']: event.value.val
        });
    };

    const handleTypeIndex = (event, index) => {
        if (event.target.value === '' && !columnMapTypes[index].optional) {
            invalidColumnType[index] = true;
            setInvalidColumnType(invalidColumnType);
            setDisableSave(true);
        } else {
            invalidColumnType[index] = false;
            setInvalidColumnType(invalidColumnType);
            setDisableSave(false);
        }
        const copyTypes = [...columnMapTypes];
        // copyTypes[index]["columnIndex"] = data.columnNames.findIndex(colType => colType === event.target.value);
        copyTypes[index]["columnIndex"] = parseInt(event.target.value);

        setColumnMapTypes(copyTypes);
    };
    // const handleTypeIndex = (event, index) => {
    //     const copyTypes = [...columnMapTypes];
    //     copyTypes[index]["columnIndex"] = parseInt(event.target.value);
    //     setColumnMapTypes(copyTypes);
    // };
    const getColumnMap = () => {
        const columnMap = {};
        columnMapTypes.forEach((item) => {
            if (item.columnIndex !== "") {
                columnMap[item.value] = item.columnIndex;
            }
        });
        return columnMap;
    };

    const getColumnMapValues = (data) => {
        const columnMapValues = [];
        data.data.columnNames.forEach((item) => {
            let columnItem = {};
            columnItem.text = item;
            columnItem.val = item;columnMapValues.push(columnItem);
        })
        return columnMapValues;
    };

    const handleMetaData = (value, type) => {
        data[type] = value;
        const updatedValues = {
            column_map: getColumnMap(),
            file_type: data.file_type,
            date_format: data.date_format,
            delimiter: data.delimiter,
            header_row_count: data.header_row_count,
            time_zone: data.time_zone,
            [type]: value,
        };
        setCurrentTimeZone({
            ...currentTimeZone,
            ['text']: data.time_zone,
            ['val']: data.time_zone
        });
        handleAudienceFile(updatedValues, true);
    };

    const handleModelActions = (isFromSaving) => {
        if (isFromSaving) {
            if (validateColumnMapTypes()) {
                return;
            }
            const updatedValues = {
                column_map: getColumnMap(),
                file_type: data.file_type,
                date_format: data.date_format,
                delimiter: data.delimiter,
                header_row_count: data.header_row_count,
                time_zone: data.time_zone,
            };
            setSavedDetails(updatedValues);
            handleAudienceFile(updatedValues, false);
        } else {
            setData({});
            props.handleClose({isOpen: false, data});
        }
        setShowTemplateExample(false);
    };

    const validateColumnMapTypes = () => {
        if (!columnMapTypes) return;
        let hasErrors = false;
        let columnMapTypesValidity = [false, false, false, false, false]
        columnMapTypes.map((item, index) => {
            if ((item.columnIndex === "" || isNaN(item.columnIndex)) && !item.optional) {
                columnMapTypesValidity[index] = true;
                hasErrors = true;
            } else {
                columnMapTypesValidity[index] = false;
            }
        });
        setInvalidColumnType(columnMapTypesValidity);
        return hasErrors;
    };

    const handleAudienceFile = (overrideObject, test = false) => {
        setPageLoader(true);
        const {
            column_map,
            actions,
            date_format,
            delimiter,
            file_type,
            ftp_settings_id,
            header_row_count,
            id,
            job_id,
            path,
            time_zone,
        } = data;
        // const payload = {
        //     actions,
        //     column_map,
        //     date_format,
        //     delimiter,
        //     file_type,
        //     ftp_settings_id,
        //     header_row_count,
        //     id,
        //     job_id,
        //     path,
        //     time_zone,
        //     shapes: null,
        //     use_time: 0,
        //     ...overrideObject,
        // };
        let payload = {};
        if (!test) {
            payload = {
                file_type:overrideObject.file_type,
                delimiter:overrideObject.delimiter,
                header_row_count:overrideObject.header_row_count,
                time_zone:overrideObject.time_zone,
                column_map:overrideObject.column_map,
                date_format:overrideObject.date_format
            }
        } else {
            payload = {
                file_type,
                delimiter,
                header_row_count,
                time_zone,
                column_map,
                date_format
            };
        }

        patchAudienceFile(payload, id, test).then((res) => {
            if (test) {
                res.data.columnValues = res.data.columnValues.splice(0, 5);
                setData(res.data);
            } else {
                setData({});
                props.handleClose({isOpen: false, data: {...res, id}});
            }
            setPageLoader(false);
        });
    };

    const renderOptions = (index) => {
        let idx = -1;
        let columnsList = "";
        if (data.header_row_count === 0) {
            columnsList = (data.columnValues && data.columnValues.length > 0) &&
                data.columnValues.map((row) => {
                    row.map(columnValue => {
                        return (
                            <>
                                <option key={columnValue} value={columnValue}>
                                    {columnValue}
                                </option>
                            </>
                        )
                    });

                    // idx++;
                    // return (
                    //     <>
                    //         <option key={columnValue} value={columnValue} selected = {idx === 0 ? true : false}>
                    //             {columnValue}
                    //         </option>
                    //     </>
                    // )
                })
        } else {

            columnsList = data.columnNames && data.columnNames.length > 0 &&
                data.columnNames.map((columnName) => {
                    idx++;
                    if (idx === index) {
                        return (
                            <>
                                <option key={columnName} value={columnName} selected>
                                    {data.columnNames[idx]}
                                </option>
                            </>
                        )
                    } else {
                        return (
                            <>
                                <option key={columnName} value={columnName}>
                                    {data.columnNames[idx]}
                                </option>
                            </>
                        )
                    }
                });
        }

        return columnsList;
    };

    // const buildOptions = (index) => {
    //     let idx = -1;
    //     let columnsList = data.columnNames.length > 0 &&
    //         data.columnNames.map((columnName) => {
    //             idx++;
    //             if (idx === index) {
    //                 return (
    //                     <>
    //                         <option key={columnName} value={columnName} selected>
    //                             {data.columnNames[idx]}
    //                         </option>
    //                     </>
    //                 )
    //             } else {
    //                 return (
    //                     <>
    //                         <option key={columnName} value={columnName}>
    //                             {data.columnNames[idx]}
    //                         </option>
    //                     </>
    //                 )
    //             }
    //         });
    //     };
    // }

    const templateLayoutExample = () => {
        return (
            <>
                {showTemplateExample && (
                    <MatchKeys
                        isOpenModel={showTemplateExample}
                        viewMode={true}
                        handleClose={() => toggleShowTemplateExample()}
                    />
                )}
            </>
        )
    };

    return (
        <>
            <div id="columnMapping" className={"column-mapping-container"}>
                {props.visible && (<Dialog width={"80%"} height={"80%"} title={"Column Mapping"} className={"column-mapping-dialog"} closeIcon={false}>
                    <div className={"column-mapping-container"}>
                        <div className={"col-sm-6"}>
                            <h4>Ensure Your Data Has Been Correctly Identified</h4>
                            <p className={"column-mapping-instructions"}>Use the drop downs to change the data in each
                                column.<br/>
                                If you can't get the data to match, try formatting it like one of these templates:</p>
                        </div>
                        {data.file_type === "IMPRESSIONS" || data.file_type === "WEB_LOG" ? (
                                <div className={"col-sm-6 time-dropdowns"}>
                                    <div className={"column-mapping-time-zone-control-div"}>
                                    <Label className="column-mapping-instructions" style={{marginRight: "1rem"}}
                                           for="tzones">The Times in this file are in what Time Zone?</Label>
                                    <DropDownList
                                        id="tzones"
                                        className={"column-mapping-time-zone-date-format-controls"}
                                        data={timeZones}
                                        value={currentTimeZone}
                                        textField="text"
                                        dataItemKey="val"
                                        onChange={(e) => onTimeZoneChange(e)}
                                        fillMode={"outline"}
                                    />
                                </div>
                                <div className={"column-mapping-date-format-control-div"}>
                                    <Label className="column-mapping-instructions" style={{marginRight: "1rem"}}
                                           for="dformats">What format is the date in?</Label>
                                    <DropDownList
                                        id="dformats"
                                        className={"column-mapping-time-zone-date-format-controls"}
                                        data={dateFormats}
                                        value={currentDateFormat}
                                        textField="text"
                                        dataItemKey="val"
                                        onChange={(e) => onDateFormatChange(e)}
                                        fillMode={"outline"}
                                    />
                                </div>
                            </div>
                        )
                        : ""}
                    </div>
                    <a href={"#"} className={"column-mapping-file-type-examples"} onClick={toggleShowTemplateExample}>
                        {showTemplateExample ? "Close" : "View"} Onboarding Match Key Template Examples
                    </a>
                    {templateLayoutExample()}
                    <Card >
                        <GridLayout className={"column-mapping-control-section"}>
                            <GridLayoutItem row={1} col={1}>
                                <div className={"column-mapping-file-type-control"}>
                                    <div>
                                        <Label className={"field-label column-mapping-control-section-text-left"}
                                               for="ftypes">
                                            Match Key
                                        </Label>
                                    </div>
                                    <DropDownList
                                        id="ftypes"
                                        data={fileTypes}
                                        value={currentFileType}
                                        textField="text"
                                        dataItemKey="val"
                                        onChange={(e) => onFileTypeChange(e)}
                                        fillMode={"outline"}
                                        className="dropdown-standard column-mapping-dropdown"
                                    />
                                </div>
                            </GridLayoutItem>
                            <GridLayoutItem row={1} col={2}>
                                <div className={"column-mapping-header-rows-control"}>
                                    <div>
                                        <Label className={"field-label column-mapping-control-section-text"} style={{marginRight: "1rem"}}
                                               for="hrows">Header Rows</Label>
                                    </div>
                                    <DropDownList
                                        id="hrows"
                                        data={headerRowCount}
                                        value={currentHeaderRowCount}
                                        textField="text"
                                        dataItemKey="val"
                                        onChange={(e) => onHeaderRowCountChange(e)}
                                        fillMode={"outline"}
                                        className="dropdown-standard"
                                    />
                                </div>
                            </GridLayoutItem>
                            <GridLayoutItem row={1} col={3}>
                                <div className={"column-mapping-delimiter-control"}>
                                    <div>
                                        <Label className={"field-label column-mapping-control-section-text"} style={{marginRight: "1rem"}}
                                               for="hrows">Delimiter</Label>
                                    </div>
                                    <DropDownList
                                        id="hrows"
                                        data={delimiters}
                                        value={currentDelimiter}
                                        textField="text"
                                        dataItemKey="val"
                                        onChange={(e) => onDelimiterChange(e)}
                                        fillMode={"outline"}
                                        className="dropdown-standard"
                                    />
                                </div>
                            </GridLayoutItem>
                        </GridLayout>
                    </Card>

                    {(fileTypes.length > 1) && <Label className={"column-mapping-multiple-types-msg"}>{multipleTypesMsg}</Label>}
                    <Card style={{marginTop:"2rem"}}>
                        <GridLayout className={"column-mapping-columns-area"}>
                            {columnMapTypes.map((item, index) => (
                                <>
                                <GridLayoutItem row={1} col={index+1}>
                                    <div className={"column-mapping-columns-area"}>
                                        {(!invalidColumnType[index]) && <Label for={index+1} className="field-label">{item.name} </Label>}
                                        {(invalidColumnType[index]) && <Label for={index+1} className="field-label" style={{color:"red", float:"left"}}>{item.name} Required</Label>}
                                        {(!item.optional) && <span className="text-danger">*</span>}
                                    </div>
                                </GridLayoutItem>
                                <GridLayoutItem row={2} col={index+1}>
                                    <select
                                        id={index+1}
                                        onChange={(event) => handleTypeIndex(event, index)}
                                        required={!item.optional}
                                        className={invalidColumnType[index] ? "dropdown-standard column-mapping-column-error" : "dropdown-standard"}
                                        // className={!item.optional && item.columnIndex === "" ? "dropdown-standard column-mapping-column-error" : "dropdown-standard"}
                                        style={{marginTop:"1.2rem", marginLeft:".5rem"}}
                                    >
                                        <option value="" data-content="<em>Not Present</em>">
                                            Not Present
                                        </option>
                                        {(data.columnNames || []).map((keyName, value) => {
                                            if (keyName !== null && keyName !== "") {
                                                return (
                                                    <option key={value} value={value}
                                                            selected={value === item.columnIndex ? "selected" : false}>
                                                        {keyName}
                                                    </option>
                                                )
                                            } else {
                                                return (
                                                    <option key={value} value={value}
                                                            selected={value === item.columnIndex ? "selected" : false}>
                                                        Column {value+1}
                                                    </option>
                                                )
                                            }
                                        })}
                                        {/*{renderOptions(index)}*/}
                                    </select>
                                    {/*<select*/}
                                    {/*    id={index+1}*/}
                                    {/*    value={item.columnIndex}*/}
                                    {/*    onChange={(event) => handleTypeIndex(event, index)}*/}
                                    {/*    required={!item.optional}*/}
                                    {/*    className="dropdown-standard"*/}
                                    {/*    style={{marginTop:"1.2rem", marginLeft:".5rem"}}*/}
                                    {/*>*/}
                                    {/*    <option value="" data-content="<em>Not Present</em>">*/}
                                    {/*        Not Present*/}
                                    {/*    </option>*/}
                                    {/*    {(data.columnNames || []).map((keyName, value) => (*/}
                                    {/*        <option key={value} value={value}>*/}
                                    {/*            {keyName}*/}
                                    {/*        </option>*/}
                                    {/*    ))}*/}
                                    {/*</select>*/}
                                </GridLayoutItem>
                            </>)) }
                            {/*<table>*/}
                            {/*<tbody>*/}
                            {/*{(data.columnValues || []).map((innerArray, index) => (*/}
                            {/*    <tr key={index}>*/}
                            {/*        {(columnMapTypes || []).map((item, itemIndex) => (*/}
                            {/*            <td key={itemIndex}>{innerArray[item.columnIndex]}</td>*/}
                            {/*        ))}*/}
                            {/*    </tr>*/}
                            {/*))}*/}
                            {/*</tbody>*/}
                            {/*</table>*/}
                            {/*{(data.column_map && Object.keys(data.column_map).length > 0 && data.columnValues || []).map((innerArray, index) => (*/}
                            {(data.columnValues || []).map((innerArray, index) => (
                            <>
                                {(columnMapTypes || []).map((item, itemIndex) => (
                                    <>
                                        <GridLayoutItem row={2+index+1} col={itemIndex+1} style={{marginTop:"1.2rem"}}>
                                            <Label style={{marginLeft:".5rem"}}>{innerArray[item.columnIndex]}</Label>
                                            {/*<Label style={{marginLeft:".5rem"}}>{innerArray[itemIndex]}</Label>*/}
                                        </GridLayoutItem>
                                    </>
                                )) }
                                </>
                            )) }
                        </GridLayout>
                    </Card>

                    <DialogActionsBar layout={"end"}>
                        <Button
                            className="dialog-cancel"
                            size="large"
                            onClick={() => {
                                handleModelActions(false)
                            }}>
                            Cancel
                        </Button>
                        <Button
                            className="dialog-save"
                            size="large"
                            disabled={disableSave}
                            onClick={() => {
                                handleModelActions(true)
                            }}>
                            Save Changes
                        </Button>
                    </DialogActionsBar>
                </Dialog>)}
            </div>
        </>
    );
}

export default ColumnMapping;
