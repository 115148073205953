import React, { useState, useEffect, useMemo, createContext } from "react";
import MyComputer from "../Components/Onboarding/MyComputer";
import FTPSS3 from "../Components/Onboarding/FTPSS3";
import KeywordsSites from "../Components/Onboarding/KeywordsSites";
import MapArea from "../Components/Onboarding/MapArea";
import {useFormik} from "formik";
import $ from 'jquery';

import {LoadScript} from "@react-google-maps/api";
import {GOOGLE_MAPS_API_KEY} from "../../api/constants";

import ColumnMapping from "../Components/Onboarding/ColumnMapping";
import MatchKeys from "../Components/Onboarding/MatchKeys";
import { postFromFile } from "../../actions/OnBoardingActions";
import AboutAudience from "../Components/Onboarding/AboutAudience";
import Targeting from "../Components/Onboarding/Targeting";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import {Card, CardTitle, CardBody} from "@progress/kendo-react-layout";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {Label} from "@progress/kendo-react-labels";
import "./OnBoarding.css"
import * as Yup from "yup";
import {showErrorToastMessage} from "../../Utils/Common";
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

import TargetingFull from "../Components/Onboarding/TargetingFull";
import UsersPage from "../Components/Permission/UsersPage";

export const OnBoardingContext = createContext();

const OnBoarding = () => {

    const mapKey = GOOGLE_MAPS_API_KEY;
    const lib = ["drawing","marker"];

    const destinationUrl = "segmentation-dashboard";

    const [source, setSource] = useState("My Computer");

    const [disableSubmit, setDisableSubmit] = useState(false);

    const sources = [
        {name: "My Computer", icon: 'icon-computer'},
        {name: "FTP or S3 Server", icon: "icon-upload-sam"},
        {name: "Map Area", icon: "icon-map-sam"}
    ]
    const [pageLoader, setPageLoader] = useState(false);
    const [activeTab, setActiveTab] = useState("computer");
    const [savedConfig, setClearSavedConfig] = useState(false);
    const [columnMappingModelData, setColumnMappingModelData] = useState({
        isOpen: false,
        fileChanged: null,
        data: {},
    });
    const [isOpenUnkownFileTypeModel, setIsOpenUnkownFileTypeModel] =
        useState(false);
    const [mapAreaFormik, setMapAreaFormik] = useState({});
    const [aboutAudienceFormik, setAboutAudienceFormik] = useState({});
    const [selectedTargetType, setSelectedTargetType] = useState("");
    const segmentNameRegExp = RegExp(/^[a-zA-Z0-9_\- ]{3,64}$/);

    const [matchKeys, setMatchKeys] = useState([]);
    const [selected, setSelected] = React.useState(0);
    const handleSelect = e => {
        // sessionStorage.setItem('onboardingTabSelected', e.selected);

        setSelected(e.selected);

        setSource(sources[e.selected].name);
    };

    const handleDisableSubmit = (disable)  => {
        setDisableSubmit(disable);
    }

    const onBoardingFormik = useFormik({
        initialValues: {
            audience_file_id: 0,
            target_type: "CONSUMER",
            segment_name: "",
            generate_nomatch_connection_id: 0,
            generate_pdf_reports: true,
            generate_top_sites: false,
            group_id: 0,
            notes: null,
            sites: null,
            keywords: null,
            segment_design: {
                merge_type: "DISTINCT",
                primaryData: {
                    age_range: [],
                    digital_activity_index: [],
                    gender: [],
                    home_value_cd: [],
                    homeowner_flg: [],
                    income_cd: [],
                    inflation_sensitivity_index: [],
                    reg_party_cd: [],
                    sem_ethnic_cd: [],
                    sem_ethnic_region_cd: [],
                    districts: [],
                    employees_total_cd: [],
                    naics: [],
                    revenue_cd: []
                },
                socialMatrix: [],
                target_type: "CONSUMER"
            }
        },
        validationSchema: Yup.object().shape({
            segment_name: Yup.string()
                .required("Segment Name is required")
                .matches(segmentNameRegExp, "Name must be at 3-64 characters and only contains letters, numbers, hyphens and underscores")
            // audience_file_id: Yup.number().positive().integer()
        }),
        onSubmit: (values) => {
            //console.log(values);
        },
    })

    const handleColumnMappingModel = (isOpen = false, fileChanged, data = {}) => {
        if (data?.data?.columnValues?.length > 5) {
            data?.data?.columnValues?.splice(5);
        }
        setColumnMappingModelData({isOpen, fileChanged, data});
    };

    const handleUnkownFileTypeModel = (isOpen = false) => {
        setIsOpenUnkownFileTypeModel(isOpen);
    };

    const handleFileUploadErrors = (msg) => {
        showErrorToastMessage(msg);
    }

    const handleSubmit = async(e, formik) => {
        try {
            setDisableSubmit(true);
            await formik.submitForm();
            //formik.isValid was returning true when should be false
            //maybe use setTimeout to wait 3 sec - aka ActivateSegmentForm.js
            if (formik.values.segment_name === "") {
                throw("Segment name cannot be empty");
                return;
            }
            if (formik.isValid) {
                console.log(source);
                if (source === sources[0].name || source === sources[1].name) {
                    handleFromComputerOrFTPS3Submit(e);
                } else if (source === "Map Area") {
                    handleMapAreaSubmit(e);
                }
            } else {
                // showErrorToastMessage("foobar");
            }
        } catch(error) {
            setDisableSubmit(false);
        }
    }

    const handleFromComputerOrFTPS3Submit = (e) => {
        try {
            if (!columnMappingModelData.data.id) {
                showErrorToastMessage("A file must be chosen.  Request not sent.");
                throw("A file must be chosen.  Request not sent.");
            }
            setPageLoader(true);
            const {data: file} = columnMappingModelData;
            postFromFile({
                audience_file_id: file.id || file.data.id,
                generate_nomatch_connection_id: onBoardingFormik.values.generate_nomatch_connection_id,
                generate_pdf_reports: onBoardingFormik.values.generate_pdf_reports,
                generate_top_sites: onBoardingFormik.values.generate_top_sites,
                group_id: onBoardingFormik.values.group_id,
                notes: onBoardingFormik.values.notes,
                segment_design: {
                    merge_type: "DISTINCT",
                    target_type: onBoardingFormik.values.target_type,
                },
                segment_name: onBoardingFormik.values.segment_name,
                target_type: onBoardingFormik.values.target_type
            }).then((res) => {
                setPageLoader(false);
                if (res && res.status === "success") {
                    setTimeout(() => {
                        window.location.href = destinationUrl;
                    }, 3000);
                } else {
                    setDisableSubmit(false);
                }
            });
        } catch(error) {
            // Re-enable button if failure.
            showErrorToastMessage("Error sending request. Request not sent.");
            throw(error);
        }
    };

    const handleMapAreaSubmit = (e) => {
        try {
            setPageLoader(true);
            const data = getData();
            if (data.audience_file_id === 0) {
                showErrorToastMessage("A region on the map must be selected.  Request not sent.");
                throw("A region on the map must be selected.  Request not sent.")
            }
            postFromFile(
                data
            ).then((res) => {
                setPageLoader(false);
                if (res && res.status === "success") {
                    setTimeout(() => {
                        window.location.href = destinationUrl;
                    }, 3000);
                }
            });
        } catch (error) {
            // Re-enable button if failure.
            showErrorToastMessage("Error sending request. Request not sent.");
            throw(error);
        }
    };

    const getData = () => {
        const audienceFileId = onBoardingFormik.values.audience_file_id;
        const generateNomatchConnectionId = onBoardingFormik.values.generate_nomatch_connection_id;
        const generatePDFReports = onBoardingFormik.values.generate_pdf_reports;
        const generateTopSites = onBoardingFormik.values.generate_top_sites;
        const segmentName = onBoardingFormik.values.segment_name;
        const notes = onBoardingFormik.values.notes;
        const keywords = onBoardingFormik.values.keywords;
        const sites = onBoardingFormik.values.sites;
        const targetType = onBoardingFormik.values.target_type;
        const groupId = onBoardingFormik.values.group_id;
        const segmentDesign = buildSegmentDesign();
        return {
            segment_name: segmentName,
            notes: notes,
            keywords: keywords,
            sites: sites,
            group_id:groupId,
            audience_file_id: audienceFileId,
            target_type: targetType,
            segment_design:segmentDesign,
            generate_nomatch_connection_id: generateNomatchConnectionId,
            generate_pdf_reports: generatePDFReports,
            generate_top_sites: generateTopSites
        }
    };

    const buildSegmentDesign = () => {
        const mergeType = onBoardingFormik.values.segment_design.merge_type;
        const targetType = onBoardingFormik.values.segment_design.target_type;
        const primaryData = buildPrimaryData(targetType);
        const homeOwnerData = targetType === "CONSUMER" ? onBoardingFormik.values.segment_design.homeOwner : [];
        const socialMatrixData = targetType === "CONSUMER" ? onBoardingFormik.values.segment_design.socialMatrix : [];

        let fileData = {
            merge_type: mergeType,
            target_type: targetType,
            primaryData: primaryData,
            homeOwner: homeOwnerData,
            socialMatrix: socialMatrixData
        }
        return stripEmptyValuesFromObject(fileData);
    };

    const buildPrimaryData = (targetType) => {
        if (targetType === "BUSINESS") {
            return buildBusinessSegments();
        } else {
            return buildPeopleSegments();
        }
    };

    const buildPeopleSegments = () => {
        return {
            age_range: onBoardingFormik.values.segment_design.primaryData.age_range,
            digital_activity_index: onBoardingFormik.values.segment_design.primaryData.digital_activity_index,
            gender: onBoardingFormik.values.segment_design.primaryData.gender,
            home_value_cd: onBoardingFormik.values.segment_design.primaryData.home_value_cd,
            homeowner_flg: onBoardingFormik.values.segment_design.primaryData.homeowner_flg,
            income_cd: onBoardingFormik.values.segment_design.primaryData.income_cd,
            inflation_sensitivity_index: onBoardingFormik.values.segment_design.primaryData.inflation_sensitivity_index,
            reg_party_cd: onBoardingFormik.values.segment_design.primaryData.reg_party_cd,
            sem_ethnic_cd: onBoardingFormik.values.segment_design.primaryData.sem_ethnic_cd,
            sem_ethnic_region_cd: onBoardingFormik.values.segment_design.primaryData.sem_ethnic_region_cd,
            districts: onBoardingFormik.values.segment_design.primaryData.districts
        }
    };

    const buildBusinessSegments = () => {
        return {
            revenue_cd: onBoardingFormik.values.segment_design.primaryData.revenue_cd,
            employees_total_cd: onBoardingFormik.values.segment_design.primaryData.employees_total_cd,
            naics: onBoardingFormik.values.segment_design.primaryData.naics
        }
    }

    const handleColumnMappingClose = (res) => {
        setColumnMappingModelData(res);
    };

    const clearSavedConfig = () => {
        setClearSavedConfig(true);
        setColumnMappingModelData({isOpen: false, fileChanged: null, data: {}});
    };

    const widjetProps = {
        handleColumnMappingModel,
        handleUnkownFileTypeModel,
        handleFileUploadErrors,
        handleSubmit,
        clearSavedConfig,
        matchKeys,
        setMatchKeys
    };

    const handleActiveTab = (tabName) => {
        setActiveTab(tabName);
    };

    const handleClick = (name) => {
        setSource(name);
    };

    /**
     * removes: nulls, empty strings, empty objects, and empty arrays from obj
     * this is a recursive function if obj[propName] is an object this will
     * @param obj {Object} any object
     * @returns {Object} object with empty values removed
     */
    const stripEmptyValuesFromObject = (obj) => {
        for (let propName in obj) {
            if ($.isPlainObject(obj[propName])) {
                obj[propName] = stripEmptyValuesFromObject(obj[propName])
            }
            if (obj[propName] === null || obj[propName] === '' || $.isEmptyObject(obj[propName]) || ($.isArray(obj[propName]) && !obj[propName].length)) {
                delete obj[propName];
            }
        }
        return obj;
    }

    useEffect(() => {

        // let tabSelected = parseInt(sessionStorage.getItem('onboardingTabSelected'));
        //
        // if(typeof tabSelected === 'number' && tabSelected >= 0 && tabSelected <= 2) setSelected(tabSelected);
        // else setSelected(0);


        <LoadScript googleMapsApiKey={mapKey} libraries={lib}/>
    }, []);

    return (
        <div className={"onboarding-outer-div"}>
            <OnBoardingContext.Provider value={widjetProps}>
            <div className="onboarding-content">
                <Card>
                    <CardTitle>
                        <Label className="section-label">CREATE SEGMENT</Label>
                    </CardTitle>

                    <CardBody>
                        <legend className={'k-form-legend spacingLine'}></legend>
                        <TabStrip selected={selected} onSelect={handleSelect} tabPosition={"top"}>
                            <TabStripTab title="My Computer" contentClassName={"my-computer-tab"}>
                                <div className={"onboarding-my-computer"}>
                                    <MyComputer/>
                                </div>
                            </TabStripTab>

                            <TabStripTab title="FTP or S3" contentClassName={"ftp-s3-tab"}>
                                <div>
                                    <FTPSS3/>
                                </div>
                            </TabStripTab>

                            <TabStripTab title="Map Area" contentClassName={"map-area-tab"}>
                                <div>
                                    <MapArea
                                        formik={onBoardingFormik}
                                        handleDisableSubmit={handleDisableSubmit}
                                    />
                                </div>
                            </TabStripTab>
                        </TabStrip>
                    </CardBody>
                </Card>

                <div style={{paddingTop: "1rem"}}></div>

                    {(source === sources[0].name || source === sources[1].name) &&
                        <AboutAudience
                            formik={onBoardingFormik}
                            isOnboarding={true}
                        />
                    }

                    {(source === sources[0].name || source === sources[1].name) &&
                        <div className={"about-audience-targeting"}>
                        <Targeting
                            formik={onBoardingFormik}
                        />
                            </div>
                    }

                    {(source === sources[0].name || source === sources[1].name) &&
                        <div style={{paddingTop: "1rem"}}></div>
                    }

                    {source === sources[2].name &&
                        <div>
                            <div className="col-md-12 pt-1">
                                <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">
                                    <AboutAudience
                                        formik={onBoardingFormik}
                                        isOnboarding={true}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12 pt-3">
                                <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">
                                    <TargetingFull
                                        formik={onBoardingFormik}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12 pt-3">
                                <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">
                                    <KeywordsSites
                                        formik={onBoardingFormik}
                                    />
                                </div>
                            </div>
                        </div>
                    }

                <div style={{paddingTop: "0.5rem", float:"right"}}>
                    <Button
                        // onClick={handleSubmit(onBoardingFormik)}
                        disabled={disableSubmit}
                        onClick={(e) => handleSubmit(e, onBoardingFormik)}
                        className="submit-button">
                        Submit
                    </Button>
                </div>
            </div>

            <ColumnMapping
                isOpenModel={columnMappingModelData.isOpen}
                handleClose={handleColumnMappingClose}
                modelData={columnMappingModelData.data}
                savedConfig={savedConfig}
                visible={columnMappingModelData.isOpen}
                fileChanged={columnMappingModelData.fileChanged}
            />
            <MatchKeys
                isOpenModel={isOpenUnkownFileTypeModel}
                clearSavedConfig={clearSavedConfig}
                handleClose={() => handleUnkownFileTypeModel()}
            />
            </OnBoardingContext.Provider>
        </div>
    );
}

export default OnBoarding;
