import React, {useCallback, useEffect, useState, useContext} from "react";
import {Button, DropDownButton, DropDownButtonItem} from "@progress/kendo-react-buttons";
import LoadOverlay from "../Components/SharedComponents/LoadOverlay";
import {Input} from "@progress/kendo-react-inputs";
import {Label} from "@progress/kendo-react-labels";
import {semcastAxios, showErrorToastMessage, showSuccessToastMessage} from "../../Utils/Common";
import {UserContext} from "../../contexts/UserContext";
import {
    Grid,
    GridColumn as Column,
    GridToolbar
} from "@progress/kendo-react-grid";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {filterBy, orderBy} from "@progress/kendo-data-query";
import "./AdxTagManager.css";
import NewAdxTagDialog from "./NewAdxTagDialog";
import gearIcon from "../images/gearIcon.PNG";
import adsLogo from "../../components/assets/img/ads_logo.fec9a72f.png";
import {SegmentTopRightBlockActions} from "../../helpers/SegmentationHelpers";
import { useHistory } from 'react-router-dom';
import {
    GROUPS_ACCESSIBLE_PIXELS, EDIT_SEGMENT_ROUTE,
    TRACKING_PIXELS_API, TRACKING_PIXELS_DATA_API, TRANSFER_LOG_API
} from "../../api/constants";
import { ExcelExport } from '@progress/kendo-react-excel-export';


const initialFilter = {
    logic: "and",
    filters: [
        {
            field: "domain",
            operator: "contains",
            value: ""
        }
    ]
};

const initialSort = [
    {
        field: "domain",
        dir: "asc"
    }
];

function formatUnixTime(unixTime) {
    if(unixTime == null) return null;

    const date = new Date(unixTime);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear().toString().slice(-2);
    return `${month}/${day}/${year}`;
}

function getCurrentDateTime() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    const seconds = currentDate.getSeconds().toString().padStart(2, '0');

    const currentDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return currentDateTime;
}

const AdxTagManager = () => {

    const history = useHistory();
    const [overlayActive, setOverlayActive] = useState(false);
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState(initialFilter);
    const [sort, setSort] = useState(initialSort);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [groupsArr, setGroupsArr] = useState([]);
    const [showTagCode, setShowTagCode] = useState(false);
    const [pixelDomain, setPixelDomain] = useState("");
    const [pixelTagId, setPixelTagId] = useState("");
    const [deletePixelObj, setDeletePixelObj] = useState({});
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

    const {user, setUser} = React.useContext(UserContext);

    const _export = React.useRef(null);
    const exportExport = () => {
        if (_export.current !== null) {
            _export.current.save(orderBy(filterBy(data, filter), sort));
        }
    };

    const getTrackingPixels = async () => {
        let response = await semcastAxios.get(TRACKING_PIXELS_API, {withCredentials: true})
            .then((res) => {
            });
    }

    const getTrackingPixelsData = async () => {
        let response = await semcastAxios.get(TRACKING_PIXELS_DATA_API, {withCredentials: true})
            .then((res) => {
                let dataTemp = res.data.data;
                dataTemp.forEach((adxTag) => {
                    adxTag.created_at = formatUnixTime(adxTag.created_at);
                    adxTag.deleted_at = formatUnixTime(adxTag.deleted_at);
                    adxTag.consumer_segment_last_updated = formatUnixTime(adxTag.consumer_segment_last_updated);
                    adxTag.business_segment_last_updated = formatUnixTime(adxTag.business_segment_last_updated);
                })
                setData(dataTemp);
            });

    }

    const getTransferLog = async () => {
        let response = await semcastAxios.get(TRANSFER_LOG_API, {withCredentials: true})
            .then((res) => {
            });
    }

    const getAccessiblePixels = async () => {
        // let response = await semcastAxios.get("/v2/secure/attributor/group/accessiblepx")
        let response = await semcastAxios.get(GROUPS_ACCESSIBLE_PIXELS, {withCredentials: true})
            .then((res) => {
                setGroupsArr(res.data.data);
            })
    }

    const refreshTable = async () => {
        await Promise.allSettled([getAccessiblePixels(), getTrackingPixels(), getTrackingPixelsData(), getTransferLog()]);
    }

    useEffect(async () => {

        setOverlayActive(true);
        let groupNameFilterCell = document.querySelector('[title="domain-filter"]');
        groupNameFilterCell.placeholder = "Search..."

        await Promise.allSettled([refreshTable()]).then(() => {
            setOverlayActive(false);
        })

    }, []);


    const handleDropdownSelect = (e, dataItem) => {
        switch (e.item.text) {
            case "Analytics Pixel Code":
                setPixelDomain(dataItem.domain);
                setPixelTagId(dataItem.id);
                setShowTagCode(true);
                break;
            case "Delete Analytics Pixel":

                setDeletePixelObj(dataItem);
                setOpenConfirmDelete(true);

                break;
            case "Download Profile Reports":

                break;
            case "Edit Consumer Segment":

                // @ts-ignore
                // history.push({`${EDIT_SEGMENT_ROUTE}?id=${props.dataItem.id}&name=${props.dataItem.segment_name}&type=${props.dataItem.segment_design.target_type}`})

                history.push(`${EDIT_SEGMENT_ROUTE}?id=${dataItem.consumer_segment_id}&name=${dataItem.consumer_segment_name}`);

                break;
            case "Edit Business Segment":

                history.push(`${EDIT_SEGMENT_ROUTE}?id=${dataItem.business_segment_id}&name=${dataItem.business_segment_name}`);

                break;

            default:
                break;
        }
    }

    const MyEditCommandCell = (props) => {
        return (
            <td className={"adx-tag-gear-dropdown-div"} key={props.dataItem.id}>
                <DropDownButton
                    className={"btn btn-primary shadow btn-xs sharp adx-gear-button"}
                    id={"adxDropdownMenuButton" + props.dataItem.id}
                    textField={"text"}
                    icon="cog"
                    title={"ADX Tag Options"}
                    onItemClick={(e) => handleDropdownSelect(e, props.dataItem)}
                >
                    <DropDownButtonItem text="Analytics Pixel Code"/>
                    <DropDownButtonItem text="Delete Analytics Pixel"/>
                    <DropDownButtonItem text="Download Profile Reports"/>
                    <DropDownButtonItem text="Edit Consumer Segment"/>
                    <DropDownButtonItem text="Edit Business Segment"/>

                </DropDownButton>

            </td>
        )
    };

    const closePixelDialog = () => {
        setShowTagCode(false);
    }

    const deletePixel = async (pixelID, pixelDomain) => {

        let response = await semcastAxios.delete("/v2/secure/attributor/tracking-pixels/" + pixelID,
            {withCredentials: true})
            .then(async (res) => {

                if(res.data.code === 200) {
                    showSuccessToastMessage(`ADX Tag for ${pixelDomain} deleted`);
                } else {
                    showErrorToastMessage(`Error occurred: ADX Tag for ${pixelDomain} not deleted`);
                }

                await refreshTable().then(() => {
                    setOpenConfirmDelete(false);
                })

            });

    }

    return (
        <div>
            <LoadOverlay active={overlayActive} height={50} width={50}>

            <div className={"adx-tag-manager-outer-container"}>
                <div className={"adx-grid"}>
                    <ExcelExport ref={_export} fileName={`ADX Tags - ${user.first_name} ${user.last_name} ${getCurrentDateTime()}`}>
                    <Grid
                        data={orderBy(filterBy(data, filter), sort)}
                        filterable={true}
                        filter={filter}
                        onFilterChange={(e) => setFilter(e.filter)}
                        sortable={true}
                        sort={sort}
                        onSortChange={(e) => {
                            setSort(e.sort);
                        }}
                        resizable={true}
                    >
                        <GridToolbar>
                            <div className={"adx-grid-toolbar-container"}>
                                <div className={"adx-grid-toolbar-left"}>
                                    <Button
                                        className={"button-standard button-submit button-no-left-margin"}
                                        iconClass={"icon-plus"}
                                        onClick={() => {
                                            setDialogOpen(true);
                                        }}
                                        disabled={true}
                                    >
                                        New
                                    </Button>
                                </div>
                                <div style={{alignContent: "center"}}>
                                    <Label style={{backgroundColor: "bisque"}}>ADX Tag will be retired soon, no new tags
                                        can be created now. If you have any questions, please contact support.</Label>
                                </div>
                                <div className={"adx-grid-toolbar-right"}>
                                    <Button
                                        className={"button-standard button-white"}
                                        iconClass={"k-icon k-i-download"}
                                        onClick={exportExport}
                                    >
                                        as CSV
                                    </Button>
                                </div>
                            </div>
                        </GridToolbar>

                        <Column field={"domain"} title={"Domain"} minResizableWidth={100} filterTitle={"domain-filter"}/>
                        <Column field={"created_at"} title={"Created"} width={"80px"} filterable={false}/>
                        <Column field={"consumer_segment_name"} title={"Consumer Segment"} width={"210px"} filterable={false}/>
                        <Column field={"consumer_segment_ct"} title={"Homes"} width={"100px"} filterable={false}/>
                        <Column field={"consumer_segment_last_updated"} title={"Consumer Updated"} width={"150px"} filterable={false}/>
                        <Column field={"business_segment_name"} title={"Business Segment"} width={"210px"} filterable={false}/>
                        <Column field={"business_segment_ct"} title={"Businesses"} width={"100px"} filterable={false}/>
                        <Column field={"business_segment_last_updated"} title={"Business Updated"} width={"150px"} filterable={false}/>
                        <Column cell={MyEditCommandCell} filterable={false} width={"50px"}/>
                    </Grid>
                    </ExcelExport>
                </div>
            </div>

                {dialogOpen && (
                    <NewAdxTagDialog dialogOpen={dialogOpen} setDialogOpen={setDialogOpen}
                                     groupsArr={groupsArr} refreshTable={refreshTable} setShowTagCode={setShowTagCode}
                                    setPixelTagId={setPixelTagId} setPixelDomain={setPixelDomain}/>
                )}

                {showTagCode && (
                    <div>
                        <Dialog
                            className={"adx-pixel-dialog dialog-standard"}
                            title={"ADX Site Visitor Tag"}
                            closeIcon={false}
                            width={750}
                        >

                            <div className={"pixel-dialog-text-container"}>
                                <div className={"pixel-dialog-text pixel-dialog-text-1"}>

                                    {`A Consumer and Business segment were created for you that will be updated daily. Counts and Profile Reports are updated as new visitors are added.
                                    This is your ADX Site Visitor Tag. Place it just after the body tag on `} <strong>{`${pixelDomain}`}</strong>{`.`}

                                </div>

                                <div className={"pixel-dialog-text pixel-dialog-text-2"}>
                                    &lt;{`img src="https://udxsva.rtactivate.com/tag?id=${pixelTagId}" alt="" style="display:none !important;"`}&gt;
                                </div>

                                <div className={"pixel-dialog-text pixel-dialog-text-3"}>
                                    {`If you forget your ADX Site Visitor Tag code, click the `} <img src={gearIcon} className={"adx-gear-icon"} /> {`icon in the ADX Tag Manager table.`}
                                </div>
                            </div>

                            <DialogActionsBar>
                                <div className={"adx-pixel-dialog-button-container"}>
                                    <Button
                                        className={"button-standard button-white"}
                                        type={'button'}
                                        disabled={false}
                                        onClick={() => {
                                            closePixelDialog();
                                        }}
                                    >
                                        Ok
                                    </Button>
                                </div>
                            </DialogActionsBar>

                        </Dialog>

                    </div>
                )}

                {openConfirmDelete && (
                    <div>
                        <Dialog
                            className={"adx-pixel-confirm-delete dialog-standard"}
                            title={"Confirm Delete"}
                            closeIcon={false}
                            width={750}
                        >
                            <div className={"pixel-dialog-text-container"}>
                                <div className={"pixel-dialog-text pixel-dialog-text-2"}>
                                    {`Are you sure you want to delete the ADX tag for `} <strong>{`${deletePixelObj.domain}`}</strong> {`?`}
                                </div>
                            </div>
                            <DialogActionsBar>
                                <div className={"adx-dialog-submit-button-div"}>
                                    <Button
                                        className={"button-standard button-white"}
                                        type={'button'}
                                        disabled={false}
                                        onClick={() => {
                                            setOpenConfirmDelete(false);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className={"button-standard button-red"}
                                        type={'button'}
                                        onClick={async () => {
                                            await deletePixel(deletePixelObj.id, deletePixelObj.domain).then((res) => {
                                                console.log("delete pixel res:", res);
                                            })
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </div>
                            </DialogActionsBar>

                        </Dialog>

                    </div>
                )}
            </LoadOverlay>
        </div>
    )
}

export default AdxTagManager;